import React, { useState } from "react";
import PageModal from "../Modal/PageModal";

const InfoIcon = ({ modalContent, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClickFn = () => {
    setIsOpen(true);
  };

  return (
    <div>
      <button className="button is-white icon-text" onClick={onClickFn}>
        <span className="is-size-7">{children}</span>
        <span className="icon">
          <i className="fas fa-info-circle"></i>
        </span>
      </button>

      {isOpen && (
        <PageModal onClose={() => setIsOpen(false)} isOpen={isOpen}>
          {modalContent}
        </PageModal>
      )}
    </div>
  );
};

export default InfoIcon;
