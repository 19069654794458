import React from "react";

import usePlaidLinkWrapper, { LINK_MODE_TYPES } from "./usePlaidLinkWrapper";
import useStateWithLocalStorage, {
  LOCAL_STORAGE_KEYS,
} from "../../services/useStateWithLocalStorage";

const PlaidReconnectWrapper = ({ style, children, token }) => {
  // The Link token from the first Link initialization
  const [, setlinkToken] = useStateWithLocalStorage(
    LOCAL_STORAGE_KEYS.PLAID_LINK_TOKEN
  );
  const [, setLinkMode] = useStateWithLocalStorage(
    LOCAL_STORAGE_KEYS.PLAID_LINK_MODE
  );

  const config = {
    onSuccess: null,
    linkMode: LINK_MODE_TYPES.UPDATE,
    linkToken: token,
  };

  const { open, ready, error } = usePlaidLinkWrapper(config);

  const onOpen = () => {
    // set link token for OAuth flow
    setlinkToken(token);
    setLinkMode(LINK_MODE_TYPES.UPDATE);
    open();
  };

  return (
    <button className={style} onClick={onOpen} disabled={!ready || error}>
      {children}
    </button>
  );
};

export default PlaidReconnectWrapper;
