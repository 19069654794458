import React from "react";

const PremiumSectionHeader = ({ headerText, title, subtitle }) => {
  const classNameSectionHeader =
    "sw-is-x-small is-uppercase has-text-weight-semibold";
  const classNameSectionTitle =
    "sw-title--big has-text-weight-bold sw-padding--top-sm";

  return (
    <div>
      <p className={classNameSectionHeader}>{headerText}</p>
      <h2 className={classNameSectionTitle}>{title}</h2>
      {subtitle && <p className="sw-padding--bottom-lg">{subtitle}</p>}{" "}
    </div>
  );
};

export default PremiumSectionHeader;
