import { setError } from "./errorActions";
import { apiGet, apiPost } from "./apiActions";

import { COURSES_ACTIONS } from "../reducers/courseReducer";

export const asyncBrowseCourses = () => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/courses/browse";

      dispatch({ type: COURSES_ACTIONS.BROWSE });
      const onSuccess = (res) => {
        dispatch({
          type: COURSES_ACTIONS.BROWSE_SUCCESS,
          courses: res.data,
        });
        resolve();
      };

      const onError = (err) => {
        dispatch({ type: COURSES_ACTIONS.ERROR, err });
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiGet(endpoint, onSuccess, onError));
    });
  };
};

export const asyncBrowseSchoolCourses = () => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/courses/schools/browse";

      dispatch({ type: COURSES_ACTIONS.BROWSE });
      const onSuccess = (res) => {
        dispatch({
          type: COURSES_ACTIONS.BROWSE_SUCCESS,
          courses: res.data,
        });
        resolve();
      };

      const onError = (err) => {
        dispatch({ type: COURSES_ACTIONS.ERROR, err });
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiGet(endpoint, onSuccess, onError));
    });
  };
};

export const asyncGetCourseDetail = (cohortUuid) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = `/api/courses/course_detail?cohort_uuid=${cohortUuid}`;

      dispatch({ type: COURSES_ACTIONS.GET });
      const onSuccess = (res) => {
        dispatch({
          type: COURSES_ACTIONS.GET_SUCCESS,
          course: res.data,
        });
        resolve(res.data);
      };

      const onError = (err) => {
        dispatch({ type: COURSES_ACTIONS.ERROR, err });
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiGet(endpoint, onSuccess, onError));
    });
  };
};

export const asyncEnrollCourse = (cohortUuid) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/courses/enroll";
      const data = { cohort_uuid: cohortUuid, should_unenroll: false };

      dispatch({ type: COURSES_ACTIONS.ENROLL });
      const onSuccess = (res) => {
        dispatch({
          type: COURSES_ACTIONS.ENROLL_SUCCESS,
          progress: res.data,
        });
        resolve();
      };

      const onError = (err) => {
        dispatch({ type: COURSES_ACTIONS.ERROR, err });
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};

export const asyncGetTasks = (cohortUuid) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = `/api/courses/tasks?cohort_uuid=${cohortUuid}`;

      dispatch({ type: COURSES_ACTIONS.TASKS });
      const onSuccess = (res) => {
        dispatch({
          type: COURSES_ACTIONS.TASKS_SUCCESS,
          tasks: res.data,
        });
        resolve();
      };

      const onError = (err) => {
        dispatch({ type: COURSES_ACTIONS.ERROR, err });
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiGet(endpoint, onSuccess, onError));
    });
  };
};

export const asyncCompleteTask = (courseTaskUui) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/courses/complete_task";
      const data = {
        course_task_uuid: courseTaskUui,
      };

      // dispatch({ type: COURSES_ACTIONS.TASKS });
      const onSuccess = (res) => {
        // dispatch({
        //   type: COURSES_ACTIONS.TASKS_SUCCESS,
        //   tasks: res.data,
        // });
        resolve(res.data);
      };

      const onError = (err) => {
        // dispatch({ type: COURSES_ACTIONS.ERROR, err });
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};
