import { AUTH_ACTIONS } from "./authReducer";

const initState = {
  aggregates: null,
  recommendations: null,
  recModelType: null,
  recMessages: null,
  recIsVisible: false,
  current: null,
  getError: null,
  getLoading: false,
};

const ACTION_PREFIX = "REC/";

export const RECOMMENDATION_ACTIONS = {
  GET: ACTION_PREFIX + "GET",
  GET_ERROR: ACTION_PREFIX + "GET_ERROR",
  GET_SUCCESS: ACTION_PREFIX + "GET_SUCCESS",
};

const recommendationsReducer = (state = initState, action) => {
  switch (action.type) {
    case RECOMMENDATION_ACTIONS.GET:
      return {
        ...state,
        getError: null,
        getLoading: true,
      };
    case RECOMMENDATION_ACTIONS.GET_ERROR:
      return {
        ...state,
        aggregates: null,
        recommendations: null,
        recMessages: null,
        recModelType: null,
        recIsVisible: false,
        current: null,
        getError: action.err.message,
        getLoading: false,
      };
    case RECOMMENDATION_ACTIONS.GET_SUCCESS:
      const aggregates = action.res.data.recommendations.aggregates;
      const recs = action.res.data.recommendations.recs;
      const current = action.res.data.recommendations.current;
      const recMessages = action.res.data.recommendations.messages;
      const recModelType = action.res.data.recommendations.rec_type;
      const isVisible = action.res.data.recommendations.is_visible;

      return {
        ...state,
        aggregates: aggregates,
        recommendations: recs,
        recMessages: recMessages,
        recModelType: recModelType,
        recIsVisible: isVisible,
        current: current,
        getError: null,
        getLoading: false,
      };
    case AUTH_ACTIONS.SIGNOUT:
      return initState; //Always return the initial state
    default:
      return state;
  }
};

export default recommendationsReducer;
