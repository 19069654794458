import React from "react";
import useGetPostLink from "./useGetPostLink";

const AdminFeedItemEditButton = ({
  className,
  feedItemUuid,
  isHidden,
  onDeletePost,
  onNotifyPost,
  onNotifyEmail,
  onHidePost,
}) => {
  const [copiedDone, onCopyFn] = useGetPostLink(feedItemUuid);

  return (
    <div className={className}>
      <div className="dropdown is-hoverable is-left">
        <div className="dropdown-trigger">
          <button
            className="button is-small is-white"
            aria-haspopup="true"
            aria-controls="loans-dropdown-menu"
          >
            Edit
          </button>
        </div>
        <div className="dropdown-menu" id="loans-dropdown-menu" role="menu">
          <div className="dropdown-content">
            <button
              className="sw-link-button dropdown-item is-hoverable"
              onClick={onCopyFn}
            >
              <span>{copiedDone ? "Copied" : "Copy link to post"}</span>
            </button>

            <button
              className="sw-link-button dropdown-item is-hoverable"
              onClick={onNotifyPost}
            >
              <span>Notify Push</span>
            </button>

            <button
              className="sw-link-button dropdown-item is-hoverable"
              onClick={onNotifyEmail}
            >
              <span>Notify Email</span>
            </button>

            <button
              className="sw-link-button dropdown-item is-hoverable"
              onClick={onHidePost}
            >
              <span>{isHidden ? "Unhide" : "Hide"}</span>
            </button>

            <button
              className="sw-link-button dropdown-item is-hoverable"
              onClick={onDeletePost}
            >
              <span>Delete</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminFeedItemEditButton;
