import { NET_WORTH_GRAPH_ACTIONS } from "../reducers/netWorthGraphReducer";
import { setError } from "./errorActions";
import { apiGet } from "./apiActions";

export const getNetWorthGraphData = () => {
    return (dispatch, getState) => {
        const endpoint = `/api/liabilities/net_worth/graph`;
        dispatch({ type: NET_WORTH_GRAPH_ACTIONS.GET });

        const onSuccess = res => {
            dispatch({
                type: NET_WORTH_GRAPH_ACTIONS.GET_SUCCESS,
                res
            });
        };

        const onError = err => {
            dispatch({
                type: NET_WORTH_GRAPH_ACTIONS.GET_ERROR,
                err
            });
            dispatch(setError(err));
        };

        dispatch(apiGet(endpoint, onSuccess, onError));
    };
};
