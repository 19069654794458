import React from "react";
import { connect } from "react-redux";

import { trackEvent, EVENTS } from "../../store/actions/analyticsActions";
import { BLOG_LINK_URLS } from "../../constants/constants";
import ROUTES from "../../constants/routes";
import GenericBoxLi from "../Box/GenericBox";
import EmojiIcon from "../Icon/EmojiIcon";
import RecIcon from "../Icon/RecIcon";

const MessageIntro = (props) => {
  return (
    <p>You should prioritize paying off any high interest personal debt:</p>
  );
};

const MessageBody = (props) => {
  return (
    <div>
      <ul>
        <li>
          Personal debt can include credit cards,{" "}
          <span role="img" aria-label="Automobile (Car) Emoji">
            🚗
          </span>{" "}
          auto loans, personal loans, or medical loans
        </li>
        <li>
          High interest personal debt is any debt with{" "}
          <strong className="has-text-primary">interest higher than 7%</strong>
        </li>
      </ul>
      <p>
        The next step is to check out resources to help you lower your interest
        and payoff your debt!
      </p>
    </div>
  );
};
const LenderMessage = (props) => {
  const lender = props.lender;
  return (
    <span>
      {lender.content.apr_fixed_min ? (
        <span>
          Fixed APR {lender.content.apr_fixed_min}% -{" "}
          {lender.content.apr_fixed_max}%
        </span>
      ) : (
        "N/A"
      )}
    </span>
  );
};

const getBlogSlugFromUrl = (fullUrl) => {
  if (fullUrl) {
    let arrStrings = fullUrl.split(".com/");
    if (arrStrings.length > 1) {
      return arrStrings[1];
    }
  }

  return fullUrl;
};

const RecDetailsPersonal = (props) => {
  const rec = props.rec;
  let lender = null;
  if (props.lenders && props.lenders.length > 0) {
    lender = props.lenders[0];
  }

  return (
    <div className="sw-container--fixed-width">
      <h1 className="title has-text-centered">
        <RecIcon recType={rec.type}></RecIcon> Your Recommendation
      </h1>
      <div className="content fs-block">
        <MessageIntro rec={rec}></MessageIntro>
        <MessageBody rec={rec}></MessageBody>
      </div>
      <br />
      <ul className="sw-flex-layout--column-center sw-container--fixed-width">
        {lender && (
          <GenericBoxLi
            key={lender.id}
            icon={
              <img
                width="64"
                height="64"
                src={lender.content.icon}
                alt={`Icon for ${lender.name}`}
              ></img>
            }
            title={`Lower your interest rate with ${lender.name}`}
            message={<LenderMessage lender={lender}></LenderMessage>}
            learnMoreRoute={`${ROUTES.LENDER_PROFILE_PREFIX_PERSONAL}${lender.slug}`}
            buttonLink={lender.content.referral_link || lender.content.website}
            buttonOnclick={props.trackEvent(EVENTS.CHECK_RATE_CLICKED, {
              lender: lender.slug,
              full_slug: lender.full_slug,
            })}
            buttonText="Check rate"
          ></GenericBoxLi>
        )}

        <GenericBoxLi
          icon={<EmojiIcon emoji="⚖️" emojiName="Balance Scale"></EmojiIcon>}
          title="Consider a 0% balance transfer card"
          message="Lower your interest for up to 18 months"
          buttonLink={BLOG_LINK_URLS.BALANCE_TRANSFER}
          buttonOnclick={props.trackEvent(EVENTS.BLOG_POST_CLICKED, {
            post: getBlogSlugFromUrl(BLOG_LINK_URLS.BALANCE_TRANSFER),
          })}
        ></GenericBoxLi>

        <GenericBoxLi
          icon={
            <EmojiIcon emoji="👩‍💻" emojiName="Woman Technologist"></EmojiIcon>
          }
          title="Look into starting a side hustle"
          message="Put that extra income towards your debt"
          buttonLink={BLOG_LINK_URLS.SIDE_HUSTLE}
          buttonOnclick={props.trackEvent(EVENTS.BLOG_POST_CLICKED, {
            post: getBlogSlugFromUrl(BLOG_LINK_URLS.BALANCE_TRANSFER),
          })}
        ></GenericBoxLi>
      </ul>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackEvent: (event, properties) => dispatch(trackEvent(event, properties)),
  };
};

export default connect(null, mapDispatchToProps)(RecDetailsPersonal);
