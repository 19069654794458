import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";

import {
  asyncGetAllFeedItems,
  asyncDeletePost,
  asyncNotifyCommunityItemUsers,
  asyncHideItem,
} from "../../store/actions/adminCommunitiesActions";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import AdminFeedList from "../../components/Communities/AdminFeedList";
import FeedPagination from "../../components/Pagination/FeedPagination";
import { setConfirmMessage } from "../../store/actions/alertingActions";

const AdminFeedPage = ({
  asyncGetFeedFn,
  asyncDeletePostFn,
  asyncNotifyCommunityItemUsersFn,
  asyncHideItemFn,
  setConfirmMessageFn,
}) => {
  useEffect(() => {
    asyncGetFeedFn();
  }, [asyncGetFeedFn]);

  const { feedItems, pagination, getFeedLoading, adminError } = useSelector(
    (state) => {
      return state.adminCommunities;
    }
  );

  const getPageContent = (page) => {
    asyncGetFeedFn(page);
  };

  const onDelete = (feedItemUuid) => {
    setConfirmMessageFn(
      "Delete post",
      "Are you sure you want to do this? This action cannot be undone.",
      "Delete",
      () => {
        asyncDeletePostFn(feedItemUuid).then(() => {
          // need to refresh feedItems
          asyncGetFeedFn();
        });
      },
      true
    );
  };

  const onNotify = (feedItemUuid, notification_type) => {
    setConfirmMessageFn(
      "Notify community users",
      "Are you sure you want to notify all users in the community about this post?",
      "Notify",
      () => {
        asyncNotifyCommunityItemUsersFn(feedItemUuid, notification_type);
      },
      false
    );
  };

  const onHideItem = (feedItemUuid, hide) => {
    setConfirmMessageFn(
      "Update Hidden Status",
      "Are you sure you want to update hidden status?",
      "Yes",
      () => {
        asyncHideItemFn(feedItemUuid, hide).then(() => {
          asyncGetFeedFn();
        });
      },
      false
    );
  };

  if (getFeedLoading) {
    return (
      <div className="sw-flex-layout--column-center">
        <LoadingSpinner></LoadingSpinner>
      </div>
    );
  }

  return (
    <div className="fs-block">
      <h2 className="title">Feed Items</h2>
      {adminError && <p className="help is-danger">{adminError}</p>}
      <FeedPagination
        pagination={pagination}
        getPageContent={getPageContent}
      ></FeedPagination>
      {feedItems && (
        <AdminFeedList
          feedItems={feedItems}
          onDelete={onDelete}
          onNotify={onNotify}
          onHideItem={onHideItem}
        ></AdminFeedList>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    asyncGetFeedFn: (page) => dispatch(asyncGetAllFeedItems(page)),
    asyncDeletePostFn: (feedItemUuid) =>
      dispatch(asyncDeletePost(feedItemUuid)),
    asyncNotifyCommunityItemUsersFn: (feedItemUuid, notification_type) =>
      dispatch(asyncNotifyCommunityItemUsers(feedItemUuid, notification_type)),
    asyncHideItemFn: (feedItemUuid, hide) =>
      dispatch(asyncHideItem(feedItemUuid, hide)),
    setConfirmMessageFn: (title, message, confirmText, onConfirm, isDanger) =>
      dispatch(
        setConfirmMessage(title, message, confirmText, onConfirm, isDanger)
      ),
  };
};

export default connect(null, mapDispatchToProps)(AdminFeedPage);
