import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { initNexus, updateBorrower } from "../../store/actions/payitoffActions";

const usePayitoffNexusLink = (payitoffId, userId, onSuccessFn) => {
  const dispatch = useDispatch();

  useEffect(() => {
    let nexusId = payitoffId ? payitoffId : userId;
    dispatch(initNexus(nexusId));
  }, [dispatch, initNexus, payitoffId, userId]);

  const onNexusClick = (e) => {
    window.Nexus.link({
      // the function to call when borrower is done
      onSuccess: function (response) {
        dispatch(updateBorrower(response.result.borrower.uuid));
        if (onSuccessFn) {
          onSuccessFn();
        }
      },
      // the function to call when borrower quits or error occurs
      onExit: function (response) {
        dispatch(updateBorrower(response.result.borrower.uuid));
      },
    });
  };

  return onNexusClick;
};

export default usePayitoffNexusLink;
