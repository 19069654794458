import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { useFormFields } from "../../components/Form/useFormFields";
import {
  asyncEditFeedItem,
  asyncGetFeedItem,
} from "../../store/actions/adminCommunitiesActions";
import { BackLink } from "../../components/Buttons/BackButtons";
import { ADMIN_ROUTES } from "../../constants/routes";

import AdminEditFeedItemForm from "../../components/Communities/AdminEditFeedItemForm";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

const DEFAULT_FIELDS = {
  feedItemUuid: "",
  videoUrl: "",
  pollUuid: "",
};

const AdminEditFeedItemPage = (props) => {
  let { feedItemUuid } = useParams();
  const dispatch = useDispatch();
  const {
    editFeedItemLoading,
    adminError,
    updatedFeedItem,
    feedItem,
    getFeedItemLoading,
  } = useSelector((state) => {
    return state.adminCommunities;
  });

  const [fields, handleFieldChange, setValues] = useFormFields(DEFAULT_FIELDS);

  const videoUrl = feedItem == null ? "" : feedItem.video_url;
  const pollUuid = feedItem == null ? "" : feedItem.poll_uuid;

  useEffect(() => {
    setValues({
      feedItemUuid: feedItemUuid,
      videoUrl: videoUrl,
      pollUuid: pollUuid,
    });
  }, [setValues, updatedFeedItem, feedItem, videoUrl, pollUuid]);

  useEffect(() => {
    dispatch(asyncGetFeedItem(feedItemUuid));
  }, [dispatch, asyncGetFeedItem]);

  const navigate = useNavigate();
  const onSubmitForm = useCallback(
    async (event) => {
      event.preventDefault();
      await dispatch(
        asyncEditFeedItem(fields.feedItemUuid, fields.videoUrl, fields.pollUuid)
      ).then((res) => {
        // navigate back to main
        navigate(ADMIN_ROUTES.FEED_ITEMS);
      });
    },
    [fields, dispatch, navigate]
  );

  if (editFeedItemLoading || getFeedItemLoading) {
    return (
      <div>
        <LoadingSpinner></LoadingSpinner>
      </div>
    );
  }

  return (
    <div className="fs-block">
      <BackLink routeLink={ADMIN_ROUTES.FEED_ITEMS} text={"Go back"}></BackLink>
      <h1 className="title">Edit Feed Item</h1>
      <AdminEditFeedItemForm
        onSubmitForm={onSubmitForm}
        fields={fields}
        handleFieldChange={handleFieldChange}
        submitLoading={editFeedItemLoading}
      />
      <br />
      {adminError && <div className="has-text-danger">Error: {adminError}</div>}
    </div>
  );
};

export default AdminEditFeedItemPage;
