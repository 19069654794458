import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import AdminPollsList from "../../components/Polls/AdminPollsList";
import { getAllPolls } from "../../store/actions/adminPollsActions";

const AdminPollsPage = (props) => {
  const dispatch = useDispatch();
  const { polls, adminError, getPollsLoading } = useSelector((state) => {
    return state.adminPolls;
  });

  useEffect(() => {
    dispatch(getAllPolls());
  }, [dispatch]);

  return (
    <div className="fs-block">
      <h1 className="title">Admin Polls</h1>
      {getPollsLoading && <LoadingSpinner></LoadingSpinner>}
      {polls && <AdminPollsList polls={polls} />}
      {adminError && <p className="help is-danger">Error: {adminError}</p>}
      <br />
      <Link className="button is-primary" to="new">
        Add a poll
      </Link>
    </div>
  );
};

export default AdminPollsPage;
