import React from "react";

import { BackLink } from "../Buttons/BackButtons";
import { NextLink } from "../Buttons/NextButton";
import VideoPlayer from "../Blog/VideoPlayer";
import TaskContent from "./TaskContent";
import Poll from "../Polls/Poll";

import { useCourseRoutes } from "./useCourseRouting";

import ROUTES from "../../constants/routes";
import { TASK_STEP_MAP } from "../../constants/constants";

const TaskWrapper = ({
  course,
  thisTask,
  taskStep,
  onCompleteTask,
  fromTaskContent,
}) => {
  const { getPreviousTask, getNextTask } = useCourseRoutes();

  const PREVIOUS_TASK_ROUTE = getPreviousTask(
    course,
    thisTask,
    taskStep,
    fromTaskContent
  );
  const NEXT_TASK_ROUTE = getNextTask(course, thisTask, taskStep);
  const showNextLink =
    !fromTaskContent &&
    (taskStep === TASK_STEP_MAP.Video || taskStep === TASK_STEP_MAP.Poll);

  return (
    <div>
      <div className="sw-container--fixed-width sw-is-wide sw-padding--bottom-lg">
        <div className="level is-mobile">
          <div className="level-item level-left">
            <BackLink
              routeLink={course ? PREVIOUS_TASK_ROUTE : ROUTES.COURSES}
              text="Back"
            ></BackLink>

            {showNextLink && (
              <div className="level-item level-right">
                <NextLink
                  routeLink={course ? NEXT_TASK_ROUTE : ROUTES.COURSES}
                ></NextLink>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="sw-flex-layout--column-center">
        {taskStep === TASK_STEP_MAP.Content && (
          <TaskContent
            task={thisTask}
            course={course}
            onCompleteTask={onCompleteTask}
          ></TaskContent>
        )}

        {taskStep === TASK_STEP_MAP.Video && (
          <VideoPlayer
            link={thisTask.content.video_url}
            width="100%"
          ></VideoPlayer>
        )}

        {taskStep === TASK_STEP_MAP.Poll && (
          <Poll poll={thisTask.content.poll}></Poll>
        )}
      </div>
    </div>
  );
};

export default TaskWrapper;
