import { ADMIN_CHALLENGE_ACTIONS } from "../reducers/adminChallengesReducer";
import { apiGet, apiPost } from "./apiActions";

export const getChallenge = (challengeId) => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      const endpoint = `/api/admin/challenge/${challengeId}`;

      dispatch({ type: ADMIN_CHALLENGE_ACTIONS.GET_CHALLENGES });

      const onSuccess = (res) => {
        dispatch({
          type: ADMIN_CHALLENGE_ACTIONS.GET_CHALLENGES_SUCCESS,
          res,
        });
        resolve(res);
      };

      const onError = (err) => {
        dispatch({ type: ADMIN_CHALLENGE_ACTIONS.GET_CHALLENGES_ERROR, err });
        reject(err);
      };

      dispatch(apiGet(endpoint, onSuccess, onError));
    });
  };
};

export const updateChallenge = (challengeId, fields) => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      const endpoint = `/api/admin/challenge/${challengeId}`;

      const data = {
        title: fields.title,
        subtitle: fields.subtitle,
        description: fields.description,
        status: fields.goalStatus,
        goal_type: fields.goalType,
        start_date: Math.round(fields.startDate.getTime() / 1000),
        end_date: Math.round(fields.endDate.getTime() / 1000),
        image_url: fields.imageUrl,
        cover_photo_url: fields.coverPhotoUrl,
      };

      if (fields.goalSuggestedAmount) {
        data["goal_suggested_amount"] = fields.goalSuggestedAmount;
      }

      if (fields.communityUuid) {
        data["community_uuid"] = fields.communityUuid;
      }

      if (fields.progressColor) {
        data["progress_color"] = fields.progressColor;
      }

      if (fields.badgeGradientStartColor) {
        data["badge_gradient_start_color"] = fields.badgeGradientStartColor;
      }

      if (fields.badgeGradientEndColor) {
        data["badge_gradient_end_color"] = fields.badgeGradientEndColor;
      }

      dispatch({ type: ADMIN_CHALLENGE_ACTIONS.EDIT_CHALLENGE });
      const onSuccess = (res) => {
        dispatch({
          type: ADMIN_CHALLENGE_ACTIONS.EDIT_CHALLENGE_SUCCESS,
          res,
        });
        resolve(res);
      };

      const onError = (err) => {
        dispatch({ type: ADMIN_CHALLENGE_ACTIONS.EDIT_CHALLENGE_ERROR, err });
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};

export const getAllChallenges = () => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/admin/challenges";

      dispatch({ type: ADMIN_CHALLENGE_ACTIONS.GET_CHALLENGES });

      const onSuccess = (res) => {
        dispatch({
          type: ADMIN_CHALLENGE_ACTIONS.GET_CHALLENGES_SUCCESS,
          res,
        });
        resolve(res);
      };

      const onError = (err) => {
        dispatch({ type: ADMIN_CHALLENGE_ACTIONS.GET_CHALLENGES_ERROR, err });
        reject(err);
      };

      dispatch(apiGet(endpoint, onSuccess, onError));
    });
  };
};

export const createChallenge = (
  title,
  subtitle,
  description,
  challengeStatus,
  goalType,
  startDate,
  endDate,
  imageUrl,
  coverPhotoUrl,
  goalSuggestedAmount,
  progressColor,
  badgeGradientStartColor,
  badgeGradientEndColor,
  communityUuid
) => {
  return (dispatch) => {
    const data = {
      title: title,
      subtitle: subtitle,
      description: description,
      status: challengeStatus,
      goal_type: goalType,
      start_date: startDate,
      end_date: endDate,
      image_url: imageUrl,
      cover_photo_url: coverPhotoUrl,
    };

    if (goalSuggestedAmount) {
      data["goal_suggested_amount"] = goalSuggestedAmount;
    }

    if (communityUuid) {
      data["community_uuid"] = communityUuid;
    }

    if (progressColor) {
      data["progress_color"] = progressColor;
    }

    if (badgeGradientStartColor) {
      data["badge_gradient_start_color"] = badgeGradientStartColor;
    }

    if (badgeGradientEndColor) {
      data["badge_gradient_end_color"] = badgeGradientEndColor;
    }

    return new Promise(function (resolve, reject) {
      const endpoint = "/api/admin/challenges";

      dispatch({ type: ADMIN_CHALLENGE_ACTIONS.CREATE_CHALLENGE });

      const onSuccess = (res) => {
        dispatch({
          type: ADMIN_CHALLENGE_ACTIONS.CREATE_CHALLENGE_SUCCESS,
          payload: { res: res },
        });
        resolve(res);
      };

      const onError = (err) => {
        dispatch({ type: ADMIN_CHALLENGE_ACTIONS.CREATE_CHALLENGE_ERROR, err });
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};
