import React from "react";

import AccountsContainerBox from "../Box/AccountsContainerBox";

import { ICONS } from "../../constants/images";

export const MortgageLoanList = (props) => {
  const displayImg = (
    <img src={ICONS.MORTGAGE_ICON} alt="house" width="40" height="40" />
  );

  return (
    <AccountsContainerBox
      title="Mortgages"
      accounts={props.accounts}
      manualAccounts={props.manualAccounts}
      totalAmount={props.totalAmount}
      isoCurrencyCode={props.isoCurrencyCode}
      displayImg={displayImg}
      isExpandedInit={false}
      sortBy={props.sortBy}
    ></AccountsContainerBox>
  );
};

export const CreditLoanList = (props) => {
  const displayImg = (
    <img src={ICONS.CREDIT_ICON} alt="credit card" width="40" height="40" />
  );

  return (
    <AccountsContainerBox
      title="Credit Cards"
      accounts={props.accounts}
      manualAccounts={props.manualAccounts}
      totalAmount={props.totalAmount}
      isoCurrencyCode={props.isoCurrencyCode}
      displayImg={displayImg}
      isExpandedInit={false}
      sortBy={props.sortBy}
    ></AccountsContainerBox>
  );
};

export const StudentLoanList = (props) => {
  const displayImg = (
    <img
      src={ICONS.STUDENT_LOAN_HAT}
      alt="student loan hat"
      width="40"
      height="40"
    />
  );

  return (
    <AccountsContainerBox
      title="Student Loans"
      accounts={props.accounts}
      manualAccounts={props.manualAccounts}
      totalAmount={props.totalAmount}
      isoCurrencyCode={props.isoCurrencyCode}
      displayImg={displayImg}
      isExpandedInit={false}
      sortBy={props.sortBy}
    ></AccountsContainerBox>
  );
};

export const PaidStudentLoanList = (props) => {
  const displayImg = (
    <img
      src={ICONS.STUDENT_LOAN_HAT}
      alt="student loan hat"
      width="40"
      height="40"
    />
  );
  return (
    <AccountsContainerBox
      title="Student Loans - Paid in Full"
      accounts={props.accounts}
      manualAccounts={props.manualAccounts}
      totalAmount={props.totalAmount}
      isoCurrencyCode={props.isoCurrencyCode}
      displayImg={displayImg}
      isExpandedInit={true}
    ></AccountsContainerBox>
  );
};

export const CashAccountList = (props) => {
  const displayImg = (
    <img src={ICONS.CASH_ICON} alt="bill" width="40" height="40" />
  );
  return (
    <AccountsContainerBox
      title="Cash"
      accounts={props.accounts}
      manualAccounts={props.manualAccounts}
      totalAmount={props.totalAmount}
      isoCurrencyCode={props.isoCurrencyCode}
      displayImg={displayImg}
      isExpandedInit={false}
      sortBy={props.sortBy}
    ></AccountsContainerBox>
  );
};

export const InvestmentAccountList = (props) => {
  const displayImg = (
    <img src={ICONS.CHART_UP} alt="chart up" width="40" height="40" />
  );
  return (
    <AccountsContainerBox
      title="Investments"
      accounts={props.accounts}
      manualAccounts={props.manualAccounts}
      totalAmount={props.totalAmount}
      isoCurrencyCode={props.isoCurrencyCode}
      displayImg={displayImg}
      isExpandedInit={false}
      sortBy={props.sortBy}
    ></AccountsContainerBox>
  );
};

export const OtherAssetAccountsList = (props) => {
  const displayImg = (
    <img src={ICONS.MONEY_GENERIC} alt="chart up" width="40" height="40" />
  );
  return (
    <AccountsContainerBox
      title="Other assets"
      accounts={[]}
      manualAccounts={props.manualAccounts}
      totalAmount={props.totalAmount}
      isoCurrencyCode={props.isoCurrencyCode}
      displayImg={displayImg}
      isExpandedInit={false}
      sortBy={props.sortBy}
    ></AccountsContainerBox>
  );
};

export const OtherDebtsAccountsList = (props) => {
  const displayImg = (
    <img src={ICONS.MONEY_GENERIC} alt="chart up" width="40" height="40" />
  );
  return (
    <AccountsContainerBox
      title="Other debts"
      accounts={props.accounts}
      manualAccounts={props.manualAccounts}
      totalAmount={props.totalAmount}
      isoCurrencyCode={props.isoCurrencyCode}
      displayImg={displayImg}
      isExpandedInit={false}
      sortBy={props.sortBy}
    ></AccountsContainerBox>
  );
};
