import React from "react";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const ContentMarkdown = ({ children }) => {
  return (
    <div className="content">
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{children}</ReactMarkdown>
    </div>
  );
};

export default ContentMarkdown;
