import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useOutletContext } from "react-router-dom";

import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";
import { compareRepaymentOptions } from "../../store/actions/payitoffActions";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import ComparePlansWidget from "../../components/FederalPrograms/ComparePlansWidget";
import ProsConsBox from "../../components/Box/ProsConsBox";
import ExplainerBox from "../../components/Box/ExplainerBox";
import { IDR_PLANS_CONTENT_MAP } from "../../constants/federalPrograms";
// import usePayitoffNexusEnroll from "../../components/PayitoffIntegration/usePayitoffNexusEnroll";
import { IDR_PLANS_OPTIONS } from "../../constants/federalPrograms";

const ComparePlansPage = ({
  compareCurrent,
  compareOptions,
  trackEvent,
  compareRepaymentOptions,
  payitoffId,
  isLoading,
}) => {
  const [optionOneTerm, setOptionOne] = useState(IDR_PLANS_OPTIONS[0]);
  const [optionTwoTerm, setOptionTwo] = useState(IDR_PLANS_OPTIONS[1]);

  const trackEventFn = trackEvent;
  const compareRepaymentOptionsFn = compareRepaymentOptions;
  // const onEnrollClick = usePayitoffNexusEnroll(payitoffId);

  // set page title
  const setPageTitle = useOutletContext();
  useEffect(() => {
    setPageTitle("Compare federal options");
  }, [setPageTitle]);

  useEffect(() => {
    trackEventFn(EVENTS.FEDERAL_COMPARE_VIEWED);
  }, [trackEventFn]);

  useEffect(() => {
    // compareRepaymentOptionsFn();
  }, [compareRepaymentOptionsFn]);

  if (isLoading) {
    return (
      <div className="sw-flex-layout--column-center">
        <LoadingSpinner></LoadingSpinner>
      </div>
    );
  }

  return (
    <>
      <div className="sw-flex-layout--column-center">
        <div className="sw-container--fixed-width sw-is-max-wide sw-padding--bottom-lg">
          {compareCurrent && compareOptions && (
            <ComparePlansWidget
              current={compareCurrent}
              optionsArray={compareOptions}
              optionOneTerm={optionOneTerm}
              optionTwoTerm={optionTwoTerm}
              setOptionOne={setOptionOne}
              setOptionTwo={setOptionTwo}
            />
          )}
          <div className="columns is-multiline">
            <div className="column">
              <ExplainerBox
                title={IDR_PLANS_CONTENT_MAP[optionOneTerm].TITLE}
                content={IDR_PLANS_CONTENT_MAP[optionOneTerm].SUMMARY}
                prosList={IDR_PLANS_CONTENT_MAP[optionOneTerm].PROS}
                consList={IDR_PLANS_CONTENT_MAP[optionOneTerm].CONS}
              ></ExplainerBox>
            </div>
            <div className="column">
              <ProsConsBox
                title={IDR_PLANS_CONTENT_MAP[optionTwoTerm].TITLE}
                content={IDR_PLANS_CONTENT_MAP[optionTwoTerm].SUMMARY}
                prosList={IDR_PLANS_CONTENT_MAP[optionTwoTerm].PROS}
                consList={IDR_PLANS_CONTENT_MAP[optionTwoTerm].CONS}
              ></ProsConsBox>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    compareRepaymentOptions: () => dispatch(compareRepaymentOptions()),
    trackEvent: (event) => dispatch(trackEvent(event)),
  };
};

const mapStateToProps = (state, ownProps) => {
  let payitoffId = state.auth.currentUser.id;
  if (state.auth.currentUser.payitoffId) {
    payitoffId = state.auth.currentUser.payitoffId;
  }

  return {
    isLoading: state.recommendations.getLoading,
    compareCurrent: state.payitoff.compare.current,
    compareOptions: state.payitoff.compare.options,
    payitoffId: payitoffId,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ComparePlansPage);
