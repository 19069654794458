import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import RecIcon from "../Icon/RecIcon";
import { LINK_URLS } from "../../constants/constants";
import { trackEvent, EVENTS } from "../../store/actions/analyticsActions";

const RecBox = (props) => {
  const item = props.item;
  return (
    <article className="media">
      <div className="media-left">
        <RecIcon recType={item.type}></RecIcon>
      </div>
      <div className="media-content">
        <div className="content fs-block">
          <p>
            <strong>{item.title}</strong>
            <br />
            {item.message}
          </p>
        </div>
      </div>
    </article>
  );
};

const RecommendationList = (props) => {
  return (
    <div>
      {props.recs.map((rec, index) => {
        return (
          <Link
            key={rec.id}
            className="sw-recommendation-box box"
            to={`${props.baseRoute}/${rec.category}/${rec.type}`}
          >
            {/* {rec.is_recommended && <RecommendedTag></RecommendedTag>} */}
            <RecBox item={rec}></RecBox>
          </Link>
        );
      })}
      {props.messages.map((message) => {
        if (message.blog_slug) {
          const messageLink = `${LINK_URLS.LANDING_HOME}/${message.blog_slug}`;

          return (
            <a
              key={message.id}
              className="sw-recommendation-box box"
              href={messageLink}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                props.trackEvent(EVENTS.REC_CLICKED, {
                  rec_type: message.type,
                  post: message.blog_slug,
                })
              }
            >
              <RecBox item={message}></RecBox>
            </a>
          );
        } else {
          return (
            <div key={message.id} className="sw-recommendation-box box">
              <RecBox item={message}></RecBox>
            </div>
          );
        }
      })}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackEvent: (eventName, properties) =>
      dispatch(trackEvent(eventName, properties)),
  };
};

export default connect(null, mapDispatchToProps)(RecommendationList);
