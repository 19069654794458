import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams, useOutletContext } from "react-router-dom";

import {
  asyncGetCourseDetail,
  asyncEnrollCourse,
  asyncGetTasks,
} from "../../store/actions/courseActions";
import { BackLink } from "../../components/Buttons/BackButtons";

import usePremiumUpsell from "../../components/FinancialPlan/usePremiumUpsell";
import CourseContent from "../../components/FinancialPlan/CourseContent";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { isInProgress } from "../../components/FinancialPlan/courseHelpers";
import { useCourseRoutes } from "../../components/FinancialPlan/useCourseRouting";

import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";

const CoursePage = () => {
  // set page title
  const setPageTitle = useOutletContext();

  let { cohortUuid } = useParams();
  const { getCoursesRoute } = useCourseRoutes();

  const BROWSE_COURSES_ROUTE = getCoursesRoute();

  const dispatch = useDispatch();

  const { isLoading, course, tasks } = useSelector((state) => {
    return state.courses;
  });

  useEffect(() => {
    setPageTitle("Courses");
  }, [setPageTitle]);

  useEffect(() => {
    if (course) {
      dispatch(
        trackEvent(EVENTS.MY_PLAN_VIEWED, {
          plan_slug: course.course.slug,
          course_type: course.course.course_type,
          cohort_uuid: course.cohort.cohort_uuid,
        })
      );
    }
  }, [dispatch, course]);

  useEffect(() => {
    // first check if we already have course data
    let shouldReload = true;
    if (course) {
      if (course.cohort.cohort_uuid === cohortUuid) {
        shouldReload = false;
      }
    } else if (isLoading) {
      // make sure we don't trigger twice
      shouldReload = false;
    }

    if (cohortUuid && shouldReload) {
      dispatch(asyncGetCourseDetail(cohortUuid)).then((courseData) => {
        if (courseData && isInProgress(courseData)) {
          dispatch(asyncGetTasks(cohortUuid));
        }
      });
    }
  }, [dispatch, course, isLoading, cohortUuid]);

  if (isLoading) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  if (!course) {
    return (
      <div>
        <div className="sw-container--fixed-width sw-is-wide sw-padding--bottom-lg">
          <div className="level is-mobile">
            <div className="level-item level-left">
              <BackLink routeLink={BROWSE_COURSES_ROUTE} text="Back"></BackLink>
            </div>
          </div>
        </div>
        <div className="sw-flex-layout--column-center">
          <h2>Course not found</h2>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="sw-container--fixed-width sw-is-wide sw-padding--bottom-lg">
        <div className="level is-mobile">
          <div className="level-item level-left">
            <BackLink routeLink={BROWSE_COURSES_ROUTE} text="Back" />
          </div>
        </div>
      </div>
      <JoinCourseWrapper course={course} tasks={tasks} />
    </div>
  );
};

const JoinCourseWrapper = ({ course, tasks }) => {
  const dispatch = useDispatch();
  const courseInProgress = isInProgress(course);
  const { getCourseRoute } = useCourseRoutes();

  const [searchParams] = useSearchParams();
  const shouldEnroll = searchParams.get("shouldEnroll") === "true";

  const courseOverview = course.content.course_overview;
  const hasFreeContent = courseOverview.has_free_content;
  const COURSE_ROUTE = getCourseRoute(course);

  // event metadata to send if click premium button
  const eventMetadata = {
    plan_slug: course.course.slug,
    course_type: course.course.course_type,
    cohort_uuid: course.cohort.cohort_uuid,
  };
  const triggerPremiumUpsell = usePremiumUpsell(COURSE_ROUTE, eventMetadata);

  const { hasPremium } = useSelector((state) => {
    return state.auth;
  });

  const onJoinCourse = useCallback(
    async (event) => {
      const cohortUuid = course.cohort.cohort_uuid;
      if (event) {
        event.preventDefault();
      }
      dispatch(asyncEnrollCourse(cohortUuid)).then(() => {
        // get tasks now that we're enrolled
        dispatch(asyncGetTasks(cohortUuid));
      });
      dispatch(
        trackEvent(EVENTS.PLAN_JOINED, {
          plan_slug: course.course.slug,
          course_type: course.course.course_type,
          cohort_uuid: course.cohort.cohort_uuid,
        })
      );
    },
    [dispatch, course]
  );

  const handleJoinCourse = useCallback(
    (event) => {
      if (hasFreeContent || hasPremium) {
        onJoinCourse(event);
      } else {
        triggerPremiumUpsell();
      }
    },
    [onJoinCourse, triggerPremiumUpsell, hasFreeContent, hasPremium]
  );

  useEffect(() => {
    if (shouldEnroll && !courseInProgress) {
      handleJoinCourse(null);
    }
  }, [shouldEnroll, handleJoinCourse, courseInProgress]);

  return (
    <CourseContent
      course={course}
      tasks={tasks}
      onClickEnroll={handleJoinCourse}
    />
  );
};

export default CoursePage;
