import React from "react";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import SignInForm from "../../components/Form/SignInForm";
import ROUTES from "../../constants/routes";
import { LINK_URLS } from "../../constants/constants";
import { GRAPHICS } from "../../constants/images";
import helpers from "../../services/helpers";

const LoginPage = ({ isLoggedIn }) => {
  let location = useLocation();
  const navigate = useNavigate();

  // Send them back to the page they tried to visit when they were
  // redirected to the login page. Use { replace: true } so we don't create
  // another entry in the history stack for the login page.  This means that
  // when they get to the protected page and click the back button, they
  // won't end up back on the login page, which is also really nice for the
  // user experience.
  let nextRoute = helpers.getNextRoute(location, ROUTES.HOME);
  if (isLoggedIn) return <Navigate to={nextRoute} replace />;

  const onClickCreateAccount = () => {
    // Redirect them to the /signup page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they sign up, which is a nicer user experience
    // than dropping them off on the home page.
    const fromState = location.state?.from || location;
    return navigate(ROUTES.SIGN_UP, { state: { from: fromState } });
  };

  return (
    <div className="sw-flex-layout--column-center">
      <div className="box box--signedout">
        <div className="sw-flex-layout--column-center">
          <div className="sw-padding--bottom-lg">
            <h1 className="title is-size-5-mobile has-text-centered">
              Welcome to{" "}
              <span className="has-text-primary">Snowball Wealth</span>
            </h1>
            <SignInForm showGoogleButton={false} />
          </div>
          <p className="has-text-centered help">
            <Link to={ROUTES.PASSWORD_FORGET}>Forgot your password?</Link>
          </p>
        </div>
      </div>
      <p className="has-text-centered level is-mobile">
        <span className="level-left">Don't have an account yet? </span>
        <button
          className="level-right button is-ghost"
          onClick={onClickCreateAccount}
        >
          Create account
        </button>
      </p>
      <div className="has-text-centered sw-padding--top-lg">
        <p className="sw-is-x-small">Get the app.</p>
        <div className="sw-padding--top-md">
          <a
            aria-label="Download on the App Store"
            href={LINK_URLS.APP_STORE_LINK}
            tabIndex="0"
          >
            <img
              height="40"
              alt="Download on the App Store"
              className=""
              src={GRAPHICS.APP_STORE_BADGE}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  // Note: Here, we want to wait for auth.isAuth, to prevent logging in before we get user
  const isLoggedIn =
    state.firebase.auth && state.firebase.auth.uid && state.auth.isAuth;

  return {
    isLoggedIn: isLoggedIn,
  };
};

export default connect(mapStateToProps)(LoginPage);
