import React, { useState } from "react";
import { Routes, Route, Outlet } from "react-router-dom";

// components and helpers
import DefaultLayout from "../PageLayouts/DefaultLayout";
import RequireAuth from "../RouteWrappers/RequireAuth";
import ROUTES from "../../constants/routes";
import { REC_CATEGORIES, TASK_STEP_MAP } from "../../constants/constants";

// not found page
import NotFoundPage from "../../pages/NotFoundPage/NotFoundPage";

// redirect page
import RedirectPage from "../../pages/RedirectPage/RedirectPage";

// signed in app pages
import NetWorthPage from "../../pages/AccountsPages/NetWorthPage";
import LiabilityAccountPage from "../../pages/AccountsPages/LiabilityAccountPage";
import FederalProgramsMainPage from "../../pages/FederalProgramsPages/FederalProgramsMainPage";
import ComparePlansPage from "../../pages/FederalProgramsPages/ComparePlansPage";
import ProfilePage from "../../pages/ProfilePage/ProfilePage";
import LinkedAccountsPage from "../../pages/ProfilePage/LinkedAccountsPage";
import StudentDebtRecPage from "../../pages/RecommendationsPage/RecPageStudentDebt";
import PersonalDebtRecPage from "../../pages/RecommendationsPage/RecPagePersonalDebt";
import LendersStudentMarketplace from "../../pages/LendersPage/LendersStudentMarketplace";
import LenderStudentProfilePage from "../../pages/LendersPage/LenderStudentProfilePage";
import LenderPersonalProfilePage from "../../pages/LendersPage/LenderPersonalProfilePage";
import RefiStepsPage from "../../pages/LendersPage/RefiStepsPage";
// import RecPaymentPage from "../../pages/RecommendationsPage/RecPaymentPage"; // not using for now
import StudentLoansDashboardPage from "../../pages/StudentLoansPages/StudentLoansDashboardPage";
import ValuesPage from "../../pages/ValuesPage/ValuesPage";
import ResourcesPage from "../../pages/ResourcesPage/ResourcesPage";
import PremiumPage from "../../pages/PremiumPages/PremiumPage";
import PremiumPricingPage from "../../pages/PremiumPages/PremiumPricing";
import PremiumWaitlist from "../../pages/PremiumPages/PremiumWaitlist";

// social pages
import ChallengesPage from "../../pages/ChallengesPages/ChallengesPage";
import ChallengePage from "../../pages/ChallengesPages/ChallengePage";
import FeedsPage from "../../pages/CommunityPages/FeedsPage";
import PostPage from "../../pages/CommunityPages/PostPage";

// financial plan pages
import BrowseCoursesPage from "../../pages/FinancialPlanPages/BrowseCoursesPage";
import CoursePage from "../../pages/FinancialPlanPages/CoursePage";
import TaskPage from "../../pages/FinancialPlanPages/TaskPage";

// oauth page for finishing Plaid OAuth flow
import PlaidOAuthPage from "../../pages/PlaidOAuthPage/PlaidOAuthPage";

const SignedInWrapper = () => {
  const [pageTitle, setPageTitle] = useState("");

  return (
    <RequireAuth>
      <DefaultLayout
        pageTitle={pageTitle}
        hideAllNav={false}
        hideSideMenu={false}
      >
        <Outlet context={setPageTitle} />
      </DefaultLayout>
    </RequireAuth>
  );
};

const SignedInPremiumWrapper = () => {
  const [pageTitle, setPageTitle] = useState("");

  return (
    <RequireAuth>
      <DefaultLayout
        pageTitle={pageTitle}
        hideAllNav={false}
        hideSideMenu={false}
        topNavTransparent={true}
      >
        <Outlet context={setPageTitle} />
      </DefaultLayout>
    </RequireAuth>
  );
};

const RoutesSignedIn = () => {
  return (
    <Routes>
      <Route path="/" element={<SignedInPremiumWrapper />}>
        <Route path={ROUTES.PREMIUM} element={<PremiumPage />} />
        <Route path={ROUTES.PREMIUM_PRICING} element={<PremiumPricingPage />} />
        <Route path={ROUTES.PREMIUM_WAITLIST} element={<PremiumWaitlist />} />
      </Route>

      <Route path="/" element={<SignedInWrapper />}>
        <Route index element={<FeedsPage />} />

        <Route path={ROUTES.NET_WORTH} element={<NetWorthPage />} />
        <Route path={`${ROUTES.NET_WORTH}/:tab`} element={<NetWorthPage />} />

        <Route
          path="account/:subtype/:loanId"
          element={<LiabilityAccountPage />}
        ></Route>

        <Route path={ROUTES.CHALLENGES} element={<ChallengesPage />} />
        <Route
          path={`${ROUTES.CHALLENGE_PREFIX}:challengeId`}
          element={<ChallengePage />}
        />

        <Route path={ROUTES.COMMUNITY} element={<FeedsPage />} />
        <Route
          path={`${ROUTES.COMMUNITY_POST}/:feedItemUuid`}
          element={<PostPage />}
        />

        <Route path={ROUTES.COURSES} element={<BrowseCoursesPage />} />
        <Route
          path={`${ROUTES.COURSES}/:cohortUuid`}
          exact
          element={<CoursePage />}
        />
        <Route
          path={`${ROUTES.COURSES}/:cohortUuid/:taskUuid`}
          exact
          element={<TaskPage taskStep={TASK_STEP_MAP.Content} />}
        />
        <Route
          path={`${ROUTES.COURSE_VIDEO}/:cohortUuid/:taskUuid`}
          exact
          element={<TaskPage taskStep={TASK_STEP_MAP.Video} />}
        />
        <Route
          path={`${ROUTES.COURSE_POLL}/:cohortUuid/:taskUuid`}
          exact
          element={<TaskPage taskStep={TASK_STEP_MAP.Poll} />}
        />

        <Route
          path={ROUTES.STUDENT_LOAN_DASHBOARD}
          element={<StudentLoansDashboardPage />}
        />

        <Route
          path={`${ROUTES.RECOMMENDATION}/${REC_CATEGORIES.STUDENT}/:recType`}
          element={<StudentDebtRecPage />}
        />

        <Route
          path={`${ROUTES.RECOMMENDATION}/${REC_CATEGORIES.PERSONAL}/:recType`}
          element={<PersonalDebtRecPage />}
        />

        <Route
          path={ROUTES.FEDERAL_PROGRAMS}
          element={<FederalProgramsMainPage />}
        />

        <Route
          path={ROUTES.FEDERAL_PROGRAMS_COMPARE}
          element={<ComparePlansPage />}
        />

        <Route
          path={ROUTES.LENDERS_STUDENT_MARKETPLACE}
          element={<LendersStudentMarketplace />}
        />

        <Route path={ROUTES.STUDENT_REFI_STEPS} element={<RefiStepsPage />} />

        <Route exact path={ROUTES.VALUES} element={<ValuesPage />} />
        <Route path={ROUTES.RESOURCES} element={<ResourcesPage />} />

        <Route
          path={`${ROUTES.LENDER_PROFILE_PREFIX_STUDENT}:slug`}
          element={<LenderStudentProfilePage />}
        />

        <Route
          path={`${ROUTES.LENDER_PROFILE_PREFIX_PERSONAL}:slug`}
          element={<LenderPersonalProfilePage />}
        />

        <Route path={ROUTES.PROFILE} element={<ProfilePage />} />
        <Route
          path={ROUTES.PROFILE_ACCOUNTS}
          element={<LinkedAccountsPage />}
        />

        <Route path={ROUTES.PLAID_OAUTH} element={<PlaidOAuthPage />}></Route>

        <Route
          path={`${ROUTES.APP_REDIRECT}/*`}
          element={<RedirectPage />}
        ></Route>

        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default RoutesSignedIn;
