import React from "react";
import { connect } from "react-redux";

import SbImage from "../Storyblok/SbImage";
import { trackEvent, EVENTS } from "../../store/actions/analyticsActions";
import { LINK_URLS } from "../../constants/constants";

const BlogPreview = (props) => {
  let blogPost = props.blog;
  const blogSlug = blogPost.full_slug;
  const postLink = `${LINK_URLS.LANDING_HOME}/${blogSlug}`;
  let image = {
    image: blogPost.content.image_home || blogPost.content.image,
  };

  return (
    <div className="tile is-child sw-blog-preview">
      <a
        className="content"
        // target="_blank"
        // rel="noopener noreferrer"
        href={postLink}
        onClick={() =>
          props.trackEvent(EVENTS.BLOG_POST_CLICKED, { post: blogSlug })
        }
      >
        <SbImage blok={image}></SbImage>
        <div className="is-size-6-desktop sw-blog-subtitle">
          {blogPost.content.title}
        </div>
      </a>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackEvent: (eventName, properties) =>
      dispatch(trackEvent(eventName, properties)),
  };
};

export default connect(null, mapDispatchToProps)(BlogPreview);
