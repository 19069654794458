import { AUTH_ACTIONS } from "./authReducer";

const initState = {
  courses: null,
  getCoursesLoading: false,
  updateCourseLoading: false,
  adminError: null,
};

const ADMIN_PREFIX = "ADMIN/";

export const ADMIN_COURSES_ACTIONS = {
  GET_COURSES: ADMIN_PREFIX + "GET_COURSES",
  GET_COURSES_SUCCESS: ADMIN_PREFIX + "GET_COURSES_SUCCESS",
  GET_COURSES_ERROR: ADMIN_PREFIX + "GET_COURSES_ERROR",
  UPDATE_COURSE: ADMIN_PREFIX + "UPDATE_COURSE",
  UPDATE_COURSE_SUCCESS: ADMIN_PREFIX + "UPDATE_COURSE_SUCCESS",
  UPDATE_COURSE_ERROR: ADMIN_PREFIX + "UPDATE_COURSE_ERROR",
};

const adminCoursesReducer = (state = initState, action) => {
  switch (action.type) {
    case ADMIN_COURSES_ACTIONS.GET_COURSES:
      return {
        ...state,
        adminError: null,
        courses: null,
        getCoursesLoading: true,
      };
    case ADMIN_COURSES_ACTIONS.GET_COURSES_SUCCESS:
      return {
        ...state,
        courses: action.res.data.courses,
        getCoursesLoading: false,
      };
    case ADMIN_COURSES_ACTIONS.GET_COURSES_ERROR:
      return {
        ...state,
        adminError: action.err.message,
        getCoursesLoading: false,
      };
    case ADMIN_COURSES_ACTIONS.UPDATE_COURSE:
      return {
        ...state,
        adminError: null,
        updateCourseLoading: true,
      };
    case ADMIN_COURSES_ACTIONS.UPDATE_COURSE_SUCCESS:
      const isNew = action.res.data.is_new;
      const courseFromResponse = action.res.data.course;

      // if new, we'll refetch courses anyway
      if (isNew) {
        return {
          ...state,
          updateCourseLoading: false,
        };
      }

      // if not new, make sure to update existing course array
      const updatedCourses = state.courses.map((existingCourse) =>
        existingCourse.course_uuid === courseFromResponse.course_uuid
          ? courseFromResponse
          : existingCourse
      );

      return {
        ...state,
        courses: updatedCourses,
        updateCourseLoading: false,
      };

    case ADMIN_COURSES_ACTIONS.UPDATE_COURSE_ERROR:
      return {
        ...state,
        adminError: action.err.message,
        updateCourseLoading: false,
      };

    case AUTH_ACTIONS.SIGNOUT:
      return initState; //Always return the initial state
    default:
      return state;
  }
};

export default adminCoursesReducer;
