import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, Navigate } from "react-router-dom";

import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";
import { PREMIUM } from "../../constants/constants";
import { PREMIUM_ICONS } from "../../constants/images";
import ROUTES from "../../constants/routes";

import ScheduleCallBox from "../../components/Premium/ScheduleCallBox";
import PremiumSectionHeader from "../../components/Premium/PremiumSectionHeaders";
import ImageButtonBox from "../../components/Box/ImageButtonBox";

const PremiumPage = () => {
  const [hasCoaching, setHasCoaching] = useState(false);

  // set page title
  const setPageTitle = useOutletContext();
  useEffect(() => {
    setPageTitle("Premium");
  }, [setPageTitle]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(trackEvent(EVENTS.PREMIUM_VIEWED));
  }, [dispatch]);

  const { currentUser, hasPremium } = useSelector((state) => {
    return state.auth;
  });

  useEffect(() => {
    // check Stripe first, to see if has coaching plan
    const subscriptionName = currentUser.subscription_name;
    if (subscriptionName) {
      const lowerSubscriptionName = subscriptionName.toLowerCase();
      // check if subscription has digital, only non-coaching plan
      if (lowerSubscriptionName.includes("digital")) {
        setHasCoaching(false);
      } else {
        setHasCoaching(true);
      }
    }

    // check IAP
    if (currentUser.has_active_iap_subscription) {
      if (
        currentUser.iap_subscriptions &&
        currentUser.iap_subscriptions.length > 0
      ) {
        // check first subscription and see if it's digial
        const firstIAP = currentUser.iap_subscriptions[0];
        if (firstIAP && firstIAP.entitlement_id) {
          if (firstIAP.entitlement_id === "entitlement_id") {
            setHasCoaching(false);
          } else {
            setHasCoaching(true);
          }
        }
      }
    }
  }, [currentUser]);

  if (!hasPremium) {
    return <Navigate to={ROUTES.HOME} />;
  }

  return (
    <div className="sw-flex-layout--column-center">
      <div className="sw-container--fixed-width sw-is-max-wide columns">
        <div className="sw-container--fixed-width sw-is-wide column">
          {/* {hasCoaching && (
            <div className="sw-padding--bottom-xl">
              <PremiumSectionHeader
                headerText="Financial coaching"
                title="1:1 sessions with your money coach"
                subtitle="Join a call with your coach to get a personalized plan and keep in touch via text."
              />
              <ScheduleCallBox
                imgUrl={PREMIUM.ANNA_IMG}
                title="Anna Paul, CFP"
                subtitle="8 years of experience"
                text="Anna is an Certified Financial Planner, but her focus is more than just money. She wants you to create balance in your life and build positive financial behaviors."
                buttonText="Schedule"
                calendlyUrl={PREMIUM.CALENDLY_LINK}
              />
            </div>
          )} */}

          <div className="sw-padding--bottom-xl">
            <PremiumSectionHeader
              headerText="Financial tools"
              title="Member-only resources"
              subtitle="Use these templates to keep track of progress on your money journey."
            />
            <ImageButtonBox
              iconSrc={PREMIUM_ICONS.budgetTemplate}
              iconAlt="spreadsheet"
              title="Premium budget template"
              text="Not your typical budget template. This workbook tracks your spending, net worth, and more."
              buttonText="Open"
              openLink={PREMIUM.BUDGET_TEMPLATE}
            />
            <ImageButtonBox
              iconSrc={PREMIUM_ICONS.roadmap}
              iconAlt="spreadsheet"
              title="Financial roadmap"
              text="This roadmap lets you customize your own pathway to generational wealth."
              buttonText="Open"
              openLink={PREMIUM.ROADMAP}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PremiumPage;
