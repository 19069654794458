import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useOutletContext } from "react-router-dom";

import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";
import {
  VIDEO_LINKS,
  LINK_URLS,
  NOTION_LINKS_URLS,
  RESOURCES_LINKS,
} from "../../constants/constants";
import { getHomeBlogs, getVideoLinks } from "../../store/actions/blogActions";
import BlogPreview from "../../components/Blog/BlogPreview";
import VideoPlayer from "../../components/Blog/VideoPlayer";
import GuidePreview from "../../components/Blog/GuidePreview";
import LinkPreview from "../../components/Blog/LinkPreview";
import { GUIDES, STORYBLOK_IMGS } from "../../constants/images";

const ResourcesPage = ({
  trackEventFn,
  getHomeBlogsFn,
  getVideoLinksFn,
  videos,
  blogs,
}) => {
  useEffect(() => {
    trackEventFn(EVENTS.RESOURCES_VIEWED);
    getHomeBlogsFn();
    getVideoLinksFn();
  }, [trackEventFn, getHomeBlogsFn, getVideoLinksFn]);

  const setPageTitle = useOutletContext();
  useEffect(() => {
    setPageTitle("Resources");
  }, [setPageTitle]);

  return (
    <div className="sw-flex-layout--column-center">
      <div className="sw-container--fixed-width sw-is-wide">
        <h2 className="subtitle sw-padding--top-md">Videos</h2>
        <div className="columns is-mobile is-multiline sw-padding--bottom-sm">
          {videos.map((video, index) => {
            return (
              <div
                className="column is-half-desktop is-half-tablet is-full-mobile"
                key={video.id}
              >
                <VideoPlayer
                  link={video.link}
                  className="sw-react-player"
                  width="100%"
                  height="100%"
                ></VideoPlayer>
              </div>
            );
          })}
        </div>
        <a
          className="help sw-padding--bottom-lg"
          href={`${VIDEO_LINKS.WATCH_MORE}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => trackEventFn(EVENTS.VIDEO_MORE_CLICKED)}
        >
          Watch more
        </a>

        <h2 className="subtitle sw-padding--top-md">Blogs</h2>
        {blogs && (
          <div className="columns is-mobile is-multiline sw-padding--bottom-sm">
            {blogs.map((blog, index) => {
              return (
                <div
                  className="column is-half-desktop is-half-tablet is-full-mobile"
                  key={blog.uuid}
                >
                  <BlogPreview blog={blog}></BlogPreview>
                </div>
              );
            })}
          </div>
        )}
        <a
          className="help sw-padding--bottom-lg"
          href={`${LINK_URLS.BLOG_HOME}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => this.props.trackEvent(EVENTS.BLOG_MORE_CLICKED)}
        >
          Read more
        </a>

        <h2 className="subtitle sw-padding--top-md">Ultimate Guides</h2>
        <p className="subtitle">
          Go deeper, and become an expert in different areas
        </p>
        <div className="columns is-mobile is-multiline sw-padding--bottom-md">
          <div className="column is-half-desktop is-half-tablet is-full-mobile">
            <GuidePreview
              title="New Graduates Guide to Student Debt"
              link={NOTION_LINKS_URLS.NEW_GRAD_GUIDE}
              image={GUIDES.GUIDE_NEW_GRAD}
            ></GuidePreview>
          </div>
          <div className="column is-half-desktop is-half-tablet is-full-mobile">
            <GuidePreview
              title="Guide to Student Loan Refinancing"
              link={NOTION_LINKS_URLS.REFI_GUIDE}
              image={GUIDES.GUIDE_REIF}
            ></GuidePreview>
          </div>
        </div>

        <h2 className="subtitle sw-padding--top-md">Finance Templates</h2>
        <p className="subtitle">
          Get your finances in check with these templates
        </p>
        <div className="columns is-mobile is-multiline sw-padding--bottom-md">
          <div className="column is-half-desktop is-half-tablet is-full-mobile">
            <LinkPreview
              title="This budget template will help you know where your money is going month-to-month."
              link={RESOURCES_LINKS.BUDGET_TEMPLATE}
              image={STORYBLOK_IMGS.BUDGET_TEMPLATE}
              imageAlt="Person looking at computer screen with charts"
              name="Budget template"
            ></LinkPreview>
          </div>
          <div className="column is-half-desktop is-half-tablet is-full-mobile">
            <LinkPreview
              title="Thinking of buying a home? This debt to income calculator can help."
              link={RESOURCES_LINKS.DEBT_TO_INCOME_RATIO}
              image={STORYBLOK_IMGS.DEBT_INCOME_RATIO}
              imageAlt="Person holding bills"
              name="Debt to income ratio"
            ></LinkPreview>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackEventFn: (event, properties) =>
      dispatch(trackEvent(event, properties)),
    getHomeBlogsFn: () => dispatch(getHomeBlogs()),
    getVideoLinksFn: () => dispatch(getVideoLinks()),
  };
};
const mapStateToProps = (state) => {
  return {
    blogs: state.blog.blogs,
    videos: state.blog.videos,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourcesPage);
