const ROUTES = {
  APP_REDIRECT: "/app-redirect",
  SCHOOLS_HOME: "/schools/courses",
  HOME: "/community",
  ACCOUNT: "/account",
  CHALLENGES: "/challenges",
  CHALLENGE_PREFIX: "/challenges/",
  NET_WORTH: "/accounts",
  FEDERAL_PROGRAMS: "/federal",
  FEDERAL_PROGRAMS_COMPARE: "/federal/compare",
  COURSES: "/courses",
  COURSE_VIDEO: "/courses/video",
  COURSE_POLL: "/courses/poll",
  LENDERS_STUDENT_MARKETPLACE: "/student/marketplace",
  LENDER_PROFILE_PREFIX_STUDENT: "/student/lenders/",
  LENDER_PROFILE_PREFIX_PERSONAL: "/personal/lenders/",
  STUDENT_REFI_STEPS: "/student/refinancing",
  STUDENT_LOAN_DASHBOARD: "/student-loans",
  ONBOARDING: "/onboarding",
  STUDENT_ONBOARDING: "/onboarding/step/1",
  PASSWORD_FORGET: "/password_forget",
  PLAID_OAUTH: "/plaid-oauth",
  PREMIUM: "/premium",
  PREMIUM_PRICING: "/premium/pricing",
  PREMIUM_WAITLIST: "/premium/waitlist",
  PROFILE: "/profile",
  PROFILE_ACCOUNTS: "/profile/accounts",
  RECOMMENDATION: "/recommendation",
  RESOURCES: "/resources",
  COMMUNITY: "/community",
  COMMUNITY_POST: "/community_item",
  SIGN_IN: "/signin",
  SIGN_UP: "/signup",
  SCHOOL_LOG_IN: "/schools/login",
  SIGN_OUT: "/logout",
  VALUES: "/values",
};

export const ADMIN_ROUTES = {
  HOME: "/admin",
  CHALLENGES: "/admin/challenges",
  COMMUNITIES: "/admin/communities",
  COURSES: "/admin/courses",
  FEED_ITEMS: "/admin/feed",
  POLLS: "/admin/polls",
  ALL_USERS: "/admin/users",
  USER_PREFIX: "/admin/user/",
  TOOLS: "/admin/tools",
};

export const ADMIN_USER_PAGES = {
  COMMUNITY: "community",
  REC: "rec",
  FINANCIAL_PLAN: "financial_plan",
  PLAID_ITEMS: "plaid",
  USER_MANAGE: "user_manage",
  IAP: "iap",
  NET_WORTH: "net_worth",
};

export const ONBOARDING_PAGES = {
  sync: "sync", // deprecated
  payment: "payment", // deprecated
  // new flow
  OVERVIEW: "/onboarding/overview",
  STEP_ONE: "/onboarding/step/1",
  STEP_TWO: "/onboarding/step/2",
  STEP_THREE: "/onboarding/step/3",
  DONE: "/onboarding/done",
  PREMIUM: "/onboarding/premium",
  // update flow
  UPDATE_PROFILE: `/onboarding/step/1?mode=update_profile`,
};

export default ROUTES;
