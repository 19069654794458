import React from "react";
import { connect } from "react-redux";

import { trackEvent, EVENTS } from "../../store/actions/analyticsActions";
import { toggleIntercomVisibility } from "../../store/actions/analyticsActions";

const StickyBanner = ({
  text,
  link,
  trackEventFn,
  toggleIntercomVisibilityFn,
}) => {
  // this handles hiding the intercom chat when the banner is open
  // useEffect(() => {
  //   toggleIntercomVisibilityFn(true);

  //   return function cleanup() {
  //     toggleIntercomVisibilityFn(false);
  //   };
  // }, [toggleIntercomVisibilityFn]);

  const onButtonClick = () => {
    trackEventFn(EVENTS.STICKY_BANNER_CLICKED, { link: link });
    window.open(link, "_blank"); // open link in new page
  };

  return (
    <button className="sw-sticky-banner" onClick={onButtonClick}>
      <span className="level">
        {text}
        <span className="sw-padding--left-md icon is-small">
          <i
            key="fas fa-arrow-right"
            className="fas fa-arrow-right"
            aria-hidden="false"
          ></i>
        </span>
      </span>
    </button>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackEventFn: (event, properties) =>
      dispatch(trackEvent(event, properties)),
    toggleIntercomVisibilityFn: (hideLauncher) =>
      dispatch(toggleIntercomVisibility(hideLauncher)),
  };
};

export default connect(null, mapDispatchToProps)(StickyBanner);
