import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import ROUTES from "../../constants/routes";
import { setIAPConfirmMessage } from "../../store/actions/alertingActions";
import { Link } from "react-router-dom";

import {
  getPremiumPortal,
  getPricingPlans,
} from "../../store/actions/premiumActions";

const BillingInfo = ({ setIAPConfirmMessageFn }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPricingPlans());
  }, [dispatch]);

  const { currentUser, hasPremium } = useSelector((state) => {
    return state.auth;
  });

  const onManagePremium = () => {
    if (currentUser.has_active_iap_subscription) {
      setIAPConfirmMessageFn();
    } else {
      dispatch(getPremiumPortal(ROUTES.PROFILE)).then((res) => {
        if (res) {
          // similar behavior as an HTTP redirect
          window.location.replace(res);
        }
      });
    }
  };

  return (
    <div>
      <h3 className="subtitle">Your subscription</h3>
      {!hasPremium && (
        <Link className="button is-rounded" to={ROUTES.PREMIUM_PRICING}>
          Learn more about Snowball Premium
        </Link>
      )}
      {/* {!hasPremium && (
        <a
          className="button is-rounded"
          href="https://premium.snowballwealth.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more about Snowball Premium
        </a>
      )} */}
      {hasPremium && (
        <div>
          <p className="sw-padding--bottom-lg">
            {currentUser.has_active_iap_subscription
              ? "Snowball Premium (via iOS App Store)"
              : currentUser.subscription_name}
          </p>
          <button className="button is-rounded" onClick={onManagePremium}>
            Manage subscription
          </button>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIAPConfirmMessageFn: () => dispatch(setIAPConfirmMessage()),
  };
};

export default connect(null, mapDispatchToProps)(BillingInfo);
