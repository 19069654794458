import React, { useState, useEffect, useRef, useCallback } from "react";

import { connect } from "react-redux";

// import { RecaptchaVerifier } from "firebase/auth";
// import { auth } from "firebase";

import { FormFieldError } from "./FormErrors.jsx";
import { useFormFields } from "./useFormFields";
import ContinueWithGoogle from "../Buttons/ContinueWithGoogle.jsx";

import {
  signIn,
  clearForm,
  signInGoogle,
} from "../../store/actions/authActions";

const FIELD_ERRORS = {
  email: "Email is required.",
  password: "Password is required.",
};

const Form = ({
  signInFn,
  signInGoogleFn,
  addLoading,
  authError,
  showGoogleButton,
}) => {
  const firstRender = useRef(true); // used to test if it's the first render
  // const recaptchaRef = useRef(null);

  const [isValid, setIsValid] = useState(false);
  const [formRules, setFormRules] = useState({
    email: false,
    password: false,
    firstName: false,
  });
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    firstName: "",
  });

  const validateForm = useCallback(() => {
    const updatedRules = {
      email: fields.email.length > 0,
      password: fields.password.length > 0,
    };
    setFormRules(updatedRules);

    for (let key in updatedRules) {
      let value = updatedRules[key];
      if (!value) {
        return false;
      }
    }
    return true;
  }, [fields]);

  // only re-run the effect if fields changes
  useEffect(() => {
    // we want to skip validation on first render
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    const formIsValid = validateForm();
    setIsValid(formIsValid);
  }, [fields, validateForm]);

  const handleSubmitClick = async (e) => {
    e.preventDefault();
    // only call signup if form is valid
    const formIsValid = validateForm();
    if (formIsValid) {
      signInFn(fields.email, fields.password);
    }
  };

  // const recaptchaVerifier = new RecaptchaVerifier(
  //   "sign-in-button",
  //   {
  //     size: "invisible",
  //     callback: function (response) {
  //       // reCAPTCHA solved, you can proceed with
  //       // phoneAuthProvider.verifyPhoneNumber(...).
  //       // onSolvedRecaptcha();
  //       console.log("testing here");
  //     },
  //   },
  //   auth
  // );

  return (
    <div>
      <form onSubmit={handleSubmitClick}>
        <div className="field">
          <label className="label has-text-weight-normal" htmlFor="email">
            Email
          </label>
          <div className="control">
            <input
              name="email"
              id="email"
              className="input is-medium fs-block"
              type="email"
              required
              value={fields.email}
              onChange={handleFieldChange}
              autoComplete="email"
            />
          </div>
          {fields.email && (
            <FormFieldError
              fieldName="email"
              fieldError={FIELD_ERRORS.email}
              isValid={formRules.email}
            />
          )}
        </div>

        <div className="field">
          <label className="label has-text-weight-normal" htmlFor="password">
            Password
          </label>
          <div className="control">
            <input
              name="password"
              id="password"
              className="input is-medium"
              type="password"
              required
              value={fields.password}
              onChange={handleFieldChange}
              autoComplete="current-password"
            />
          </div>
          {fields.password && (
            <FormFieldError
              fieldName="password"
              fieldError={FIELD_ERRORS.password}
              isValid={formRules.password}
            />
          )}
        </div>
        <div className="has-text-centered">
          {authError && <p className="help is-danger">{authError}</p>}

          <br />
          <button
            type="submit"
            id="sign-in-button"
            className={
              addLoading ? "button is-primary is-loading" : "button is-primary"
            }
            disabled={!isValid}
          >
            Log in
          </button>
        </div>
      </form>

      {showGoogleButton && (
        <div className="sw-padding--top-lg has-text-centered">
          <hr className="navbar-divider" />
          <div className="help">OR</div>
          <div className="sw-padding--top-lg">
            <ContinueWithGoogle onClick={signInGoogleFn}></ContinueWithGoogle>
          </div>
        </div>
      )}
    </div>
  );
};

// export default withFirebase(Form);
const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    addLoading: state.auth.addLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signInFn: (email, password) => dispatch(signIn(email, password)),
    signInGoogleFn: () => dispatch(signInGoogle()),
    clearForm: () => dispatch(clearForm()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
