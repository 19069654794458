import React, { Component } from "react";

// import ComparisonChart from "./ComparisonChart";
import BreakdownTable from "./BreakdownTable";

class RecommendationCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    };
    this.clickedNext = this.clickedNext.bind(this);
    this.clickedBack = this.clickedBack.bind(this);
  }

  clickedNext() {
    let nextIndex = (this.state.index + 1) % 2;
    this.setState({ index: nextIndex });
  }

  clickedBack() {
    let nextIndex = Math.abs((this.state.index - 1) % 2);
    this.setState({ index: nextIndex });
  }

  render() {
    const rec = this.props.rec;
    const baseCase = this.props.baseCase;

    return (
      <div className="level">
        <div className="level-item is-hidden-tablet buttons has-addons">
          <button
            className="button is-round"
            disabled={this.state.index === 0}
            onClick={this.clickedBack}
          >
            <span className="icon">
              <i className="fas fa-angle-left"></i>
            </span>
          </button>
          <button
            className="button is-round"
            disabled={this.state.index === 1}
            onClick={this.clickedNext}
          >
            <span className="icon">
              <i className="fas fa-angle-right"></i>
            </span>
          </button>
        </div>
        {/* <div className="level-left is-hidden-mobile">
          <button
            className="button is-round"
            disabled={this.state.index === 0}
            onClick={this.clickedBack}
          >
            <span className="icon">
              <i className="fas fa-angle-left"></i>
            </span>
          </button>
        </div> */}
        <div className="level-item">
          {/* {this.state.index === 0 && (
            <ComparisonChart baseCase={baseCase} rec={rec}></ComparisonChart>
          )} */}
          {this.state.index === 0 && (
            <BreakdownTable baseCase={baseCase} rec={rec}></BreakdownTable>
          )}
        </div>
        {/* <div className="level-right is-hidden-mobile">
          <button
            className="button is-round"
            disabled={this.state.index === 1}
            onClick={this.clickedNext}
          >
            <span className="icon">
              <i className="fas fa-angle-right"></i>
            </span>
          </button>
        </div> */}
      </div>
    );
  }
}

export default RecommendationCarousel;
