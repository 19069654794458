import flagIcon from "./images/flag@2x.png";
import piggyImg from "./images/piggy@2x.png";
import glassImg from "./images/glass@2x.png";
import studentLoanImg from "./images/student_loan_icon.png";
import mortgageImg from "./images/mortgage_icon.png";
import creditCardImg from "./images/credit_card_icon.png";
import cashIcon from "./images/cash_icon.png";
import emailImg from "./images/email_icon.png";
import chartUp from "./images/chart_up_icon.png";
import plusSign from "./images/plus_sign.png";
import moneyGeneric from "./images/money_generic.png";
import checkGreenIcon from "./images/checkGreenIcon.png";
import arrowBlueIcon from "./images/arrowBlueIcon.png";
import building from "./images/building.png";
import playButton from "./images/play_button.png";
import textIcon from "./images/textIcon.png";

import guideGraphicNewGrad from "./images/guide_graphic_new_grad.jpg";
import guideGraphicRefi from "./images/guide_graphic_refi.jpg";
import loanBreakdownEmpty from "./images/loan_breakdown_empty.png";
import premiumGraphic from "./images/premiumGraphic.png";

import menuItemActionPlan from "./images/menuItemActionPlan.png";
import menuItemChallenges from "./images/menuItemChallenges.png";
import menuItemCommunity from "./images/menuItemCommunity.png";
import menuItemFederal from "./images/menuItemFederal.png";
import menuItemInviteFriends from "./images/menuItemInviteFriends.png";
import menuItemNetWorth from "./images/menuItemNetWorth.png";
import menuItemRefi from "./images/menuItemRefi.png";
import menuItemResources from "./images/menuItemResources.png";
import menuItemStudentLoans from "./images/menuItemStudentLoans.png";
import menuItemPremium from "./images/menuItemPremium.png";

import resourceBudgetTemplate from "./images/resourceBudgetTemplate.png";
import resourceRoadmap from "./images/resourceRoadmap.png";
import personWithPhone from "./images/person_w_phone.png";

import appStoreBadge from "./images/app_store_badge-blk.svg";

export const ICONS = {
  ARROW_BLUE: arrowBlueIcon,
  CASH_ICON: cashIcon,
  CHART_UP: chartUp,
  CHECK_GREEN: checkGreenIcon,
  CREDIT_ICON: creditCardImg,
  EMAIL_ICON: emailImg,
  FLAG_BLACK: flagIcon,
  GLASS_BLACK: glassImg,
  MORTGAGE_ICON: mortgageImg,
  MONEY_GENERIC: moneyGeneric,
  PIGGY_BLACK: piggyImg,
  PLUS_SIGN: plusSign,
  STUDENT_LOAN_HAT: studentLoanImg,
  PLAY_BUTTON: playButton,
};

export const GUIDES = {
  GUIDE_NEW_GRAD: guideGraphicNewGrad,
  GUIDE_REIF: guideGraphicRefi,
};

export const GRAPHICS = {
  BUILDING: building,
  PERSON_WITH_PHONE: personWithPhone,
  LOAN_BREAKDOWN_EMTPY_STATE: loanBreakdownEmpty,
  PREMIUM_GRAPHIC: premiumGraphic,
  APP_STORE_BADGE: appStoreBadge,
};

export const MENU_ITEMS = {
  actionPlan: menuItemActionPlan,
  challenges: menuItemChallenges,
  community: menuItemCommunity,
  federal: menuItemFederal,
  inviteFriends: menuItemInviteFriends,
  netWorth: menuItemNetWorth,
  refi: menuItemRefi,
  resources: menuItemResources,
  studentLoans: menuItemStudentLoans,
  premium: menuItemPremium,
};

export const PREMIUM_ICONS = {
  budgetTemplate: resourceBudgetTemplate,
  roadmap: resourceRoadmap,
  textIcon: textIcon,
};

export const STORYBLOK_IMGS = {
  BUDGET_TEMPLATE:
    "https://a.storyblok.com/f/45004/760x370/1253b45ff3/budget_template.jpg",
  DEBT_INCOME_RATIO:
    "https://a.storyblok.com/f/45004/760x370/4769ec6f4b/cash_bills.jpg",
};

export function transformImage(image, option) {
  var imageService = "https://img2.storyblok.com/";
  var path = image.replace("https://a.storyblok.com", "");
  return imageService + option + path;
}
