import React from "react";
import { useSelector } from "react-redux";

import { Routes, Route, Link, Outlet, useParams } from "react-router-dom";

import { ADMIN_ROUTES, ADMIN_USER_PAGES } from "../../constants/routes";

import { USER_ROLES, REC_CATEGORIES } from "../../constants/constants";

import AdminUserCommunitiesPage from "./AdminUserCommunitiesPage";
import AdminUserManagePage from "./AdminUserManagePage";
import AdminUserPlaidItemsPage from "./AdminUserPlaidItemsPage";
import AdminUserRecDetailsPage from "./AdminUserRecDetailsPage";
import AdminUserRecPage from "./AdminUserRecPage";
import AdminUserNetWorth from "./AdminUserNetWorthPage";
import AdminUserIAPPage from "./AdminUserIAPPage";

const getMenuForUser = (authUserRoles, userId) => {
  let menu_items = [];

  if (
    authUserRoles.includes(USER_ROLES.coach) ||
    authUserRoles.includes(USER_ROLES.support) ||
    authUserRoles.includes(USER_ROLES.admin)
  ) {
    menu_items.push({
      toLink: `${ADMIN_ROUTES.USER_PREFIX}${userId}/${ADMIN_USER_PAGES.COMMUNITY}`,
      name: "Community",
    });
  }

  if (
    authUserRoles.includes(USER_ROLES.coach) ||
    authUserRoles.includes(USER_ROLES.admin)
  ) {
    menu_items.push({
      toLink: `${ADMIN_ROUTES.USER_PREFIX}${userId}/${ADMIN_USER_PAGES.NET_WORTH}`,
      name: "Net Worth",
    });
  }

  if (
    authUserRoles.includes(USER_ROLES.support) ||
    authUserRoles.includes(USER_ROLES.admin)
  ) {
    menu_items.push(
      {
        toLink: `${ADMIN_ROUTES.USER_PREFIX}${userId}/${ADMIN_USER_PAGES.USER_MANAGE}`,
        name: "User Data Management",
      },
      {
        toLink: `${ADMIN_ROUTES.USER_PREFIX}${userId}/${ADMIN_USER_PAGES.PLAID_ITEMS}`,
        name: "Plaid Item Management",
      },
      {
        toLink: `${ADMIN_ROUTES.USER_PREFIX}${userId}/${ADMIN_USER_PAGES.IAP}`,
        name: "IAP Subscriptions",
      }
    );
  }

  if (authUserRoles.includes(USER_ROLES.admin)) {
    menu_items.push({
      toLink: `${ADMIN_ROUTES.USER_PREFIX}${userId}/${ADMIN_USER_PAGES.REC}`,
      name: "Rec",
    });
  }

  return menu_items;
};

const AdminUserMenu = ({ userId }) => {
  const { userRoles } = useSelector((state) => {
    return state.auth;
  });

  const MENU_ITEMS = getMenuForUser(userRoles, userId);

  return (
    <aside className="menu column is-narrow">
      <ul className="menu-list">
        <li>
          <Link to={ADMIN_ROUTES.ALL_USERS}>{"<- "}Back to all users</Link>
        </li>
      </ul>

      <p className="menu-label">User {userId}</p>
      <ul className="menu-list">
        {MENU_ITEMS.map((menuItem) => {
          return (
            <li key={menuItem.name}>
              <Link to={menuItem.toLink}>{menuItem.name}</Link>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

const AdminUserPageWrapper = ({ userId }) => {
  return (
    <div className="fs-block columns">
      <AdminUserMenu userId={userId}></AdminUserMenu>
      <div className="column">
        <Outlet />
      </div>
    </div>
  );
};

const AdminUserRootPage = () => {
  const { userId } = useParams();
  const { currentUser } = useSelector((state) => {
    return state.auth;
  });

  return (
    <Routes>
      <Route
        path="/"
        element={
          <AdminUserPageWrapper userId={userId} currentUser={currentUser} />
        }
      >
        <Route index element={<AdminUserCommunitiesPage />} />
        <Route
          path={ADMIN_USER_PAGES.COMMUNITY}
          element={<AdminUserCommunitiesPage />}
        />
        <Route
          path={ADMIN_USER_PAGES.NET_WORTH}
          element={<AdminUserNetWorth userId={userId} />}
        />
        <Route
          path={ADMIN_USER_PAGES.PLAID_ITEMS}
          element={<AdminUserPlaidItemsPage userId={userId} />}
        />
        <Route
          path={ADMIN_USER_PAGES.USER_MANAGE}
          element={<AdminUserManagePage userId={userId} />}
        />
        <Route
          path={ADMIN_USER_PAGES.IAP}
          element={<AdminUserIAPPage userId={userId} />}
        />
        <Route path={ADMIN_USER_PAGES.REC} element={<AdminUserRecPage />} />

        <Route
          path={`${ADMIN_USER_PAGES.REC}/${REC_CATEGORIES.PERSONAL}/:recType`}
          element={<AdminUserRecDetailsPage userId={userId} />}
        />

        <Route
          path={`${ADMIN_USER_PAGES.REC}/${REC_CATEGORIES.STUDENT}/:recType`}
          element={<AdminUserRecDetailsPage userId={userId} />}
        />
      </Route>
    </Routes>
  );
};

export default AdminUserRootPage;
