import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useOutletContext } from "react-router-dom";

import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";
import {
  asyncGetFeed,
  asyncGetCommunities,
  asyncPost,
  asyncDeletePost,
} from "../../store/actions/communityActions";
import { asyncGetChallenges } from "../../store/actions/challengesActions";
import { setConfirmMessage } from "../../store/actions/alertingActions";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import PostToFeedComponent from "../../components/Communities/PostToFeedComponent";
import FeedSection from "../../components/Communities/FeedSection";
import ExploreCommunities from "../../components/Communities/ExploreCommunities";

const FeedsPage = ({
  trackEventFn,
  asyncGetFeedFn,
  asyncGetCommunitiesFn,
  getChallengesFn,
  asyncPostFn,
  asyncDeletePostFn,
  setConfirmMessageFn,
  isLoading,
  isFeedItemsLoading,
  feedItems,
  pagination,
  communities,
  challenges,
  currentUser,
}) => {
  // set page title
  const setPageTitle = useOutletContext();
  useEffect(() => {
    setPageTitle("Community");
  }, [setPageTitle]);

  useEffect(() => {
    // get feed and communities
    asyncGetCommunitiesFn();
    asyncGetFeedFn();
    getChallengesFn();
    trackEventFn(EVENTS.COMMUNITY_ACTIVITY_VIEWED);
  }, [asyncGetFeedFn, asyncGetCommunitiesFn, getChallengesFn, trackEventFn]);

  const onCreatePost = (fields) => {
    asyncPostFn(fields).then(() => {
      // refetch feed
      asyncGetFeedFn();
    });
    trackEventFn(EVENTS.COMMUNITY_POST_CREATED, {
      community_uuid: fields.communityUuid,
    });
  };

  const getPageContent = (page) => {
    return asyncGetFeedFn(page);
  };

  const onDeletePost = (communityUuid, feedItemUuid) => {
    setConfirmMessageFn(
      "Delete post",
      "Are you sure you want to do this? This action cannot be undone.",
      "Delete",
      () => {
        asyncDeletePostFn(feedItemUuid).then(() => {
          // need to refresh feedItems
          asyncGetFeedFn();
        });
        trackEventFn(EVENTS.COMMUNITY_POST_DELETED, {
          community_uuid: communityUuid,
          feed_item_uuid: feedItemUuid,
        });
      },
      true
    );
  };

  const showExtraOptions = currentUser.email.includes("snowballwealth");

  if (isLoading) {
    return (
      <div className="sw-flex-layout--column-center">
        <LoadingSpinner></LoadingSpinner>
      </div>
    );
  }

  return (
    <div>
      <PostToFeedComponent
        showExtraOptions={showExtraOptions}
        authorProfile={currentUser}
        communities={communities}
        challenges={challenges.challenges}
        onCreatePost={onCreatePost}
      ></PostToFeedComponent>
      <div className="columns reverse-columns is-desktop">
        <div className="column is-two-thirds-desktop is-two-thirds-widescreen is-two-thirds-fullhd">
          <h2 className="subtitle sw-padding--top-lg">Activity</h2>
          <FeedSection
            feedItems={feedItems}
            pagination={pagination}
            getPageContent={getPageContent}
            currentUser={currentUser}
            onDeletePost={onDeletePost}
            isFeedItemsLoading={isFeedItemsLoading}
          />
        </div>
        <div className="column is-one-third-desktop is-one-third-widescreen is-two-thirds-fullhd sw-padding--left-lg">
          <h2 className="subtitle sw-padding--top-lg">Explore communities</h2>
          <ExploreCommunities communities={communities} />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackEventFn: (event, properties) =>
      dispatch(trackEvent(event, properties)),
    asyncGetFeedFn: (page) => dispatch(asyncGetFeed(page)),
    asyncGetCommunitiesFn: () => dispatch(asyncGetCommunities()),
    asyncPostFn: (fields) => dispatch(asyncPost(fields)),
    getChallengesFn: () => dispatch(asyncGetChallenges()),
    asyncDeletePostFn: (feedItemUuid) =>
      dispatch(asyncDeletePost(feedItemUuid)),
    setConfirmMessageFn: (title, message, confirmText, onConfirm, isDanger) =>
      dispatch(
        setConfirmMessage(title, message, confirmText, onConfirm, isDanger)
      ),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.communities.isLoading,
    isFeedItemsLoading: state.communities.isFeedItemsLoading,
    feedItems: state.communities.feedItems,
    pagination: state.communities.pagination,
    communities: state.communities.communities,
    challenges: state.challenges,
    currentUser: state.auth.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedsPage);
