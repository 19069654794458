const initState = {
  isAuth: false,
  currentUser: null,
  userRoles: [],
  hasPremium: null,
  flags: null,
  addLoading: false,
  signInLoading: false,
  googleLoading: false, // used to manage race conditions on auth listener
  resetPwLoading: false,
  resetPwError: null,
  authError: null,
  createFirstName: null,
  tokenReady: false, // NOTE: We condition rendering on this.
};

const AUTH_PREFIX = "AUTH/";

export const AUTH_ACTIONS = {
  ADD: AUTH_PREFIX + "ADD",
  SIGN_IN: AUTH_PREFIX + "SIGN_IN",
  GET_SUCCESS: AUTH_PREFIX + "GET_SUCCESS",
  RESET_PW: AUTH_PREFIX + "RESET_PW",
  RESET_PW_SUCCESS: AUTH_PREFIX + "RESET_PW_SUCCESS",
  RESET_PW_ERROR: AUTH_PREFIX + "RESET_PW_ERROR",
  SIGNOUT: AUTH_PREFIX + "SIGNOUT",
  ERROR: AUTH_PREFIX + "ERROR",
  CLEAR_ERROR: AUTH_PREFIX + "CLEAR_ERROR",
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case AUTH_ACTIONS.ADD:
      return {
        ...state,
        addLoading: true,
        googleLoading: action.payload.googleLoading,
        createFirstName: action.payload.firstName,
      };
    case AUTH_ACTIONS.SIGN_IN:
      return {
        ...state,
        signInLoading: true,
        googleLoading: action.payload ? action.payload.googleLoading : false,
      };
    case AUTH_ACTIONS.GET_SUCCESS:
      const userData = action.res.data.data;
      const flags = action.res.data.flags;

      return {
        ...state,
        tokenReady: true,
        isAuth: true,
        currentUser: userData,
        userRoles: userData.roles,
        hasPremium: userData ? userData.has_premium : null,
        flags: flags,
        authError: null,
        addLoading: false,
        signInLoading: false,
      };
    case AUTH_ACTIONS.RESET_PW:
      return {
        ...state,
        resetPwLoading: true,
        resetPwError: null,
      };
    case AUTH_ACTIONS.RESET_PW_SUCCESS:
      return {
        ...state,
        resetPwLoading: false,
        resetPwError: null,
      };
    case AUTH_ACTIONS.RESET_PW_ERROR:
      return {
        ...state,
        resetPwLoading: false,
        resetPwError: action.err,
      };
    case AUTH_ACTIONS.ERROR:
      let errMessage = action.err.message;
      if (action.err.code === "auth/user-not-found") {
        errMessage =
          "The email or password you entered is incorrect. If you haven't created an account, please request access to create an account.";
      }

      return {
        ...state,
        tokenReady: true,
        addLoading: false,
        authError: errMessage,
      };
    case AUTH_ACTIONS.CLEAR_ERROR:
      return {
        ...state,
        authError: null,
        resetPwError: null,
      };
    case AUTH_ACTIONS.SIGNOUT:
      return {
        ...initState,
        tokenReady: true,
      };
    default:
      return state;
  }
};

export default authReducer;
