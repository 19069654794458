import { AUTH_ACTIONS } from "./authReducer";

const initState = {
  servicers: null,
  compare: {
    current: null,
    options: null,
  },
  err: null,
  isLoading: false,
};

const ACTION_PREFIX = "PAYITOFF/";

export const PAYITOFF_ACTIONS = {
  DASHBOARD: ACTION_PREFIX + "DASHBOARD",
  DASHBOARD_ERROR: ACTION_PREFIX + "DASHBOARD_ERROR",
  DASHBOARD_SUCCESS: ACTION_PREFIX + "DASHBOARD_SUCCESS",
  COMPARE: ACTION_PREFIX + "COMPARE",
  COMPARE_SUCCESS: ACTION_PREFIX + "COMPARE_SUCCESS",
  COMPARE_ERROR: ACTION_PREFIX + "COMPARE_ERROR",
};

const payitoffReducer = (state = initState, action) => {
  switch (action.type) {
    case PAYITOFF_ACTIONS.DASHBOARD:
    case PAYITOFF_ACTIONS.COMPARE:
      return {
        ...state,
        err: null,
        isLoading: true,
      };
    case PAYITOFF_ACTIONS.DASHBOARD_ERROR:
    case PAYITOFF_ACTIONS.COMPARE_ERROR:
      return {
        ...state,
        err: action.err.message,
        isLoading: false,
      };
    case PAYITOFF_ACTIONS.DASHBOARD_SUCCESS:
      return {
        ...state,
        servicers: action.res.data.servicers,
        err: null,
        isLoading: false,
      };
    case PAYITOFF_ACTIONS.COMPARE_SUCCESS:
      return {
        ...state,
        compare: {
          current: action.res.data.current,
          options: action.res.data.options,
        },
        err: null,
        isLoading: false,
      };
    case AUTH_ACTIONS.SIGNOUT:
      return initState; //Always return the initial state
    default:
      return state;
  }
};

export default payitoffReducer;
