import { AUTH_ACTIONS } from "./authReducer";

const initState = {
  allLendersStudent: null,
  matchedLendersStudent: null,
  matchedLendersPersonal: null,
  lenderProfile: {},
  getLendersError: null,
  getLendersLoading: false,
  getProfileError: null,
  getProfileLoading: false,
};

const ACTION_PREFIX = "REC/";

export const LENDERS_ACTIONS = {
  GET_LENDERS_ALL_STUDENT: ACTION_PREFIX + "GET_LENDERS_ALL_STUDENT",
  GET_LENDERS_ALL_STUDENT_ERROR:
    ACTION_PREFIX + "GET_LENDERS_ALL_STUDENT_ERROR",
  GET_LENDERS_ALL_STUDENT_SUCCESS:
    ACTION_PREFIX + "GET_LENDERS_ALL_STUDENT_SUCCESS",
  GET_LENDERS_STUDENT: ACTION_PREFIX + "GET_LENDERS_STUDENT",
  GET_LENDERS_STUDENT_ERROR: ACTION_PREFIX + "GET_LENDERS_STUDENT_ERROR",
  GET_LENDERS_STUDENT_SUCCESS: ACTION_PREFIX + "GET_LENDERS_STUDENT_SUCCESS",
  GET_LENDERS_PERSONAL: ACTION_PREFIX + "GET_LENDERS_PERSONAL",
  GET_LENDERS_PERSONAL_ERROR: ACTION_PREFIX + "GET_LENDERS_PERSONAL_ERROR",
  GET_LENDERS_PERSONAL_SUCCESS: ACTION_PREFIX + "GET_LENDERS_PERSONAL_SUCCESS",
  GET_PROFILE_STUDENT: ACTION_PREFIX + "GET_PROFILE_STUDENT",
  GET_PROFILE_STUDENT_ERROR: ACTION_PREFIX + "GET_PROFILE_STUDENT_ERROR",
  GET_PROFILE_STUDENT_SUCCESS: ACTION_PREFIX + "GET_PROFILE_STUDENT_SUCCESS",
  GET_PROFILE_PERSONAL: ACTION_PREFIX + "GET_PROFILE_PERSONAL",
  GET_PROFILE_PERSONAL_ERROR: ACTION_PREFIX + "GET_PROFILE_PERSONAL_ERROR",
  GET_PROFILE_PERSONAL_SUCCESS: ACTION_PREFIX + "GET_PROFILE_PERSONAL_SUCCESS",
};

const lendersReducer = (state = initState, action) => {
  switch (action.type) {
    case LENDERS_ACTIONS.GET_LENDERS_ALL_STUDENT:
    case LENDERS_ACTIONS.GET_LENDERS_STUDENT:
      return {
        ...state,
        getLendersError: null,
        getLendersLoading: true,
      };
    case LENDERS_ACTIONS.GET_LENDERS_ALL_STUDENT_ERROR:
    case LENDERS_ACTIONS.GET_LENDERS_STUDENT_ERROR:
      return {
        ...state,
        matchedLendersStudent: null,
        allLenders: null,
        getLendersError: action.err.message,
        getLendersLoading: false,
      };
    case LENDERS_ACTIONS.GET_LENDERS_ALL_STUDENT_SUCCESS:
      return {
        ...state,
        allLendersStudent: action.allLenders,
        getLendersError: null,
        getLendersLoading: false,
      };
    case LENDERS_ACTIONS.GET_LENDERS_STUDENT_SUCCESS:
      return {
        ...state,
        matchedLendersStudent: action.lenders,
        getLendersError: null,
        getLendersLoading: false,
      };
    case LENDERS_ACTIONS.GET_LENDERS_PERSONAL:
      return {
        ...state,
        getLendersError: null,
        getLendersLoading: true,
      };
    case LENDERS_ACTIONS.GET_LENDERS_PERSONAL_ERROR:
      return {
        ...state,
        matchedLendersPersonal: null,
        getLendersError: action.err.message,
        getLendersLoading: false,
      };
    case LENDERS_ACTIONS.GET_LENDERS_PERSONAL_SUCCESS:
      return {
        ...state,
        matchedLendersPersonal: action.lenders,
        getLendersError: null,
        getLendersLoading: false,
      };
    case LENDERS_ACTIONS.GET_PROFILE_STUDENT:
      return {
        ...state,
        getProfileError: null,
        getProfileLoading: true,
      };
    case LENDERS_ACTIONS.GET_PROFILE_STUDENT_ERROR:
      return {
        ...state,
        lenderProfile: {},
        getProfileError: action.err.message,
        getProfileLoading: false,
      };
    case LENDERS_ACTIONS.GET_PROFILE_STUDENT_SUCCESS:
      const slugStudent = action.lender.slug;

      let profileStudent = {};
      profileStudent[slugStudent] = action.lender;

      return {
        ...state,
        lenderProfile: profileStudent,
        getProfileError: null,
        getProfileLoading: false,
      };
    case LENDERS_ACTIONS.GET_PROFILE_PERSONAL:
      return {
        ...state,
        getProfileError: null,
        getProfileLoading: true,
      };
    case LENDERS_ACTIONS.GET_PROFILE_PERSONAL_ERROR:
      return {
        ...state,
        lenderProfile: {},
        getProfileError: action.err.message,
        getProfileLoading: false,
      };
    case LENDERS_ACTIONS.GET_PROFILE_PERSONAL_SUCCESS:
      const slugPersonal = action.lender.slug;
      let profilePersonal = {};
      profilePersonal[slugPersonal] = action.lender;

      return {
        ...state,
        lenderProfile: profilePersonal,
        getProfileError: null,
        getProfileLoading: false,
      };

    case AUTH_ACTIONS.SIGNOUT:
      return initState; //Always return the initial state
    default:
      return state;
  }
};

export default lendersReducer;
