import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

import ReactionImg from "./ReactionImg";

const ReactionPicker = ({ onReactClick }) => {
  const { reactions } = useSelector((state) => {
    return state.communities;
  });

  const [hideReactionPicker, setHideReactionPicker] = useState(true);

  const onClickReaction = (key) => {
    onReactClick(key);
    setHideReactionPicker(true);
  };

  const toggleReactionPicker = () => {
    setHideReactionPicker(!hideReactionPicker);
  };

  return (
    <span>
      {!hideReactionPicker && reactions && (
        <div className="sw-reaction-picker-content">
          {Object.keys(reactions).map((key, index) => {
            const reactionObj = reactions[key];
            return (
              <button
                key={index}
                className="button is-small is-rounded is-white"
                onClick={() => onClickReaction(key)}
              >
                <ReactionImg reactionKey={key} reactionObj={reactionObj} />
              </button>
            );
          })}
        </div>
      )}
      <button
        className="button is-small is-rounded"
        onClick={toggleReactionPicker}
      >
        <span className="icon is-small">
          <i className="far fa-smile"></i>
        </span>
      </button>
    </span>
  );
};

export default ReactionPicker;
