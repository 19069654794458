import { setError } from "./errorActions";
import { apiGet, apiPost } from "./apiActions";

import { COMMUNITIES_ACTIONS } from "../reducers/communityReducer";

export const asyncGetCommunities = () => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/feed/communities";

      dispatch({ type: COMMUNITIES_ACTIONS.GET_COMMUNITIES });
      const onSuccess = (res) => {
        dispatch({
          type: COMMUNITIES_ACTIONS.GET_COMMUNITIES_SUCCESS,
          res,
        });
        resolve();
      };

      const onError = (err) => {
        dispatch({ type: COMMUNITIES_ACTIONS.GET_COMMUNITIES_ERROR, err });
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiGet(endpoint, onSuccess, onError));
    });
  };
};

export const asyncGetFeed = (pageKey) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      let endpoint = "/api/feed?limit=20";

      if (pageKey) {
        endpoint += `&page_key=${pageKey}`;
      }

      dispatch({ type: COMMUNITIES_ACTIONS.GET_FEED });
      const onSuccess = (res) => {
        dispatch({
          type: COMMUNITIES_ACTIONS.GET_FEED_SUCCESS,
          res,
        });
        resolve();
      };

      const onError = (err) => {
        dispatch({ type: COMMUNITIES_ACTIONS.GET_FEED_ERROR, err });
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiGet(endpoint, onSuccess, onError));
    });
  };
};

export const asyncGetFeedItem = (feedItemUuid) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      let endpoint = "/api/feed/feed_item";

      if (feedItemUuid) {
        endpoint += `?feed_item_uuid=${feedItemUuid}`;
      }

      dispatch({ type: COMMUNITIES_ACTIONS.GET_FEED });
      const onSuccess = (res) => {
        dispatch({
          type: COMMUNITIES_ACTIONS.GET_FEED_ITEM_SUCCESS,
          res,
        });
        resolve(res);
      };

      const onError = (err) => {
        dispatch({ type: COMMUNITIES_ACTIONS.GET_FEED_ERROR, err });
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiGet(endpoint, onSuccess, onError));
    });
  };
};

export const asyncAddReaction = (
  feed_item_uuid,
  reaction_type,
  shouldDelete
) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/feed/reaction";
      const data = {
        feed_item_uuid: feed_item_uuid,
        reaction_type: reaction_type,
        to_delete_reaction: shouldDelete,
      };

      const onSuccess = (res) => {
        dispatch({
          type: COMMUNITIES_ACTIONS.REACTION_SUCCESS,
          res,
          feedItemUpdated: feed_item_uuid,
        });
        resolve(res);
      };

      const onError = (err) => {
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};

export const asyncAddCommentReaction = (
  comment_uuid,
  reaction_type,
  shouldDelete
) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/feed/comment/reaction";
      const data = {
        comment_uuid: comment_uuid,
        reaction_type: reaction_type,
        to_delete_reaction: shouldDelete,
      };

      const onSuccess = (res) => {
        dispatch({
          type: COMMUNITIES_ACTIONS.UPDATE_COMMENT_SUCCESS,
          data: res.data,
        });

        resolve(res);
      };

      const onError = (err) => {
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};

export const asyncJoinCommunity = (communityUuid) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/feed/communities";
      const data = {
        community_uuid: communityUuid,
      };

      dispatch({ type: COMMUNITIES_ACTIONS.JOIN });
      const onSuccess = (res) => {
        dispatch({ type: COMMUNITIES_ACTIONS.JOIN_SUCCESS, res });
        resolve();
      };

      const onError = (err) => {
        dispatch({ type: COMMUNITIES_ACTIONS.JOIN_ERROR, err });
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};

export const asyncPost = (fields) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/feed/feed_item";
      const data = {
        text: fields.text,
      };

      if (fields.communityUuid) {
        data["community_uuid"] = fields.communityUuid;
      }

      if (fields.mediaUrl) {
        data["media_url"] = fields.mediaUrl;
      }

      if (fields.videoUrl) {
        data["video_url"] = fields.videoUrl;
      }

      if (fields.pollUuid) {
        data["poll_uuid"] = fields.pollUuid;
      }

      if (fields.challengeId) {
        data["linked_challenge_id"] = fields.challengeId;
      }

      const onSuccess = (res) => {
        resolve(res);
      };

      const onError = (err) => {
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};

export const asyncGetComments = (feedItemUuid) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      let endpoint = `/api/feed/comment?feed_item_uuid=${feedItemUuid}`;

      dispatch({ type: COMMUNITIES_ACTIONS.GET_FEED_COMMENTS });
      const onSuccess = (res) => {
        let commentFeedItem = {};
        commentFeedItem[feedItemUuid] = res.data;

        dispatch({
          type: COMMUNITIES_ACTIONS.GET_FEED_COMMENTS_SUCCESS,
          commentDict: commentFeedItem,
        });
        resolve(res);
      };

      const onError = (err) => {
        dispatch({ type: COMMUNITIES_ACTIONS.GET_FEED_COMMENTS_ERROR, err });
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiGet(endpoint, onSuccess, onError));
    });
  };
};

export const asyncPostComment = (fields) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/feed/comment";
      const data = {
        text: fields.text,
        feed_item_uuid: fields.feedItemUuid,
      };

      if (fields.comment_uuid) {
        // we are editing existing comment
        data["comment_uuid"] = fields.comment_uuid;
      }

      const onSuccess = (res) => {
        resolve(res);
      };

      const onError = (err) => {
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};

export const asyncDeletePost = (feedItemUuid) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/feed/feed_item/delete";
      const data = {
        feed_item_uuid: feedItemUuid,
      };

      const onSuccess = (res) => {
        resolve();
      };

      const onError = (err) => {
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};

export const asyncDeleteComment = (commentUuid) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/feed/comment/delete";
      const data = {
        comment_uuid: commentUuid,
      };

      const onSuccess = (res) => {
        resolve();
      };

      const onError = (err) => {
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};

export const asyncPollVote = (pollUuidArray) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/polls/vote";
      const data = {
        poll_option_uuids: pollUuidArray,
      };

      const onSuccess = (res) => {
        resolve(res);
      };

      const onError = (err) => {
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};
