import React from "react";

const DropdownButtonMenu = ({ children }) => {
  return (
    <div className="dropdown is-hoverable is-right">
      <div className="dropdown-trigger">
        <button
          className="button is-small is-light"
          aria-haspopup="true"
          aria-controls="loans-dropdown-menu"
        >
          <span className="icon is-medium">
            <i className="fas fa-ellipsis-h" aria-hidden="true"></i>
          </span>
        </button>
      </div>
      <div className="dropdown-menu" id="loans-dropdown-menu" role="menu">
        <div className="dropdown-content">{children}</div>
      </div>
    </div>
  );
};

export default DropdownButtonMenu;
