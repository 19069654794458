import React, { useState } from "react";

const Select = ({
  name,
  label,
  className,
  value,
  required,
  disabled,
  onChange,
  children,
}) => {
  const id = name;
  const [isActive, setIsActive] = useState(value === "" ? false : true);

  function handleTextChange(e) {
    onChange(e);

    if (e.target.value !== "") {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }

  return (
    <fieldset className="sw-label-input sw-label-input__select">
      <select
        id={id}
        name={id}
        value={value}
        className={
          label
            ? `sw-label-input_input ${className}`
            : `sw-label-input_input ${className} --no-label`
        }
        onChange={handleTextChange}
        disabled={disabled}
      >
        {children}
      </select>

      {label && (
        <label className={isActive ? "active" : ""} htmlFor={id}>
          {label}
        </label>
      )}
    </fieldset>
  );
};

export default Select;
