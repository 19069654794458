import { setError } from "./errorActions";
import { apiGet, apiPost } from "./apiActions";

import { ADMIN_COURSES_ACTIONS } from "../reducers/adminCoursesReducer";

export const asyncAdminGetCourses = () => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/admin/courses";

      dispatch({ type: ADMIN_COURSES_ACTIONS.GET_COURSES });
      const onSuccess = (res) => {
        dispatch({
          type: ADMIN_COURSES_ACTIONS.GET_COURSES_SUCCESS,
          res,
        });
        resolve(res.data);
      };

      const onError = (err) => {
        dispatch({ type: ADMIN_COURSES_ACTIONS.GET_COURSES_ERROR, err });
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiGet(endpoint, onSuccess, onError));
    });
  };
};

export const asyncAdminGetCourse = (courseId) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = `/api/admin/courses/${courseId}`;

      dispatch({ type: ADMIN_COURSES_ACTIONS.GET_COURSES });
      const onSuccess = (res) => {
        dispatch({
          type: ADMIN_COURSES_ACTIONS.GET_COURSES_SUCCESS,
          res,
        });
        resolve(res.data);
      };

      const onError = (err) => {
        dispatch({ type: ADMIN_COURSES_ACTIONS.GET_COURSES_ERROR, err });
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiGet(endpoint, onSuccess, onError));
    });
  };
};

export const asyncAdminUpdateCourse = (fields) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/admin/courses/create_course";

      let data = {
        course_type: fields.courseType,
        course_access: fields.courseAccess,
        reference_name: fields.referenceName,
        storyblok_slug: fields.slug,
        linked_community_uuid: fields.linkedCommunityUuid
          ? fields.linkedCommunityUuid
          : null,
        is_active: fields.isActive,
      };

      if (fields.courseType === "cohort") {
        // pass extra cohort fields
        data["group_due_date_offset"] = fields.groupDueDateOffset;
        data["duration_in_days"] = fields.durationInDays;
      }

      if (fields.courseUuid) {
        // this means it's an existing course, not a new one
        data["course_uuid"] = fields.courseUuid;
      }

      dispatch({ type: ADMIN_COURSES_ACTIONS.UPDATE_COURSE });
      const onSuccess = (res) => {
        dispatch({
          type: ADMIN_COURSES_ACTIONS.UPDATE_COURSE_SUCCESS,
          res,
        });
        resolve(res.data);
      };

      const onError = (err) => {
        dispatch({ type: ADMIN_COURSES_ACTIONS.UPDATE_COURSE_ERROR, err });
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};

export const asyncAdminUpdateTask = (fields) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/admin/courses/create_tasks";

      let data = {
        course_uuid: fields.courseUuid,
        reference_name: fields.referenceName,
        storyblok_slug: fields.slug,
        group_position: fields.groupPosition,
        task_position: fields.taskPosition,
        task_type: fields.taskType,
      };

      if (fields.taskUuid) {
        // this means it's an existing task, not a new one
        data["task_uuid"] = fields.taskUuid;
      }

      dispatch({ type: ADMIN_COURSES_ACTIONS.UPDATE_COURSE });
      const onSuccess = (res) => {
        dispatch({
          type: ADMIN_COURSES_ACTIONS.UPDATE_COURSE_SUCCESS,
          res,
        });
        resolve(res.data);
      };

      const onError = (err) => {
        dispatch({ type: ADMIN_COURSES_ACTIONS.UPDATE_COURSE_ERROR, err });
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};

export const asyncAdminDeleteTask = (fields) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/admin/courses/delete_task";

      let data = {
        course_uuid: fields.courseUuid,
        task_uuid: fields.taskUuid,
      };

      dispatch({ type: ADMIN_COURSES_ACTIONS.UPDATE_COURSE });
      const onSuccess = (res) => {
        dispatch({
          type: ADMIN_COURSES_ACTIONS.UPDATE_COURSE_SUCCESS,
          res,
        });
        resolve(res.data);
      };

      const onError = (err) => {
        dispatch({ type: ADMIN_COURSES_ACTIONS.UPDATE_COURSE_ERROR, err });
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};

export const asyncAdminUpdateCohort = (fields) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/admin/courses/upsert_cohort";

      let data = {
        course_uuid: fields.courseUuid,
        start_date: Math.round(fields.startDate.getTime() / 1000),
        community_uuid: fields.communityUuid,
      };

      if (fields.cohortUuid) {
        // this means it's an existing cohort, not a new one
        // and date can be updated
        data["cohort_uuid"] = fields.cohortUuid;
      }

      dispatch({ type: ADMIN_COURSES_ACTIONS.UPDATE_COURSE });
      const onSuccess = (res) => {
        dispatch({
          type: ADMIN_COURSES_ACTIONS.UPDATE_COURSE_SUCCESS,
          res,
        });
        resolve(res.data);
      };

      const onError = (err) => {
        dispatch({ type: ADMIN_COURSES_ACTIONS.UPDATE_COURSE_ERROR, err });
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};
