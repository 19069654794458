import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { useFormFields } from "../../components/Form/useFormFields";
import "react-datepicker/dist/react-datepicker.css";

import { BackLink } from "../../components/Buttons/BackButtons";
import { ADMIN_ROUTES } from "../../constants/routes";
import { createPollOrEditPoll } from "../../store/actions/adminPollsActions";
import AdminPollsForm from "../../components/Polls/AdminPollsForm";
import { POLL_TYPES } from "../../constants/constants";

const AdminCreatePollPage = (props) => {
  const location = useLocation();
  const poll = location.state;
  const dispatch = useDispatch();
  const { createPollLoading, adminError, newPoll } = useSelector((state) => {
    return state.adminPolls;
  });

  const defaultFields = useMemo(() => {
    if (poll == null) {
      return {
        prompt: "",
        pollType: POLL_TYPES[0],
        currentOptions: [],
        newOptions: [],
        correctQuizAnswerText: "",
      };
    } else {
      const currentOptions = poll["poll_options"];
      var initialCorrectOption = "";
      if (poll["type"] === "quiz") {
        const filteredOptions = currentOptions.filter((option) => {
          return option["is_correct_answer"];
        });
        if (filteredOptions.length === 1) {
          initialCorrectOption = filteredOptions[0]["text"];
        }
      }
      return {
        prompt: poll["prompt"],
        pollType: poll["type"],
        currentOptions: currentOptions,
        newOptions: [],
        correctQuizAnswerText: initialCorrectOption,
      };
    }
  }, [poll]);

  const [fields, handleFieldChange, setValues] = useFormFields(defaultFields);

  useEffect(() => {
    setValues(defaultFields);
  }, [setValues, defaultFields, newPoll]);

  const navigate = useNavigate();
  const onSubmitForm = useCallback(
    async (event) => {
      event.preventDefault();
      // Take the diff of the original model and the edited options to
      // determine which ones to delete
      const optionsToDelete = [];
      var options = [];
      if (poll != null) {
        const updatedOptionUuids = fields.currentOptions.map((option) => {
          return option["poll_option_uuid"];
        });
        poll["poll_options"].forEach((option) => {
          const optionUuid = option["poll_option_uuid"];
          if (updatedOptionUuids.includes(optionUuid)) {
            const optionPayload = {};
            optionPayload[optionUuid] = option["text"];
            options.push(optionPayload);
          } else {
            optionsToDelete.push(optionUuid);
          }
        });
      }
      if (fields.newOptions) {
        options = [
          ...options,
          ...fields.newOptions.map((option) => {
            return {
              new: option,
            };
          }),
        ];
      }
      var correctAnswerIndex = null;
      if (fields.pollType === "quiz") {
        for (var index = 0; index < options.length; index++) {
          const optionPayload = options[index];
          const key = Object.keys(optionPayload)[0];
          if (optionPayload[key] === fields.correctQuizAnswerText) {
            correctAnswerIndex = index;
            break;
          }
        }
      }
      await dispatch(
        createPollOrEditPoll(
          fields.prompt,
          fields.pollType,
          poll,
          options,
          optionsToDelete,
          correctAnswerIndex
        )
      ).then((res) => {
        // navigate back to main
        navigate(ADMIN_ROUTES.POLLS);
      });
    },
    [fields, dispatch, navigate, poll]
  );

  return (
    <div className="fs-block">
      <BackLink routeLink={ADMIN_ROUTES.POLLS} text={"Go back"}></BackLink>

      <h1 className="title">Admin Polls</h1>
      <AdminPollsForm
        onSubmitForm={onSubmitForm}
        fields={fields}
        handleFieldChange={handleFieldChange}
        submitLoading={createPollLoading}
      ></AdminPollsForm>
      <br />
      {adminError && <div className="has-text-danger">Error: {adminError}</div>}
    </div>
  );
};

export default AdminCreatePollPage;
