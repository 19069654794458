import { setError } from "./errorActions";
import { apiPost } from "./apiActions";

export const updateUserSettings = (fields) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/users/update";
      const data = {
        name: fields.name,
        display_name: fields.displayName,
      };

      if (fields.profileImageUrl) {
        data["profile_img_url"] = fields.profileImageUrl;
      }

      const onSuccess = (res) => {
        resolve(res);
      };

      const onError = (err) => {
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};
