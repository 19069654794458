import React from "react";

import ComparePlansTable from "./ComparePlansTable";

const ComparePlansWidget = ({
  current,
  optionsArray,
  optionOneTerm,
  optionTwoTerm,
  setOptionOne,
  setOptionTwo,
}) => {
  const optionOneObj = optionsArray.find((obj) => {
    return obj.term === optionOneTerm;
  });
  const optionTwoObj = optionsArray.find((obj) => {
    return obj.term === optionTwoTerm;
  });

  return (
    <div className="box">
      <div className="level">
        <div className="level-item level-left">
          <p className="title">Compare plans</p>
        </div>
        {/* <div className="level-item level-right">
          <p className="is-size-7">INCLUDE PSLF</p>
        </div> */}
      </div>
      <ComparePlansTable
        current={current}
        optionOne={optionOneObj}
        optionTwo={optionTwoObj}
        setOptionOne={setOptionOne}
        setOptionTwo={setOptionTwo}
      ></ComparePlansTable>
    </div>
  );
};

export default ComparePlansWidget;
