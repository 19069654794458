import React from "react";

import GroupContainer from "./GroupContainer";

const Curriculum = ({ course, tasks }) => {
  const currentGroups = tasks.current_groupings;
  const pastGroups = tasks.past_groupings;

  // keeps track of which level to have expanded
  let expandedIndex = 0;

  return (
    <div>
      {currentGroups &&
        currentGroups.map((groupObj, index) => {
          // check if already finished this level
          if (groupObj.num_completed_tasks === groupObj.num_total_tasks) {
            // increase the index by one so that the next level is expanded
            expandedIndex = expandedIndex + 1;
          }

          return (
            <GroupContainer
              key={index}
              group={groupObj}
              course={course}
              startExpanded={index === expandedIndex ? true : false}
            ></GroupContainer>
          );
        })}
      {pastGroups && pastGroups.length > 0 && (
        <div>
          <h2 className="subtitle sw-padding--top-lg">Past</h2>
          {pastGroups.map((groupObj, index) => {
            return (
              <GroupContainer
                key={index}
                group={groupObj}
                course={course}
              ></GroupContainer>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Curriculum;
