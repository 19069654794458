import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import CreateAccountForm from "../../components/Form/CreateAccountForm";
import ROUTES from "../../constants/routes";
import helpers from "../../services/helpers";
import { SignupLogoNav } from "../../components/NavBar/LogoNav";

const CreateAccountColumn = ({ referralCode }) => {
  return (
    <div className="column sw-signup-page-column is-white is-fullscreen-mobile">
      <div className="is-hidden-tablet">
        <SignupLogoNav useWhiteLogo={false}></SignupLogoNav>
      </div>

      <h1 className="sw-title--hero sw-padding--bottom-lg">
        Snowball for Schools
      </h1>
      <h2 className="subtitle is-size-6 has-text-weight-normal">
        Sign in to access your account.
      </h2>
      <CreateAccountForm
        showGoogleButton={true}
        showUserPw={false}
      ></CreateAccountForm>
      <p className="sw-padding--top-lg is-size-7">
        By clicking the button above, you agree to our{" "}
        <a href="https://www.snowballwealth.com/legal/tos">Terms of Service</a>{" "}
        and{" "}
        <a href="https://www.snowballwealth.com/legal/privacy">
          Privacy Policy
        </a>
      </p>
    </div>
  );
};

const SchoolLoginPage = (props) => {
  let location = useLocation();

  const { isLoggedIn, referralCode } = props;

  // Send them back to the page they tried to visit when they were
  // redirected to the login page. Use { replace: true } so we don't create
  // another entry in the history stack for the login page.  This means that
  // when they get to the protected page and click the back button, they
  // won't end up back on the login page, which is also really nice for the
  // user experience.
  let nextRoute = helpers.getNextRoute(location, ROUTES.SCHOOLS_HOME);
  if (isLoggedIn) return <Navigate to={nextRoute} replace />;

  return (
    <>
      <div className="sw-signup-page-container columns">
        <CreateAccountColumn referralCode={referralCode}></CreateAccountColumn>
        <div className="is-hidden-mobile column is-one-third sw-signup-page-column is-gradient">
          <SignupLogoNav useWhiteLogo={true}></SignupLogoNav>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  // Note: Here, we want to wait for auth.isAuth, to prevent logging in before we get user
  const isLoggedIn =
    state.firebase.auth && state.firebase.auth.uid && state.auth.isAuth;

  // check if we have a referral code in cookies
  const referralCode = helpers.getCookie("snowball-referral-code");

  return {
    isLoggedIn: isLoggedIn,
    referralCode: referralCode,
  };
};

export default connect(mapStateToProps)(SchoolLoginPage);
