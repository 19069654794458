import React from "react";

import { GRAPHICS } from "../../constants/images";
import { ImageIconSqr } from "../Icon/ImageIcon";

const ComparePlansCTA = ({ onButtonClick }) => {
  return (
    <div className="sw-federal-programs-cta box">
      <div className="columns sw-flex-layout--centered">
        <div className="column is-narrow is-hidden-touch">
          <ImageIconSqr
            iconSrc={GRAPHICS.BUILDING}
            alt="green check"
            size="large"
          />
        </div>
        <div className="column sw-padding--left-lg">
          <div className="sw-padding--bottom-lg">
            <p className="sw-federal-programs-cta__line-one">
              Continue where you left off
            </p>
            <p className="sw-federal-programs-cta__line-two">
              Compare federal programs
            </p>
            <p className="sw-federal-programs-cta__line-three">
              Explore and enroll in federal programs. The new SAVE plan is the
              most affordable income-driven repayment plan. With Snowball you
              can find your loans, see if you are eligible and enroll in any
              income-driven plan, including the SAVE plan.
            </p>
          </div>
          <div className="button sw-is-wide is-primary">
            <button
              className="button is-primary sw-is-wide"
              onClick={() => onButtonClick()}
            >
              Compare plans now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparePlansCTA;
