import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useOutletContext, useParams } from "react-router-dom";

import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";
import {
  asyncGetChallengeProgress,
  asyncJoinChallenge,
} from "../../store/actions/challengesActions";

import { BackLink } from "../../components/Buttons/BackButtons";

import ROUTES from "../../constants/routes";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import ChallengeProgress from "../../components/Challenges/ChallengeProgress";
import EditChallengeGoalButton from "../../components/Challenges/EditChallengeGoalButton";

const ChallengePage = ({
  trackEventFn,
  asyncGetChallengeProgressFn,
  asyncJoinChallengeFn,
  isLoading,
  challenges,
}) => {
  let { challengeId } = useParams();

  // set page title
  const setPageTitle = useOutletContext();
  useEffect(() => {
    setPageTitle("Challenges");
  }, [setPageTitle]);

  useEffect(() => {
    trackEventFn(EVENTS.CHALLENGE_VIEWED, { challenge_id: challengeId });
  }, [trackEventFn, challengeId]);

  const [thisChallenge, setThisChallenge] = useState(null);

  useEffect(() => {
    // get this challenge progress
    asyncGetChallengeProgressFn(challengeId);
  }, [asyncGetChallengeProgressFn, challengeId]);

  useEffect(() => {
    // check from challenges array
    if (challenges && challenges.length > 0) {
      // note: this array should only have 1 item, but we want to double check id
      const filteredChallenges = challenges.filter(
        (el) => el.challenge_id === parseInt(challengeId)
      );
      setThisChallenge(filteredChallenges[0]);
    }
  }, [challenges, challengeId]);

  const onJoinFn = (challengeId) => {
    trackEventFn(EVENTS.CHALLENGE_JOINED, { challenge_id: challengeId });
    trackEventFn(`${EVENTS.CHALLENGE_JOINED} ${challengeId}`);
    asyncJoinChallengeFn(challengeId);
  };

  if (isLoading) {
    return (
      <div className="sw-flex-layout--column-center">
        <LoadingSpinner></LoadingSpinner>
      </div>
    );
  }

  if (!thisChallenge) {
    return (
      <div className="sw-container--fixed-width sw-is-wide">
        <BackLink routeLink={ROUTES.CHALLENGES} text="Back"></BackLink>
        <div className="columns">
          <p>No challenge found</p>
        </div>
      </div>
    );
  }

  return (
    <div className="sw-container--fixed-width sw-is-wide">
      <div className="level is-mobile">
        <div className="level-item level-left">
          <BackLink routeLink={ROUTES.CHALLENGES} text="Back"></BackLink>
        </div>
        {thisChallenge &&
          thisChallenge.progress.has_joined &&
          !thisChallenge.progress.has_ended && (
            <div className="level-item level-right">
              <EditChallengeGoalButton
                challengeObj={thisChallenge}
                challengeId={thisChallenge.challenge_id}
              />
            </div>
          )}
      </div>
      <div className="sw-challenges-page-container">
        {thisChallenge && (
          <ChallengeProgress
            challengeObj={thisChallenge}
            joinFn={onJoinFn}
          ></ChallengeProgress>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackEventFn: (event, properties) =>
      dispatch(trackEvent(event, properties)),
    asyncGetChallengeProgressFn: (challengeId) =>
      dispatch(asyncGetChallengeProgress(challengeId)),
    asyncJoinChallengeFn: (challengeId) =>
      dispatch(asyncJoinChallenge(challengeId)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.challenges.isLoading,
    challenges: state.challenges.challenges,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChallengePage);
