import React from "react";
import { LINK_URLS } from "../../constants/constants";

export const AnnouncementBanner = (props) => {
  return (
    <article className="message is-warning sw-full-width has-text-centered-desktop">
      <div className="message-body">{props.message}</div>
    </article>
  );
};

export const CovidAnnouncement = (props) => {
  let prefix = "This recommendation does";
  if (props.type === "insights") {
    prefix = "These insights do";
  }
  const announcementMessage = (
    <div className="sw-flex-layout--column-center">
      <p className="sw-flex-layout--centered">
        <span className="icon is-small">
          <i className="fas fa-bullhorn"></i>
        </span>{" "}
        <span className="sw-padding--left-lg sw-padding--right-lg">
          {prefix} not reflect policy changes related to COVID-19.
        </span>
      </p>
      <a
        className="has-text-centered"
        href={LINK_URLS.COVID_BLOG_AUG_24_2022_UPDATE}
        target="_blank"
        rel="noopener noreferrer"
      >
        See Latest Updates
      </a>
    </div>
  );

  return (
    <AnnouncementBanner message={announcementMessage}></AnnouncementBanner>
  );
};

export const ImpactedByCovidAnnouncement = () => {
  const announcementMessage = (
    <div className="sw-flex-layout--column-center">
      <p className="sw-flex-layout--centered">
        <span className="icon is-small">
          <i className="fas fa-bullhorn"></i>
        </span>{" "}
        <span className="sw-padding--left-lg sw-padding--right-lg">
          Because of legislation passed by Congress, the student loan payment
          pause is ending. Interest resumes on Sept. 1, and payments are due in
          October. Borrowers can lower their payments, even to $0, by enrolling
          in the new SAVE Plan.
        </span>
      </p>
      <a
        className="has-text-centered"
        href={LINK_URLS.STUDENT_LOANS_UDPATE_SEP_2023}
        target="_blank"
        rel="noopener noreferrer"
      >
        Read more here
      </a>
    </div>
  );

  return (
    <AnnouncementBanner message={announcementMessage}></AnnouncementBanner>
  );
};
