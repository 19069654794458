import React, { useState, useEffect } from "react";

import helpers from "../../services/helpers";

export const NUMBER_INPUT_TYPES = {
  CURRENCY: "currency",
  INTEREST: "interest",
  INTEGER: "integer",
};

const NumberInput = ({
  name,
  label,
  placeholder,
  className,
  value,
  required,
  onChange,
  numberType, // defaults to currency
}) => {
  const id = name;
  const [isActive, setIsActive] = useState(
    value === "" || value === null ? false : true
  );
  const [isFocused, setIsFocused] = useState(false);
  const [displayPlaceholder, setDiplayPlaceholder] = useState("");

  // don't show placeholder until is active to avoid overlapping text
  useEffect(() => {
    if (placeholder && value === "" && isFocused) {
      setDiplayPlaceholder(placeholder);
    } else {
      setDiplayPlaceholder("");
    }
  }, [value, placeholder, isFocused]);

  function handleTextChange(e) {
    onChange(e);

    if (e.target.value !== "") {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }

  const formatNumber = (number) => {
    if (number === "") {
      return "";
    }

    if (numberType === NUMBER_INPUT_TYPES.INTEREST) {
      return helpers.interestFormat(number);
    }

    if (numberType === NUMBER_INPUT_TYPES.INTEGER) {
      return number;
    }

    return helpers.currencyFormat(number);
  };

  return (
    <fieldset className="sw-label-input">
      {isFocused ? (
        <input
          id={id}
          name={id}
          type="number"
          min="0"
          step="any"
          value={value}
          onChange={handleTextChange}
          className={`sw-label-input_input ${className}`}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          required={required}
        />
      ) : (
        <input
          name={id}
          type="text"
          value={formatNumber(value)}
          required={required}
          className={`sw-label-input_input ${className}`}
          placeholder={displayPlaceholder}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={() => console.error("Number input error")}
        />
      )}

      <label className={isActive ? "active" : ""} htmlFor={id}>
        {label}
      </label>
    </fieldset>
  );
};

export default NumberInput;

export const ControlMoneyInput = (props) => (
  <div className="control has-icons-left">
    <input
      type="number"
      min="0"
      step="any"
      {...props}
      className={`input ${props.className}`}
    />
    <span className="icon is-small is-left">
      <i className="fas fa-dollar-sign"></i>
    </span>
  </div>
);
