import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useOutletContext, Link } from "react-router-dom";

import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";

import { BackButton } from "../../components/Buttons/BackButtons";
import { NumberedListItem } from "../../components/Lists/NumberedListItem";
import CheckedListLi from "../../components/Lists/CheckedListLi";

import { LINK_URLS, NOTION_LINKS_URLS } from "../../constants/constants";
import ROUTES from "../../constants/routes";

const RecRefiPage = ({ trackEventFn }) => {
  let navigate = useNavigate();

  useEffect(() => {
    trackEventFn(EVENTS.REFI_STEPS_VIEWED);
  }, [trackEventFn]);

  const setPageTitle = useOutletContext();
  useEffect(() => {
    setPageTitle("Student loan refinancing");
  }, [setPageTitle]);

  const goNext = () => {
    navigate(ROUTES.LENDERS_STUDENT_MARKETPLACE);
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="sw-container--fixed-width sw-is-wide">
      <div className="sw-padding--bottom-lg">
        <BackButton onBack={goBack} text="Back"></BackButton>
      </div>

      <h1 className="sw-title--big sw-padding--bottom-xl">
        How to refinance your loans in 3 easy steps
      </h1>

      <div className="sw-is-narrow sw-padding--bottom-xl">
        <div className="sw-how-to-refi__step block">
          <NumberedListItem
            num="1"
            title="Evaluate if refinancing is for you (5-10 min)"
          ></NumberedListItem>
          <ul className="sw-padding--top-md sw-padding--bottom-lg">
            <CheckedListLi>
              See how much you could save with our calculator
            </CheckedListLi>
            <CheckedListLi>
              Read refinancing <a href={NOTION_LINKS_URLS.REFI_GUIDE}>FAQ’s</a>
            </CheckedListLi>
            <CheckedListLi>
              If you have federal loans, made sure you don’t need any benefits
            </CheckedListLi>
            <CheckedListLi>
              Optional: Schedule a{" "}
              <a href={LINK_URLS.SNOWBALL_CONSULT}> consultation</a> with a
              refinancing expert
            </CheckedListLi>
          </ul>
        </div>

        <div className="sw-how-to-refi__step block">
          <NumberedListItem
            num="2"
            title="Evaluate lenders and check rates (5-10 min)"
          ></NumberedListItem>
          <ul className="sw-padding--top-md sw-padding--bottom-lg">
            <CheckedListLi>
              Evaluate Snowball{" "}
              <Link to={ROUTES.LENDERS_STUDENT_MARKETPLACE}>
                recommended lenders
              </Link>
            </CheckedListLi>
            <CheckedListLi>
              With recommended lenders, you can check rates without affecting
              your credit score
            </CheckedListLi>
            <CheckedListLi>
              Pick the best lender for you based on rates and other benefits
            </CheckedListLi>
          </ul>
        </div>

        <div className="sw-how-to-refi__step block">
          <NumberedListItem
            num="3"
            title="Apply and close on your loan (a few days)"
          ></NumberedListItem>
          <ul className="sw-padding--top-md sw-padding--bottom-lg">
            <CheckedListLi>
              Upload your documents and wait for approval
            </CheckedListLi>
            <CheckedListLi>
              Once you close, make sure to celebrate!
            </CheckedListLi>
            <CheckedListLi>
              You can refinance again if rates go down
            </CheckedListLi>
          </ul>
        </div>
      </div>
      <button onClick={goNext} className="button is-primary is-medium">
        Check out lenders now
      </button>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackEventFn: (event, properties) =>
      dispatch(trackEvent(event, properties)),
  };
};

export default connect(null, mapDispatchToProps)(RecRefiPage);
