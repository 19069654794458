import React from "react";
import { connect } from "react-redux";
import { clearConfirmMessage } from "../../store/actions/alertingActions";
import Modal from "../Modal/Modal";

const ConfirmationMessage = (props) => {
  const modalTitle = props.title || "";
  const message = props.message || "";
  const confirmText = props.confirmText || "Yes";
  const isDanger = props.isDanger;

  const onConfirm = () => {
    if (props.onConfirm) {
      props.onConfirm();
    }
    props.clearConfirmMessage();
  };

  const onCancel = () => {
    props.clearConfirmMessage();
  };

  const footerObj = (
    <>
      <button
        className={isDanger ? "button is-danger" : "button is-success"}
        onClick={onConfirm}
      >
        {confirmText}
      </button>
      <button className="button" onClick={onCancel}>
        Cancel
      </button>
    </>
  );

  return (
    <Modal
      onClose={onCancel}
      isOpen={true}
      title={modalTitle}
      footer={footerObj}
    >
      <p className="sw-padding--right-lg sw-padding--left-lg">{message}</p>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearConfirmMessage: () => dispatch(clearConfirmMessage()),
  };
};

export default connect(null, mapDispatchToProps)(ConfirmationMessage);
