import React from "react";

import helpers from "../../services/helpers";
import {
  getDaysLeftString,
  getProgressPercent,
  getProgressString,
} from "./challengeUtils";

import ChallengeBadge from "./ChallengeBadge";
import ProgressBar from "./ProgressBar";

const ProgressSummaryCard = ({ challengeObj, joinFn }) => {
  const hasJoined = challengeObj.progress && challengeObj.progress.has_joined;
  const progressObj = challengeObj.progress;

  const onJoinClick = () => {
    return joinFn(challengeObj.challenge_id);
  };
  const daysLeftString = getDaysLeftString(
    challengeObj.start_date,
    challengeObj.end_date
  );

  let progressPercent = 0;
  if (hasJoined) {
    progressPercent = getProgressPercent(
      progressObj.goal_progress,
      progressObj.goal_amount
    );
  }

  return (
    <div className="box sw-challenge-card with-header">
      <div className="sw-challenge-card__img-header">
        <ChallengeBadge
          badgeDrawingInfo={challengeObj.badge_drawing_info}
          progressPercent={progressPercent}
        ></ChallengeBadge>
      </div>

      {!hasJoined && (
        <div className="is-size-5 has-text-weight-semibold">
          0 / {helpers.currencyFormat(challengeObj.goal_suggested_amount)}
        </div>
      )}

      {hasJoined && (
        <div className="sw-padding--top-md sw-full-width">
          <div className="is-size-5 has-text-weight-semibold">
            {helpers.currencyFormat(progressObj.goal_progress)} /{" "}
            {helpers.currencyFormat(progressObj.goal_amount)}
          </div>
          <div className="is-size-7">
            {getProgressString(
              challengeObj.has_started,
              challengeObj.has_ended,
              progressObj.goal_remaining
            )}
          </div>
          {progressObj.account_id && (
            <div className="sw-full-width sw-padding--top-lg sw-padding--bottom-lg">
              <ProgressBar progressPercent={progressPercent}></ProgressBar>
            </div>
          )}
        </div>
      )}

      <div className="sw-padding--top-lg">
        {daysLeftString && (
          <div className="sw-challenge-tag">{daysLeftString}</div>
        )}
      </div>

      {!hasJoined && (
        <div className="sw-padding--top-lg">
          <button
            className="button is-primary sw-full-width"
            onClick={onJoinClick}
          >
            Join
          </button>
        </div>
      )}
    </div>
  );
};

export default ProgressSummaryCard;
