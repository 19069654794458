import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import CreateAccountForm from "../../components/Form/CreateAccountForm";
import ROUTES from "../../constants/routes";
import helpers from "../../services/helpers";
import { SignupLogoNav } from "../../components/NavBar/LogoNav";
import { LINK_URLS } from "../../constants/constants";
import { GRAPHICS } from "../../constants/images";

const SnowballExplanationColumn = () => {
  return (
    <div className="column is-one-third sw-signup-page-column">
      <div className="is-hidden-mobile">
        <SignupLogoNav></SignupLogoNav>
      </div>

      <ul>
        <li className="sw-padding--bottom-lg">
          <p className="has-text-weight-bold">Your Money Community</p>
          The place to go before making your next financial move. Celebrate wins
          and connect with others who are in a similar money journey.
        </li>

        <li className="sw-padding--bottom-lg sw-padding--top-md">
          <p className="has-text-weight-bold sw-padding--top-md">
            Join Challenges
          </p>
          Whether to jumpstart your savings or pay off debt. Join a Money
          Challenge to reach your goals.
        </li>

        <li className="sw-padding--bottom-lg sw-padding--top-md">
          <p className="has-text-weight-bold sw-padding--top-md">
            Track your Net Worth
          </p>
          Sync your accounts to get a complete picture of your debt paydown,
          cash, and investments.
        </li>
        <li className="sw-padding--bottom-lg sw-padding--top-md">
          <p className="has-text-weight-bold sw-padding--top-md">
            Safe and Secure
          </p>
          Snowball Wealth uses bank-level encryption to keep your data safe.
        </li>
      </ul>
    </div>
  );
};

const CreateAccountColumn = ({ referralCode }) => {
  return (
    <div className="column sw-signup-page-column is-white">
      <div className="is-hidden-tablet">
        <SignupLogoNav></SignupLogoNav>
      </div>

      <h1 className="title is-hidden-mobile">Welcome to Snowball Wealth</h1>
      <h2 className="subtitle is-size-6 has-text-weight-normal">
        Get started by creating your free account.
      </h2>
      <CreateAccountForm
        referralCode={referralCode}
        showGoogleButton={false}
        showUserPw={true}
      ></CreateAccountForm>
      <p className="sw-padding--top-lg is-size-7">
        By clicking on “Create account”, you agree to Snowball Wealth’s{" "}
        <a href="https://www.snowballwealth.com/legal/tos">Terms of Service</a>,
        including our{" "}
        <a href="https://www.snowballwealth.com/legal/privacy">
          Privacy Policy
        </a>
      </p>
      <p className="sw-padding--top-lg">
        Already have an account? <Link to={ROUTES.SIGN_IN}>Log in here</Link>
      </p>
      <div className="has-text-centered">
        <p className="sw-is-x-small sw-padding--top-lg">Get the app.</p>
        <div className="sw-padding--top-md">
          <a
            aria-label="Download on the App Store"
            href={LINK_URLS.APP_STORE_LINK}
            tabIndex="0"
          >
            <img
              height="40"
              alt="Download on the App Store"
              className=""
              src={GRAPHICS.APP_STORE_BADGE}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

const SignupPage = (props) => {
  let location = useLocation();

  const { isLoggedIn, referralCode } = props;

  // Send them back to the page they tried to visit when they were
  // redirected to the login page. Use { replace: true } so we don't create
  // another entry in the history stack for the login page.  This means that
  // when they get to the protected page and click the back button, they
  // won't end up back on the login page, which is also really nice for the
  // user experience.
  let nextRoute = helpers.getNextRoute(location, ROUTES.HOME);
  if (isLoggedIn) return <Navigate to={nextRoute} replace />;

  return (
    <>
      <div className="sw-signup-page-container columns">
        <CreateAccountColumn referralCode={referralCode}></CreateAccountColumn>
        <SnowballExplanationColumn></SnowballExplanationColumn>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  // Note: Here, we want to wait for auth.isAuth, to prevent logging in before we get user
  const isLoggedIn =
    state.firebase.auth && state.firebase.auth.uid && state.auth.isAuth;

  // check if we have a referral code in cookies
  const referralCode = helpers.getCookie("snowball-referral-code");

  return {
    isLoggedIn: isLoggedIn,
    referralCode: referralCode,
  };
};

export default connect(mapStateToProps)(SignupPage);
