import React, { useState } from "react";

import PostModal from "./PostModal";
import UserIcon from "./UserIcon";

const PostToFeedComponent = ({
  showExtraOptions,
  authorProfile,
  communities,
  challenges,
  onCreatePost,
  placeholderText,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  let challengesList = [];
  if (challenges && challenges.challenges) {
    challengesList = challenges.challenges;
  }

  return (
    <div className="box sw-box--no-height">
      <article className="sw-media media">
        <div className="media-left">
          <UserIcon authorProfile={authorProfile} />
        </div>
        <div className="media-content">
          <button
            className="button sw-full-width is-outlined is-rounded is-justify-content-flex-start"
            onClick={() => setIsOpen(true)}
          >
            <span className="sw-padding--top-lg sw-padding--bottom-lg">
              Post to the community
            </span>
          </button>
        </div>
      </article>
      {communities && (
        <PostModal
          onClose={() => setIsOpen(false)}
          isOpen={isOpen}
          onPost={onCreatePost}
          authorProfile={authorProfile}
          communities={communities}
          challengesList={challengesList}
          showExtraOptions={showExtraOptions}
          placeholderText={placeholderText}
        ></PostModal>
      )}
    </div>
  );
};

export default PostToFeedComponent;
