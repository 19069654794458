import React, { Component } from "react";

import RecommendationList from "./RecommendationList";
import RecDisclaimerMessage from "../../components/Recommendation/RecDisclaimerMessage";
import { REC_MODEL_TYPE, COVID_END_DATE } from "../../constants/constants";

const covidReliefTips = (
  <div>
    Given these benefits, here are a few tips to keep in mind:
    <ul>
      <li>
        If you have private loans, you can still refinance or increase payments
        to save money and pay off your loans faster.
      </li>
      <li>
        If you have qualifying federal loans, you can make payments while your
        interest is set to 0% and save on interest. Instead of paying directly
        to your servicer, you can put this money aside in a savings account and
        pay right before relief ends to maximize your options (i.e. in case
        relief gets extended or you have other financial priorities).
      </li>
      <li>
        The Biden administration is considering student loan forgiveness.
        Snowball will keep you in the loop on any new developments as details
        continue to be released.
      </li>
    </ul>
  </div>
);

class RecommendationSummary extends Component {
  render() {
    const recModelType = this.props.recModelType;
    const recs = this.props.recs;

    let recTitle = "Want to pay off faster and save?";
    let recContext = null;
    if (recModelType === REC_MODEL_TYPE.REC_START_PAYMENT) {
      recTitle = "Options when your loans are in repayment:";
      recContext = (
        <p>
          It looks like your student loans are not in repayment. This is the
          case if you are in school, or your loans are in a grace period,
          forbearance, or deferment.
          <br />
          <br />
          Due to COVID-19 relief provisions, student loan payments are suspended
          and interest rates are set to 0% for qualifying student loans. They
          are expected to resume in {COVID_END_DATE}.
        </p>
      );
    } else if (recModelType === REC_MODEL_TYPE.REC_COVID) {
      recContext = (
        <p>
          Your payments are currently suspended and your interest rate is set to
          0% due to COVID-19 relief provisions. They are expected to resume in{" "}
          {COVID_END_DATE}.
        </p>
      );
    } else {
      recContext = (
        <p>
          Due to COVID-19 relief provisions, student loan payments are suspended
          and interest rates are set to 0% for qualifying student loans. They
          are expected to resume in {COVID_END_DATE}.
        </p>
      );
    }

    return (
      <div className="sw-container--fixed-width">
        {recContext && (
          <div className="sw-padding--bottom-lg content">
            <h2 className="subtitle sw-padding--top-lg">
              What you should know:
            </h2>
            {recContext}
            {covidReliefTips}
          </div>
        )}

        {recs && this.props.recVisible && (
          <div>
            <h2 className="subtitle has-text-weight-semibold sw-padding--top-lg">
              {recTitle}
            </h2>
            <RecommendationList
              recs={recs}
              recModelType={recModelType}
              messages={this.props.messages}
              baseRoute={this.props.baseRoute}
            />
          </div>
        )}

        <div className="sw-padding--top-xl">
          <RecDisclaimerMessage></RecDisclaimerMessage>
        </div>
      </div>
    );
  }
}

export default RecommendationSummary;
