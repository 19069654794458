import React from "react";

export const ImageIconSqr = ({ iconSrc, altText, size, style }) => {
  let imgSize = "40";
  let className = "sw-icon";

  if (size === "small") {
    imgSize = "24";
    className += " is-small";
  } else if (size === "large") {
    imgSize = "212";
    className += " is-large";
  } else if (size === "menu") {
    imgSize = "36";
    className += " is-menu";
  } else if (size === "challenge") {
    imgSize = "110";
    className += " is-challenge";
  } else if (size === "60") {
    imgSize = "60";
    className += " is-60";
  } else if (size === "80") {
    imgSize = "80";
    className += " is-80";
  } else if (size === "160") {
    imgSize = "160";
    className += " is-160 is-rounded";
  }

  return (
    <img
      className={className}
      style={style}
      src={iconSrc}
      alt={altText}
      width={imgSize}
      height={imgSize}
    />
  );
};
