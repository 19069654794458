import { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getPricingPlans } from "../../store/actions/premiumActions";
import { setConfirmMessage } from "../../store/actions/alertingActions";
import { usePremiumPlanCheckout } from "../Premium/usePremiumPlanCheckout";
import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";

const usePremiumUpsell = (returnUrl, eventMetadata) => {
  const dispatch = useDispatch();
  const onPremiumCheckout = usePremiumPlanCheckout(returnUrl);

  const [productId, setProductId] = useState("");
  const [priceId, setPriceId] = useState("");
  const [freeTrial, setFreeTrial] = useState("");

  const { prices, isLoading } = useSelector((state) => {
    return state.premium;
  });

  useEffect(() => {
    // get pricing info
    if (!prices && !isLoading) {
      // optional return url
      dispatch(getPricingPlans());
    }
  }, [prices, isLoading, dispatch]);

  useEffect(() => {
    if (prices && prices.digital_monthly) {
      setProductId(prices.digital_monthly.price.product.id);
      setPriceId(prices.digital_monthly.price.id);
      setFreeTrial(prices.digital_monthly.free_trial);
    }
  }, [prices, productId, priceId, freeTrial]);

  const onClick = useCallback(
    (e) => {
      dispatch(
        setConfirmMessage(
          "Upgrade to Premium",
          "Elevate your skills with unlimited courses at just $10/month. Cancel anytime.",
          isLoading ? "Loading..." : "Try now",
          () => {
            dispatch(
              trackEvent(EVENTS.START_DIGITAL_TRIAL_CLICKED, eventMetadata)
            );
            onPremiumCheckout(productId, priceId, freeTrial);
          },
          false
        )
      );
    },
    [
      dispatch,
      onPremiumCheckout,
      isLoading,
      productId,
      priceId,
      freeTrial,
      eventMetadata,
    ]
  );

  return onClick;
};

export default usePremiumUpsell;
