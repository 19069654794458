import React from "react";

import { ImageIconSqr } from "../Icon/ImageIcon";
import { ICONS } from "../../constants/images";

const WhatIsRefiBox = () => {
  const prosList = [
    "Pay less interest",
    "Reduce monthly payment",
    "One payment with one lender (if refinance all of your loans)",
  ];
  const consList = [
    "Lose federal benefits and protections",
    "Lenders may not be flexible if you experience financial hardship",
    "Must hit eligibility requirements",
  ];

  return (
    <div className="sw-layout-container--no-padding no-margin-top is-background-white">
      <div className="sw-padding--bottom-lg">
        <p className="subtitle sw-what-is-refi__line-one">
          What is student loan refinancing?
        </p>
        <p className="sw-what-is-refi__line-two">
          Student loan refinancing is when a private lender pays off your
          existing loans and gives you a new loan with new terms. Refinancing is
          free and can save you thousands of dollars by lowering your interest
          rate.
        </p>
      </div>
      <div className="sw-what-is-refi__pros-cons-container columns">
        <div className="sw-what-is-refi__pros-cons-list column">
          <p>PROS</p>
          {prosList && (
            <ul>
              {prosList.map((item, index) => {
                return (
                  <li key={index}>
                    <ImageIconSqr
                      iconSrc={ICONS.CHECK_GREEN}
                      alt="green check"
                      size="small"
                    />
                    <span className="sw-padding--left-md">{item}</span>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        <div className="sw-what-is-refi__pros-cons-list column">
          <p>CONSIDERATIONS</p>
          {consList && (
            <ul>
              {consList.map((item, index) => {
                return (
                  <li key={index}>
                    <ImageIconSqr
                      iconSrc={ICONS.ARROW_BLUE}
                      alt="blue arrow"
                      size="small"
                    />
                    <span className="sw-padding--left-md">{item}</span>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default WhatIsRefiBox;
