import { STUDENT_DASHBOARD_ACTIONS } from "../reducers/studentDashboardReducer";
import { apiGet } from "./apiActions";
import { setError } from "./errorActions";

export const getStudentDashboard = () => {
  return (dispatch, getState) => {
    const endpoint = "/api/student_loan_dashboard/loans";
    dispatch({ type: STUDENT_DASHBOARD_ACTIONS.GET });

    const onSuccess = (res) => {
      dispatch({ type: STUDENT_DASHBOARD_ACTIONS.GET_SUCCESS, res });
    };

    const onError = (err) => {
      const errMessage =
        "There was an error getting your data. Please contact the Snowball team and we'll help you resolve this.";

      dispatch({
        type: STUDENT_DASHBOARD_ACTIONS.GET_ERROR,
        err: err,
      });
      dispatch(setError(err, errMessage));
    };

    dispatch(apiGet(endpoint, onSuccess, onError));
  };
};
