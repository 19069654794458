import React from "react";
import { Link } from "react-router-dom";

import ROUTES from "../../constants/routes";

const RefiThreeSteps = () => {
  return (
    <div className="sw-refi-steps-box box">
      <h2 className="subtitle sw-padding--bottom-md">
        Don't know where to start?
      </h2>
      <p className="sw-title--big">
        How to refinance in{" "}
        <span className="has-text-primary">3 easy steps</span>
      </p>
      <p className="sw-padding--bottom-lg">
        When you refinance student loans, you get a loan with a new lender and a
        different interest rate, monthly payment, and payoff date. This can help
        you save money.
      </p>

      <Link className="button" to={ROUTES.STUDENT_REFI_STEPS}>
        See more
      </Link>
    </div>
  );
};

export default RefiThreeSteps;
