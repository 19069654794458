import React from "react";
import { Link } from "react-router-dom";

import ChallengeBadge from "./ChallengeBadge";

import ROUTES from "../../constants/routes";
import { getDaysLeftString, getProgressPercent } from "./challengeUtils";

const ChallengeCard = ({ challengeObj, joinFn }) => {
  const title = challengeObj.title;
  const hasJoined = challengeObj.progress && challengeObj.progress.has_joined;
  const progressObj = challengeObj.progress;
  let progressPercent = 0;
  if (progressObj && progressObj.account_id && challengeObj.has_started) {
    progressPercent = getProgressPercent(
      progressObj.goal_progress,
      progressObj.goal_amount
    );
  }

  const daysLeftString = getDaysLeftString(
    challengeObj.start_date,
    challengeObj.end_date
  );

  const onJoinClick = () => {
    return joinFn(challengeObj.challenge_id);
  };

  if (!challengeObj) {
    return <div></div>;
  }

  return (
    <Link
      to={`${ROUTES.CHALLENGE_PREFIX}${challengeObj.challenge_id}`}
      className={
        challengeObj.has_ended
          ? "box sw-challenge-preview has-ended"
          : "box sw-challenge-preview"
      }
    >
      <div className="columns is-mobile">
        <div className="column">
          <div className="sw-padding--bottom-md">
            <p className="title">{hasJoined ? title : `Join the ${title}`}</p>
          </div>
          {daysLeftString && <div className="">{daysLeftString}</div>}
        </div>
        <div className="column is-narrow">
          <div className="sw-padding--top-md sw-full-width">
            <div className="sw-full-width sw-padding--top-md sw-padding--bottom-md">
              <ChallengeBadge
                badgeDrawingInfo={challengeObj.badge_drawing_info}
                progressPercent={progressPercent}
              ></ChallengeBadge>
            </div>
          </div>
        </div>
      </div>
      {!hasJoined && !challengeObj.has_ended && (
        <button
          className="button is-rounded is-primary sw-is-wide"
          onClick={onJoinClick}
        >
          Join
        </button>
      )}
    </Link>
  );
};

export default ChallengeCard;
