import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useOutletContext } from "react-router-dom";

import { getUserFinancialProfile } from "../../store/actions/profileActions";
import PersonalInfo from "../../components/Profile/PersonalInfo";
import ProfileSection from "../../components/Profile/ProfileSection";
import BillingInfo from "../../components/Profile/BillingSection";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

const ProfilePage = ({
  getUserFinancialProfileFn,
  isLoading,
  currentUser,
  profileObj,
}) => {
  useEffect(() => {
    getUserFinancialProfileFn();
  }, [getUserFinancialProfileFn]);

  const setPageTitle = useOutletContext();
  useEffect(() => {
    setPageTitle("Account settings");
  }, [setPageTitle]);

  if (isLoading) {
    return (
      <div className="sw-flex-layout--column-center">
        <LoadingSpinner></LoadingSpinner>
      </div>
    );
  }

  return (
    <div className="sw-flex-layout--column-center">
      <div className="sw-container--fixed-width sw-is-wide">
        <PersonalInfo currentUser={currentUser}></PersonalInfo>
        <br />
        {false && profileObj && (
          <ProfileSection profileObj={profileObj}></ProfileSection>
        )}
        <BillingInfo></BillingInfo>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserFinancialProfileFn: () => dispatch(getUserFinancialProfile()),
  };
};

const mapStateToProps = (state, ownProps) => {
  const isLoading = state.profile.getLoading || state.studentLoans.getLoading;
  const currentUser = state.auth.currentUser;

  return {
    isLoading: isLoading,
    profileObj: state.profile.profile,
    currentUser: currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
