import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import PasswordForgetForm from "../../components/Form/PasswordForgetForm";
import {
  sendResetPasswordEmail,
  clearForm,
} from "../../store/actions/authActions";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

import { BackLink } from "../../components/Buttons/BackButtons";
import ROUTES from "../../constants/routes";
import { LINK_URLS } from "../../constants/constants";
import { GRAPHICS } from "../../constants/images";

const EmailSentSuccess = (props) => {
  return (
    <div className="sw-content sw-content--wide">
      <h1 className="title">
        <span role="img" aria-label="Closed Lock With Key Emoji">
          🔐
        </span>{" "}
        Instructions sent!
      </h1>
      <div className="sw-onboarding-body has-text-centered">
        <p className="subtitle ">
          Instructions for resetting your password have been sent to{" "}
          {props.email}.
        </p>
        <p>
          You’ll receive this email within 5 minutes. Be sure to check your spam
          folder, too.
        </p>
      </div>
    </div>
  );
};

class PasswordForgetPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clickedNext: false,
      email: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.clearForm();
  }

  onSubmit(email) {
    this.setState({ email: email, clickedNext: true });
    this.props.sendResetPasswordEmail(email);
  }

  render() {
    const showForm = !this.state.clickedNext || this.props.resetPwError;

    let content = (
      <EmailSentSuccess email={this.state.email}></EmailSentSuccess>
    );
    if (this.props.resetPwLoading) {
      content = <LoadingSpinner></LoadingSpinner>;
    } else if (showForm) {
      content = (
        <PasswordForgetForm onSubmit={this.onSubmit}></PasswordForgetForm>
      );
    }

    return (
      <div className="sw-flex-layout--column-center">
        <div className="is-flex is-align-self-flex-start	sw-padding--bottom-md">
          <BackLink routeLink={ROUTES.SIGN_IN} text={"Back"}></BackLink>
        </div>
        <div className="box box--signedout">{content}</div>
        <p className="has-text-centered">
          Don't have an account yet?{" "}
          <Link to={ROUTES.SIGN_UP}>Create account</Link>
        </p>
        <div className="has-text-centered sw-padding--top-lg">
          <p className="sw-is-x-small">Get the app.</p>
          <div className="sw-padding--top-md">
            <a
              aria-label="Download on the App Store"
              href={LINK_URLS.APP_STORE_LINK}
              tabIndex="0"
            >
              <img
                height="40"
                alt="Download on the App Store"
                className=""
                src={GRAPHICS.APP_STORE_BADGE}
              />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    resetPwLoading: state.auth.resetPwLoading,
    resetPwError: state.auth.resetPwError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendResetPasswordEmail: (email) => dispatch(sendResetPasswordEmail(email)),
    clearForm: () => dispatch(clearForm()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForgetPage);
