import React, { useState } from "react";

import SelectAccountModal from "../SelectAccountFlow/SelectAccountModal";

const SelectAccountButton = ({
  challengeId,
  challengeType,
  className,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClickFn = () => {
    setIsOpen(true);
  };

  const onDone = () => {
    setIsOpen(false);
  };

  return (
    <div>
      {isOpen && (
        <SelectAccountModal
          onClose={() => setIsOpen(false)}
          isOpen={isOpen}
          challengeId={challengeId}
          challengeType={challengeType}
          onSuccess={onDone}
        ></SelectAccountModal>
      )}
      <button
        className={className ? className : "button is-rounded"}
        onClick={onClickFn}
      >
        {children}
      </button>
    </div>
  );
};

export default SelectAccountButton;
