import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";

// components and helpers
import DefaultLayout from "../PageLayouts/DefaultLayout";
import RequireAuth from "../RouteWrappers/RequireAuth";
import { LogoFullNav } from "../NavBar/LogoNav";

// onboarding pages
import OverviewPage from "../../pages/OnboardingPages/OverviewPage";

import QuestionsOne from "../../pages/OnboardingPages/QuestionsOne";
import QuestionsTwo from "../../pages/OnboardingPages/QuestionsTwo";
import PlaidConnectPage from "../../pages/OnboardingPages/PlaidConnectPage";
import PlaidConnectSuccess from "../../pages/OnboardingPages/PlaidConnectSuccess";

// not found page
import NotFoundPage from "../../pages/NotFoundPage/NotFoundPage";

const OnboardingRoutes = ({ component: Component, ...rest }) => {
  return (
    <RequireAuth>
      <DefaultLayout hideAllNav={true}>
        <div className="sw-onboarding-root">
          <LogoFullNav isLoggedIn={true}></LogoFullNav>
          <Outlet />
        </div>
      </DefaultLayout>
    </RequireAuth>
  );
};

const RoutesOnboarding = () => {
  return (
    <Routes>
      <Route path="/" element={<OnboardingRoutes />}>
        <Route index element={<OverviewPage />} />
        <Route path="step/1" element={<QuestionsOne />} />
        <Route path="step/2" element={<QuestionsTwo />} />
        <Route path="step/3" element={<PlaidConnectPage />} />
        <Route path="done" element={<PlaidConnectSuccess />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default RoutesOnboarding;
