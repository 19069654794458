import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useOutletContext } from "react-router-dom";

import { LINK_URLS } from "../../constants/constants";

import { getUserFinancialProfile } from "../../store/actions/profileActions";
import {
  setStickyBanner,
  clearStickyBanner,
} from "../../store/actions/alertingActions";
import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";
import { CREDIT_SCORE_OPTIONS_MAP } from "../../store/actions/profileActions";
import { ImpactedByCovidAnnouncement } from "../../components/Messages/AnnouncementBanner";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import MatchedLendersStudent from "../../components/Lenders/MatchedLendersStudent";
import WhatIsRefiBox from "../../components/Refi/WhatIsRefiBox";
import RefiSuccessStories from "../../components/Refi/RefiSuccessStories";
import RefiFAQ from "../../components/Refi/RefiFAQ";
import RefiThreeSteps from "../../components/Refi/RefiThreeSteps";

const LendersStudentMarketplace = (props) => {
  const trackEventFn = props.trackEvent;
  const getProfileFn = props.getProfile;
  const setStickyBannerFn = props.setStickyBanner;
  const clearStickyBannerFn = props.clearStickyBanner;

  const setPageTitle = useOutletContext();
  useEffect(() => {
    setPageTitle("Student loan refinancing");
  }, [setPageTitle]);

  useEffect(() => {
    trackEventFn(EVENTS.LENDERS_VIEWED);
    getProfileFn();
  }, [trackEventFn, getProfileFn]);

  useEffect(() => {
    setStickyBannerFn(
      "Have questions? Talk to a financial coach",
      LINK_URLS.SNOWBALL_CONSULT
    );
    return function cleanup() {
      clearStickyBannerFn();
    };
  }, [setStickyBannerFn, clearStickyBannerFn]);

  if (props.loading) {
    return (
      <div className="sw-flex-layout--column-center">
        <LoadingSpinner></LoadingSpinner>
      </div>
    );
  }

  return (
    <div>
      <WhatIsRefiBox />
      <div className="sw-flex-layout--column-center sw-padding--top-lg sw-padding--bottom-xl">
        <ImpactedByCovidAnnouncement></ImpactedByCovidAnnouncement>
        <MatchedLendersStudent
          creditScore={props.creditScore}
          hasCosigner={props.hasCosigner}
        ></MatchedLendersStudent>
        <RefiSuccessStories />
      </div>
      <div className="sw-layout-container--no-padding no-margin-bottom is-background-blue">
        <RefiThreeSteps />
        <RefiFAQ></RefiFAQ>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: () => dispatch(getUserFinancialProfile()),
    setStickyBanner: (text, link) => dispatch(setStickyBanner(text, link)),
    clearStickyBanner: () => dispatch(clearStickyBanner()),
    trackEvent: (event) => dispatch(trackEvent(event)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const loading = state.profile.getLoading;
  let creditScore = null;
  let hasCosigner = false;

  if (state.profile.profile) {
    creditScore = state.profile.profile.credit_score;
    // If bad credit score, assume has a cosigner and show lenders with cosigners
    if (creditScore === CREDIT_SCORE_OPTIONS_MAP.bad) {
      hasCosigner = true;
    }
  }

  return {
    loading: loading,
    creditScore: creditScore,
    hasCosigner: hasCosigner,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LendersStudentMarketplace);
