import React from "react";
import { connect } from "react-redux";

import { trackEvent, EVENTS } from "../../store/actions/analyticsActions";

const CourseBlogPreview = ({ blog, trackEventFn }) => {
  return (
    <div className="sw-course-blog-preview is-flex">
      <a
        className="content"
        // target="_blank"
        // rel="noopener noreferrer"
        href={blog.url}
        onClick={() =>
          trackEventFn(EVENTS.BLOG_POST_CLICKED, { post: blog.url })
        }
      >
        <img src={blog.image} alt="" className="" />
        <div className="subtitle">{blog.title}</div>
        <div className="sw-is-grey-800">{blog.description}</div>
      </a>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackEventFn: (eventName, properties) =>
      dispatch(trackEvent(eventName, properties)),
  };
};

export default connect(null, mapDispatchToProps)(CourseBlogPreview);
