import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";

import {
  blockUser,
  deleteUser,
  updateUserData,
} from "../../store/actions/adminActions";
import { setConfirmMessage } from "../../store/actions/alertingActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  useFormFields,
  useFormCheckbox,
} from "../../components/Form/useFormFields";
import FormControl from "@material-ui/core/FormControl";
import { getUsers } from "../../store/actions/adminActions";
import Checkbox from "../../components/Input/Checkbox";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

const AdminUserManagePage = ({
  userId,
  setConfirmMessageFn,
  deleteUserFn,
  getUsersFn,
  updateUserDataFn,
  blockUserFn,
}) => {
  const { isLoading, usersList } = useSelector((state) => {
    return state.admin;
  });

  const [userObj, setUserObj] = useState(null);
  const [doneDeleting, setDoneDeleting] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const [fields, handleFieldChange, setValues] = useFormFields({
    newEmail: "",
  });

  const [checkboxFields, handleCheckFieldChange, setCheckboxValues] =
    useFormCheckbox({
      isVerified: false,
      isBlocked: false,
    });

  useEffect(() => {
    if (usersList && usersList.length > 0) {
      setUserObj(usersList[0]);
    }
  }, [usersList]);

  useEffect(() => {
    getUsersFn({ user_id: userId });
  }, [getUsersFn, userId]);

  useEffect(() => {
    if (userObj && userObj.is_verified != null && !checkboxFields.isVerified) {
      setCheckboxValues({
        isVerified: userObj.is_verified,
        isBlocked: userObj.is_blocked,
      });
    }
  }, [userObj, setCheckboxValues, checkboxFields.isVerified]);

  useEffect(() => {
    if (userObj && userObj.email && !fields.newEmail) {
      setValues({ newEmail: userObj.email });
    }
  }, [userObj, setValues, fields.newEmail]);

  const toggleDelete = () => {
    setShowDelete(!showDelete);
  };

  const onDeleteUserData = (userId) => {
    deleteUserFn(userId);
    setDoneDeleting(true);
  };

  const onDeleteClicked = (userId) => {
    setConfirmMessageFn(
      "Delete User Data",
      "Are you sure you want to do this? This action cannot be undone.",
      "Delete",
      () => onDeleteUserData(userId),
      true
    );
  };

  const updateEmail = (userId) => {
    updateUserDataFn(userId, fields.newEmail, null);
  };

  const updateIsVerified = (userId) => {
    updateUserDataFn(userId, null, checkboxFields.isVerified);
  };

  const updateIsBlocked = (userId) => {
    blockUserFn(userId, checkboxFields.isBlocked);
  };

  if (isLoading) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  if (doneDeleting) {
    return (
      <div className="fs-block sw-container--fixed-width">
        <h3 className="sw-section-header">Delete User</h3>
        <p className="sw-padding--bottom-md">
          User has been deleted.
          <br />
          <br />
          <strong>
            Please refresh and verify data is deleted for this user.
          </strong>
        </p>
      </div>
    );
  }

  return (
    <div className="fs-block sw-container--fixed-width">
      <h3 className="sw-section-header">Update user email</h3>
      <div className="field sw-padding--bottom-xl">
        <label className="label has-text-weight-normal">New email</label>
        <div className="control">
          <input
            name="newEmail"
            id="newEmail"
            className="input is-medium fs-block"
            type="text"
            maxLength={255}
            value={fields.newEmail}
            onChange={handleFieldChange}
          />
        </div>
        <div>
          <button
            className="button is-primary"
            onClick={() => updateEmail(userId)}
          >
            Update email
          </button>
        </div>
      </div>

      <h3 className="sw-section-header">Verify user</h3>
      <div className="field sw-padding--bottom-xl">
        <label className="label has-text-weight-normal">User is verified</label>
        <FormControl
          component="fieldset"
          className="sw-onboarding-body fs-block"
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxFields.isVerified}
                onChange={handleCheckFieldChange}
                value="isVerified"
                id="isVerified"
              />
            }
            label="Is verified"
          />
        </FormControl>
        <div>
          <button
            className="button is-primary"
            onClick={() => updateIsVerified(userId)}
          >
            Update is_verified
          </button>
        </div>
      </div>

      <h3 className="sw-section-header">Block user</h3>
      <div className="field sw-padding--bottom-xl">
        <label className="label has-text-weight-normal">User is blocked</label>
        <FormControl
          component="fieldset"
          className="sw-onboarding-body fs-block"
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxFields.isBlocked}
                onChange={handleCheckFieldChange}
                value="isBlocked"
                id="isBlocked"
              />
            }
            label="Is blocked"
          />
        </FormControl>
        <div>
          <button
            className="button is-primary"
            onClick={() => updateIsBlocked(userId)}
          >
            Update is_blocked
          </button>
        </div>
      </div>

      <h3 className="sw-section-header">Delete User</h3>
      <p className="sw-padding--bottom-md">
        Deleting a user cannot be undone. Please be careful when taking this
        action.
      </p>

      <div className="sw-padding--bottom-md">
        <button className="button is-warning" onClick={toggleDelete}>
          {showDelete ? "Hide " : "Show "} Delete button
        </button>
      </div>
      {showDelete && (
        <div>
          <button
            className="button is-danger is-outlined"
            onClick={() => onDeleteClicked(userId)}
          >
            Delete User Data
          </button>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setConfirmMessageFn: (title, message, confirmText, onConfirm, isDanger) =>
      dispatch(
        setConfirmMessage(title, message, confirmText, onConfirm, isDanger)
      ),
    deleteUserFn: (userId) => dispatch(deleteUser(userId)),
    getUsersFn: (fields) => dispatch(getUsers(fields)),
    updateUserDataFn: (userId, newEmail, isVerified) =>
      dispatch(updateUserData(userId, newEmail, isVerified)),
    blockUserFn: (userId, isBlocked) => dispatch(blockUser(userId, isBlocked)),
  };
};

export default connect(null, mapDispatchToProps)(AdminUserManagePage);
