import React from "react";

import "./FormErrors.css";

const FormErrors = ({ formRules, field }) => {
  const fieldRules = formRules.filter((rule) => rule.field === field);

  return (
    <ul className="validation-list">
      {fieldRules.map((filteredRule) => {
        return (
          <li
            className={filteredRule.valid ? "help success" : "help is-danger"}
            key={filteredRule.id}
          >
            {filteredRule.name}
          </li>
        );
      })}
    </ul>
  );
};

export default FormErrors;

export const FormFieldError = ({ fieldName, fieldError, isValid }) => {
  return (
    <ul className="validation-list">
      <li
        className={isValid ? "help success" : "help is-danger"}
        key={fieldName}
      >
        {fieldError}
      </li>
    </ul>
  );
};
