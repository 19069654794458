import React from "react";
import helpers from "../../services/helpers";

const PlanBoxLi = ({ text }) => {
  return (
    <li className="is-flex is-align-items-center sw-padding--bottom-sm">
      <span className="icon is-small">
        <i className="fa fa-arrow-right"></i>
      </span>
      <span className="sw-padding--left-sm">{text}</span>
    </li>
  );
};

export const GenericPlanBox = ({
  title,
  priceString,
  planFeatures,
  learnMoreLink,
  additionalClassName,
  buttonClassName,
  onButtonClicked,
  disabled,
  buttonText,
}) => {
  return (
    <div
      className={
        additionalClassName
          ? `box sw-container-box sw-flex-layout--column ${additionalClassName}`
          : "box sw-container-box sw-flex-layout--column"
      }
    >
      <p className="is-size-4 has-underlined-accent-title">{title}</p>
      <p className="is-size-4 has-text-weight-bold sw-padding--top-lg">
        {priceString}
      </p>
      {planFeatures && (
        <ul className="sw-padding--top-lg is-flex-grow-1">
          {planFeatures.map((feature, index) => {
            return <PlanBoxLi key={index} text={feature} />;
          })}
        </ul>
      )}
      {learnMoreLink && (
        <a
          className="sw-padding--top-lg"
          href={learnMoreLink}
          target="_blank"
          rel="noreferrer"
        >
          Learn more
        </a>
      )}

      <div className="sw-padding--top-lg">
        <button
          className={`button is-rounded is-fullwidth is-medium ${buttonClassName}`}
          onClick={onButtonClicked}
          disabled={disabled}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

const PlanBox = ({
  title,
  priceId,
  productId,
  priceUnit,
  priceInterval,
  planFeatures,
  currentPlan,
  additionalClassName,
  learnMoreLink,
  freeTrial,
  onButtonClicked,
}) => {
  let priceString = `${helpers.currencyFormat(priceUnit / 100, 0)}/`;
  if (priceInterval) {
    priceString += priceInterval;
  }

  const hasThisPlan = currentPlan === productId;

  let buttonClassName = "is-light";
  let buttonText = "";
  if (currentPlan) {
    // first check if has a plan
    if (hasThisPlan) {
      buttonText = "Current plan";
    } else {
      buttonText = "Change plan";
    }
  } else {
    // otherwise, has no plan yet
    if (freeTrial) {
      buttonText = "Try free for 30 days";
      buttonClassName = "is-primary";
    } else {
      buttonText = "Start now";
    }
  }

  const onClick = () => {
    onButtonClicked(productId, priceId, freeTrial);
  };

  return (
    <GenericPlanBox
      title={title}
      priceString={priceString}
      planFeatures={planFeatures}
      learnMoreLink={learnMoreLink}
      additionalClassName={additionalClassName}
      buttonClassName={buttonClassName}
      onButtonClicked={onClick}
      disabled={hasThisPlan}
      buttonText={buttonText}
    />
  );
};

export default PlanBox;
