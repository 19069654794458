import React from "react";
import { connect } from "react-redux";

import UsersList from "../../components/UsersList/UsersList";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { getUsers } from "../../store/actions/adminActions";
import { useFormFields } from "../../components/Form/useFormFields";
import NumberInput, {
  NUMBER_INPUT_TYPES,
} from "../../components/Input/NumberInput";
import TextInput from "../../components/Input/TextInput";

const AdminUsersPage = ({ getUsersFn, getUsersLoading, allUsers }) => {
  const [fields, handleFieldChange] = useFormFields({
    user_id: "",
    email: "",
    display_name: "",
  });

  const onSubmitForm = (event) => {
    event.preventDefault();
    getUsersFn(fields);
  };

  if (getUsersLoading) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  return (
    <div className="fs-block">
      <h1 className="title">User Lookup</h1>
      <br />
      <form id="sw-search-user" onSubmit={(event) => onSubmitForm(event)}>
        <div className="">
          <p className="sw-padding--bottom-lg">
            Search for a user by the following:
          </p>

          <div className="columns">
            <div className="column">
              <TextInput
                label="Email"
                name="email"
                value={fields.email}
                onChange={handleFieldChange}
                placeholder="User email"
              ></TextInput>
            </div>
            <div className="column">
              <TextInput
                label="User name"
                name="display_name"
                value={fields.display_name}
                onChange={handleFieldChange}
                placeholder="Display name"
              ></TextInput>
            </div>
            <div className="column">
              <NumberInput
                label="User id"
                name="user_id"
                value={fields.user_id}
                onChange={handleFieldChange}
                numberType={NUMBER_INPUT_TYPES.INTEGER}
                placeholder="User id"
              ></NumberInput>
            </div>
          </div>
        </div>
        <br />
        <button
          form="sw-search-user"
          type="submit"
          className="button is-primary"
        >
          Search for user
        </button>
      </form>

      <br />
      <br />
      {allUsers && <UsersList users={allUsers} />}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    getUsersLoading: state.admin.getUsersLoading,
    allUsers: state.admin.usersList,
    currentUser: state.auth.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsersFn: (fields) => dispatch(getUsers(fields)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsersPage);
