import { AUTH_ACTIONS } from "./authReducer";

const initState = {
  communities: null,
  feedItems: null,
  feedItem: null,
  pagination: null,
  newCommunity: null,
  userCommunities: null,
  updatedFeedItem: null,
  updatedFeedItemWithUniversalLink: null,
  getCommunitiesLoading: false,
  createCommunityLoading: false,
  editFeedItemLoading: false,
  editUniversalLinkAttachmentLoading: false,
  editCommunityLoading: false,
  userCommunitiesLoading: false,
  getFeedItemLoading: false,
  getFeedLoading: false,
  adminError: null,
};

const ADMIN_PREFIX = "ADMIN/";

export const ADMIN_COMMUNITY_ACTIONS = {
  CREATE_COMMUNITY: ADMIN_PREFIX + "CREATE_COMMUNITY",
  CREATE_COMMUNITY_SUCCESS: ADMIN_PREFIX + "CREATE_COMMUNITY_SUCCESS",
  CREATE_COMMUNITY_ERROR: ADMIN_PREFIX + "CREATE_COMMUNITY_ERROR",
  GET_COMMUNITIES: ADMIN_PREFIX + "GET_COMMUNITIES",
  GET_COMMUNITIES_SUCCESS: ADMIN_PREFIX + "GET_COMMUNITIES_SUCCESS",
  GET_COMMUNITIES_ERROR: ADMIN_PREFIX + "GET_COMMUNITIES_ERROR",
  EDIT_COMMUNITY: ADMIN_PREFIX + "EDIT_COMMUNITY",
  EDIT_COMMUNITY_SUCCESS: ADMIN_PREFIX + "EDIT_COMMUNITY_SUCCESS",
  EDIT_COMMUNITY_ERROR: ADMIN_PREFIX + "EDIT_COMMUNITY_ERROR",
  GET_FEED: ADMIN_PREFIX + "GET_FEED",
  GET_FEED_SUCCESS: ADMIN_PREFIX + "GET_FEED_SUCCESS",
  GET_FEED_ERROR: ADMIN_PREFIX + "GET_FEED_ERROR",
  GET_FEED_ITEM: ADMIN_PREFIX + "GET_FEED_ITEM",
  GET_FEED_ITEM_SUCCESS: ADMIN_PREFIX + "GET_FEED_ITEM_SUCCESS",
  GET_FEED_ITEM_ERROR: ADMIN_PREFIX + "GET_FEED_ITEM_ERROR",
  USER_COMMUNITIES: ADMIN_PREFIX + "USER_COMMUNITIES",
  USER_COMMUNITIES_SUCCESS: ADMIN_PREFIX + "USER_COMMUNITIES_SUCCESS",
  USER_COMMUNITIES_ERROR: ADMIN_PREFIX + "USER_COMMUNITIES_ERROR",
  EDIT_UNIVERSAL_LINK_ATTACHMENT:
    ADMIN_PREFIX + "EDIT_UNIVERSAL_LINK_ATTACHMENT",
  EDIT_UNIVERSAL_LINK_ATTACHMENT_SUCCESS:
    ADMIN_PREFIX + "EDIT_UNIVERSAL_LINK_ATTACHMENT_SUCCESS",
  EDIT_UNIVERSAL_LINK_ATTACHMENT_ERROR:
    ADMIN_PREFIX + "EDIT_UNIVERSAL_LINK_ATTACHMENT_ERROR",
  EDIT_FEED_ITEM: ADMIN_PREFIX + "EDIT_FEED_ITEM",
  EDIT_FEED_ITEM_SUCCESS: ADMIN_PREFIX + "EDIT_FEED_ITEM_SUCCESS",
  EDIT_FEED_ITEM_ERROR: ADMIN_PREFIX + "EDIT_FEED_ITEM_ERROR",
};

const adminCommunitiesReducer = (state = initState, action) => {
  switch (action.type) {
    case ADMIN_COMMUNITY_ACTIONS.CREATE_COMMUNITY:
      return {
        ...state,
        adminError: null,
        newCommunity: null,
        createCommunityLoading: true,
      };
    case ADMIN_COMMUNITY_ACTIONS.CREATE_COMMUNITY_SUCCESS:
      return {
        ...state,
        newCommunity: action.payload.res.data,
        createCommunityLoading: false,
      };
    case ADMIN_COMMUNITY_ACTIONS.CREATE_COMMUNITY_ERROR:
      return {
        ...state,
        adminError: action.err.message,
        createCommunityLoading: false,
      };
    case ADMIN_COMMUNITY_ACTIONS.GET_COMMUNITIES:
      return {
        ...state,
        adminError: null,
        communities: null,
        getCommunitiesLoading: true,
      };
    case ADMIN_COMMUNITY_ACTIONS.GET_COMMUNITIES_SUCCESS:
      return {
        ...state,
        communities: action.res.data.communities,
        getCommunitiesLoading: false,
      };
    case ADMIN_COMMUNITY_ACTIONS.USER_COMMUNITIES_ERROR:
      return {
        ...state,
        adminError: action.err.message,
        userCommunitiesLoading: false,
      };
    case ADMIN_COMMUNITY_ACTIONS.USER_COMMUNITIES:
      return {
        ...state,
        adminError: null,
        userCommunities: null,
        userCommunitiesLoading: true,
      };
    case ADMIN_COMMUNITY_ACTIONS.USER_COMMUNITIES_SUCCESS:
      return {
        ...state,
        adminError: null,
        userCommunities: action.res.data.communities,
        userCommunitiesLoading: false,
      };
    case ADMIN_COMMUNITY_ACTIONS.GET_COMMUNITIES_ERROR:
      return {
        ...state,
        adminError: action.err.message,
        getCommunitiesLoading: false,
      };

    case ADMIN_COMMUNITY_ACTIONS.EDIT_COMMUNITY:
      return {
        ...state,
        adminError: null,
        editCommunityLoading: true,
      };
    case ADMIN_COMMUNITY_ACTIONS.EDIT_COMMUNITY_SUCCESS:
      // we get back updated challenge, do nothing with it for now
      // editCommunity: action.res.data,

      return {
        ...state,
        editCommunityLoading: false,
      };
    case ADMIN_COMMUNITY_ACTIONS.EDIT_COMMUNITY_ERROR:
      return {
        ...state,
        adminError: action.err.message,
        editCommunityLoading: false,
      };
    case ADMIN_COMMUNITY_ACTIONS.GET_FEED:
      return {
        ...state,
        feedItems: null,
        pagination: null,
        getFeedLoading: true,
        adminError: null,
      };
    case ADMIN_COMMUNITY_ACTIONS.GET_FEED_SUCCESS:
      return {
        ...state,
        feedItems: action.res.data.feed_items,
        pagination: action.res.data.pagination,
        getFeedLoading: false,
        adminError: null,
      };
    case ADMIN_COMMUNITY_ACTIONS.GET_FEED_ERROR:
      return {
        ...state,
        feedItems: null,
        pagination: null,
        getFeedLoading: false,
        adminError: action.err.message,
      };
    case ADMIN_COMMUNITY_ACTIONS.GET_FEED_ITEM:
      return {
        ...state,
        feedItem: null,
        getFeedItemLoading: true,
        adminError: null,
      };
    case ADMIN_COMMUNITY_ACTIONS.GET_FEED_ITEM_SUCCESS:
      return {
        ...state,
        feedItem: action.payload.res.data.feed_item,
        getFeedItemLoading: false,
        adminError: null,
      };
    case ADMIN_COMMUNITY_ACTIONS.GET_FEED_ITEM_ERROR:
      return {
        ...state,
        feedItem: null,
        getFeedItemLoading: false,
        adminError: action.err.message,
      };
    case ADMIN_COMMUNITY_ACTIONS.EDIT_UNIVERSAL_LINK_ATTACHMENT:
      return {
        ...state,
        updatedFeedItemWithUniversalLink: null,
        editUniversalLinkAttachmentLoading: true,
        adminError: null,
      };
    case ADMIN_COMMUNITY_ACTIONS.EDIT_UNIVERSAL_LINK_ATTACHMENT_SUCCESS:
      return {
        ...state,
        updatedFeedItemWithUniversalLink: action.payload.res.data,
        editUniversalLinkAttachmentLoading: false,
        adminError: null,
      };
    case ADMIN_COMMUNITY_ACTIONS.EDIT_UNIVERSAL_LINK_ATTACHMENT_ERROR:
      return {
        ...state,
        updatedFeedItemWithUniversalLink: null,
        editUniversalLinkAttachmentLoading: false,
        adminError: action.err.message,
      };
    case ADMIN_COMMUNITY_ACTIONS.EDIT_FEED_ITEM:
      return {
        ...state,
        updatedFeedItem: null,
        editFeedItemLoading: true,
        adminError: null,
      };
    case ADMIN_COMMUNITY_ACTIONS.EDIT_FEED_ITEM_SUCCESS:
      return {
        ...state,
        updatedFeedItem: action.payload.res.data,
        editFeedItemLoading: false,
        adminError: null,
      };
    case ADMIN_COMMUNITY_ACTIONS.EDIT_FEED_ITEM_ERROR:
      return {
        ...state,
        updatedFeedItem: null,
        editFeedItemLoading: false,
        adminError: action.err.message,
      };
    case AUTH_ACTIONS.SIGNOUT:
      return initState; //Always return the initial state
    default:
      return state;
  }
};

export default adminCommunitiesReducer;
