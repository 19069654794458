import { AUTH_ACTIONS } from "./authReducer";

const initState = {
  feedItems: null,
  pagination: null,
  reactions: null,
  communities: null,
  postCommentsDict: {},
  err: null,
  isLoading: false,
  isCommentsLoading: false,
  isFeedItemsLoading: false,
};

const ACTION_PREFIX = "COMMUNITIES/";

export const COMMUNITIES_ACTIONS = {
  GET_FEED: ACTION_PREFIX + "GET_FEED",
  GET_FEED_ERROR: ACTION_PREFIX + "GET_FEED_ERROR",
  GET_FEED_SUCCESS: ACTION_PREFIX + "GET_FEED_SUCCESS",
  GET_FEED_ITEM_SUCCESS: ACTION_PREFIX + "GET_FEED_ITEM_SUCCESS",
  GET_FEED_COMMENTS: ACTION_PREFIX + "GET_FEED_COMMENTS",
  GET_FEED_COMMENTS_ERROR: ACTION_PREFIX + "GET_FEED_COMMENTS_ERROR",
  GET_FEED_COMMENTS_SUCCESS: ACTION_PREFIX + "GET_FEED_COMMENTS_SUCCESS",
  UPDATE_COMMENT_SUCCESS: ACTION_PREFIX + "UPDATE_COMMENT_SUCCESS",
  GET_COMMUNITIES: ACTION_PREFIX + "GET_COMMUNITIES",
  GET_COMMUNITIES_ERROR: ACTION_PREFIX + "GET_COMMUNITIES_ERROR",
  GET_COMMUNITIES_SUCCESS: ACTION_PREFIX + "GET_COMMUNITIES_SUCCESS",
  JOIN: ACTION_PREFIX + "JOIN",
  JOIN_ERROR: ACTION_PREFIX + "JOIN_ERROR",
  JOIN_SUCCESS: ACTION_PREFIX + "JOIN_SUCCESS",
  REACTION: ACTION_PREFIX + "REACTION",
  REACTION_ERROR: ACTION_PREFIX + "REACTION_ERROR",
  REACTION_SUCCESS: ACTION_PREFIX + "REACTION_SUCCESS",
};

const communitiesReducer = (state = initState, action) => {
  switch (action.type) {
    case COMMUNITIES_ACTIONS.GET_FEED:
      return {
        ...state,
        err: null,
        isFeedItemsLoading: true,
      };
    case COMMUNITIES_ACTIONS.GET_COMMUNITIES:
    case COMMUNITIES_ACTIONS.JOIN:
      return {
        ...state,
        err: null,
        isLoading: true,
      };
    case COMMUNITIES_ACTIONS.GET_FEED_COMMENTS:
      return {
        ...state,
        err: null,
        isCommentsLoading: true,
      };
    case COMMUNITIES_ACTIONS.GET_FEED_SUCCESS:
      /***
       * We use this in a few cases:
       * 1. Loading the page for the first time
       * 2. Infinite scroll, when we need to append
       * 3. Making a new post
       * 4. Joining a new community, refreshing feed
       */

      let newFeedItems = [];
      let isDuplicateRequest = false;

      if (action.res.data.pagination && action.res.data.pagination.page > 1) {
        // Case 2: We are beyond page 1 and need to append existing items
        if (state.feedItems) {
          newFeedItems = newFeedItems.concat(state.feedItems);
        }

        // Double check this is not a duplicate request. This only
        // applies if page > 1, since we could be refreshing the feed
        if (
          state.pagination &&
          state.pagination.page === action.res.data.pagination.page
        ) {
          isDuplicateRequest = true;
        }
      }

      // then add the new items
      if (!isDuplicateRequest) {
        newFeedItems = newFeedItems.concat(action.res.data.feed_items);
      }

      return {
        ...state,
        feedItems: newFeedItems,
        pagination: action.res.data.pagination,
        reactions: action.res.data.reaction_emoji_dict,
        err: null,
        isFeedItemsLoading: false,
      };
    case COMMUNITIES_ACTIONS.GET_FEED_ITEM_SUCCESS:
      /***
       * We use this to display one feed item
       */
      return {
        ...state,
        feedItems: [action.res.data.feed_item],
        reactions: action.res.data.reaction_emoji_dict,
        err: null,
        isFeedItemsLoading: false,
      };
    case COMMUNITIES_ACTIONS.GET_FEED_COMMENTS_SUCCESS:
      const newPostCommentsDict = Object.assign(
        {},
        state.postCommentsDict,
        action.commentDict
      );
      return {
        ...state,
        postCommentsDict: newPostCommentsDict,
        err: null,
        isCommentsLoading: false,
      };
    case COMMUNITIES_ACTIONS.UPDATE_COMMENT_SUCCESS:
      const feedItemUuid = action.data.feed_item_uuid;
      const commentUuid = action.data.comment_uuid;
      const commentObj = action.data;

      // clone existing comments dict
      const newCommentsDict = Object.assign({}, state.postCommentsDict);

      // update data inside of the comments dict
      if (newCommentsDict[feedItemUuid]) {
        let feedItemComments = newCommentsDict[feedItemUuid].comments;
        // find the matching comment from comments list
        const indexOfComment = feedItemComments.findIndex(
          (x) => x.comment_uuid === commentUuid
        );
        // now, update this comment
        feedItemComments[indexOfComment] = commentObj;
      }

      return {
        ...state,
        postCommentsDict: newCommentsDict,
        err: null,
        isCommentsLoading: false,
      };

    case COMMUNITIES_ACTIONS.GET_COMMUNITIES_SUCCESS:
      return {
        ...state,
        communities: action.res.data.communities,
        err: null,
        isLoading: false,
      };
    case COMMUNITIES_ACTIONS.JOIN_SUCCESS:
      // need to update the community that matches id
      const newCommunityItem = action.res.data;
      const updatedCommunities = state.communities.map((currCommunity) =>
        currCommunity.community_uuid === newCommunityItem.community_uuid
          ? newCommunityItem
          : currCommunity
      );
      return {
        ...state,
        communities: updatedCommunities,
        err: null,
        isLoading: false,
      };
    case COMMUNITIES_ACTIONS.GET_FEED_ERROR:
      return {
        ...state,
        err: action.err.message,
        isFeedItemsLoading: false,
      };
    case COMMUNITIES_ACTIONS.GET_FEED_COMMENTS_ERROR:
      return {
        ...state,
        err: action.err.message,
        isCommentsLoading: false,
      };
    case COMMUNITIES_ACTIONS.GET_COMMUNITIES_ERROR:
    case COMMUNITIES_ACTIONS.JOIN_ERROR:
      return {
        ...state,
        err: action.err.message,
        isLoading: false,
        isCommentsLoading: false,
      };
    case COMMUNITIES_ACTIONS.REACTION_SUCCESS:
      // need to update the feed item that matches id
      const newFeedItem = action.res.data.feed_item;
      const updatedFeedItems = state.feedItems.map((existingFeedItem) =>
        existingFeedItem.feed_item_uuid === action.feedItemUpdated
          ? newFeedItem
          : existingFeedItem
      );
      return {
        ...state,
        feedItems: updatedFeedItems,
      };
    case AUTH_ACTIONS.SIGNOUT:
      return initState; //Always return the initial state
    default:
      return state;
  }
};

export default communitiesReducer;
