import React from "react";

const EmojiIcon = (props) => {
  const emoji = props.emoji;
  const emojiName = props.emojiName;

  let className = "sw-rec-emoji";
  let dimensions = "50px";
  if (props.size === "small") {
    dimensions = "30px";
    className += " is-small";
  }

  return (
    <figure className={className} width={dimensions} height={dimensions}>
      <span role="img" aria-label={`${emojiName} Emoji`}>
        {emoji}
      </span>
    </figure>
  );
};

export default EmojiIcon;
