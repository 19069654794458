import React from "react";

import ExpandableBox from "../Box/ExpandableBox";
import VideoPlayer from "../../components/Blog/VideoPlayer";

import { VIDEO_LINKS } from "../../constants/constants";

const FAQsection = () => {
  const contentNetWorth = (
    <div>
      <p className="sw-padding--bottom-lg">
        Your net worth is the combination of your assets (e.g. cash,
        investments) minus your debts (e.g. credit card debt, student loans).
        <br />
        <br />
        At Snowball Wealth, we believe in progress over perfection. It's okay if
        you're starting out with a negative net worth. Our mission is to give
        you the tools you need to pay down debt and start building wealth.
      </p>
      <div className="is-mobile is-multiline sw-padding--bottom-sm">
        <div className="" key={VIDEO_LINKS.NET_WORTH}>
          <VideoPlayer
            link={VIDEO_LINKS.NET_WORTH}
            className="sw-react-player"
            width="100%"
            height="100%"
          ></VideoPlayer>
        </div>
      </div>
    </div>
  );

  const contentData = (
    <p>
      At Snowball Wealth, your privacy and security is our top priority. We use
      bank-level security to protect your data at all times, using 256-bit
      encryption to protect your data at rest and on transit.
      <br /> <br />
      We do not sell your personal information to or share it with unaffiliated
      third parties for their own advertising or marketing purposes. Check out
      our{" "}
      <a href="https://www.snowballwealth.com/legal/privacy">
        Privacy Policy
      </a>{" "}
      for more information.
      <br /> <br />
      Our team is on the lookout for suspicious activity and will respond to
      security threats quickly, and will alert you if there is any suspicious
      activity on your account.
    </p>
  );

  const contentPlaid = (
    <p>
      We use <a href="https://plaid.com">Plaid</a> to connect your loan accounts
      and bank institutions to Snowball Wealth. Plaid supports thousands of
      financial institutions including major banks and many local credit unions.
      <br />
      <br />
      You are asked for your credentials when connecting accounts, which Plaid
      uses to communicate with your financial institution securely. Snowball
      Wealth does not store or have access to your credentials in any way.
    </p>
  );

  return (
    <div className="sw-padding--top-lg">
      <p className="has-text-weight-semibold sw-padding--bottom-md">
        Frequently asked questions
      </p>
      <ExpandableBox
        title="What is net worth?"
        content={contentNetWorth}
        startExpanded={true}
      ></ExpandableBox>
      <ExpandableBox
        title="How is my data protected?"
        content={contentData}
      ></ExpandableBox>
      <ExpandableBox
        title="What is Plaid?"
        content={contentPlaid}
      ></ExpandableBox>
    </div>
  );
};

export default FAQsection;
