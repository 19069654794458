import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { postAdminRecommendation } from "../../store/actions/recommendationActions";
import { getMatchedLendersPersonal } from "../../store/actions/lendersActions";
import RecDetailsStudent from "../../components/Recommendation/RecDetailsStudent";
import RecDetailsPersonal from "../../components/Recommendation/RecDetailsPersonal";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

import { REC_CATEGORIES } from "../../constants/constants";

const AdminUserRecDetailsPage = ({
  postAdminRecommendationFn,
  getMatchedLendersPersonalFn,
  isLoading,
  allRecs,
  baseCase,
  lenders,
}) => {
  const { userId, recType } = useParams();

  let recObj = null;
  if (allRecs) {
    const filteredRecs = allRecs.filter((rec) => rec.type === recType);
    if (filteredRecs) {
      recObj = filteredRecs[0];
    }
  }

  useEffect(() => {
    postAdminRecommendationFn(userId);
    getMatchedLendersPersonalFn();
  }, [userId, postAdminRecommendationFn, getMatchedLendersPersonalFn]);

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  if (!isLoading && recObj) {
    return (
      <div className="sw-flex-layout--column-center">
        {recObj.category === REC_CATEGORIES.STUDENT && baseCase && (
          <RecDetailsStudent
            rec={recObj}
            baseCase={baseCase}
          ></RecDetailsStudent>
        )}

        {recObj.category === REC_CATEGORIES.PERSONAL && lenders && (
          <RecDetailsPersonal
            rec={recObj}
            lenders={lenders}
          ></RecDetailsPersonal>
        )}
        <br />

        <button onClick={goBack} className="button is-dark is-medium">
          Go Back
        </button>
      </div>
    );
  }

  return (
    <div className="sw-flex-layout--column-center">
      <LoadingSpinner></LoadingSpinner>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    postAdminRecommendationFn: (userId) =>
      dispatch(postAdminRecommendation(userId)),
    getMatchedLendersPersonalFn: () => dispatch(getMatchedLendersPersonal()),
  };
};

const mapStateToProps = (state, ownProps) => {
  const allRecs = state.recommendations.recommendations;
  let baseCase = state.recommendations.current;
  let lenders = null;

  if (state.lenders.matchedLendersPersonal) {
    lenders = state.lenders.matchedLendersPersonal;
  }

  return {
    allRecs: allRecs,
    lenders: lenders,
    baseCase: baseCase,
    isLoading:
      state.recommendations.getLoading || state.lenders.getLendersLoading,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUserRecDetailsPage);
