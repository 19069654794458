import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getUsers } from "../../store/actions/adminActions";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

const AdminUserIAPPage = ({ isLoading, getUsersFn, userId, userObj }) => {
  useEffect(() => {
    getUsersFn({ user_id: userId });
  }, [getUsersFn, userId]);

  if (isLoading) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  return (
    <div className="fs-block sw-container--fixed-width">
      <h3 className="sw-section-header">IAP Subscriptions</h3>
      <div className="field sw-padding--bottom-xl">
        <div className="tile">
          {userObj.iap_subscriptions && (
            <pre>{JSON.stringify(userObj.iap_subscriptions, null, 2)}</pre>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const userId = ownProps.userId;
  let userObj = null;

  if (state.admin.usersList && state.admin.usersList.length > 0) {
    userObj = state.admin.usersList[0];
  }

  return {
    userId: userId,
    userObj: userObj,
    isLoading: state.admin.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsersFn: (fields) => dispatch(getUsers(fields)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserIAPPage);
