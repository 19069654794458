import { useState, useEffect } from "react";
import ROUTES from "../../constants/routes";

const BASE_APP_URL = process.env.REACT_APP_USERS_SERVICE_URL;

const useGetPostLink = (feedItemUuid) => {
  const [copiedDone, setCopiedDone] = useState(false);

  useEffect(() => {
    if (copiedDone) {
      const interval = setInterval(() => {
        setCopiedDone(false);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [copiedDone]);

  const onCopyFn = async () => {
    const linkToPost = `${BASE_APP_URL}/app-redirect${ROUTES.COMMUNITY_POST}/${feedItemUuid}`;
    await navigator.clipboard.writeText(linkToPost);
    setCopiedDone(true);
  };

  return [copiedDone, onCopyFn];
};

export default useGetPostLink;
