import { COURSE_ACCESS, COURSE_TYPES } from "../../constants/constants";

export const getDefaultCourseFields = (course) => {
  if (course) {
    return {
      courseUuid: course.course_uuid,
      courseType: course.course_type,
      courseAccess: course.course_access
        ? course.course_access
        : COURSE_ACCESS[0],
      referenceName: course.reference_name,
      slug: course.slug,
      linkedCommunityUuid: course.linked_community_uuid,
      durationInDays: course.duration_in_days,
      groupDueDateOffset: course.group_due_date_offset,
      isActive: course.is_active,
      courseTasks: course.course_tasks,
      cohorts: course.cohorts,
    };
  } else {
    return {
      courseType: COURSE_TYPES[0],
      courseAccess: COURSE_ACCESS[0],
      referenceName: "",
      slug: "",
      linkedCommunityUuid: "",
      durationInDays: 28, // defaults to a month
      groupDueDateOffset: 7, // defaults to a week
      isActive: false,
      courseTasks: [],
      cohorts: [],
    };
  }
};
