import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useOutletContext } from "react-router-dom";

import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";
import {
  asyncGetChallenges,
  asyncJoinChallenge,
} from "../../store/actions/challengesActions";
import ChallengesSections from "../../components/Challenges/ChallengesSections";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

const ChallengesPage = ({
  trackEventFn,
  getChallengesFn,
  asyncJoinChallengeFn,
  isLoading,
  challenges,
}) => {
  // set page title
  const setPageTitle = useOutletContext();
  useEffect(() => {
    setPageTitle("Challenges");
  }, [setPageTitle]);

  useEffect(() => {
    trackEventFn(EVENTS.CHALLENGES_VIEWED);
  }, [trackEventFn]);

  useEffect(() => {
    // get challenges
    getChallengesFn();
  }, [getChallengesFn]);

  const onJoinFn = (challengeId) => {
    trackEventFn(EVENTS.CHALLENGE_JOINED, { challenge_id: challengeId });
    trackEventFn(`${EVENTS.CHALLENGE_JOINED} ${challengeId}`);
    asyncJoinChallengeFn(challengeId).then(() => {
      // refetch challenges
      getChallengesFn();
    });
  };

  if (isLoading) {
    return (
      <div className="sw-flex-layout--column-center">
        <LoadingSpinner></LoadingSpinner>
      </div>
    );
  }

  return (
    <div className="sw-challenges-page-container">
      {challenges && challenges.challenges && (
        <ChallengesSections
          challenges={challenges.challenges}
          joinFn={onJoinFn}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackEventFn: (event, properties) =>
      dispatch(trackEvent(event, properties)),
    getChallengesFn: () => dispatch(asyncGetChallenges()),
    asyncJoinChallengeFn: (challengeId) =>
      dispatch(asyncJoinChallenge(challengeId)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.challenges.isLoading,
    challenges: state.challenges,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChallengesPage);
