import React from "react";
import { IDR_PLANS_OPTIONS } from "../../constants/federalPrograms";

const IdrPlansDropdown = ({ selectedOption, setOption }) => {
  return (
    <div className="select fs-block">
      <select
        name="idrPlansDropdown"
        value={selectedOption}
        onChange={(e) => setOption(e.target.value)}
      >
        {IDR_PLANS_OPTIONS.map((sortOption) => {
          return (
            <option key={sortOption} value={sortOption}>
              {sortOption}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default IdrPlansDropdown;
