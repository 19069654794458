import React from "react";

import CommunityThumbnail from "../../components/Communities/CommunityThumbnail";

const ExploreCommunities = ({ communities }) => {
  return (
    <div className="sw-explore-communities-section">
      {communities &&
        communities.map((communityObj, index) => {
          return (
            <CommunityThumbnail
              key={communityObj.community_uuid}
              communityObj={communityObj}
            ></CommunityThumbnail>
          );
        })}
    </div>
  );
};

export default ExploreCommunities;
