import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { PremiumTag } from "../Box/Tags";
import { ImageIconSqr } from "../Icon/ImageIcon";
import { ICONS } from "../../constants/images";
import { TASK_STEP_MAP } from "../../constants/constants";

import usePremiumUpsell from "./usePremiumUpsell";

// import hooks for routing
import { useCourseRoutes } from "./useCourseRouting";

import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";

const TaskItem = ({ course, taskObj, hasPremium }) => {
  const content = taskObj.content;
  const isComplete = taskObj.progress.is_completed;
  const taskNumber = taskObj.task.task_position;
  const showPremiumUpsell = !(hasPremium || content.is_free);

  const dispatch = useDispatch();

  // custom hooks to get routes
  const { getCourseRoute, getNextTask } = useCourseRoutes();
  const CURRENT_COURSE_ROUTE = getCourseRoute(course);

  // event metadata to send if click premium button
  const eventMetadata = {
    plan_slug: course.course.slug,
    course_type: course.course.course_type,
    cohort_uuid: course.cohort.cohort_uuid,
  };
  const triggerPremiumUpsell = usePremiumUpsell(
    CURRENT_COURSE_ROUTE,
    eventMetadata
  );

  const onBlockRoute = (e) => {
    e.preventDefault();
    triggerPremiumUpsell();
    dispatch(
      trackEvent(EVENTS.PREMIUM_TASK_CLICKED, {
        plan_slug: course.course.slug,
        course_type: course.course.course_type,
        cohort_uuid: course.cohort.cohort_uuid,
        task_slug: taskObj.task.storyblok_slug,
        level_slug: taskObj.task.group_position,
      })
    );
  };

  const linkRoute = showPremiumUpsell
    ? CURRENT_COURSE_ROUTE
    : getNextTask(course, taskObj, TASK_STEP_MAP.Initial);

  return (
    <Link
      className="sw-task-box columns is-gapless is-mobile"
      onClick={showPremiumUpsell ? onBlockRoute : null}
      to={linkRoute}
    >
      <div className="column is-narrow sw-padding--right-md">
        <div className="sw-course-task-img">
          <img
            src={content.cover_photo_image.filename}
            alt={content.cover_photo_image.alt}
          />
        </div>
      </div>
      <div className="column">
        <p className="sw-padding--left-md">
          {`Step ${taskNumber}`}
          {isComplete && (
            <span className="is-pulled-right">
              <ImageIconSqr
                iconSrc={ICONS.CHECK_GREEN}
                alt="green check"
                size="small"
              />
            </span>
          )}
        </p>
        <p className="sw-padding--left-md">{content.title}</p>
        {showPremiumUpsell && (
          <div className="sw-padding--left-md">
            <PremiumTag></PremiumTag>
          </div>
        )}
      </div>
    </Link>
  );
};

const TasksContainer = ({ course, tasks, hasPremium }) => {
  return (
    <div className="">
      {tasks &&
        tasks.map((value, keyIndex) => {
          return (
            <TaskItem
              key={value.task.course_task_uuid}
              taskObj={value}
              course={course}
              hasPremium={hasPremium}
            ></TaskItem>
          );
        })}
    </div>
  );
};

export default TasksContainer;
