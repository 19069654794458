import React, { useMemo } from "react";

import Modal from "../Modal/Modal";
import TextArea from "../Input/TextArea";
import TextInput from "../Input/TextInput";
import Select from "../Input/Select";
import UserIcon from "./UserIcon";

import { useFormFields } from "../Form/useFormFields";

const FEED_ITEM_TEXT_LIMIT = 3000;

const PostModal = ({
  onClose,
  isOpen,
  onPost,
  authorProfile,
  communities,
  challengesList,
  showExtraOptions,
  placeholderText,
}) => {
  const defaultFields = useMemo(() => {
    return {
      text: "",
      mediaUrl: "",
      communityUuid: communities[0].community_uuid,
      challengeId: "",
    };
  }, [communities]);

  const [fields, handleFieldChange, setValues] = useFormFields(defaultFields);

  const newPostValidated = useMemo(() => {
    const validationState = {
      text: fields.text.length > 0,
      communityUuid: true,
    };
    return !Object.values(validationState).includes(false);
  }, [fields]);

  const footerObj = (
    <div className="sw-full-width buttons is-right">
      <button
        form="sw-make-post-form"
        type="submit"
        className="button is-primary"
        disabled={!newPostValidated}
      >
        Post
      </button>
    </div>
  );

  const onSubmitForm = (event) => {
    event.preventDefault();
    onPost(fields);
    setValues(defaultFields);
    onClose();
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      title="Create a post"
      footer={footerObj}
    >
      <form id="sw-make-post-form" onSubmit={(event) => onSubmitForm(event)}>
        <div className="sw-padding--left-lg sw-padding--right-lg">
          <article className="sw-media media">
            <div className="media-left is-hidden-mobile">
              <UserIcon authorProfile={authorProfile} />
            </div>
            <div className="media-content">
              <div className="field">
                <Select
                  id="communityUuid"
                  name="communityUuid"
                  required={true}
                  value={fields.communityUuid}
                  onChange={handleFieldChange}
                >
                  {!fields.communityUuid && (
                    <option key={""} value={""}>
                      None
                    </option>
                  )}
                  {communities
                    .filter((communityObj) => communityObj.has_joined === true)
                    .map((communityObj) => {
                      return (
                        <option
                          key={communityObj.community_uuid}
                          value={communityObj.community_uuid}
                        >
                          {communityObj.name}
                        </option>
                      );
                    })}
                </Select>
                <div className="field sw-padding--top-lg">
                  <TextArea
                    label="Start a post"
                    name="text"
                    maxLength={FEED_ITEM_TEXT_LIMIT}
                    required={true}
                    className="fs-block"
                    value={fields.text}
                    rows={4}
                    onChange={handleFieldChange}
                    placeholder={
                      placeholderText ? placeholderText : "What's happening?"
                    }
                  ></TextArea>
                </div>

                {showExtraOptions && (
                  <div className="field">
                    <TextInput
                      label="Link"
                      name="mediaUrl"
                      className="fs-block"
                      value={fields.mediaUrl}
                      onChange={handleFieldChange}
                      placeholder="Media url"
                    ></TextInput>
                  </div>
                )}

                {showExtraOptions && (
                  <div>
                    {challengesList && (
                      <div className="field">
                        <Select
                          label="Linked challenge"
                          name="challengeId"
                          value={fields.challengeId}
                          onChange={handleFieldChange}
                        >
                          {!fields.challengeId && (
                            <option key={""} value={""}>
                              None
                            </option>
                          )}

                          {challengesList &&
                            challengesList.map((challenge) => {
                              return (
                                <option
                                  key={challenge.challenge_id}
                                  value={challenge.challenge_id}
                                >
                                  {challenge.title}
                                </option>
                              );
                            })}
                        </Select>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </article>
        </div>
      </form>
    </Modal>
  );
};

export default PostModal;
