import React from "react";
import { useDispatch } from "react-redux";

import { trackEvent, EVENTS } from "../../store/actions/analyticsActions";
import { ImageIconSqr } from "../Icon/ImageIcon";

const ImageButtonBox = ({
  iconSrc,
  iconAlt,
  title,
  text,
  buttonText,
  openLink,
  additionalClassName,
}) => {
  const dispatch = useDispatch();
  const onButtonClick = () => {
    dispatch(
      trackEvent(EVENTS.RESOURCE_TEMPLATE_CLICKED, {
        link: openLink,
      })
    );
    window.open(openLink, "_blank"); // open link in new page
  };

  return (
    <div
      className={
        additionalClassName
          ? `sw-container-box ${additionalClassName}`
          : "sw-container-box"
      }
    >
      <div className="columns level sw-padding--top-lg sw-padding--bottom-lg sw-padding--right-lg sw-padding--left-lg">
        <div className="column is-narrow">
          <ImageIconSqr
            iconSrc={iconSrc}
            altText={iconAlt}
            size="60"
          ></ImageIconSqr>
        </div>
        <div className="column">
          <p className="title has-text-weight-bold sw-unset-margin">{title}</p>
          <p className="sw-padding--top-sm">{text}</p>
        </div>

        {buttonText && (
          <div className="column is-narrow">
            <button
              className="button is-light is-rounded is-medium"
              onClick={onButtonClick}
            >
              {buttonText}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageButtonBox;
