import React from "react";

import helpers from "../../services/helpers";
import {
  ACCOUNT_MANUAL_TYPE_MAP,
  ACCOUNT_SUBTYPE_MAP,
} from "../../constants/constants";

export const AccountSubtitle = ({ accountObj }) => {
  let subtitle = "";
  if (helpers.notNullnotUndefined(accountObj.interest_rate)) {
    subtitle = `${accountObj.interest_rate}% Interest Rate`;
  } else {
    if (ACCOUNT_SUBTYPE_MAP[accountObj.subtype]) {
      subtitle = ACCOUNT_SUBTYPE_MAP[accountObj.subtype];
    } else {
      subtitle = accountObj.subtype;
    }
  }

  return <p className="fs-block">{subtitle}</p>;
};

export const ManualAccountSubtitle = ({ accountObj }) => {
  let interestRate = "";
  let subtitle = "";

  if (helpers.notNullnotUndefined(accountObj.interest_rate)) {
    interestRate = `${accountObj.interest_rate}% Interest Rate `;
  }

  if (ACCOUNT_MANUAL_TYPE_MAP[accountObj.subtype]) {
    subtitle += ACCOUNT_MANUAL_TYPE_MAP[accountObj.subtype];
  } else {
    subtitle += accountObj.subtype;
  }

  return (
    <div>
      {interestRate && <p className="fs-block">{interestRate}</p>}
      <p>{subtitle}</p>
    </div>
  );
};
