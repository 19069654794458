import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "../Modal/Modal";

import { asyncGetNetWorthItems } from "../../store/actions/netWorthActions";
import { asyncUpdateChallengeProgress } from "../../store/actions/challengesActions";
import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";

import { AccountBoxWrapper } from "../Box/AccountBox";
import AddAccountButton from "../Buttons/AddAccountButton";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const SelectAccountModal = ({
  onClose,
  isOpen,
  challengeId,
  challengeType,
  onSuccess,
}) => {
  const [addedNewAccount, setAddedNewAccount] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(asyncGetNetWorthItems());
  }, [dispatch, addedNewAccount]);

  const { deposits, investments, liabilities, isLoading } = useSelector(
    (state) => {
      return state.netWorth;
    }
  );

  const onSelectAccount = (accountId) => {
    dispatch(
      trackEvent(EVENTS.CHALLENGE_SAVED, {
        challengeId: challengeId,
        account_added: true,
      })
    );
    dispatch(
      asyncUpdateChallengeProgress(challengeId, accountId, null, null)
    ).then((res) => {
      onSuccess();
    });
  };

  let accountsArray = [];
  if (challengeType === "save") {
    // use asset types if save challenge
    accountsArray = [...deposits.plaid_accounts, ...investments.plaid_accounts];
  } else if (challengeType === "payoff") {
    // use liability type if payoff challenges
    accountsArray = [
      ...liabilities.credit.plaid_accounts,
      ...liabilities.mortgage.plaid_accounts,
      ...liabilities.student.plaid_accounts,
    ];
  }

  // sort by name
  let sortFunction = (a, b) => (a.display_name > b.display_name ? 1 : -1);
  const sortedAccounts = accountsArray.sort(sortFunction);

  return (
    <Modal onClose={onClose} isOpen={isOpen} title="Select an account">
      {isLoading && <LoadingSpinner></LoadingSpinner>}

      {sortedAccounts.length < 1 && (
        <div className="is-flex-grow-1 sw-padding--left-lg sw-padding--right-lg">
          <div>
            There are no eligible accounts for this challenge type "
            {challengeType}". Please add a new account and try again.
          </div>

          <AddAccountButton
            onAddAccountSuccess={() => setAddedNewAccount(true)}
            className="button is-white sw-modal-button sw-full-width"
          ></AddAccountButton>
        </div>
      )}

      <ul className="is-flex-grow-1">
        {sortedAccounts &&
          sortedAccounts.map((accountObj, i) => (
            <li className="is-flex is-flex-grow-1" key={accountObj.account_id}>
              <button
                className="button is-white sw-modal-button"
                onClick={() => onSelectAccount(accountObj.account_id)}
              >
                <AccountBoxWrapper
                  accountObj={accountObj}
                  key={accountObj.account_id}
                  className="sw-full-width"
                  showFullDetails={false}
                ></AccountBoxWrapper>
              </button>
            </li>
          ))}
      </ul>
    </Modal>
  );
};

export default SelectAccountModal;
