import React from "react";
import snowballLogo from "./snowballLogo.png";
import snowballLogoWhite from "./snowballLogoWhite.png";
import "./logo.css";

const Logo = (props) => {
  return (
    <div className="sb-logo--wrapper" height={props.height || "28"}>
      <img
        className="sb-logo"
        src={props.isWhite ? snowballLogoWhite : snowballLogo}
        alt={props.altText || "Snowball Wealth logo"}
        width={props.width || "28"}
        height={props.height || "28"}
      />
      {!props.hideText && (
        <span
          className={
            "sb-logo--text is-size-5 " +
            (props.isMobileOptimized ? "is-hidden-mobile" : "") +
            (props.isWhite ? " has-text-white" : " has-text-primary")
          }
        >
          Snowball Wealth
        </span>
      )}
    </div>
  );
};

export default Logo;
