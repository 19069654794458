import React from "react";
import moment from "moment";

import {
  MakePaymentsConsiderations,
  RefiConsiderations,
} from "../Messages/CovidRecConsiderations";
import helpers from "../../services/helpers";
import { REC_TYPES } from "../../constants/constants";

export const RecStudentContentIntro = (props) => {
  const rec = props.rec;
  const baseCase = props.baseCase;

  const previousPayment = helpers.currencyFormat(baseCase.payment, 0);
  const previousInterest = baseCase.rate_annual;
  const newPayment = helpers.currencyFormat(rec.details.payment, 0);

  if (
    rec.type === REC_TYPES.LOWER_INTEREST ||
    rec.type === REC_TYPES.LOWER_INTEREST_REACH ||
    rec.type === REC_TYPES.REPAYMENT_LOWER_INTEREST
  ) {
    return (
      <p>
        By lowering your interest from {previousInterest}% to{" "}
        <strong className="has-text-primary">{rec.details.rate_annual}%</strong>{" "}
        you will save on interest and pay off your loan faster.
      </p>
    );
  } else if (
    rec.type === REC_TYPES.REPAYMENT_TEN ||
    rec.type === REC_TYPES.REPAYMENT_TWENTYFIVE
  ) {
    return (
      <p>
        By paying <strong className="has-text-primary">{newPayment}</strong> a
        month on your loans:
      </p>
    );
  } else if (rec.type === REC_TYPES.COVID_PAY) {
    return (
      <p>
        By making payments during COVID-19 of{" "}
        <strong className="has-text-primary">{newPayment}</strong> for the next{" "}
        {rec.details.months_no_interest}
        {rec.details.months_no_interest === 1 ? " month" : " months"}, all of
        your payments will go towards your principal.
      </p>
    );
  } else if (rec.type === REC_TYPES.COVID_POST_INCREASE) {
    return (
      <p>
        By increasing your payment from {previousPayment} to{" "}
        <strong className="has-text-primary">{newPayment}</strong> in{" "}
        {moment()
          .add(rec.details.months_no_interest, "months")
          .format("MMMM YYYY")}
        , you will save on interest and pay off your loans faster.
      </p>
    );
  } else if (rec.type === REC_TYPES.COVID_POST_LOWER_INTEREST) {
    return (
      <p>
        By lowering your interest from {previousInterest}% to{" "}
        <strong className="has-text-primary">{rec.details.rate_annual}%</strong>{" "}
        in{" "}
        {moment()
          .add(rec.details.months_no_interest, "months")
          .format("MMMM YYYY")}
        , you will save on interest and pay off your loan faster.
      </p>
    );
  }

  return (
    <p>
      By increasing your payment from {previousPayment} to{" "}
      <strong className="has-text-primary">{newPayment}</strong> you will save
      on interest and pay off your loan faster.
    </p>
  );
};

export const RecStudentContentBody = (props) => {
  const rec = props.rec;
  const baseCase = props.baseCase;
  const yearsLeft = rec.details.pay_down_length_years;

  const isRepaymentPlan =
    rec.type === REC_TYPES.REPAYMENT_TEN ||
    rec.type === REC_TYPES.REPAYMENT_TWENTYFIVE;

  if (
    rec.type === REC_TYPES.LOWER_INTEREST ||
    rec.type === REC_TYPES.LOWER_INTEREST_REACH ||
    rec.type === REC_TYPES.INCREASE_MODERATE ||
    rec.type === REC_TYPES.INCREASE_REACH ||
    rec.type === REC_TYPES.REPAYMENT_TEN ||
    rec.type === REC_TYPES.REPAYMENT_TWENTYFIVE
  ) {
    return (
      <div>
        <ul>
          {!isRepaymentPlan && rec.saved && (
            <li>
              <strong>
                You will save {helpers.currencyFormat(rec.saved, 0)} in total
              </strong>
            </li>
          )}
          <li>
            You will pay off your loan in {yearsLeft}{" "}
            {yearsLeft === 1 ? "year" : "years"}
          </li>
          <li>
            You will pay a total of{" "}
            {helpers.currencyFormat(
              rec.details.total_interest_plus_principal,
              0
            )}{" "}
            in interest and principal
          </li>
        </ul>
        {rec.type === REC_TYPES.REPAYMENT_TEN && (
          <div>
            If you choose to pay in {yearsLeft} years instead of{" "}
            {baseCase.pay_down_length_years} years:
            <ul>
              <li>
                You will save {helpers.currencyFormat(rec.saved, 0)} in total
              </li>
            </ul>
          </div>
        )}
      </div>
    );
  } else if (rec.type === REC_TYPES.REPAYMENT_LOWER_INTEREST) {
    return (
      <div>
        <p>
          To lower your interest, you will need to refinance your loans. This
          will immediately trigger repayment. We recommend you refinance when
          you are ready to start paying off your loans!
        </p>
        <p>
          Here is what it would look like if you choose to pay your loans in{" "}
          {yearsLeft} years:
        </p>
        <ul>
          <li>You will save {helpers.currencyFormat(rec.saved, 0)} in total</li>
          <li>
            You will pay {helpers.currencyFormat(rec.details.payment, 0)} a
            month
          </li>
        </ul>
      </div>
    );
  } else if (
    rec.type === REC_TYPES.COVID_PAY ||
    rec.type === REC_TYPES.COVID_POST_INCREASE ||
    rec.type === REC_TYPES.COVID_POST_LOWER_INTEREST
  ) {
    return (
      <div>
        We estimate:
        <ul>
          <li>
            <strong>
              {helpers.currencyFormat(rec.saved, 0)} in total savings
            </strong>
          </li>
          <li>
            {yearsLeft === 1 ? `${yearsLeft} year` : `${yearsLeft} years`} to
            pay off your loans
          </li>
          {rec.type === REC_TYPES.COVID_PAY && (
            <li>
              {helpers.currencyFormat(
                rec.details.payment * rec.details.months_no_interest,
                0
              )}{" "}
              paid towards your principal
            </li>
          )}
          {rec.type !== REC_TYPES.COVID_PAY && (
            <li>
              {helpers.currencyFormat(
                rec.details.total_interest_plus_principal,
                0
              )}{" "}
              in total paid interest and principal
            </li>
          )}
        </ul>
        {rec.type === REC_TYPES.COVID_PAY && (
          <MakePaymentsConsiderations></MakePaymentsConsiderations>
        )}
        {rec.type === REC_TYPES.COVID_POST_LOWER_INTEREST && (
          <RefiConsiderations></RefiConsiderations>
        )}
      </div>
    );
  }

  return <div></div>;
};
