import React, { useState, useEffect } from "react";

const TextInput = ({
  name,
  label,
  placeholder,
  className,
  value,
  required,
  onChange,
}) => {
  const id = name;
  const [isActive, setIsActive] = useState(value === "" ? false : true);
  const [isFocused, setIsFocused] = useState(false);
  const [displayPlaceholder, setDiplayPlaceholder] = useState("");

  // don't show placeholder until is active to avoid overlapping text
  useEffect(() => {
    if (placeholder && value === "" && isFocused) {
      setDiplayPlaceholder(placeholder);
    } else if (!label) {
      setDiplayPlaceholder(placeholder);
    } else {
      setDiplayPlaceholder("");
    }
  }, [value, placeholder, label, isFocused]);

  function handleTextChange(e) {
    onChange(e);

    if (e.target.value !== "") {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }

  return (
    <fieldset className="sw-label-input">
      <input
        id={id}
        name={id}
        type="text"
        value={value}
        required={required}
        className={
          label
            ? `sw-label-input_input ${className}`
            : `sw-label-input_input ${className} --no-label`
        }
        onChange={handleTextChange}
        placeholder={displayPlaceholder}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      {label && (
        <label className={isActive ? "active" : ""} htmlFor={id}>
          {label}
        </label>
      )}
    </fieldset>
  );
};

export default TextInput;
