import React, { useMemo } from "react";

const AdminEditFeedItemForm = ({
  onSubmitForm,
  fields,
  handleFieldChange,
  submitLoading,
}) => {
  const feedItemValidated = useMemo(() => {
    const validationState = {
      videoUrl: true,
      pollUuid: true,
    };
    return !Object.values(validationState).includes(false);
  }, [fields]);

  return (
    <form onSubmit={onSubmitForm}>
      <div className="field">
        <label className="label has-text-weight-normal">Video Url</label>
        <div className="control">
          <input
            name="videoUrl"
            id="videoUrl"
            className="input is-medium fs-block"
            type="text"
            maxLength={200}
            value={fields.videoUrl}
            onChange={handleFieldChange}
          />
        </div>
      </div>

      <div className="field">
        <label className="label has-text-weight-normal">Poll UUID</label>
        <div className="control">
          <input
            name="pollUuid"
            id="pollUuid"
            className="input is-medium fs-block"
            type="text"
            maxLength={200}
            value={fields.pollUuid}
            onChange={handleFieldChange}
          />
        </div>
      </div>

      <br />
      <div className="has-text-centered">
        <input
          type="submit"
          className={
            submitLoading
              ? "button is-warning is-outline is-loading"
              : "button is-warning is-outline"
          }
          value={"Submit"}
          disabled={!feedItemValidated}
        />
      </div>
    </form>
  );
};

export default AdminEditFeedItemForm;
