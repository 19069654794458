import React, { Component } from "react";
import { Link } from "react-router-dom";

import ROUTES from "../../constants/routes";
import { REC_TYPES } from "../../constants/constants";
import RecIcon from "../Icon/RecIcon";

class RepaymentOptions extends Component {
  render() {
    return (
      <div>
        <h2 className="subtitle has-text-weight-semibold sw-padding--top-lg">
          Explore repayment options:
        </h2>
        <div>
          <Link
            key="1"
            className="sw-recommendation-box box"
            to={ROUTES.FEDERAL_PROGRAMS}
          >
            <article className="media">
              <div className="media-left">
                <RecIcon recType={REC_TYPES.FEDERAL_OVERVIEW}></RecIcon>
              </div>
              <div className="media-content">
                <div className="content fs-block">
                  <p>
                    <strong>New: Federal options</strong>
                    <br />
                    The Department of Education has launched a new repayment
                    plan. The SAVE Plan replaces the Revised Pay As You Earn
                    (REPAYE) Plan and provides multiple new benefits, including
                    lower monthly payments. See what this means for you here.
                  </p>
                </div>
              </div>
            </article>
          </Link>
          <Link
            key="2"
            className="sw-recommendation-box box"
            to={ROUTES.LENDERS_STUDENT_MARKETPLACE}
          >
            <article className="media">
              <div className="media-left">
                <RecIcon recType={REC_TYPES.PRIVATE_OVERVIEW}></RecIcon>
              </div>
              <div className="media-content">
                <div className="content fs-block">
                  <p>
                    <strong>Refinancing</strong>
                    <br />
                    Do you have private loans? Learn more about refinancing.
                  </p>
                </div>
              </div>
            </article>
          </Link>
        </div>
      </div>
    );
  }
}

export default RepaymentOptions;
