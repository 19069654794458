import React, { useState } from "react";

import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

import helpers from "../../services/helpers";
import { NW_TABS, NW_CHART_BUTTONS } from "../../constants/constants";
import NetWorthChartButtons from "./NetWorthChartButtons";

const NAME_MAP = {
  net_worth: "Net worth",
  debts: "Debt",
  assets: "Assets",
};

const formatMoney = (value) => {
  return helpers.currencyFormat(value, 0);
};

const tooltipFormatter = (value, name, props) => {
  let formattedValue = "";
  if (helpers.notNullnotUndefined(value)) {
    formattedValue = formatMoney(value);
  }
  return [formattedValue, NAME_MAP[name]];
};

const getDiffValue = (timeRange, activeTab, deltasObj, invertGraph) => {
  let deltaObjForTab = null;
  let currentDiffValue = null;

  switch (activeTab) {
    case NW_TABS.NET_WORTH:
      if (deltasObj && deltasObj.net_worth) {
        deltaObjForTab = deltasObj.net_worth;
      }
      break;
    case NW_TABS.ASSETS:
      if (deltasObj && deltasObj.assets) {
        deltaObjForTab = deltasObj.assets;
      }
      break;
    case NW_TABS.DEBT:
      if (deltasObj && deltasObj.debts) {
        deltaObjForTab = deltasObj.debts;
      }
      break;
    default:
      deltaObjForTab = {
        all_time: null,
        one_month: null,
        one_year: null,
        six_month: null,
        three_month: null,
      };
      break;
  }

  switch (timeRange) {
    case NW_CHART_BUTTONS.M_ONE:
      currentDiffValue = deltaObjForTab.one_month;
      break;
    case NW_CHART_BUTTONS.M_THREE:
      currentDiffValue = deltaObjForTab.three_month;
      break;
    case NW_CHART_BUTTONS.M_SIX:
      currentDiffValue = deltaObjForTab.six_month;
      break;
    case NW_CHART_BUTTONS.Y_ONE:
      currentDiffValue = deltaObjForTab.six_month;
      break;
    default:
      currentDiffValue = deltaObjForTab.all_time;
      break;
  }

  if (invertGraph) {
    currentDiffValue = currentDiffValue * -1;
  }

  return currentDiffValue;
};

const getStartTimestamp = (timeRange, datesObj) => {
  switch (timeRange) {
    case NW_CHART_BUTTONS.M_ONE:
      return datesObj.one_month;
    case NW_CHART_BUTTONS.M_THREE:
      return datesObj.three_month;
    case NW_CHART_BUTTONS.M_SIX:
      return datesObj.six_month;
    case NW_CHART_BUTTONS.Y_ONE:
      return datesObj.one_year;
    default:
      // Case ALL, and default. Set start to earliest date in data
      return datesObj.all_time;
  }
};

const getDiffString = (timeRange) => {
  switch (timeRange) {
    case NW_CHART_BUTTONS.M_ONE:
      return "in the last month";
    case NW_CHART_BUTTONS.M_THREE:
      return "in last three months";
    case NW_CHART_BUTTONS.M_SIX:
      return "in last six months";
    case NW_CHART_BUTTONS.Y_ONE:
      return "in last year";
    default:
      // Case ALL, and default. Set start to earliest date in data
      return "since joining";
  }
};

const getEndDate = (allData) => {
  const totalEntries = allData.length;
  const lastEntry = allData[totalEntries - 1];
  return lastEntry.timestamp;
};

const DiffUp = () => {
  return (
    <span className="icon is-small sw-padding--right-md">
      <span className="icon is-small">
        <i
          key="fa fa-arrow-up"
          className="fa fa-arrow-up"
          aria-hidden="false"
        ></i>
      </span>
    </span>
  );
};

const DiffDown = () => {
  return (
    <span className="icon is-small sw-padding--right-md">
      <span className="icon is-small">
        <i
          key="fa fa-arrow-down"
          className="fa fa-arrow-down"
          aria-hidden="false"
        ></i>
      </span>
    </span>
  );
};

const NetWorthChart = ({ activeTab, showGrid, graphData }) => {
  let [timeRange, setTimeRange] = useState(NW_CHART_BUTTONS.ALL);

  const snapshotData = graphData.snapshot_data;
  const networthDeltas = graphData.net_worth_deltas;

  if (snapshotData && snapshotData.length === 0) {
    // TODO: handle empty state. For now, show nothing.
    return <div></div>;
  }

  const startDate = getStartTimestamp(timeRange, graphData.dates);
  const endDate = getEndDate(snapshotData);
  const xDomain = [() => startDate, () => endDate.valueOf()];
  const yDomain = ["auto", "auto"];
  const invertGraph = activeTab === NW_TABS.DEBT; // invert graph for debt

  let strokeColor = "#00A3B7";
  let dataKey = "net_worth";
  if (activeTab === NW_TABS.DEBT) {
    strokeColor = "#DD807B";
    dataKey = "debts";
  } else if (activeTab === NW_TABS.ASSETS) {
    strokeColor = "#5F89DA";
    dataKey = "assets";
  }

  let diffAmount = getDiffValue(
    timeRange,
    activeTab,
    networthDeltas,
    invertGraph
  );
  let diffString = getDiffString(timeRange);

  return (
    <div className="sw-net-worth-chart-container">
      {diffAmount !== null && (
        <p className="fs-block">
          <span style={{ color: strokeColor }}>
            {diffAmount > 0 && <DiffUp></DiffUp>}
            {diffAmount < 0 && <DiffDown></DiffDown>}
            {helpers.currencyFormat(Math.abs(diffAmount), 0)}
          </span>{" "}
          {diffString}
        </p>
      )}
      <ResponsiveContainer width="100%" height={190} className="fs-block">
        <LineChart data={snapshotData} margin={{ top: 20 }}>
          <Tooltip
            formatter={tooltipFormatter}
            separator=": "
            labelFormatter={helpers.getDateFromTimestamp}
          />

          {showGrid && <CartesianGrid strokeDasharray="3 3" />}
          <YAxis
            tickFormatter={formatMoney}
            width={80}
            hide={!showGrid}
            mirror={false}
            tick={{ fontSize: 12 }}
            domain={yDomain}
            reversed={invertGraph}
          />
          <XAxis
            dataKey="timestamp"
            tickFormatter={helpers.getDateFromTimestamp}
            type="number"
            domain={xDomain}
            scale="time"
            hide={!showGrid}
            tick={{ fontSize: 12 }}
          />
          <Line
            type="monotone"
            dataKey={dataKey}
            stroke={strokeColor}
            activeDot={{ r: 8 }}
            strokeWidth={3}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>

      <NetWorthChartButtons
        activeTab={activeTab}
        selectedButton={timeRange}
        setSelectedButton={setTimeRange}
      ></NetWorthChartButtons>
    </div>
  );
};

export default NetWorthChart;
