import React from "react";
import ReactionImg from "./ReactionImg";

const ReactionsList = ({ reactions, onReactClick }) => {
  if (!reactions) return <></>;

  return (
    <span className="sw-padding--left-sm sw-padding--right-sm">
      {Object.keys(reactions).map((key) => {
        const reactionObj = reactions[key];

        return (
          <button
            key={key}
            className={
              reactionObj.has_reacted
                ? "button is-small is-rounded is-success is-light"
                : "button is-small is-rounded is-white"
            }
            onClick={() => onReactClick(key)}
          >
            <ReactionImg reactionKey={key} reactionObj={reactionObj} />
            <span className="sw-padding--left-sm">{reactionObj.count}</span>
          </button>
        );
      })}
    </span>
  );
};

export default ReactionsList;
