import { AUTH_ACTIONS } from "./authReducer";

const initState = {
  challenges: null,
  newChallenge: null,
  getChallengesLoading: false,
  createChallengeLoading: false,
  editChallengeLoading: false,
  adminError: null,
};

const ADMIN_PREFIX = "ADMIN/";

export const ADMIN_CHALLENGE_ACTIONS = {
  CREATE_CHALLENGE: ADMIN_PREFIX + "CREATE_CHALLENGE",
  CREATE_CHALLENGE_SUCCESS: ADMIN_PREFIX + "CREATE_CHALLENGE_SUCCESS",
  CREATE_CHALLENGE_ERROR: ADMIN_PREFIX + "CREATE_CHALLENGE_ERROR",
  GET_CHALLENGES: ADMIN_PREFIX + "GET_CHALLENGES",
  GET_CHALLENGES_SUCCESS: ADMIN_PREFIX + "GET_CHALLENGES_SUCCESS",
  GET_CHALLENGES_ERROR: ADMIN_PREFIX + "GET_CHALLENGES_ERROR",
  EDIT_CHALLENGE: ADMIN_PREFIX + "EDIT_CHALLENGE",
  EDIT_CHALLENGE_SUCCESS: ADMIN_PREFIX + "EDIT_CHALLENGE_SUCCESS",
  EDIT_CHALLENGE_ERROR: ADMIN_PREFIX + "EDIT_CHALLENGE_ERROR",
};

const adminChallengesReducer = (state = initState, action) => {
  switch (action.type) {
    case ADMIN_CHALLENGE_ACTIONS.CREATE_CHALLENGE:
      return {
        ...state,
        adminError: null,
        newChallenge: null,
        createChallengeLoading: true,
      };
    case ADMIN_CHALLENGE_ACTIONS.CREATE_CHALLENGE_SUCCESS:
      return {
        ...state,
        newChallenge: action.payload.res.data,
        createChallengeLoading: false,
      };
    case ADMIN_CHALLENGE_ACTIONS.CREATE_CHALLENGE_ERROR:
      return {
        ...state,
        adminError: action.err.message,
        createChallengeLoading: false,
      };
    case ADMIN_CHALLENGE_ACTIONS.GET_CHALLENGES:
      return {
        ...state,
        adminError: null,
        challenges: null,
        getChallengesLoading: true,
      };
    case ADMIN_CHALLENGE_ACTIONS.GET_CHALLENGES_SUCCESS:
      return {
        ...state,
        challenges: action.res.data.challenges,
        getChallengesLoading: false,
      };
    case ADMIN_CHALLENGE_ACTIONS.GET_CHALLENGES_ERROR:
      return {
        ...state,
        adminError: action.err.message,
        getChallengesLoading: false,
      };
    case ADMIN_CHALLENGE_ACTIONS.EDIT_CHALLENGE:
      return {
        ...state,
        adminError: null,
        editChallengeLoading: true,
      };
    case ADMIN_CHALLENGE_ACTIONS.EDIT_CHALLENGE_SUCCESS:
      // we get back updated challenge, do nothing with it for now
      // editChallenge: action.res.data,

      return {
        ...state,
        editChallengeLoading: false,
      };
    case ADMIN_CHALLENGE_ACTIONS.EDIT_CHALLENGE_ERROR:
      return {
        ...state,
        adminError: action.err.message,
        editChallengeLoading: false,
      };

    case AUTH_ACTIONS.SIGNOUT:
      return initState; //Always return the initial state
    default:
      return state;
  }
};

export default adminChallengesReducer;
