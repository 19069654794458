import React, { useState } from "react";

import EditChallengeGoalModal from "./EditChallengeGoalModal";

const EditChallengeGoalButton = ({ challengeObj, challengeId, className }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClickFn = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div>
      {isOpen && (
        <EditChallengeGoalModal
          onClose={closeModal}
          isOpen={isOpen}
          challengeObj={challengeObj}
          challengeId={challengeId}
          onSuccess={closeModal}
        ></EditChallengeGoalModal>
      )}
      <button
        className={className ? className : "button is-ghost is-size-7"}
        onClick={onClickFn}
      >
        <span className="sw-padding--right-md">Edit goal</span>
        <i className="fas fa-edit" aria-hidden="true"></i>
      </button>
    </div>
  );
};

export default EditChallengeGoalButton;
