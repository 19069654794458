import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";

// components and helpers
import ROUTES, { ADMIN_ROUTES } from "../../constants/routes";
import DefaultLayout from "../PageLayouts/DefaultLayout";

// signed out pages
import LoginPage from "../../pages/LoginPage/LoginPage";
import SignoutPage from "../../pages/SignoutPage/SignoutPage";
import SignupPage from "../../pages/SignupPage/SignupPage";
import SchoolLoginPage from "../../pages/SignupPage/SchoolLoginPage";
import PasswordForgetPage from "../../pages/PasswordResetPage/PasswordForgetPage";

// ROUTES for signed in pages
import RoutesSignedIn from "./RoutesSignedIn";

// ROUTES for organization pages
import RoutesOrg from "./RoutesOrg";

// ROUTES for onboarding
import RoutesOnboarding from "./RoutesOnboarding";

// ROUTES for admin
import RoutesAdmin from "./RoutesAdmin";

const ORG_NAMES = ["schools"];

const SignedOutWrapper = ({ hideAllNav }) => {
  return (
    <DefaultLayout hideAllNav={hideAllNav} hideSideMenu={true}>
      <Outlet />
    </DefaultLayout>
  );
};

const Main = () => {
  return (
    <Routes>
      {/* Logged out routes */}
      <Route element={<SignedOutWrapper />}>
        <Route path={ROUTES.SIGN_IN} element={<LoginPage />} />
        <Route path={ROUTES.PASSWORD_FORGET} element={<PasswordForgetPage />} />
        <Route path={ROUTES.SIGN_OUT} element={<SignoutPage />} />
      </Route>
      <Route element={<SignedOutWrapper hideAllNav={true} />}>
        <Route path={ROUTES.SIGN_UP} element={<SignupPage />} />
        <Route path={ROUTES.SCHOOL_LOG_IN} element={<SchoolLoginPage />} />
      </Route>

      {/* Logged in, org routes */}
      {/* {ORG_NAMES.map((orgName) => {
        return (
          <Route
            key={orgName}
            path={`/${orgName}/*`}
            element={<RoutesOrg orgName={orgName} />}
          ></Route>
        );
      })} */}

      {/* Logged in, school routes */}
      <Route
        key={"schools"}
        path="/schools/*"
        element={<RoutesOrg orgName={"schools"} />}
      ></Route>

      {/* Logged in, core routes */}
      <Route path="/*" element={<RoutesSignedIn />}></Route>

      {/* Logged in, onboarding routes */}
      <Route
        path={`${ROUTES.ONBOARDING}/*`}
        element={<RoutesOnboarding />}
      ></Route>

      {/* ADMIN ONLY ROUTES */}
      <Route path={`${ADMIN_ROUTES.HOME}/*`} element={<RoutesAdmin />}></Route>
    </Routes>
  );
};

export default Main;
