import React from "react";
import { Link } from "react-router-dom";

const AdminCommunitiesList = ({ communities }) => {
  return (
    <div>
      <table className="table is-hoverable is-fullwidth fs-block">
        <thead>
          <tr>
            <th></th>
            <th>ID</th>
            <th>Name</th>
            <th>Is private</th>
            <th>Is global</th>
            <th>Members</th>
          </tr>
        </thead>
        <tbody>
          {communities.map((community) => {
            return (
              <tr key={community.community_uuid}>
                <td>
                  <Link to={`/admin/communities/${community.community_uuid}`}>
                    Edit
                  </Link>
                </td>
                <td>{community.community_uuid}</td>
                <td>{community.name}</td>
                <td>{community.is_private ? "true" : "false"}</td>
                <td>{community.is_global ? "true" : "false"}</td>
                <td>{community.member_count}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AdminCommunitiesList;
