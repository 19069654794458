import React, { useState } from "react";

export const ToggleCircleButton = ({ onToggle, isExpandedInit }) => {
  const [isUp, setIsUp] = useState(isExpandedInit);

  const toggleButton = () => {
    setIsUp(!isUp);
    if (onToggle) {
      onToggle();
    }
  };
  const upIcon = <i className="fas fa-chevron-up" aria-hidden="true"></i>;
  const downIcon = <i className="fas fa-chevron-down" aria-hidden="true"></i>;

  return (
    <button
      className="button is-small is-outlined is-round"
      onClick={toggleButton}
    >
      {isUp && <span className="icon is-small">{upIcon}</span>}
      {!isUp && <span className="icon is-small">{downIcon}</span>}
    </button>
  );
};
