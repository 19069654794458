import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";

import { asyncBrowseSchoolCourses } from "../../store/actions/courseActions";
import CourseOverviewBox from "../../components/FinancialPlan/CourseOverviewBox";
import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";

const CourseSection = ({ sectionTitle, coursesArr }) => {
  return (
    <div className="sw-padding--bottom-lg">
      {coursesArr && coursesArr.length > 0 && (
        <h2 className="subtitle sw-padding--top-lg">{sectionTitle}</h2>
      )}

      {coursesArr && (
        <div className="columns sw-browse-courses">
          {Object.entries(coursesArr).map(([keyName, value], keyIndex) => {
            return (
              <div className="column is-narrow" key={value.cohort.cohort_uuid}>
                <CourseOverviewBox course={value}></CourseOverviewBox>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const OrgBrowseCoursesPage = ({ asyncBrowseSchoolCourses, courses }) => {
  // set page title
  const setPageTitle = useOutletContext();
  const dispatch = useDispatch();

  useEffect(() => {
    setPageTitle("Browse Courses");
  }, [setPageTitle]);

  useEffect(() => {
    dispatch(trackEvent(EVENTS.BROWSE_PLANS_VIEWED));
  }, [dispatch]);

  useEffect(() => {
    // get all plans
    asyncBrowseSchoolCourses();
  }, [asyncBrowseSchoolCourses]);

  return (
    <div className="sw-container--fixed-width sw-is-max-wide">
      {courses && (
        <div>
          <h1 className="sw-title--big sw-padding--top-lg">My Courses</h1>

          <CourseSection
            sectionTitle="In progress"
            coursesArr={courses.active}
          ></CourseSection>
          <CourseSection
            sectionTitle="Available"
            coursesArr={courses.available}
          ></CourseSection>
          <CourseSection
            sectionTitle="Completed courses"
            coursesArr={courses.completed}
          ></CourseSection>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    asyncBrowseSchoolCourses: (slug) =>
      dispatch(asyncBrowseSchoolCourses(slug)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    courses: state.courses.courses,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgBrowseCoursesPage);
