import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";

import { asyncBrowseCourses } from "../../store/actions/courseActions";
import CourseOverviewBox from "../../components/FinancialPlan/CourseOverviewBox";
import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";

const CourseSection = ({ sectionTitle, coursesArr }) => {
  return (
    <div className="sw-padding--bottom-lg">
      {coursesArr && coursesArr.length > 0 && (
        <h2 className="subtitle sw-padding--top-lg">{sectionTitle}</h2>
      )}

      {coursesArr && coursesArr.length > 0 && (
        <div className="columns sw-browse-courses">
          {Object.entries(coursesArr).map(([keyName, value], keyIndex) => {
            return (
              <div className="column is-narrow" key={value.cohort.cohort_uuid}>
                <CourseOverviewBox course={value}></CourseOverviewBox>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const BrowseCoursesPage = ({ asyncBrowseCourses, courses }) => {
  // set page title
  const setPageTitle = useOutletContext();
  const dispatch = useDispatch();

  useEffect(() => {
    setPageTitle("Browse Courses");
  }, [setPageTitle]);

  useEffect(() => {
    dispatch(trackEvent(EVENTS.BROWSE_PLANS_VIEWED));
  }, [dispatch]);

  useEffect(() => {
    // get all plans
    asyncBrowseCourses();
  }, [asyncBrowseCourses]);

  return (
    <div className="sw-container--fixed-width sw-is-max-wide">
      {courses && (
        <div>
          <CourseSection
            sectionTitle="In progress"
            coursesArr={courses.active}
          ></CourseSection>
          <CourseSection
            sectionTitle="Available"
            coursesArr={courses.available}
          ></CourseSection>
          <CourseSection
            sectionTitle="Completed courses"
            coursesArr={courses.completed}
          ></CourseSection>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    asyncBrowseCourses: (slug) => dispatch(asyncBrowseCourses(slug)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    courses: state.courses.courses,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrowseCoursesPage);
