import React from "react";
import { connect } from "react-redux";
import { clearError } from "../../store/actions/errorActions";

const ErrorMessage = (props) => {
  return (
    <article className="message is-danger">
      <div className="message-body">
        {props.title && (
          <span>
            <strong>{props.title}</strong> -
          </span>
        )}
        <span>{props.message}</span>
        <button
          className="delete is-pulled-right"
          aria-label="delete"
          onClick={props.clearError}
        ></button>
      </div>
    </article>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearError: () => dispatch(clearError()),
  };
};

export default connect(null, mapDispatchToProps)(ErrorMessage);
