import React from "react";
import { Link } from "react-router-dom";

import { CovidTag, ManualEntryTag } from "../Box/Tags";
import { AccountSubtitle, ManualAccountSubtitle } from "./AccountSubtitles";
import UpdateManualAccountButton from "../Buttons/UpdateManualAccountButton";

import helpers from "../../services/helpers";
import ROUTES from "../../constants/routes";

const hasAccountDetailsPage = (accountType, accountSubtype) => {
  if (accountType === "depository" || accountType === "investment") {
    return false;
  }

  if (accountType === "loan") {
    if (accountSubtype === "student" || accountSubtype === "mortgage") {
      return true;
    }
  }

  if (accountType === "credit") {
    return true;
  }

  return false;
};

export const AccountBox = ({ accountObj, className, showFullDetails }) => {
  return (
    <React.Fragment>
      <div
        className={
          className
            ? `${className} columns is-gapless is-mobile`
            : "columns is-gapless is-mobile"
        }
      >
        <div className="column">
          <p className="fs-block has-text-weight-bold">
            {accountObj.display_name}
            {accountObj.name ? ` - ${accountObj.name}` : ""}
          </p>
          <AccountSubtitle accountObj={accountObj}></AccountSubtitle>
          {showFullDetails && accountObj.covid_relief && <CovidTag></CovidTag>}
          {showFullDetails && accountObj.plaid_error_code && (
            <Link
              className="help is-danger has-text-weight-normal"
              to={ROUTES.PROFILE_ACCOUNTS}
            >
              Error updating account
            </Link>
          )}
        </div>
        <div className="column has-text-right">
          <p className="has-text-weight-bold fs-block">
            {helpers.currencyFormat(
              accountObj.total,
              2,
              accountObj.account_details
                ? accountObj.account_details.iso_currency_code
                : ""
            )}
          </p>
          {showFullDetails &&
            hasAccountDetailsPage(
              accountObj.account_type,
              accountObj.subtype
            ) && (
              <Link
                className="is-size-7"
                to={`${ROUTES.ACCOUNT}/${accountObj.subtype}/${accountObj.account_details.account_id}`}
              >
                Learn More
              </Link>
            )}
        </div>
      </div>
    </React.Fragment>
  );
};

export const ManualAccountBox = ({
  accountObj,
  className,
  showFullDetails,
}) => {
  return (
    <React.Fragment>
      <div
        className={
          className
            ? `${className} columns is-gapless is-mobile`
            : "columns is-gapless is-mobile"
        }
      >
        <div className="column">
          <p className="fs-block has-text-weight-bold">
            {accountObj.display_name}
          </p>
          <ManualAccountSubtitle
            accountObj={accountObj}
          ></ManualAccountSubtitle>
          {showFullDetails && <ManualEntryTag></ManualEntryTag>}
        </div>
        <div className="column has-text-right">
          <p className="has-text-weight-bold fs-block">
            {helpers.currencyFormat(accountObj.total, 2)}
          </p>
          {showFullDetails && (
            <UpdateManualAccountButton
              accountObj={accountObj}
            ></UpdateManualAccountButton>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
