import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useOutletContext } from "react-router-dom";

import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";
import { asyncPostRecommendation } from "../../store/actions/recommendationActions";
import { getMatchedLendersPersonal } from "../../store/actions/lendersActions";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import RecDisclaimerMessage from "../../components/Recommendation/RecDisclaimerMessage";
import RecDetailsPersonal from "../../components/Recommendation/RecDetailsPersonal";

import { BackLink } from "../../components/Buttons/BackButtons";
import ROUTES from "../../constants/routes";

const RecPagePersonalDebt = ({
  getMatchedLendersPersonalFn,
  trackEventFn,
  allRecs,
  lenders,
  isLoading,
}) => {
  let { recType } = useParams();
  let recObj = null;

  if (allRecs) {
    const filteredRecs = allRecs.filter((rec) => rec.type === recType);
    if (filteredRecs && filteredRecs.length > 0) {
      recObj = filteredRecs[0];
    }
  }

  // fetch latest recs
  useEffect(() => {
    getMatchedLendersPersonalFn();
  }, [getMatchedLendersPersonalFn]);

  // set page title
  const setPageTitle = useOutletContext();
  useEffect(() => {
    setPageTitle(""); // set blank for now, should revisit at some point
  }, [setPageTitle]);

  // track page with rec obj data
  useEffect(() => {
    const properties = { rec_type: "", is_recommended: "" };
    if (recObj) {
      properties.rec_category = recObj.rec_category;
      properties.rec_type = recObj.type;
      properties.is_recommended = recObj.is_recommended;
    }
    trackEventFn(EVENTS.REC_DETAILS_VIEWED, properties);
  }, [trackEventFn, recObj]);

  if (isLoading) {
    return (
      <div className="sw-flex-layout--column-center">
        <LoadingSpinner></LoadingSpinner>
      </div>
    );
  }

  return (
    <div className="sw-flex-layout--column-center">
      <div className="sw-container--fixed-width sw-is-wide">
        <BackLink
          routeLink={ROUTES.STUDENT_LOAN_DASHBOARD}
          text="Back"
        ></BackLink>
      </div>

      {recObj && lenders && (
        <RecDetailsPersonal rec={recObj} lenders={lenders}></RecDetailsPersonal>
      )}
      <div className="sw-padding--top-lg">
        <RecDisclaimerMessage></RecDisclaimerMessage>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMatchedLendersPersonalFn: () => dispatch(getMatchedLendersPersonal()),
    trackEventFn: (event, properties) =>
      dispatch(trackEvent(event, properties)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const allRecs = state.recommendations.recommendations;
  let lenders = null;

  if (state.lenders.matchedLendersPersonal) {
    lenders = state.lenders.matchedLendersPersonal;
  }

  return {
    allRecs,
    lenders: lenders,
    isLoading:
      state.recommendations.getLoading || state.lenders.getLendersLoading,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecPagePersonalDebt);
