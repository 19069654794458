import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import usePlaidLinkWrapper, {
  LINK_MODE_TYPES,
} from "../../components/PlaidIntegration/usePlaidLinkWrapper";

import useStateWithLocalStorage, {
  LOCAL_STORAGE_KEYS,
} from "../../services/useStateWithLocalStorage";
import ROUTES from "../../constants/routes";

const PlaidOAuthPage = () => {
  const navigate = useNavigate();

  // The Link token from the first Link initialization
  const [linkToken] = useStateWithLocalStorage(
    LOCAL_STORAGE_KEYS.PLAID_LINK_TOKEN
  );
  const [linkMode] = useStateWithLocalStorage(
    LOCAL_STORAGE_KEYS.PLAID_LINK_MODE
  );

  const onSuccess = useCallback(() => {
    if (linkMode === LINK_MODE_TYPES.UPDATE) {
      // redirect back to Linked Accounts view
      navigate(ROUTES.PROFILE_ACCOUNTS);
    } else {
      // assume type "add"
      // redirect back to net worth view
      navigate(ROUTES.NET_WORTH);
    }
  }, [navigate, linkMode]);

  const config = {
    onSuccess: onSuccess,
    linkMode: linkMode,
    linkToken: linkToken,
    receivedRedirectUri: window.location.href, //the redirect URI with an OAuth state ID parameter
  };

  const { open, ready } = usePlaidLinkWrapper(config);

  // automatically reinitialize Link
  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);

  return <></>;
};

export default PlaidOAuthPage;
