import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

import { useFormFields } from "../../components/Form/useFormFields";
import {
  getChallenge,
  updateChallenge,
} from "../../store/actions/adminChallengesActions";
import {
  CHALLENGE_GOAL_TYPES,
  CHALLENGE_GOAL_STATUS,
  DEFAULT_CHALLENGE_PROGRESS_COLOR,
} from "../../constants/constants";
import { BackLink } from "../../components/Buttons/BackButtons";
import { ADMIN_ROUTES } from "../../constants/routes";

import AdminChallengesForm from "../../components/Challenges/AdminChallengeForm";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

const AdminEditChallengePage = (props) => {
  const dispatch = useDispatch();

  let { challengeId } = useParams();
  const { getChallengesLoading, editChallengeLoading, adminError, challenges } =
    useSelector((state) => {
      return state.adminChallenges;
    });

  useEffect(() => {
    dispatch(getChallenge(challengeId));
  }, [dispatch, challengeId]);

  const tomorrow = useMemo(() => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    return tomorrow;
  });

  const defaultFields = useMemo(() => {
    return {
      title: "",
      subtitle: "",
      description: "",
      goalStatus: CHALLENGE_GOAL_STATUS[0],
      goalType: CHALLENGE_GOAL_TYPES[0],
      startDate: new Date(),
      endDate: tomorrow,
      goalSuggestedAmount: "",
      progressColor: DEFAULT_CHALLENGE_PROGRESS_COLOR,
      badgeGradientStartColor: DEFAULT_CHALLENGE_PROGRESS_COLOR,
      badgeGradientEndColor: DEFAULT_CHALLENGE_PROGRESS_COLOR,
      imageUrl: "",
      coverPhotoUrl: "",
      communityUuid: "",
    };
  });

  const [fields, handleFieldChange, setValues] = useFormFields(defaultFields);

  useEffect(() => {
    let thisChallenge = null;

    // check from challenges array
    if (challenges && challenges.length > 0) {
      // note: this array should only have 1 item, but we want to double check id
      const filteredChallenges = challenges.filter(
        (el) => el.challenge_id === parseInt(challengeId)
      );
      thisChallenge = filteredChallenges[0];
    }

    if (thisChallenge) {
      const fields = {
        title: thisChallenge.title,
        subtitle: thisChallenge.subtitle || "",
        description: thisChallenge.description || "",
        goalStatus: thisChallenge.status,
        goalType: thisChallenge.goal_type,
        startDate: new Date(thisChallenge.start_date * 1000),
        endDate: new Date(thisChallenge.end_date * 1000),
        goalSuggestedAmount: thisChallenge.goal_suggested_amount || "",
        progressColor:
          thisChallenge.badge_drawing_info.progress_color ||
          DEFAULT_CHALLENGE_PROGRESS_COLOR,
        badgeGradientStartColor:
          thisChallenge.badge_drawing_info.gradient_start_color ||
          DEFAULT_CHALLENGE_PROGRESS_COLOR,
        badgeGradientEndColor:
          thisChallenge.badge_drawing_info.gradient_end_color ||
          DEFAULT_CHALLENGE_PROGRESS_COLOR,
        imageUrl: thisChallenge.image_url || "",
        coverPhotoUrl: thisChallenge.cover_photo_url || "",
        communityUuid: thisChallenge.community_uuid,
      };
      setValues(fields);
    }
  }, [setValues, challenges, challengeId]);

  const onSubmitForm = useCallback(
    async (event) => {
      event.preventDefault();
      await dispatch(updateChallenge(challengeId, fields)).then((res) => {
        // navigate back to main
        // navigate(ADMIN_ROUTES.CHALLENGES);
      });
    },
    [fields, dispatch, challengeId]
  );

  if (getChallengesLoading || editChallengeLoading) {
    return (
      <div>
        <LoadingSpinner></LoadingSpinner>
      </div>
    );
  }

  return (
    <div className="fs-block">
      <BackLink routeLink={ADMIN_ROUTES.CHALLENGES} text={"Go back"}></BackLink>

      <h1 className="title">Admin Challenges</h1>
      <AdminChallengesForm
        onSubmitForm={onSubmitForm}
        fields={fields}
        handleFieldChange={handleFieldChange}
        submitLoading={editChallengeLoading}
        tomorrow={tomorrow}
      ></AdminChallengesForm>
      <br />
      {adminError && <div className="has-text-danger">Error: {adminError}</div>}
    </div>
  );
};

export default AdminEditChallengePage;
