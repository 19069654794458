import React, { useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SketchPicker } from "react-color";

import { getAllCommunities } from "../../store/actions/adminCommunitiesActions";

import NumberInput, {
  NUMBER_INPUT_TYPES,
} from "../../components/Input/NumberInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  CHALLENGE_GOAL_TYPES,
  CHALLENGE_GOAL_STATUS,
} from "../../constants/constants";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const AdminChallengesForm = ({
  onSubmitForm,
  fields,
  handleFieldChange,
  submitLoading,
  tomorrow,
}) => {
  const dispatch = useDispatch();

  const updateDate = useCallback(
    (id, date) => {
      handleFieldChange({
        target: {
          id: id,
          value: date,
        },
      });
    },
    [handleFieldChange]
  );

  const updateFieldColor = useCallback(
    (color, id) => {
      handleFieldChange({
        target: {
          id: id,
          value: color.hex,
        },
      });
    },
    [handleFieldChange]
  );

  useEffect(() => {
    dispatch(getAllCommunities());
  }, [dispatch]);

  const { getCommunitiesLoading, communities } = useSelector((state) => {
    return state.adminCommunities;
  });

  const newChallengeValidated = useMemo(() => {
    const validationState = {
      title: fields.title.length > 0,
      subtitle: true,
      description: true,
      status: true,
      startDate: fields.startDate < fields.endDate,
      endDate: fields.endDate > fields.startDate,
      goalType: true,
      goalSuggestedAmount: true,
      progressColor: true,
      imageUrl: fields.imageUrl.length > 0,
      coverPhotoUrl: fields.coverPhotoUrl.length > 0,
    };
    return !Object.values(validationState).includes(false);
  }, [fields]);

  return (
    <form onSubmit={onSubmitForm}>
      <div className="field">
        <label className="label has-text-weight-normal">Title</label>
        <div className="control">
          <input
            name="title"
            id="title"
            className="input is-medium fs-block"
            type="text"
            maxLength={255}
            required
            value={fields.title}
            onChange={handleFieldChange}
          />
        </div>
      </div>
      <div className="field">
        <label className="label has-text-weight-normal">
          Subtitle (optional)
        </label>
        <div className="control">
          <input
            name="subtitle"
            id="subtitle"
            className="input is-medium fs-block"
            type="text"
            maxLength={255}
            value={fields.subtitle}
            onChange={handleFieldChange}
          />
        </div>
      </div>

      <div className="field">
        <label className="label has-text-weight-normal">
          Description (optional)
        </label>
        <div className="control">
          <textarea
            name="description"
            id="description"
            className="input is-medium fs-block"
            type="text"
            maxLength={500}
            style={{ height: 200 }}
            value={fields.description}
            onChange={handleFieldChange}
          />
        </div>
      </div>
      <div className="field">
        <label className="label has-text-weight-normal">
          Start Date (must be earlier than End Date)
        </label>
        <DatePicker
          selected={fields.startDate}
          showTimeInput={true}
          onChange={(date) => updateDate("startDate", date)}
          minDate={new Date()}
        />
      </div>

      <div className="field">
        <label className="label has-text-weight-normal">End Date</label>
        <DatePicker
          selected={fields.endDate}
          showTimeInput={true}
          onChange={(date) => updateDate("endDate", date)}
          minDate={tomorrow}
        />
      </div>

      <div className="field">
        <label className="label has-text-weight-normal">Image URL</label>
        <div className="control">
          <input
            name="imageUrl"
            id="imageUrl"
            className="input is-medium fs-block"
            type="text"
            required
            value={fields.imageUrl}
            onChange={handleFieldChange}
          />
        </div>
      </div>

      <div className="field">
        <label className="label has-text-weight-normal">Cover Photo URL</label>
        <div className="control">
          <input
            name="coverPhotoUrl"
            id="coverPhotoUrl"
            className="input is-medium fs-block"
            type="text"
            required
            value={fields.coverPhotoUrl}
            onChange={handleFieldChange}
          />
        </div>
      </div>

      <div className="field">
        <label className="label has-text-weight-normal">Goal Status</label>
        <div className="select fs-block field">
          <select
            id="goalStatus"
            name="goalStatus"
            value={fields.goalStatus}
            onChange={handleFieldChange}
          >
            {CHALLENGE_GOAL_STATUS.map((goalStatus) => {
              return (
                <option key={goalStatus} value={goalStatus}>
                  {goalStatus}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <div className="field">
        <label className="label has-text-weight-normal">Goal Type</label>
        <div className="select fs-block field">
          <select
            id="goalType"
            name="goalType"
            value={fields.goalType}
            onChange={handleFieldChange}
          >
            {CHALLENGE_GOAL_TYPES.map((goalType) => {
              return (
                <option key={goalType} value={goalType}>
                  {goalType}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <div className="field">
        <label className="label has-text-weight-normal">Progress Color</label>
        <div className="control">
          <SketchPicker
            color={fields.progressColor}
            onChange={(color) => updateFieldColor(color, "progressColor")}
          />
        </div>
      </div>

      <div className="field">
        <label className="label has-text-weight-normal">
          Badge Gradient Start Color
        </label>
        <div className="control">
          <SketchPicker
            color={fields.badgeGradientStartColor}
            onChange={(color) =>
              updateFieldColor(color, "badgeGradientStartColor")
            }
          />
        </div>
      </div>

      <div className="field">
        <label className="label has-text-weight-normal">
          Badge Gradient End Color
        </label>
        <div className="control">
          <SketchPicker
            color={fields.badgeGradientEndColor}
            onChange={(color) =>
              updateFieldColor(color, "badgeGradientEndColor")
            }
          />
        </div>
      </div>

      <NumberInput
        label="$ Suggested Amount (optional)"
        name="goalSuggestedAmount"
        className="fs-block"
        value={fields.goalSuggestedAmount}
        onChange={handleFieldChange}
        numberType={NUMBER_INPUT_TYPES.INTEGER}
      ></NumberInput>

      <br />

      <div className="field">
        <label className="label has-text-weight-normal">
          Associated community
        </label>
        <div className="is-size-7 is-danger">
          NOTE: Please be aware of unintended consequences if switching from one
          community to another for challenges with participants.
        </div>
        {getCommunitiesLoading && <LoadingSpinner></LoadingSpinner>}
        {!getCommunitiesLoading && communities && (
          <div className="select fs-block field">
            <select
              id="communityUuid"
              name="communityUuid"
              value={fields.communityUuid}
              onChange={handleFieldChange}
            >
              {!fields.communityUuid && (
                <option key={""} value={""}>
                  None
                </option>
              )}
              {communities.map((communityObj) => {
                return (
                  <option
                    key={communityObj.community_uuid}
                    value={communityObj.community_uuid}
                  >
                    {communityObj.name}
                  </option>
                );
              })}
            </select>
          </div>
        )}
      </div>

      <br />
      <div className="has-text-centered">
        <input
          type="submit"
          className={
            submitLoading
              ? "button is-warning is-outline is-loading"
              : "button is-warning is-outline"
          }
          value={"Submit"}
          disabled={!newChallengeValidated}
        />
      </div>
    </form>
  );
};

export default AdminChallengesForm;
