import { AUTH_ACTIONS } from "./authReducer";

const initState = {
  getError: null,
  getLoading: false,
  getUpdateTokenError: null,
  getUpdateTokenLoading: false,
  institutions: null,
  updatePublicToken: null,
  errorInstitutions: null,
};

const ACTION_PREFIX = "INSTITUTIONS/";
export const INSTITUTION_ACTIONS = {
  GET: ACTION_PREFIX + "GET",
  GET_ERROR: ACTION_PREFIX + "GET_ERROR",
  GET_SUCCESS: ACTION_PREFIX + "GET_SUCCESS",
  POST: ACTION_PREFIX + "GET",
  POST_ERROR: ACTION_PREFIX + "GET_ERROR",
  POST_SUCCESS: ACTION_PREFIX + "GET_SUCCESS",
  GET_UPDATE_TOKEN: ACTION_PREFIX + "GET_UPDATE_TOKEN",
  GET_UPDATE_TOKEN_ERROR: ACTION_PREFIX + "GET_UPDATE_TOKEN_ERROR",
  GET_UPDATE_TOKEN_SUCCESS: ACTION_PREFIX + "GET_UPDATE_TOKEN_SUCCESS",
};

const institutionReducer = (state = initState, action) => {
  switch (action.type) {
    case INSTITUTION_ACTIONS.GET:
    case INSTITUTION_ACTIONS.POST:
      return {
        ...state,
        getError: null,
        getLoading: true,
      };
    case INSTITUTION_ACTIONS.GET_ERROR:
    case INSTITUTION_ACTIONS.POST_ERROR:
      return {
        ...state,
        getError: action.err.message,
        getLoading: false,
      };
    case INSTITUTION_ACTIONS.GET_SUCCESS:
    case INSTITUTION_ACTIONS.POST_SUCCESS:
      const responseData = action.res.data;
      return {
        ...state,
        getError: null,
        getLoading: false,
        institutions: responseData.accounts,
        errorInstitutions: responseData.error_accounts,
      };
    case INSTITUTION_ACTIONS.GET_UPDATE_TOKEN:
      return {
        ...state,
        getUpdateTokenError: null,
        getUpdateTokenLoading: true,
      };
    case INSTITUTION_ACTIONS.GET_UPDATE_TOKEN_ERROR:
      return {
        ...state,
        getUpdateTokenError: action.err.message,
        getUpdateTokenLoading: false,
      };
    case INSTITUTION_ACTIONS.GET_UPDATE_TOKEN_SUCCESS:
      const updateTokenData = action.res.data;
      return {
        ...state,
        getUpdateTokenError: null,
        getUpdateTokenLoading: false,
        updatePublicToken: updateTokenData.public_token,
      };
    case AUTH_ACTIONS.SIGNOUT:
      return initState; // Always return the initial state

    default:
      return state;
  }
};

export default institutionReducer;
