import React from "react";
import { Link } from "react-router-dom";

import { GRAPHICS } from "../../constants/images";
import ROUTES from "../../constants/routes";

import AddAccountButton from "../Buttons/AddAccountButton";
import { isOnboardingComplete } from "../../store/actions/profileActions";

const EmptyStateCTA = ({ profileObj, onAddAccountSuccess }) => {
  if (profileObj && isOnboardingComplete(profileObj)) {
    return (
      <div>
        <p className="subtitle">
          Get started by adding your student loan accounts
        </p>

        <AddAccountButton
          onAddAccountSuccess={onAddAccountSuccess}
          className="button is-medium is-primary sw-is-wide"
        ></AddAccountButton>
      </div>
    );
  }

  return (
    <div>
      <p className="subtitle">Answer a few questions to get started</p>
      <Link
        className="button is-medium is-primary is-outlined sw-is-wide"
        to={ROUTES.STUDENT_ONBOARDING}
      >
        Get started
      </Link>
    </div>
  );
};

const StudentLoanDashboardEmpty = ({ profileObj, onAddAccountSuccess }) => {
  return (
    <div className="hero is-halfheight">
      <div className="hero-body">
        <div className="columns">
          <div className="column">
            <h1 className="title is-size-3">Student loan breakdown</h1>
            <EmptyStateCTA
              profileObj={profileObj}
              onAddAccountSuccess={onAddAccountSuccess}
            ></EmptyStateCTA>
          </div>
          <div className="column">
            <img
              src={GRAPHICS.LOAN_BREAKDOWN_EMTPY_STATE}
              alt="pie chart example of loan breakdown"
              width="428"
              height="428"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentLoanDashboardEmpty;
