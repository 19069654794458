import React, { useEffect } from "react";
import { connect } from "react-redux";

import NumberInput, {
  NUMBER_INPUT_TYPES,
} from "../../components/Input/NumberInput";
import { useFormFields } from "../../components/Form/useFormFields";
import { asyncGetItemInfo } from "../../store/actions/adminActions";
import { getUserStats } from "../../store/actions/adminActions";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

const UserStats = ({ userStats }) => {
  if (!userStats) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  const totals = userStats.totals;
  return (
    <div>
      <h2 className="subtitle sw-padding--top-lg">User stats</h2>
      <ul className="sw-padding--bottom-lg">
        <li>
          {totals.total_test_filtered} total user accounts (test accounts
          filtered)
        </li>
        <li>{totals.total_admin} admin accounts</li>
        <li>{totals.total_fin_coaches} financial coach accounts</li>
        <li>{totals.total_test} test accounts</li>
      </ul>
    </div>
  );
};

const AdminToolsPage = ({ getUserStatsFn, userStats, asyncGetItemInfoFn }) => {
  const [fields, handleFieldChange] = useFormFields({
    itemId: "",
  });

  useEffect(() => {
    getUserStatsFn();
  }, [getUserStatsFn]);

  const onSubmitForm = (event) => {
    event.preventDefault();
    asyncGetItemInfoFn(fields.itemId).then((response) => {
      // do nothing for now
    });
  };

  return (
    <div className="fs-block">
      <h1 className="title">Admin Tools</h1>
      <UserStats userStats={userStats}></UserStats>

      <h2 className="subtitle sw-padding--top-lg">Plaid Item Lookup</h2>
      <form onSubmit={onSubmitForm} className="sw-content">
        <div className="field">
          <NumberInput
            label="ItemId"
            name="itemId"
            required={true}
            className="fs-block"
            value={fields.itemId}
            onChange={handleFieldChange}
            numberType={NUMBER_INPUT_TYPES.INTEGER}
          ></NumberInput>
        </div>
        <br />
        <button type="submit" className="button is-small">
          Look up
        </button>
      </form>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    userStats: state.admin.userStats,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    asyncGetItemInfoFn: (itemId) => dispatch(asyncGetItemInfo(itemId)),
    getUserStatsFn: () => dispatch(getUserStats()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminToolsPage);
