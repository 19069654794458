import React, { useState } from "react";

import { ToggleCircleButton } from "../Buttons/CircleButtons";

const ExpandableBox = (props) => {
  let { title, content, startExpanded } = props;
  const [isExpanded, setIsExpanded] = useState(startExpanded || false);

  const toggleButton = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="li sw-container-box">
      <div className="level is-mobile">
        {/* <!-- Left side --> */}
        <div className="level-left">
          <p className="sw-section-header">{title}</p>
        </div>

        {/* <!-- Right side --> */}
        <div className="level-right">
          <ToggleCircleButton onToggle={toggleButton}></ToggleCircleButton>
        </div>
      </div>
      {isExpanded && <div>{content}</div>}
    </div>
  );
};

export default ExpandableBox;
