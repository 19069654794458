import React, { createRef, useState } from "react";

import { useIsOverflow } from "./useIsOverflow";

const OverflowParagraph = ({ text }) => {
  const ref = createRef();
  const isOverflow = useIsOverflow(ref);
  const [showMore, setShowMore] = useState(false);

  const onSeeMoreClicked = (e) => {
    e.stopPropagation();
    setShowMore(true);
  };

  let className = "sw-feed-text";
  if (isOverflow) {
    className += " has-overflow";
  }

  if (showMore) {
    className += " is-expanded";
  }

  return (
    <div>
      <p ref={ref} className={className}>
        {text}
      </p>
      {isOverflow && (
        <button
          className="button is-small is-text is-pulled-right"
          onClick={onSeeMoreClicked}
        >
          ...see more
        </button>
      )}
    </div>
  );
};

export default OverflowParagraph;
