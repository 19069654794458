import React from "react";

import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "../../components/Input/Checkbox";

import { ControlMoneyInput } from "../Input/NumberInput";
import { useFormFields, useFormCheckbox } from "../Form/useFormFields";
import { NextSubmitButton } from "../Buttons/NextButton";

import {
  FEDERAL_PROGRAM_OPTIONS,
  FEDERAL_PROGRAM_OPTIONS_MAP,
} from "../../store/actions/profileActions";

import {
  GOAL_OPTIONS,
  GOAL_OPTIONS_MAP_DISPLAY,
} from "../../store/actions/profileActions";

const getCheckedGoals = (goalsObj) => {
  // convert goals to array that matches goal strings
  return Object.keys(goalsObj)
    .filter((key) => {
      return goalsObj[key] === true;
    })
    .map((key) => GOAL_OPTIONS[key]);
};

const QuestionsPartOneForm = ({
  initialValues,
  goalsObj,
  onSubmit,
  onBack,
}) => {
  const [fields, handleFieldChange] = useFormFields({
    currentPayment: initialValues.currentPayment,
    federalProgram: initialValues.federalProgram,
    employmentLoanForgiveness: initialValues.employmentLoanForgiveness,
  });

  const [goals, handleGoalChange] = useFormCheckbox({
    payDown: goalsObj.payDown || false,
    repaymentOptions: goalsObj.repaymentOptions || false,
    lowerInterest: goalsObj.lowerInterest || false,
    lowerPayment: goalsObj.lowerPayment || false,
    stopPayment: goalsObj.stopPayment || false,
  });

  const onSubmitForm = (event) => {
    event.preventDefault();

    const checkedGoals = getCheckedGoals(goals);

    const responses = {
      goals: checkedGoals,
      currentPayment: fields.currentPayment,
      federalProgram: fields.federalProgram,
      employmentLoanForgiveness: fields.employmentLoanForgiveness,
    };

    onSubmit(responses);
  };

  return (
    <form onSubmit={(event) => onSubmitForm(event)}>
      <div className="sw-padding--bottom-xl">
        <label className="label sw-onboarding-questions__label" htmlFor="goals">
          What are your goals for your student debt?
          <span className="help has-text-weight-normal sw-padding--bottom-md">
            You can select more than one goal.
          </span>
        </label>
        <FormControl
          component="fieldset"
          className="sw-onboarding-body fs-block"
        >
          <FormGroup id="goals">
            <FormControlLabel
              control={
                <Checkbox
                  checked={goals.payDown}
                  onChange={handleGoalChange}
                  value={goals.payDown}
                  id="payDown"
                />
              }
              label={GOAL_OPTIONS_MAP_DISPLAY.pay_down}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={goals.lowerInterest}
                  onChange={handleGoalChange}
                  value="lowerInterest"
                  id="lowerInterest"
                />
              }
              label={GOAL_OPTIONS_MAP_DISPLAY.lower_interest}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={goals.lowerPayment}
                  onChange={handleGoalChange}
                  value="lowerPayment"
                  id="lowerPayment"
                />
              }
              label={GOAL_OPTIONS_MAP_DISPLAY.lower_payment}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={goals.stopPayment}
                  onChange={handleGoalChange}
                  value="stopPayment"
                  id="stopPayment"
                />
              }
              label={GOAL_OPTIONS_MAP_DISPLAY.stop_payment}
            />
          </FormGroup>
        </FormControl>

        <div className="field sw-padding--top-lg">
          <label
            className="label sw-onboarding-questions__label"
            htmlFor="currentPayment"
          >
            How much do you usually pay towards student loans each month?
            <span className="help has-text-weight-normal sw-padding--bottom-md">
              If your loan payments are paused due to relief, enter the amount
              you used to pay.
            </span>
          </label>
          <ControlMoneyInput
            name="currentPayment"
            id="currentPayment"
            required
            className="is-medium fs-block"
            value={fields.currentPayment}
            onChange={handleFieldChange}
          ></ControlMoneyInput>
        </div>

        <div className="field sw-padding--top-lg">
          <label
            className="label sw-onboarding-questions__label"
            htmlFor="federalProgram"
          >
            If you have federal loans, are you in any of these federal programs?
          </label>
          <div className="select is-medium is-fullwidth fs-block">
            <select
              id="federalProgram"
              name="federalProgram"
              value={fields.federalProgram}
              onChange={handleFieldChange}
            >
              {FEDERAL_PROGRAM_OPTIONS.map((federalOption) => {
                return (
                  <option
                    key={FEDERAL_PROGRAM_OPTIONS_MAP[federalOption]}
                    value={FEDERAL_PROGRAM_OPTIONS_MAP[federalOption]}
                  >
                    {federalOption}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="field sw-padding--top-lg">
          <label
            className="label sw-onboarding-questions__label"
            htmlFor="employmentLoanForgiveness"
          >
            Do you currently work or plan to work at a non-profit, federal, or
            state organization?
          </label>

          <div className="select is-medium is-fullwidth fs-block">
            <select
              id="employmentLoanForgiveness"
              name="employmentLoanForgiveness"
              onChange={handleFieldChange}
              value={fields.employmentLoanForgiveness}
            >
              <option value={"no"}>No</option>
              <option value={"yes"}>Yes</option>
              <option value={"unknown"}>I'm not sure</option>
            </select>
          </div>
        </div>
      </div>

      <div className="sw-onboarding-questions__buttons">
        <button className="button is-medium is-light" onClick={onBack}>
          Back
        </button>
        <NextSubmitButton className="is-medium is-primary sw-onboarding-questions__buttons--next"></NextSubmitButton>
      </div>
    </form>
  );
};

export default QuestionsPartOneForm;
