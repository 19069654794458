import { AUTH_ACTIONS } from "./authReducer";

const initState = {
  totalStudentLoans: 0,
  totalMinPayment: 0,
  loanStatusTypes: [],
  isoCurrencyCode: "USD",
  allLoans: [],
  paidLoans: [],
  accountPlaidErrors: [], // true if some accounts need updating
  totals: null,
  insights: null,
  addError: null,
  addLoading: false,
  getError: null,
  getLoading: false,
  updateLoading: false,
  updateError: false,
  insightsError: null,
  insightsLoading: false,
};

const ACTION_PREFIX = "LOANS/";
export const LOAN_ACTIONS = {
  GET: ACTION_PREFIX + "GET",
  GET_ERROR: ACTION_PREFIX + "GET_ERROR",
  GET_SUCCESS: ACTION_PREFIX + "GET_SUCCESS",
  UPDATE: ACTION_PREFIX + "UPDATE",
  UPDATE_ERROR: ACTION_PREFIX + "UPDATE_ERROR",
  UPDATE_SUCCESS: ACTION_PREFIX + "UPDATE_SUCCESS",
  INSIGHTS: ACTION_PREFIX + "INSIGHTS",
  INSIGHTS_ERROR: ACTION_PREFIX + "INSIGHTS_ERROR",
  INSIGHTS_SUCCESS: ACTION_PREFIX + "INSIGHTS_SUCCESS",
};

const studentLoansReducer = (state = initState, action) => {
  switch (action.type) {
    case LOAN_ACTIONS.GET:
      return {
        ...state,
        getError: null,
        getLoading: true,
      };
    case LOAN_ACTIONS.GET_ERROR:
      return {
        ...state,
        getError: action.err.message,
        getLoading: false,
      };
    case LOAN_ACTIONS.GET_SUCCESS:
      const responseData = action.res.data;

      return {
        ...state,
        getError: null,
        getLoading: false,
        totalStudentLoans: responseData.totals
          ? responseData.totals.total_student_loans
          : null,
        totals: responseData.totals,
        isoCurrencyCode: "USD",
        allLoans: responseData.liabilities,
        paidLoans: responseData.paid_liabilities,
        accountPlaidErrors: responseData.plaid_errors || "",
        loanStatusTypes: "",
      };
    case LOAN_ACTIONS.UPDATE:
      return {
        ...state,
        updateLoading: true,
        updateError: null,
      };
    case LOAN_ACTIONS.UPDATE_ERROR:
      return {
        ...state,
        updateLoading: false,
        updateError: action.err.message,
      };
    case LOAN_ACTIONS.UPDATE_SUCCESS:
      const resData = action.res.data;
      return {
        ...state,
        updateLoading: false,
        updateError: null,
        totalStudentLoans: resData.student.total,
        totals: resData.totals,
        isoCurrencyCode: "USD",
        allLoans: resData.liabilities,
        paidLoans: resData.paid_liabilities,
      };
    case LOAN_ACTIONS.INSIGHTS:
      return {
        ...state,
        insightsError: null,
        insightsLoading: true,
      };
    case LOAN_ACTIONS.INSIGHTS_ERROR:
      return {
        ...state,
        insights: null,
        insightsError: action.err.message,
        insightsLoading: false,
      };
    case LOAN_ACTIONS.INSIGHTS_SUCCESS:
      return {
        ...state,
        insightsError: null,
        insightsLoading: false,
        insights: action.res.data.insights,
      };
    case AUTH_ACTIONS.SIGNOUT:
      return initState; //Always return the initial state
    default:
      return state;
  }
};

export default studentLoansReducer;
