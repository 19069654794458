import React from "react";

import ROUTES from "../../constants/routes";
import { MatchedLenderTag } from "./Tags";
import { Link } from "react-router-dom";
import { trackEvent, EVENTS } from "../../store/actions/analyticsActions";
import { connect } from "react-redux";

const LenderBoxLi = (props) => {
  const lender = props.lender;
  const onCheckRateClick = (lenderSite) => (e) => {
    props.trackEvent(EVENTS.CHECK_RATE_CLICKED, {
      lender: lender.slug,
      full_slug: lender.full_slug,
    });
    window.open(lenderSite, "_blank"); // open link in new page
  };

  return (
    <li className="sw-lender-box box columns is-mobile">
      {lender.is_match && <MatchedLenderTag></MatchedLenderTag>}
      <Link
        className="columns column is-vcentered is-multiline"
        to={`${ROUTES.LENDER_PROFILE_PREFIX_STUDENT}${lender.slug}`}
      >
        <div className="column sw-lender-box__fixed-column">
          <div className="image">
            {lender.content.icon_wide && (
              <img
                width="100"
                src={lender.content.icon_wide}
                alt={lender.name}
              ></img>
            )}
          </div>
        </div>

        <div className="column sw-lender-box__fixed-column">
          {lender.content.apr_fixed_min ? (
            <span className="">
              {lender.content.apr_fixed_min}% - {lender.content.apr_fixed_max}%
            </span>
          ) : (
            "N/A"
          )}
          <p className="sw-lender-box__learn-more is-hidden-tablet">
            More info
          </p>
        </div>

        <div className="column sw-lender-box__main-column">
          <p>{lender.content.in_our_own_words}</p>

          <p className="sw-lender-box__learn-more is-hidden-mobile">
            Learn more
          </p>
        </div>
      </Link>

      <div className="column is-narrow">
        <button
          className="button is-primary is-outlined"
          onClick={onCheckRateClick(
            lender.content.referral_link || lender.content.website
          )}
        >
          Check rate
        </button>
      </div>
    </li>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackEvent: (event, properties) => dispatch(trackEvent(event, properties)),
  };
};

export default connect(null, mapDispatchToProps)(LenderBoxLi);
