import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { useFormFields } from "../../components/Form/useFormFields";

import { AdminTextField } from "../Input/AdminFormFields";
import NumberInput, {
  NUMBER_INPUT_TYPES,
} from "../../components/Input/NumberInput";

import { COURSE_TASK_TYPES } from "../../constants/constants";

import { setConfirmMessage } from "../../store/actions/alertingActions";

const AddEditTask = ({ task, courseUuid, onUpdateTask, onDeleteTask }) => {
  const dispatch = useDispatch();
  const defaultFields = useMemo(() => {
    if (task) {
      return {
        taskUuid: task.course_task_uuid,
        courseUuid: courseUuid,
        referenceName: task.reference_name,
        slug: task.storyblok_slug,
        taskType: task.task_type,
        groupPosition: task.group_position,
        taskPosition: task.task_position,
      };
    } else {
      return {
        courseUuid: courseUuid,
        referenceName: "",
        slug: "",
        taskType: COURSE_TASK_TYPES[0],
        groupPosition: 0,
        taskPosition: 0,
      };
    }
  }, [task, courseUuid]);

  const [fields, handleFieldChange, setValues] = useFormFields(defaultFields);

  const onUpdateCreateClick = (event) => {
    event.preventDefault();
    onUpdateTask(fields).then(() => {
      if (!task) {
        // clear values if added a new task
        setValues(defaultFields);
      }
    });
  };

  const onDeleteButtonClick = (event) => {
    event.preventDefault();
    dispatch(
      setConfirmMessage(
        "Delete this task in this course",
        "Are you sure you want to do this? This will remove progress if users have completed this task.",
        "Delete",
        () => {
          onDeleteTask(fields);
        },
        true
      )
    );
  };

  const taskValidated = useMemo(() => {
    let validationState = {
      referenceName: fields.referenceName.length > 0,
      slug: fields.slug.length > 0,
      taskType: fields.taskType.length > 0,
      groupPosition: fields.groupPosition >= 0,
      taskPosition: fields.taskPosition >= 0,
    };

    return !Object.values(validationState).includes(false);
  }, [fields]);

  return (
    <tr
      key={task ? task.taskUuid : "new"}
      style={{
        backgroundColor: "#eef0f3",
      }}
    >
      <td>
        <AdminTextField
          label="Reference Name"
          fields={fields}
          fieldName="referenceName"
          onChange={handleFieldChange}
        ></AdminTextField>
      </td>
      <td>
        <AdminTextField
          label="Slug"
          fields={fields}
          fieldName="slug"
          onChange={handleFieldChange}
        ></AdminTextField>
      </td>
      <td>
        <div className="select fs-block field">
          <select
            id="taskType"
            name="taskType"
            value={fields.taskType}
            onChange={handleFieldChange}
          >
            {COURSE_TASK_TYPES.map((taskType) => {
              return (
                <option key={taskType} value={taskType}>
                  {taskType}
                </option>
              );
            })}
          </select>
        </div>
      </td>
      <td>
        <NumberInput
          label="Group #"
          name="groupPosition"
          value={fields.groupPosition}
          onChange={handleFieldChange}
          numberType={NUMBER_INPUT_TYPES.INTEGER}
        ></NumberInput>
      </td>
      <td>
        <NumberInput
          label="Position"
          name="taskPosition"
          value={fields.taskPosition}
          onChange={handleFieldChange}
          numberType={NUMBER_INPUT_TYPES.INTEGER}
        ></NumberInput>
      </td>
      <td>
        <button
          className="button is-primary is-small"
          onClick={onUpdateCreateClick}
          disabled={!taskValidated}
        >
          {task ? "Update" : "Add New"}
        </button>
        {task && (
          <button
            className="button is-ghost has-text-danger is-small"
            onClick={onDeleteButtonClick}
          >
            Delete task
          </button>
        )}
      </td>
    </tr>
  );
};

const TaskRow = ({ courseUuid, task, onUpdateTask, onDeleteTask }) => {
  const [isEditing, setIsEditing] = useState(false);

  const onClickEdit = useCallback(
    async (fields) => {
      setIsEditing(false);
      return onUpdateTask(fields);
    },
    [onUpdateTask]
  );

  if (isEditing) {
    return (
      <AddEditTask
        task={task}
        courseUuid={courseUuid}
        onUpdateTask={onClickEdit}
        onDeleteTask={onDeleteTask}
      ></AddEditTask>
    );
  }

  return (
    <tr key={task.course_task_uuid}>
      <td>{task.reference_name}</td>
      <td>{task.storyblok_slug}</td>
      <td>{task.task_type}</td>
      <td>{task.group_position}</td>
      <td>{task.task_position}</td>
      <td className="help">{task.course_task_uuid}</td>
      <td>
        <button
          className="button is-text is-small"
          onClick={() => setIsEditing(true)}
        >
          Edit
        </button>
      </td>
    </tr>
  );
};

const AdminCourseEditTasks = ({
  tasks,
  courseUuid,
  onUpdateTask,
  onDeleteTask,
}) => {
  return (
    <div className="box">
      <h2 className="subtitle">Tasks</h2>
      <p>
        NOTE: Deleting a task will delete a user's progress. Only delete tasks
        before turning the course on as Active.
      </p>

      <table className="table is-hoverable is-fullwidth fs-block">
        <thead>
          <tr>
            <th>Reference Name</th>
            <th>Slug</th>
            <th>Task Type</th>
            <th>Group Number</th>
            <th>Task Position</th>
            <th>Task UUID</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tasks &&
            tasks.map((task, index) => {
              return (
                <TaskRow
                  key={task.course_task_uuid}
                  task={task}
                  courseUuid={courseUuid}
                  onUpdateTask={onUpdateTask}
                  onDeleteTask={onDeleteTask}
                />
              );
            })}

          <tr>
            <th></th>
          </tr>
          <AddEditTask
            task={null}
            courseUuid={courseUuid}
            onUpdateTask={onUpdateTask}
          ></AddEditTask>
        </tbody>
      </table>
    </div>
  );
};

export default AdminCourseEditTasks;
