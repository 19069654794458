import React from "react";
import { Link } from "react-router-dom";

const GenericBoxLi = (props) => {
  const icon = props.icon;
  const title = props.title || "";
  const message = props.message || "";
  const buttonText = props.buttonText || "Learn more";
  const buttonLink = props.buttonLink || "";
  const buttonOnClick =
    props.buttonOnClick ||
    (() => {
      return;
    });
  const learnMoreRoute = props.learnMoreRoute;

  return (
    <li
      key="sw-rec-personal__balance-transfer"
      className="box columns tile is-gapless sw-box--li"
    >
      <div className="columns column is-9 is-vcentered is-mobile">
        <div className="column is-narrow">
          <div className="image is-64x64">{icon && icon}</div>
        </div>
        <div className="column is-9 fs-block">
          <p>
            <strong>{title}</strong>
            <br />
            {message && <span>{message}</span>}
          </p>
          {learnMoreRoute && (
            <Link className="is-size-7-mobile" to={`${learnMoreRoute}`}>
              Learn More
            </Link>
          )}
        </div>
      </div>
      <div className="column">
        <a
          className="button is-medium is-dark"
          onClick={() => buttonOnClick()}
          href={buttonLink}
        >
          {buttonText}
        </a>
      </div>
    </li>
  );
};

export default GenericBoxLi;
