import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useFormFields } from "../../components/Form/useFormFields";
import { createCommunity } from "../../store/actions/adminCommunitiesActions";
import { BackLink } from "../../components/Buttons/BackButtons";
import { ADMIN_ROUTES } from "../../constants/routes";

import AdminCommunityForm from "../../components/Communities/AdminCommunityForm";

const DEFAULT_FIELDS = {
  title: "",
  isPrivate: false,
  isGlobal: false,
  description: "",
  imageUrl: "",
  thumbnailImageUrl: "",
};

const AdminCreateCommunityPage = (props) => {
  const dispatch = useDispatch();
  const { createCommunityLoading, adminError, newCommunity } = useSelector(
    (state) => {
      return state.adminCommunities;
    }
  );

  const [fields, handleFieldChange, setValues] = useFormFields(DEFAULT_FIELDS);

  useEffect(() => {
    setValues(DEFAULT_FIELDS);
  }, [setValues, newCommunity]);

  const navigate = useNavigate();
  const onSubmitForm = useCallback(
    async (event) => {
      event.preventDefault();
      await dispatch(createCommunity(fields)).then((res) => {
        // navigate back to main
        navigate(ADMIN_ROUTES.COMMUNITIES);
      });
    },
    [fields, dispatch, navigate]
  );

  return (
    <div className="fs-block">
      <BackLink
        routeLink={ADMIN_ROUTES.COMMUNITIES}
        text={"Go back"}
      ></BackLink>

      <h1 className="title">Admin Communities</h1>
      <AdminCommunityForm
        onSubmitForm={onSubmitForm}
        fields={fields}
        handleFieldChange={handleFieldChange}
        submitLoading={createCommunityLoading}
      ></AdminCommunityForm>
      <br />
      {adminError && <div className="has-text-danger">Error: {adminError}</div>}
    </div>
  );
};

export default AdminCreateCommunityPage;
