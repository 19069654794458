import axios from "axios";
import helpers from "../../services/helpers";

import { clearError } from "./errorActions";
import { AUTH_ACTIONS } from "../reducers/authReducer";
const API_URL = process.env.REACT_APP_USERS_SERVICE_URL;

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: { "X-Requested-With": "XMLHttpRequest" },
});

// Add request interceptor. Do something before request is sent
// axiosInstance.interceptors.request.use(
//   function (config) {
//     const fb_jwt = helpers.getCookie("snowball-jwt");

//     if (fb_jwt) {
//       config.headers.Authorization = "Bearer " + fb_jwt;
//       // Note: Temporary addition to see if it fixes auth issues.
//       // Sending additional headers in case Authorization headers are being overwritten
//       config.headers['x-access-token'] = 'Bearer ' + fb_jwt;
//     }

//     return config;
//   }, function (error) {
//     return Promise.reject(error);
//   }
// );

const getAuthHeaders = (varToken) => {
  if (varToken) {
    return {
      headers: {
        Authorization: "Bearer " + varToken,
        "Client-Platform": "webapp",
      },
    };
  }

  console.error("No token passed to getAuthHeaders");
  return {};
};

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.error(error);
    return Promise.reject(error);
  }
);

/**
 * This function gets the authenticated user through firebase
 * and sets the user's auth token in the cookie. This ensures
 * that the user has a valid auth token before initiating
 * the callback api request.
 */
const getToken = (onGetToken) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      currentUser
        .getIdToken()
        .then(function (idToken) {
          helpers.setCookie("snowball-jwt", idToken);
          onGetToken(idToken);
        })
        .catch((err) => {
          console.error(err);
          console.error("Tried to get token but failed.");
          helpers.deleteCookie("snowball-jwt");
          dispatch({ type: AUTH_ACTIONS.SIGNOUT });
        });
    } else {
      console.error("Trying to get token but currentUser is null.");
      helpers.deleteCookie("snowball-jwt");
      dispatch({ type: AUTH_ACTIONS.SIGNOUT });
    }
  };
};

/**
 * This initiates a get request to the backend.
 * In order to make this request, we need the user's auth token.
 *
 * Thus, we pass the get request as a callback into the getToken function.
 */
export const apiGet = (url, onSuccess, onError) => {
  return (dispatch, getState, { getFirebase }) => {
    const makeRequestWithToken = (jwtToken) => {
      const myConfig = getAuthHeaders(jwtToken);
      axiosInstance
        .get(url, myConfig)
        .then((res) => {
          dispatch(clearError());
          onSuccess(res);
        })
        .catch(onError);
    };

    dispatch(getToken(makeRequestWithToken));
  };
};

/**
 * This initiates a post request to the backend.
 * In order to make this request, we need the user's auth token.
 *
 * Thus, we pass the post request as a callback into the getToken function.
 */
export const apiPost = (url, payload, onSuccess, onError) => {
  return (dispatch, getState) => {
    // TODO: renamed to onGetTokenPost --> signify this is a post request
    const makeRequestWithToken = (jwtToken) => {
      const myConfig = getAuthHeaders(jwtToken);
      axiosInstance
        .post(url, payload, myConfig)
        .then((res) => {
          dispatch(clearError());
          onSuccess(res);
        })
        .catch(onError);
    };

    dispatch(getToken(makeRequestWithToken));
  };
};
