import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { asyncAdminUpdateCourse } from "../../store/actions/adminCoursesActions";
import { ADMIN_ROUTES } from "../../constants/routes";

import { useFormFields } from "../../components/Form/useFormFields";
import { BackLink } from "../../components/Buttons/BackButtons";
import AdminCoursesForm from "../../components/Courses/AdminCoursesForm";
import { getDefaultCourseFields } from "../../components/Courses/adminCourseHelpers";

const AdminNewCoursePage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { adminError, updateCourseLoading } = useSelector((state) => {
    return state.adminCourses;
  });

  const defaultFields = useMemo(() => {
    return getDefaultCourseFields();
  }, []);

  const [fields, handleFieldChange, setValues] = useFormFields(defaultFields);

  const onUpdateCourseInfo = useCallback(
    async (event) => {
      event.preventDefault();
      await dispatch(asyncAdminUpdateCourse(fields)).then((data) => {
        setValues(defaultFields);
        navigate(ADMIN_ROUTES.COURSES);
      });
    },
    [defaultFields, setValues, fields, dispatch, navigate]
  );

  return (
    <div className="fs-block">
      <BackLink routeLink={ADMIN_ROUTES.COURSES} text={"Go back"}></BackLink>

      <h1 className="title">Add Course</h1>
      <div>
        <AdminCoursesForm
          onSubmitForm={onUpdateCourseInfo}
          fields={fields}
          handleFieldChange={handleFieldChange}
          isLoading={updateCourseLoading}
        ></AdminCoursesForm>
      </div>
      {adminError && <div className="has-text-danger">Error: {adminError}</div>}
    </div>
  );
};

export default AdminNewCoursePage;
