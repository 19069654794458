import { ADMIN_POLL_ACTIONS } from "../reducers/adminPollsReducer";
import { apiGet, apiPost } from "./apiActions";

export const getPolls = () => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      const endpoint = `/api/admin/polls`;

      dispatch({ type: ADMIN_POLL_ACTIONS.GET_POLLS });

      const onSuccess = (res) => {
        dispatch({
          type: ADMIN_POLL_ACTIONS.GET_POLLS_SUCCESS,
          res,
        });
        resolve(res);
      };

      const onError = (err) => {
        dispatch({ type: ADMIN_POLL_ACTIONS.GET_POLLS_ERROR, err });
        reject(err);
      };

      dispatch(apiGet(endpoint, onSuccess, onError));
    });
  };
};

export const getAllPolls = () => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/admin/polls";

      dispatch({ type: ADMIN_POLL_ACTIONS.GET_POLLS });

      const onSuccess = (res) => {
        dispatch({
          type: ADMIN_POLL_ACTIONS.GET_POLLS_SUCCESS,
          res,
        });
        resolve(res);
      };

      const onError = (err) => {
        dispatch({ type: ADMIN_POLL_ACTIONS.GET_POLLS_ERROR, err });
        reject(err);
      };

      dispatch(apiGet(endpoint, onSuccess, onError));
    });
  };
};

export const createPollOrEditPoll = (
  prompt,
  pollType,
  poll,
  options,
  optionsToDelete,
  correctAnswerIndex
) => {
  return (dispatch) => {
    const isQuiz = pollType === "quiz";
    const data = {
      prompt: prompt,
      poll_type: pollType,
    };

    if (poll) {
      data["poll_uuid"] = poll["poll_uuid"];
    }

    if (optionsToDelete) {
      data["options_to_delete"] = optionsToDelete;
    }

    const optionsKey = isQuiz ? "quiz_options_list" : "poll_options_list";
    data[optionsKey] = options;

    if (isQuiz) {
      data["correct_answer_index"] = correctAnswerIndex;
    }

    return new Promise(function (resolve, reject) {
      const endpoint = isQuiz ? "/api/admin/quizzes" : "/api/admin/polls";

      dispatch({ type: ADMIN_POLL_ACTIONS.CREATE_POLL });

      const onSuccess = (res) => {
        dispatch({
          type: ADMIN_POLL_ACTIONS.CREATE_POLL_SUCCESS,
          payload: { res: res },
        });
        resolve(res);
      };

      const onError = (err) => {
        dispatch({ type: ADMIN_POLL_ACTIONS.CREATE_POLL_ERROR, err });
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};
