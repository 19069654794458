import { Link } from "react-router-dom";
import React from "react";
import helpers from "../../services/helpers";

import AdminFeedItemEditButton from "./AdminFeedItemEditButton";
import OverflowParagraph from "../Input/OverflowParagraph";

const AdminFeedList = ({ feedItems, onDelete, onNotify, onHideItem }) => {
  return (
    <div className="sw-padding--bottom-lg">
      <table className="table is-hoverable is-fullwidth fs-block">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>Author id</th>
            <th>Author display name</th>
            <th>Community</th>
            <th>Created at</th>
            <th>Text</th>
          </tr>
        </thead>
        <tbody>
          {feedItems.map((item) => {
            const feedItem = item.feed_item;
            const universalLinkAttachment = feedItem.universal_link_attachment;
            return (
              <tr key={feedItem.feed_item_uuid}>
                <td>
                  <AdminFeedItemEditButton
                    className=""
                    feedItemUuid={feedItem.feed_item_uuid}
                    isHidden={feedItem.is_hidden}
                    onDeletePost={() => onDelete(feedItem.feed_item_uuid)}
                    onNotifyPost={() =>
                      onNotify(feedItem.feed_item_uuid, "fcm")
                    }
                    onNotifyEmail={() =>
                      onNotify(feedItem.feed_item_uuid, "email")
                    }
                    onHidePost={() =>
                      onHideItem(feedItem.feed_item_uuid, !feedItem.is_hidden)
                    }
                  ></AdminFeedItemEditButton>
                </td>
                <td>
                  <Link
                    to={`/admin/feed/universal_link/${feedItem.feed_item_uuid}`}
                  >
                    {universalLinkAttachment == null
                      ? "Add Link"
                      : universalLinkAttachment.full_link}
                  </Link>
                </td>
                <td>
                  <Link to={`/admin/feed/edit/${feedItem.feed_item_uuid}`}>
                    Edit Other Fields
                  </Link>
                </td>
                <td>{feedItem.author_id}</td>
                <td>{feedItem.author_profile.display_name}</td>
                <td>{feedItem.community.name}</td>
                <td>{helpers.getDateFromTimestamp(feedItem.created_at)}</td>
                <td className="is-flex">
                  <OverflowParagraph text={feedItem.text}></OverflowParagraph>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AdminFeedList;
