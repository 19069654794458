import { setError } from "./errorActions";
import { apiGet, apiPost } from "./apiActions";
import { PAYITOFF_ACTIONS } from "../reducers/payitoffReducer";
// import { trackEvent, EVENTS } from "./analyticsActions";

const NEXUS_KEY = process.env.REACT_APP_PAYITOFF_NEXUS_KEY;

/**
 * Initializes the nexus widget
 * @param {} borrowerId 
 * @returns 
 */
export const initNexus = (borrowerId) => {
  return (dispatch, getState, { getFirebase }) => {
    window.Nexus({
      nexus_key: NEXUS_KEY,
      borrower: borrowerId || null,
    });
  };
};

/**
 * Updates the borrower (i.e. user) with the payitoff id
 * Sometimes to initialize payitoff we use our own id, but with subsequent calls,
 * we get back a payitoff id that we want to save in our database.
 * @param {} borrowerUuid 
 * @returns 
 */
export const updateBorrower = (borrowerUuid) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/liabilities/nexus/borrower";
      const data = {
        payitoff_borrower_uuid: borrowerUuid,
      };
      // dispatch({ type: NET_WORTH_ACTIONS.GET });
      const onSuccess = (res) => {
        // dispatch({ type: NET_WORTH_ACTIONS.GET_SUCCESS, res });
        resolve();
      };

      const onError = (err) => {
        const errMessage =
          "There was an error getting your loans. Please contact the Snowball team and we'll help you resolve this.";
        // dispatch({ type: NET_WORTH_ACTIONS.GET_ERROR, err });
        dispatch(setError(err, errMessage));
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};

/**
 * Gets student loans synced to Payitoff
 * @returns 
 */
export const getPayitoffDashboad = () => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/federal_loan_dashboard";

      dispatch({ type: PAYITOFF_ACTIONS.DASHBOARD });
      const onSuccess = (res) => {
        dispatch({ type: PAYITOFF_ACTIONS.DASHBOARD_SUCCESS, res });
        resolve();
      };

      const onError = (err) => {
        const errMessage =
          "There was an error getting your federal loans. Please contact the Snowball team and we'll help you resolve this.";
        dispatch({ type: PAYITOFF_ACTIONS.DASHBOARD_ERROR, err });
        dispatch(setError(err, errMessage));
        reject(err);
      };

      dispatch(apiGet(endpoint, onSuccess, onError));
    });
  };
};

/**
 * Gets options for student loan repayment options.
 * NOT USING RIGHT NOW
 * @returns 
 */
export const compareRepaymentOptions = () => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/federal_loan_dashboard/repayment_options";

      dispatch({ type: PAYITOFF_ACTIONS.COMPARE });
      const onSuccess = (res) => {
        dispatch({ type: PAYITOFF_ACTIONS.COMPARE_SUCCESS, res });
        resolve();
      };

      const onError = (err) => {
        const errMessage =
          "There was an error getting your loans. Please contact the Snowball team and we'll help you resolve this.";
        dispatch({ type: PAYITOFF_ACTIONS.COMPARE_ERROR, err });
        dispatch(setError(err, errMessage));
        reject(err);
      };

      dispatch(apiGet(endpoint, onSuccess, onError));
    });
  };
};
