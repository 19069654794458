import React from "react";

import usePayitoffNexusLink from "../PayitoffIntegration/usePayitoffNexusLink";
import helpers from "../../services/helpers";

const PayitoffAccount = ({ servicerObj }) => {
  let totalAmount = servicerObj.outstanding_balance;
  return (
    <ol className="sw-federal-loan-box">
      <div className="columns is-mobile is-multiline">
        <span className="column">{servicerObj.servicer.name}</span>
        <span className="column is-narrow">
          {helpers.currencyFormat(totalAmount, 0)}
        </span>
      </div>
    </ol>
  );
};

const FederalLoansBox = ({
  payitoffId,
  userId,
  payitoffServicers,
  refreshDashboard,
}) => {
  const buttonOnClick = usePayitoffNexusLink(
    payitoffId,
    userId,
    refreshDashboard
  );

  if (payitoffServicers && payitoffServicers.length > 0) {
    return (
      <div className="box sw-full-height sw-flex-layout--column">
        <p className="subtitle has-text-weight-semibold">My Federal Loans</p>
        <div className="fs-block">
          {payitoffServicers &&
            payitoffServicers.map((servicerObj, index) => (
              <PayitoffAccount
                servicerObj={servicerObj}
                key={index}
              ></PayitoffAccount>
            ))}
        </div>
        <div className="sw-flex-layout--bottom-right">
          <button className="button is-light" onClick={buttonOnClick}>
            <i className="fa fa-plus" aria-hidden="true"></i>
            <span className="sw-padding--left-md">Add account</span>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="box sw-full-height sw-flex-layout--column-center">
      <p className="level-item subtitle has-text-weight-semibold">
        Add your federal student loans to get started
      </p>
      <button className="button is-light" onClick={buttonOnClick}>
        <i className="fa fa-plus" aria-hidden="true"></i>
        <span className="sw-padding--left-md">Add account</span>
      </button>
    </div>
  );
};

export default FederalLoansBox;
