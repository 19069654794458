import { setError } from "./errorActions";
import { apiGet, apiPost } from "./apiActions";
import { CHALLENGES_ACTIONS } from "../reducers/challengesReducer";

export const asyncGetChallenges = () => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/challenges";

      dispatch({ type: CHALLENGES_ACTIONS.GET });
      const onSuccess = (res) => {
        dispatch({
          type: CHALLENGES_ACTIONS.GET_SUCCESS,
          challenges: res.data,
        });
        resolve();
      };

      const onError = (err) => {
        const errMessage =
          "There was an error getting your challenges. Please contact the Snowball team and we'll help you resolve this.";
        dispatch({ type: CHALLENGES_ACTIONS.GET_ERROR, err });
        dispatch(setError(err, errMessage));
        reject(err);
      };

      dispatch(apiGet(endpoint, onSuccess, onError));
    });
  };
};

export const asyncGetChallengeProgress = (challenge_id) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = `/api/challenge/${challenge_id}`;

      dispatch({ type: CHALLENGES_ACTIONS.GET });
      const onSuccess = (res) => {
        dispatch({
          type: CHALLENGES_ACTIONS.GET_SUCCESS,
          challenges: [res.data.challenge],
        });
        resolve();
      };

      const onError = (err) => {
        const errMessage =
          "There was an error getting your challenges. Please contact the Snowball team and we'll help you resolve this.";
        dispatch({ type: CHALLENGES_ACTIONS.GET_ERROR, err });
        dispatch(setError(err, errMessage));
        reject(err);
      };

      dispatch(apiGet(endpoint, onSuccess, onError));
    });
  };
};

export const asyncUpdateChallengeProgress = (
  challenge_id,
  account_id,
  goal_amount,
  account_balance_start
) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = `/api/challenge/${challenge_id}`;
      const data = {
        goal_amount: goal_amount,
        account_id: account_id,
        account_balance_start: account_balance_start,
      };

      dispatch({ type: CHALLENGES_ACTIONS.UPDATE });
      const onSuccess = (res) => {
        dispatch({
          type: CHALLENGES_ACTIONS.UPDATE_SUCCESS,
          challenges: [res.data.challenge],
        });
        resolve();
      };

      const onError = (err) => {
        const errMessage =
          "There was an error getting your challenges. Please contact the Snowball team and we'll help you resolve this.";
        dispatch({ type: CHALLENGES_ACTIONS.UPDATE_ERROR, err });
        dispatch(setError(err, errMessage));
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};

export const asyncJoinChallenge = (challengeId) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/challenges";
      const data = {
        challenge_id: challengeId,
      };

      dispatch({ type: CHALLENGES_ACTIONS.JOIN });
      const onSuccess = (res) => {
        dispatch({ type: CHALLENGES_ACTIONS.JOIN_SUCCESS, res });
        resolve();
      };

      const onError = (err) => {
        dispatch({ type: CHALLENGES_ACTIONS.JOIN_ERROR, err });
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};
