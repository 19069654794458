import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useOutletContext } from "react-router-dom";

import { LINK_URLS } from "../../constants/constants";
import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";
import { getPayitoffDashboad } from "../../store/actions/payitoffActions";
import { FEATURE_FLAGS } from "../../constants/constants";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import ProsConsBox from "../../components/Box/ProsConsBox";
import NewSavePlanCTA from "../../components/FederalPrograms/NewSavePlanCTA";
import ComparePlansCTA from "../../components/FederalPrograms/ComparePlansCTA";
import ComingSoonBox from "../../components/FederalPrograms/ComingSoonBox";
import FederalLoansBox from "../../components/FederalPrograms/FederalLoansBox";
import {
  setStickyBanner,
  clearStickyBanner,
} from "../../store/actions/alertingActions";

import usePayitoffNexusEnroll from "../../components/PayitoffIntegration/usePayitoffNexusEnroll";

import {
  SAVE,
  IDR,
  PSLF,
  CONSOLIDATION,
} from "../../constants/federalPrograms";
import { ImpactedByCovidAnnouncement } from "../../components/Messages/AnnouncementBanner";

const TopCTASection = ({
  hasFederalProgramsOn,
  servicers,
  payitoffId,
  userId,
  onEnrollClick,
  refreshDashboard,
}) => {
  const containerClassName = " sw-padding--bottom-xl";

  // user has already synced some accounts
  if (hasFederalProgramsOn && servicers) {
    return (
      <div className={containerClassName}>
        <div className="sw-container--fixed-width sw-is-max-wide columns">
          <div className="column is-one-third">
            <FederalLoansBox
              payitoffId={payitoffId}
              userId={userId}
              payitoffServicers={servicers}
              refreshDashboard={refreshDashboard}
            ></FederalLoansBox>
          </div>
          <div className="column is-two-thirds">
            <ComparePlansCTA onButtonClick={onEnrollClick}></ComparePlansCTA>
          </div>
        </div>
      </div>
    );
  }

  // otherwise, show empty state
  return (
    <div className={containerClassName}>
      <div className="sw-container--fixed-width sw-is-max-wide">
        {hasFederalProgramsOn ? (
          <NewSavePlanCTA onButtonClick={onEnrollClick}></NewSavePlanCTA>
        ) : (
          <ComingSoonBox
            redirectLink={LINK_URLS.GOV_STUDENT_AID}
          ></ComingSoonBox>
        )}
      </div>
    </div>
  );
};

const FederalProgramsMainPage = (props) => {
  const trackEventFn = props.trackEvent;
  const getPayitoffDashboadFn = props.getPayitoffDashboad;
  const setStickyBannerFn = props.setStickyBanner;
  const clearStickyBannerFn = props.clearStickyBanner;

  const onEnrollClick = usePayitoffNexusEnroll(
    props.payitoffId,
    props.userId,
    getPayitoffDashboadFn
  );

  // set page title
  const setPageTitle = useOutletContext();
  useEffect(() => {
    setPageTitle("Federal options");
  }, [setPageTitle]);

  useEffect(() => {
    trackEventFn(EVENTS.FEDERAL_PROGRAMS_VIEWED);
  }, [trackEventFn]);

  useEffect(() => {
    getPayitoffDashboadFn();
  }, [getPayitoffDashboadFn]);

  useEffect(() => {
    setStickyBannerFn(
      "Have questions? Talk to a financial coach",
      LINK_URLS.SNOWBALL_CONSULT
    );
    return function cleanup() {
      clearStickyBannerFn();
    };
  }, [setStickyBannerFn, clearStickyBannerFn]);

  if (props.isLoading) {
    return (
      <div className="sw-flex-layout--column-center">
        <LoadingSpinner></LoadingSpinner>
      </div>
    );
  }

  return (
    <div className="sw-flex-layout--column-center">
      <div className="sw-container--fixed-width sw-is-max-wide sw-padding--bottom-lg">
        <ImpactedByCovidAnnouncement></ImpactedByCovidAnnouncement>

        <TopCTASection
          hasFederalProgramsOn={props.hasFederalProgramsOn}
          onEnrollClick={onEnrollClick}
          servicers={props.servicers}
          payitoffId={props.payitoffId}
          userId={props.userId}
          refreshDashboard={getPayitoffDashboadFn}
        ></TopCTASection>

        <div className="columns is-multiline">
          <div className="column">
            <ProsConsBox
              title="What is the new SAVE plan?"
              prosList={SAVE.PROS}
              consList={SAVE.CONS}
              readMoreLink={SAVE.MORE_LINK}
              // buttonText="Apply for IDR"
              // onClick={onEnrollClick}
            ></ProsConsBox>
          </div>
          <div className="column">
            <ProsConsBox
              title="What are income-driven repayment plans?"
              prosList={IDR.PROS}
              consList={IDR.CONS}
              readMoreLink={IDR.MORE_LINK}
              // buttonText="Apply for IDR"
              // onClick={onEnrollClick}
            ></ProsConsBox>
          </div>
          <div className="column">
            <ProsConsBox
              title="What is Public Student Loan Forgiveness (PSLF)?"
              prosList={PSLF.PROS}
              consList={PSLF.CONS}
              readMoreLink={PSLF.MORE_LINK}
              // buttonText="See if I qualify"
            ></ProsConsBox>
          </div>
          <div className="column">
            <ProsConsBox
              title="Should I consolidate my loans?"
              prosList={CONSOLIDATION.PROS}
              consList={CONSOLIDATION.CONS}
              // readMoreLink={CONSOLIDATION.MORE_LINK}
              // buttonText="See if I qualify"
            ></ProsConsBox>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPayitoffDashboad: () => dispatch(getPayitoffDashboad()),
    setStickyBanner: (text, link) => dispatch(setStickyBanner(text, link)),
    clearStickyBanner: () => dispatch(clearStickyBanner()),
    trackEvent: (event) => dispatch(trackEvent(event)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const flags = state.auth.flags;
  let hasFederalProgramsOn = false;
  if (flags && flags[FEATURE_FLAGS.FEDERAL_PROGRAMS]) {
    hasFederalProgramsOn = flags[FEATURE_FLAGS.FEDERAL_PROGRAMS];
  }

  return {
    isLoading: state.payitoff.isLoading,
    servicers: state.payitoff.servicers,
    payitoffId: state.auth.currentUser.payitoff_borrower_uuid,
    userId: state.auth.currentUser.id,
    hasFederalProgramsOn: hasFederalProgramsOn,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FederalProgramsMainPage);
