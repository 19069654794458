import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

import AddPlaidAccountWrapper from "./PlaidAddAccountWrapper";
import { asyncCreateLinkToken } from "../../store/actions/netWorthActions";

const PlaidAddAccountButton = ({
  children,
  className,
  onSyncSuccess,
  plaidProduct,
  asyncCreateLinkTokenFn,
}) => {
  // token, saved in state
  const [token, setToken] = useState(null);

  useEffect(() => {
    let mounted = true;

    (async () => {
      const linkToken = await asyncCreateLinkTokenFn(plaidProduct);
      if (mounted) {
        setToken(linkToken);
      }
    })();
    return () => (mounted = false);
  }, [asyncCreateLinkTokenFn, plaidProduct, setToken]);

  if (token) {
    return (
      <AddPlaidAccountWrapper
        token={token}
        style={className || "has-text-centered sw-add-loan-link"}
        onSyncSuccess={onSyncSuccess}
      >
        {children}
      </AddPlaidAccountWrapper>
    );
  } else return <div className={className + " is-loading"}>{children}</div>;
};

const mapDispatchToProps = (dispatch) => {
  return {
    asyncCreateLinkTokenFn: (plaidProduct) =>
      dispatch(asyncCreateLinkToken(plaidProduct)),
  };
};

export default connect(null, mapDispatchToProps)(PlaidAddAccountButton);
