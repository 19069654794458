import React from "react";
import { connect } from "react-redux";

import OrgNavBar from "../NavBar/OrgNavBar";
import ConfirmationMessage from "../Messages/ConfirmationMessage";
import ErrorMessage from "../Messages/ErrorMessage";
import WarningMessage from "../Messages/WarningMessage";

const MessageContainer = ({
  errMessage,
  errTitle,
  showConfirmMessage,
  confirmMessageProps,
  warningTitle,
  warningMessage,
  warningRedirectRoute,
}) => {
  return (
    <>
      {showConfirmMessage && (
        <ConfirmationMessage {...confirmMessageProps}></ConfirmationMessage>
      )}

      {errMessage && (
        <ErrorMessage title={errTitle} message={errMessage}></ErrorMessage>
      )}

      {warningTitle && (
        <WarningMessage
          title={warningTitle}
          message={warningMessage}
          redirectRoute={warningRedirectRoute}
        ></WarningMessage>
      )}
    </>
  );
};

const OrgLayout = ({
  pageTitle, // passed param
  hideAllNav, // passed param - hides top nav and side menu
  hideSideMenu, // passed param - hides side menu
  topNavTransparent, // passed param - removes white color from top nav
  messageProps,
  showSticky,
  stickyBannerProps,
  ffFlags,
  children,
}) => {
  if (hideAllNav) {
    return (
      <>
        <MessageContainer {...messageProps}></MessageContainer>
        {children}
      </>
    );
  }

  return (
    <>
      <div className="bd-docs bd-docs--hide-side-nav">
        <main className="bd-docs-main">
          <OrgNavBar
            pageTitle={pageTitle}
            ffFlags={ffFlags}
            isWhite={topNavTransparent ? false : true}
            hideSideMenu={hideSideMenu}
          />
          <div className="bd-docs-main__container">
            <MessageContainer {...messageProps}></MessageContainer>
            {children}
          </div>
        </main>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const messageProps = {
    errMessage: state.error.message,
    errTitle: state.error.title,
    showConfirmMessage: state.alerting.showConfirmMessage,
    confirmMessageProps: state.alerting.confirmMessageProps,
    warningTitle: state.error.warningTitle,
    warningMessage: state.error.warningMessage,
    warningRedirectRoute: state.error.warningRedirectRoute,
  };

  return {
    ffFlags: state.auth.flags,
    messageProps: messageProps,
    showSticky: state.alerting.showSticky,
    stickyBannerProps: state.alerting.stickyBannerProps,
  };
};

export default connect(mapStateToProps, null)(OrgLayout);
