import React from "react";
import { Link } from "react-router-dom";

const AdminPollsList = ({ polls }) => {
  return (
    <div>
      <table className="table is-hoverable is-fullwidth fs-block">
        <thead>
          <tr>
            <th></th>
            <th>UUID</th>
            <th>Type</th>
            <th>Prompt</th>
            <th>Votes</th>
          </tr>
        </thead>
        <tbody>
          {polls.map((poll) => {
            return (
              <tr key={poll["poll_uuid"]}>
                <td>
                  <Link state={poll} to={`/admin/polls/new`}>
                    Edit
                  </Link>
                </td>
                <td>{poll["poll_uuid"]}</td>
                <td>{poll["type"]}</td>
                <td>{poll["prompt"]}</td>
                <td>{poll["vote_count"]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AdminPollsList;
