import {
  getLendersStudent,
  getLendersPersonal,
  getLenderStudentBySlug,
  getLenderPersonalBySlug,
} from "../../services/storyblokApi";
import { LENDERS_ACTIONS } from "../reducers/lendersReducer";
import { setError } from "./errorActions";
// import { trackEvent, EVENTS } from "./analyticsActions";

// Note: Used to match based on min credit score, but moved to category matching instead
// const CREDIT_SCORE_STRING_TO_MIN_NUM = {
//   excellent: 750,
//   good: 700,
//   fair: 650,
//   bad: 0,
//   unknown: undefined
// };

// const CREDIT_SCORE_STRING_TO_MAX_NUM = {
//   excellent: 850,
//   good: 750,
//   fair: 700,
//   bad: 650,
//   unknown: undefined
// };

// const hasGoodEnoughCredit = (creditScore, minRequired) => {
//   let minUserScore = CREDIT_SCORE_STRING_TO_MIN_NUM[creditScore];
//   let maxUserScore = CREDIT_SCORE_STRING_TO_MAX_NUM[creditScore];

//   if (minUserScore === undefined || minUserScore === null) {
//     return false;
//   }

//   if (minUserScore >= minRequired) {
//     return true;
//   } else if (maxUserScore >= minRequired) {
//     // Note: We might want to be more flexible
//   }

//   return false;
// };

const matchesCreditScore = (lenderContent, userCreditScore, hasCosigner) => {
  const lenderCreditScores = lenderContent.credit_score_match;
  // const cosignerAllowed = lenderContent.cosigner_allowed;

  // If user credit score matches lender credit score, return true
  if (
    userCreditScore &&
    lenderCreditScores &&
    lenderCreditScores.includes(userCreditScore)
  ) {
    return true;
  }

  // // If user has cosigner, allow lenders that accept cosigners
  // if (cosignerAllowed && hasCosigner) {
  //   return true;
  // }

  return false;
};

export const getAllLendersStudent = (userCreditScore, hasCosigner) => {
  return (dispatch, getState) => {
    dispatch({ type: LENDERS_ACTIONS.GET_LENDERS_ALL_STUDENT });

    getLendersStudent()
      .then((response) => {
        let lenders = response.data.stories;
        let allLenders = [];

        lenders.forEach((l) => {
          if (l.content.is_active === true) {
            l.is_match = matchesCreditScore(
              l.content,
              userCreditScore,
              hasCosigner
            );
            allLenders.push(l);
          }
        });

        allLenders.sort((a, b) => {
          // Sort first, by whether it's matched or not
          // Matched items go first
          if (a.is_match !== b.is_match) {
            return a.is_match ? -1 : 1;
          }

          // Otherwise, sort alphabetically
          return a.name.localeCompare(b.name);
        });

        dispatch({
          type: LENDERS_ACTIONS.GET_LENDERS_ALL_STUDENT_SUCCESS,
          allLenders: allLenders,
        });
      })
      .catch((err) => {
        dispatch(setError(err));
        dispatch({ type: LENDERS_ACTIONS.GET_LENDERS_ALL_STUDENT_ERROR, err });
      });
  };
};

export const getMatchedLendersStudent = (
  userCreditScore,
  hasCosigner = false
) => {
  return (dispatch, getState) => {
    dispatch({ type: LENDERS_ACTIONS.GET_LENDERS_STUDENT });

    getLendersStudent()
      .then((response) => {
        let lenders = response.data.stories;
        let matchedLenders = [];

        lenders.forEach((l) => {
          if (
            l.content.is_active === true &&
            matchesCreditScore(l.content, userCreditScore, hasCosigner)
          ) {
            l.is_match = true;
            matchedLenders.push(l);
          }
        });

        matchedLenders.sort((a, b) => {
          // Sort alphabetically
          return a.name.localeCompare(b.name);
        });

        dispatch({
          type: LENDERS_ACTIONS.GET_LENDERS_STUDENT_SUCCESS,
          lenders: matchedLenders,
        });
      })
      .catch((err) => {
        dispatch(setError(err));
        dispatch({ type: LENDERS_ACTIONS.GET_LENDERS_STUDENT_ERROR, err });
      });
  };
};

export const getMatchedLendersPersonal = () => {
  return (dispatch, getState) => {
    dispatch({ type: LENDERS_ACTIONS.GET_LENDERS_PERSONAL });

    getLendersPersonal()
      .then((response) => {
        let lenders = response.data.stories;
        lenders = lenders.filter((l) => {
          return l.content.is_active === true && l.content.featured;
        });
        dispatch({
          type: LENDERS_ACTIONS.GET_LENDERS_PERSONAL_SUCCESS,
          lenders: lenders,
        });
      })
      .catch((err) => {
        dispatch(setError(err));
        dispatch({ type: LENDERS_ACTIONS.GET_LENDERS_PERSONAL_ERROR, err });
      });
  };
};

export const getLenderStudentFromSlug = (lenderSlug) => {
  return (dispatch, getState) => {
    dispatch({ type: LENDERS_ACTIONS.GET_PROFILE_STUDENT });

    getLenderStudentBySlug(lenderSlug)
      .then((response) => {
        dispatch({
          type: LENDERS_ACTIONS.GET_PROFILE_STUDENT_SUCCESS,
          lender: response.data.story,
        });
      })
      .catch((err) => {
        dispatch(setError(err));
        dispatch({ type: LENDERS_ACTIONS.GET_PROFILE_STUDENT_ERROR, err });
      });
  };
};

export const getLenderPersonalFromSlug = (lenderSlug) => {
  return (dispatch, getState) => {
    dispatch({ type: LENDERS_ACTIONS.GET_PROFILE_PERSONAL });

    getLenderPersonalBySlug(lenderSlug)
      .then((response) => {
        dispatch({
          type: LENDERS_ACTIONS.GET_PROFILE_PERSONAL_SUCCESS,
          lender: response.data.story,
        });
      })
      .catch((err) => {
        dispatch(setError(err));
        dispatch({ type: LENDERS_ACTIONS.GET_PROFILE_PERSONAL_ERROR, err });
      });
  };
};
