import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import AdminCoursesList from "../../components/Courses/AdminCoursesList";
import { asyncAdminGetCourses } from "../../store/actions/adminCoursesActions";

const AdminCoursesPage = (props) => {
  const dispatch = useDispatch();
  const { courses, adminError, updateCourseLoading, getCoursesLoading } =
    useSelector((state) => {
      return state.adminCourses;
    });

  useEffect(() => {
    dispatch(asyncAdminGetCourses());
  }, [dispatch]);

  if (updateCourseLoading || getCoursesLoading) {
    return (
      <div className="fs-block">
        <h1 className="title">Admin Courses</h1>
        <LoadingSpinner></LoadingSpinner>
      </div>
    );
  }
  return (
    <div className="fs-block">
      <h1 className="title">Admin Courses</h1>
      {courses && <AdminCoursesList courses={courses} />}
      {adminError && <p className="help is-danger">Error: {adminError}</p>}
      <br />
      <Link className="button is-primary" to="new">
        Add Course
      </Link>
    </div>
  );
};

export default AdminCoursesPage;
