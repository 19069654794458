import React from "react";

import Modal from "../Modal/Modal";
import NumberInput, { NUMBER_INPUT_TYPES } from "../Input/NumberInput";
import TextInput from "../Input/TextInput";
import Select from "../Input/Select";

import { useFormFields } from "../Form/useFormFields";

export const ACCOUNT_TYPES_REVERSE_MAP = {
  credit_card: "Credit card",
  student_loan: "Student loan",
  mortgage: "Mortgage",
  auto_loan: "Auto loan",
  general_loan: "Loan",
  other_liability: "Other liability",
  checking: "Checking",
  savings: "Savings",
  investment: "Investment",
  real_estate: "Real estate",
  real_auto: "Auto",
  other_asset: "Other asset",
};

export const ACCOUNT_TYPES_MAP = {
  "Credit card": "credit_card",
  "Student loan": "student_loan",
  Mortgage: "mortgage",
  "Auto loan": "auto_loan",
  Loan: "general_loan",
  "Other liability": "other_liability",
  Checking: "checking",
  Savings: "savings",
  Investment: "investment",
  "Real estate": "real_estate",
  Auto: "real_auto",
  "Other asset": "other_asset",
};

const ManualAccountUpdateModal = ({
  accountObj,
  onClose,
  isOpen,
  onNext,
  onDelete,
}) => {
  const [fields, handleFieldChange] = useFormFields({
    accountType: ACCOUNT_TYPES_REVERSE_MAP[accountObj.subtype],
    nickname: accountObj.display_name,
    balance: Math.abs(accountObj.total),
    interestRate: accountObj.interest_rate,
  });

  const footerObj = (
    <div className="buttons">
      <button
        form="sw-track-debt-form"
        type="submit"
        className="button is-primary"
      >
        Save
      </button>
      <button className="button is-danger is-inverted" onClick={onDelete}>
        Delete
      </button>
    </div>
  );

  const onSubmitForm = (event) => {
    event.preventDefault();
    onNext(fields);
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} title="Edit" footer={footerObj}>
      <form id="sw-track-debt-form" onSubmit={(event) => onSubmitForm(event)}>
        <div className="sw-padding--left-lg sw-padding--right-lg">
          <div className="field">
            <Select
              label="Type"
              name="accountType"
              className="fs-block is-medium"
              disabled={true}
              value={fields.accountType}
            >
              return (
              <option
                key={ACCOUNT_TYPES_MAP[fields.accountType]}
                value={ACCOUNT_TYPES_MAP[fields.accountType]}
              >
                {fields.accountType}
              </option>
              );
            </Select>
          </div>

          <div className="field">
            <TextInput
              label="Name"
              name="nickname"
              required={true}
              className="fs-block"
              value={fields.nickname}
              onChange={handleFieldChange}
            ></TextInput>
          </div>

          <div className="field">
            <NumberInput
              label="Current balance"
              name="balance"
              required={true}
              className="fs-block"
              value={fields.balance}
              onChange={handleFieldChange}
            ></NumberInput>
          </div>

          {!accountObj.is_asset && (
            <div className="field">
              <NumberInput
                label="Interest Rate"
                name="interestRate"
                className="fs-block"
                value={fields.interestRate}
                onChange={handleFieldChange}
                required={
                  ACCOUNT_TYPES_MAP["Student loan"] === fields.accountType
                }
                numberType={NUMBER_INPUT_TYPES.INTEREST}
              ></NumberInput>
            </div>
          )}
        </div>
      </form>
    </Modal>
  );
};

export default ManualAccountUpdateModal;
