import React from "react";

import ProfileLabelInfo from "../Input/ProfileLabelInfo";
import { EditUserSettingsButton } from "./EditUserSettings";

const PersonalInfo = (props) => {
  return (
    <div>
      <div className="columns is-mobile">
        <h3 className="column is-narrow subtitle">Personal Info</h3>
        <span className="column is-narrow">
          <EditUserSettingsButton></EditUserSettingsButton>
        </span>
      </div>

      {props.currentUser.first_name && (
        <ProfileLabelInfo
          label="Name"
          name="firstName"
          value={props.currentUser.first_name}
        ></ProfileLabelInfo>
      )}
      <ProfileLabelInfo
        label="Email"
        name="email"
        value={props.currentUser.email}
      ></ProfileLabelInfo>
      {props.currentUser.display_name && (
        <ProfileLabelInfo
          label="Username"
          name="display_name"
          value={props.currentUser.display_name}
        ></ProfileLabelInfo>
      )}
    </div>
  );
};

export default PersonalInfo;
