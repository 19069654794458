import React, { useState } from "react";

import DebtAccounts from "./DebtAccounts";
import AssetsAccounts from "./AssetsAccounts";
import NetWorthHeader from "./NetWorthHeader";
import SortByDropdown from "../Buttons/SortByDropdown";

import { NW_TABS, SORT_BY_OPTIONS } from "../../constants/constants";

const getTabAccountInfo = (tab, netWorthAgg) => {
  let hasAccountsForTab = false;
  let totalAmountForTab = 0;

  if (!netWorthAgg) {
    return [hasAccountsForTab, totalAmountForTab];
  }

  // determine tab specific variables
  if (tab === NW_TABS.DEBT) {
    totalAmountForTab = netWorthAgg.total_debts_balance;
    hasAccountsForTab = netWorthAgg.num_debt_accounts > 0;
  } else if (tab === NW_TABS.ASSETS) {
    totalAmountForTab = netWorthAgg.total_assets_balance;
    hasAccountsForTab = netWorthAgg.num_asset_accounts > 0;
  } else {
    // default to net worth
    // net worth is assets - debts. Debts already come negative.
    totalAmountForTab =
      netWorthAgg.total_assets_balance + netWorthAgg.total_debts_balance;
    hasAccountsForTab = netWorthAgg.num_total_accounts > 0;
  }

  return [hasAccountsForTab, totalAmountForTab];
};

const NetWorthTracker = ({
  tab,
  baseRoute,
  netWorth,
  graphData,
  updateNetWorthItemsFn,
  isLoading,
}) => {
  const activeTab = tab || NW_TABS.NET_WORTH;
  const [hasAccountsForTab, totalAmountForTab] = getTabAccountInfo(
    activeTab,
    netWorth ? netWorth.aggregates : null
  );

  // only used on debt pages
  const [sortBy, setSortBy] = useState(SORT_BY_OPTIONS[0]);

  return (
    <div>
      <NetWorthHeader
        graphData={graphData}
        totalAmount={totalAmountForTab}
        isoCurrencyCode={netWorth ? netWorth.metadata.iso_currency_code : null}
        updateLoans={updateNetWorthItemsFn}
        activeTab={activeTab}
        baseRoute={baseRoute}
        showEmptyState={hasAccountsForTab}
        isLoading={isLoading}
      />

      {hasAccountsForTab && (
        <div className="sw-padding--top-lg sw-padding--bottom-md level">
          <span className="level-left level-item has-text-weight-semibold">
            All Accounts
          </span>
          <span className="level-right level-item">
            <span className="sw-padding--right-sm">Sort by: </span>{" "}
            <SortByDropdown
              sortBy={sortBy}
              setSortBy={setSortBy}
            ></SortByDropdown>
          </span>
        </div>
      )}

      {netWorth && activeTab !== NW_TABS.DEBT && (
        <AssetsAccounts
          deposits={netWorth.deposits}
          investments={netWorth.investments}
          otherAssets={netWorth.other_assets}
          sortBy={sortBy}
        ></AssetsAccounts>
      )}

      {netWorth && activeTab !== NW_TABS.ASSETS && (
        <DebtAccounts
          credit={netWorth.liabilities.credit}
          mortgage={netWorth.liabilities.mortgage}
          student={netWorth.liabilities.student}
          otherDebts={netWorth.other_debts}
          sortBy={sortBy}
        />
      )}
    </div>
  );
};

export default NetWorthTracker;
