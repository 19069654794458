import React from "react";

const LoadingSpinner = ({ size }) => {
  let spinnerSizeClassName = "fa-3x";

  if (size === "small") {
    spinnerSizeClassName = "fa-1x";
  } else if (size === "medium") {
    spinnerSizeClassName = "fa-2x";
  }

  return (
    <div className={spinnerSizeClassName}>
      <i className="fas fa-circle-notch fa-spin"></i>
    </div>
  );
};

export default LoadingSpinner;
