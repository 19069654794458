import StoryblokClient from "storyblok-js-client";

// version for storyblok content, use published for prod
const SB_VERSION =
  process.env.REACT_APP_STORYBLOK_IS_DRAFT === "true" ? "draft" : "published";

// for requests, need to pass token as "token", not "access token"
const storyblok_options = {
  version: SB_VERSION,
};

const Storyblok = new StoryblokClient({
  ...storyblok_options,
  accessToken: process.env.REACT_APP_STORYBLOK_TOKEN,
});

export const getLendersStudent = () => {
  return Storyblok.get("cdn/stories", {
    ...storyblok_options,
    starts_with: "student-debt/lenders",
    is_startpage: false,
    sort_by: "content.name:asc",
  });
};

export const getLendersPersonal = () => {
  return Storyblok.get("cdn/stories", {
    ...storyblok_options,
    starts_with: "personal-debt/lenders",
    is_startpage: false,
  });
};

export const getLenderStudentBySlug = (slug) => {
  return Storyblok.get(
    `cdn/stories/student-debt/lenders/${slug}`,
    {
      ...storyblok_options,
    },
    false
  );
};

export const getLenderPersonalBySlug = (slug) => {
  return Storyblok.get(
    `cdn/stories/personal-debt/lenders/${slug}`,
    {
      ...storyblok_options,
    },
    false
  );
};

export const getBlogsForHomepage = (limit = 2) => {
  return Storyblok.get("cdn/stories", {
    ...storyblok_options,
    starts_with: "blog",
    per_page: limit,
    resolve_relations: "author,category",
    is_startpage: false,
    filter_query: {
      featured_home: {
        in: true,
      },
    },
  });
};

export const getBlogsForRefiSuccess = (limit = 3) => {
  // filters for "money story" and "refinancing" categories
  return Storyblok.get("cdn/stories", {
    ...storyblok_options,
    starts_with: "blog",
    per_page: limit,
    resolve_relations: "author,category",
    is_startpage: false,
    filter_query: {
      category: {
        all_in_array:
          "dfeb85d7-4be7-4c8d-b46e-d736cb1dd533,44fef3da-484d-429b-82ef-d8afc85d3adc",
      },
    },
  });
};
