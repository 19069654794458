import React from "react";
import { ImageIconSqr } from "../Icon/ImageIcon";

const LegacyBadge = ({ badgeDrawingInfo, alt }) => {
  return (
    <ImageIconSqr
      iconSrc={badgeDrawingInfo.image_url}
      alt={alt}
      size="challenge"
    ></ImageIconSqr>
  );
};

const InProgressBadge = ({ badgeDrawingInfo, alt, progressDegree }) => {
  const inProgressIcon = {
    filter:
      "invert(38%) sepia(92%) saturate(1425%) hue-rotate(165deg) brightness(100%) contrast(101%)",
    position: "absolute",
    zIndex: "11",
  };

  // don't use gradient if in progress
  return (
    <div className="sw-challenge-progress-bar__wrapper">
      <div style={inProgressIcon}>
        <ImageIconSqr
          iconSrc={badgeDrawingInfo.image_url}
          alt={alt}
          size="challenge"
          style={{
            filter: "brightness(0) saturate(100%)",
          }}
        ></ImageIconSqr>
      </div>
      <span
        className="sw-icon is-challenge sw-challenge-progress-bar"
        style={{
          "--progress": `${progressDegree}deg`,
          "--progressColor": `${badgeDrawingInfo.image_tint}`,
          "--progressCircleBkg": "rgba(71, 161, 180, 0.25)",
          "--backgroundAfter": "white",
        }}
      ></span>
    </div>
  );
};

const GradientBadge = ({ badgeDrawingInfo, alt, progressDegree }) => {
  const backgroundGradient = `linear-gradient(180deg, ${badgeDrawingInfo.gradient_start_color} 0%, ${badgeDrawingInfo.gradient_end_color} 98.73%)`;

  const insideCircle = {
    borderRadius: "50%",
    position: "absolute",
    zIndex: "30",
  };

  const biggerCircleBackground = {
    background: backgroundGradient,
    borderRadius: "50%",
    width: "110px",
    height: "110px",
    position: "absolute",
  };

  return (
    <div className="sw-challenge-progress-bar__wrapper">
      <div style={insideCircle}>
        <ImageIconSqr
          iconSrc={badgeDrawingInfo.image_url}
          alt={alt}
          size="challenge"
        ></ImageIconSqr>
      </div>
      <span
        className="sw-challenge-progress-bar"
        style={{
          "--progress": `${progressDegree}deg`,
          "--progressColor": `${badgeDrawingInfo.image_tint}`,
          "--progressCircleBkg": "rgba(255, 255, 255, .5)",
          "--backgroundAfter": `${backgroundGradient}`,
        }}
      ></span>
      <div style={biggerCircleBackground}></div>
    </div>
  );
};

const ChallengeBadge = ({ badgeDrawingInfo, progressPercent }) => {
  let progressDegree = progressPercent ? (progressPercent / 100) * 360 : 0;
  const alt = `Challenge badge with ${progressPercent} percent progress`;

  if (badgeDrawingInfo.show_legacy_image) {
    return (
      <LegacyBadge
        badgeDrawingInfo={badgeDrawingInfo}
        alt={alt}
        size="challenge"
      ></LegacyBadge>
    );
  }

  if (badgeDrawingInfo.challenge_in_progress) {
    return (
      <InProgressBadge
        badgeDrawingInfo={badgeDrawingInfo}
        alt={alt}
        size="challenge"
        progressDegree={progressDegree}
      ></InProgressBadge>
    );
  }

  return (
    <GradientBadge
      badgeDrawingInfo={badgeDrawingInfo}
      alt={alt}
      size="challenge"
      progressDegree={progressDegree}
    ></GradientBadge>
  );
};

export default ChallengeBadge;
