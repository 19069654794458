import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { ADMIN_ROUTES } from "../../constants/routes";
import {
  asyncAdminUpdateCourse,
  asyncAdminUpdateTask,
  asyncAdminDeleteTask,
  asyncAdminGetCourse,
  asyncAdminUpdateCohort,
} from "../../store/actions/adminCoursesActions";

import { useFormFields } from "../../components/Form/useFormFields";
import { BackLink } from "../../components/Buttons/BackButtons";
import AdminCoursesForm from "../../components/Courses/AdminCoursesForm";
import AdminCourseEditTasks from "../../components/Courses/AdminCourseEditTasks";
import AdminCourseCohorts from "../../components/Courses/AdminCourseCohorts";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { getDefaultCourseFields } from "../../components/Courses/adminCourseHelpers";

const AdminEditCoursePage = (props) => {
  let { courseId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(asyncAdminGetCourse(courseId));
  }, [dispatch, courseId]);

  const { courses, adminError, updateCourseLoading, getCoursesLoading } =
    useSelector((state) => {
      return state.adminCourses;
    });

  const defaultFields = useMemo(() => {
    return getDefaultCourseFields();
  }, []);

  const [fields, handleFieldChange, setValues] = useFormFields(defaultFields);

  useEffect(() => {
    if (courses && courses.length > 0) {
      // note: this array should only have 1 item, but double check id
      const filteredCourse = courses.filter(
        (el) => el.course_uuid === courseId
      );
      const thisCourse = filteredCourse[0];
      const updatedFields = getDefaultCourseFields(thisCourse);
      setValues(updatedFields);
    }
  }, [courses, courseId, setValues, defaultFields]);

  const onUpdateCourseInfo = useCallback(
    async (event) => {
      event.preventDefault();
      dispatch(asyncAdminUpdateCourse(fields));
    },
    [fields, dispatch]
  );

  const onUpdateTask = useCallback(
    async (taskFields) => {
      // request will update the courses array and update this page
      dispatch(asyncAdminUpdateTask(taskFields));
    },
    [dispatch]
  );

  const onDeleteTask = useCallback(
    async (taskFields) => {
      // request will update the courses array and update this page
      dispatch(asyncAdminDeleteTask(taskFields));
    },
    [dispatch]
  );

  const onAddCohort = useCallback(
    async (cohortFields) => {
      // request will update the courses array and update this page
      dispatch(asyncAdminUpdateCohort(cohortFields));
    },
    [dispatch]
  );

  if (getCoursesLoading) {
    return (
      <div className="fs-block">
        <BackLink routeLink={ADMIN_ROUTES.COURSES} text={"Go back"}></BackLink>
        <LoadingSpinner></LoadingSpinner>
      </div>
    );
  }

  return (
    <div className="fs-block">
      <BackLink routeLink={ADMIN_ROUTES.COURSES} text={"Go back"}></BackLink>

      <h1 className="title">Course Details</h1>
      <div>
        <AdminCoursesForm
          onSubmitForm={onUpdateCourseInfo}
          fields={fields}
          handleFieldChange={handleFieldChange}
          isLoading={updateCourseLoading}
        ></AdminCoursesForm>
      </div>
      {fields.courseTasks && (
        <div className="sw-padding--top-lg">
          <AdminCourseEditTasks
            courseUuid={courseId}
            tasks={fields.courseTasks}
            onUpdateTask={onUpdateTask}
            onDeleteTask={onDeleteTask}
          ></AdminCourseEditTasks>
        </div>
      )}
      {fields.cohorts && (
        <div className="sw-padding--top-lg">
          <AdminCourseCohorts
            courseUuid={courseId}
            courseType={fields.courseType}
            courseAccess={fields.courseAccess}
            cohorts={fields.cohorts}
            onAddCohort={onAddCohort}
          ></AdminCourseCohorts>
        </div>
      )}
      <br />
      {adminError && <div className="has-text-danger">Error: {adminError}</div>}
    </div>
  );
};

export default AdminEditCoursePage;
