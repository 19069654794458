import { AUTH_ACTIONS } from "./authReducer";

const initState = {
  getLoading: false,
  loan_data: {
    accounts: null,
    all_accounts_array: null,
    aggregates: null,
  },
  snapshot_data: null,
};

const ACTION_PREFIX = "LOANS/";
export const STUDENT_DASHBOARD_ACTIONS = {
  GET: ACTION_PREFIX + "GET",
  GET_ERROR: ACTION_PREFIX + "GET_ERROR",
  GET_SUCCESS: ACTION_PREFIX + "GET_SUCCESS",
};

const studentDashboardReducer = (state = initState, action) => {
  switch (action.type) {
    case STUDENT_DASHBOARD_ACTIONS.GET:
      return {
        ...state,
        getLoading: true,
      };
    case STUDENT_DASHBOARD_ACTIONS.GET_ERROR:
      return {
        ...state,
        getLoading: false,
      };
    case STUDENT_DASHBOARD_ACTIONS.GET_SUCCESS:
      const responseData = action.res.data;
      return {
        ...state,
        getLoading: false,
        loan_data: responseData.loan_data,
        snapshot_data: responseData.snapshot_data,
      };
    case AUTH_ACTIONS.SIGNOUT:
      return initState; //Always return the initial state
    default:
      return state;
  }
};

export default studentDashboardReducer;
