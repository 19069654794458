import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useOutletContext } from "react-router-dom";

import { LINK_URLS } from "../../constants/constants";

import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";
import { getStudentDashboard } from "../../store/actions/studentDashboardActions";
import {
  getUserFinancialProfile,
  isOnboardingComplete,
} from "../../store/actions/profileActions";
import {
  setStickyBanner,
  clearStickyBanner,
} from "../../store/actions/alertingActions";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { ImpactedByCovidAnnouncement } from "../../components/Messages/AnnouncementBanner";
import StudentLoansListChart from "../../components/LoanCharts/StudentLoansListChart";
import StudentLoanHistoryWidget from "../../components/LoanCharts/StudentLoanHistoryWidget";
import RepaymentOptions from "../../components/Recommendation/RepaymentOptions";
import StudentLoanDashboardEmpty from "../../components/StudentLoans/StudentLoanDashboardEmpty";
import WhatYouShouldKnow from "../../components/Recommendation/WhatYouShouldKnow";

const StudentLoansDashboardPage = (props) => {
  const trackEventFn = props.trackEvent;
  const getStudentDashboardFn = props.getStudentDashboard;
  const getUserFinancialProfileFn = props.getUserFinancialProfile;
  const setStickyBannerFn = props.setStickyBanner;
  const clearStickyBannerFn = props.clearStickyBanner;

  const setPageTitle = useOutletContext();
  useEffect(() => {
    setPageTitle("Student loan dashboard");
  }, [setPageTitle]);

  useEffect(() => {
    trackEventFn(EVENTS.LOAN_SUMMARY_VIEWED);
    getStudentDashboardFn();
    getUserFinancialProfileFn();
  }, [trackEventFn, getStudentDashboardFn, getUserFinancialProfileFn]);

  useEffect(() => {
    setStickyBannerFn(
      "Have questions? Talk to a financial coach",
      LINK_URLS.SNOWBALL_CONSULT
    );
    return function cleanup() {
      clearStickyBannerFn();
    };
  }, [setStickyBannerFn, clearStickyBannerFn]);

  if (props.isLoading) {
    return (
      <div className="sw-flex-layout--column-center">
        <LoadingSpinner></LoadingSpinner>
      </div>
    );
  }

  const onAddAccountSuccess = () => {
    getStudentDashboardFn();
  };

  // check if should show empty state
  let showEmpty = false;
  if (
    !isOnboardingComplete(props.profileObj) ||
    !props.aggregates ||
    props.accountsArray.length < 1
  ) {
    showEmpty = true;
  }

  return (
    <div className="sw-flex-layout--column-center">
      <div className="sw-container--fixed-width sw-is-max-wide">
        <ImpactedByCovidAnnouncement></ImpactedByCovidAnnouncement>
        <div className="columns is-multiline sw-student-loan-dashboard__column-container">
          <div className="column is-loan-history">
            {showEmpty ? (
              <StudentLoanDashboardEmpty
                onAddAccountSuccess={onAddAccountSuccess}
                profileObj={props.profileObj}
              ></StudentLoanDashboardEmpty>
            ) : (
              <StudentLoanHistoryWidget
                snapshotData={props.snapshotData}
                aggregates={props.aggregates}
              ></StudentLoanHistoryWidget>
            )}
            <div className="is-hidden-mobile">
              <RepaymentOptions></RepaymentOptions>
            </div>
          </div>
          <div className="column is-narrow is-loan-list">
            {!showEmpty && (
              <StudentLoansListChart
                accountsArray={props.accountsArray}
                loanBalance={props.aggregates.loan_balance}
                onAddAccountSuccess={onAddAccountSuccess}
              ></StudentLoansListChart>
            )}
            <WhatYouShouldKnow></WhatYouShouldKnow>
          </div>
        </div>
        <div className="columns is-multiline sw-student-loan-dashboard__column-container is-hidden-tablet">
          <RepaymentOptions></RepaymentOptions>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStudentDashboard: () => dispatch(getStudentDashboard()),
    getUserFinancialProfile: () => dispatch(getUserFinancialProfile()),
    setStickyBanner: (text, link) => dispatch(setStickyBanner(text, link)),
    clearStickyBanner: () => dispatch(clearStickyBanner()),
    trackEvent: (event) => dispatch(trackEvent(event)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const profileObj = state.profile ? state.profile.profile : null;

  return {
    isLoading: state.recommendations.getLoading,
    accountsArray: state.studentDashboard.loan_data.all_accounts_array,
    aggregates: state.studentDashboard.loan_data.aggregates,
    snapshotData: state.studentDashboard.snapshot_data,
    recs: state.recommendations.recommendations,
    recMessages: state.recommendations.recMessages,
    recIsVisible: state.recommendations.recIsVisible,
    recModelType: state.recommendations.recModelType,
    profileObj: profileObj,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentLoansDashboardPage);
