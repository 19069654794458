import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useFormFields } from "../../components/Form/useFormFields";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

import {
  getAllCommunities,
  asyncGetUserCommunities,
  asyncUpdateUserCommunities,
} from "../../store/actions/adminCommunitiesActions";

const AdminUserCommunitiesPage = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();

  const [fields, handleFieldChange] = useFormFields({
    newCommunityUuid: "",
  });

  const onDeleteCommunity = (communityUuid) => {
    if (communityUuid) {
      dispatch(asyncUpdateUserCommunities(userId, communityUuid, true));
    } else {
      return;
    }
  };

  const onAddCommunity = () => {
    if (fields.newCommunityUuid) {
      dispatch(
        asyncUpdateUserCommunities(userId, fields.newCommunityUuid, false)
      );
    } else {
      return;
    }
  };

  useEffect(() => {
    dispatch(getAllCommunities());
    dispatch(asyncGetUserCommunities(userId));
  }, [dispatch, userId]);

  const { getCommunitiesLoading, userCommunities, communities } = useSelector(
    (state) => {
      return state.adminCommunities;
    }
  );

  // create dict of communities is NOT in
  const currCommunitiesDict = {};
  const newCommunitiesArray = [];

  if (userCommunities) {
    userCommunities.forEach((community) => {
      currCommunitiesDict[community.community_uuid] = true;
    });
  }

  if (communities) {
    communities.forEach((community) => {
      if (!currCommunitiesDict[community.community_uuid]) {
        newCommunitiesArray.push(community);
      }
    });
  }

  if (!userId) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  return (
    <div className="fs-block sw-container--fixed-width">
      <h3 className="sw-section-header">User communities</h3>

      <div className="sw-padding--bottom-xl">
        <table className="table is-hoverable is-fullwidth fs-block">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Is private</th>
              <th>Is global</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {userCommunities &&
              userCommunities.map((community) => {
                return (
                  <tr key={community.community_uuid}>
                    <td>{community.community_uuid}</td>
                    <td>{community.name}</td>
                    <td>{community.is_private ? "true" : "false"}</td>
                    <td>{community.is_global ? "true" : "false"}</td>
                    <td>
                      <button
                        className="button is-ghost"
                        onClick={() =>
                          onDeleteCommunity(community.community_uuid)
                        }
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      <div className="">
        <div className="field">
          <label className="subtitle label has-text-weight-normal">
            Add a new community
          </label>
          {getCommunitiesLoading && <LoadingSpinner></LoadingSpinner>}
          {!getCommunitiesLoading && communities && newCommunitiesArray && (
            <div className="select fs-block field">
              <select
                id="newCommunityUuid"
                name="newCommunityUuid"
                value={fields.newCommunityUuid}
                onChange={handleFieldChange}
              >
                <option key={""} value={""}>
                  None
                </option>
                {newCommunitiesArray.map((communityObj) => {
                  return (
                    <option
                      key={communityObj.community_uuid}
                      value={communityObj.community_uuid}
                    >
                      {communityObj.name}
                      {communityObj.is_private ? " (Private)" : ""}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          <button
            className="button is-primary"
            onClick={onAddCommunity}
            disabled={!fields.newCommunityUuid}
          >
            Add community
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminUserCommunitiesPage;
