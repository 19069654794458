import React from "react";

import { ImageIconSqr } from "../Icon/ImageIcon";
import { ICONS } from "../../constants/images";

const ExplainerBox = ({
  title,
  content,
  prosList,
  consList,
  buttonText,
  onClick,
}) => {
  const buttonOnClick =
    onClick ||
    (() => {
      return;
    });

  return (
    <div className="">
      <div className="sw-box-card-header">
        <p className="subtitle has-text-weight-semibold">{title}</p>
      </div>

      <div className="sw-box-card-body">
        <div className="media-content">
          <p className="content">{content}</p>
        </div>
        <p className="has-text-grey sw-padding--bottom-md">PROS</p>
        {prosList && (
          <ul>
            {prosList.map((item, index) => {
              return (
                <li className="is-flex" key={index}>
                  <ImageIconSqr
                    iconSrc={ICONS.CHECK_GREEN}
                    alt="green check"
                    size="small"
                  />
                  <span className="sw-padding--left-md">{item}</span>
                </li>
              );
            })}
          </ul>
        )}

        <p className="has-text-grey sw-padding--top-lg sw-padding--bottom-md">
          CONSIDERATIONS
        </p>
        {consList && (
          <ul>
            {consList.map((item, index) => {
              return (
                <li className="is-flex" key={index}>
                  <ImageIconSqr
                    iconSrc={ICONS.ARROW_BLUE}
                    alt="blue arrow"
                    size="small"
                  />
                  <span className="sw-padding--left-md">{item}</span>
                </li>
              );
            })}
          </ul>
        )}

        {buttonText && (
          <div className="is-flex is-fullwidth is-justify-content-center sw-padding--top-lg">
            <button
              className="button is-outlined is-primary sw-is-wide"
              onClick={buttonOnClick}
            >
              {buttonText}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExplainerBox;
