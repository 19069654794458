import React, { useState } from "react";

import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import Modal from "../Modal/Modal";
import Radio from "../Input/Radio";

const TrackManuallyModal = ({ onClose, isOpen, onBack, onNext }) => {
  const [isAsset, setIsAsset] = useState(true);

  const onIsAssetChange = (event) => {
    // input values are automatically converte to string, so check manually
    setIsAsset(event.target.value === "true" || event.target.value === true);
  };

  const footerObj = (
    <button className={"button is-primary"} onClick={() => onNext(isAsset)}>
      Continue
    </button>
  );

  return (
    <Modal onClose={onClose} isOpen={isOpen} onBack={onBack} footer={footerObj}>
      <div className="sw-padding--left-lg sw-padding--right-lg">
        <p className="modal-card-body__title">Track an account manually</p>

        <div className="field">
          <label className="modal-card-body__subtitle" htmlFor="isAsset">
            What would you like to track?
          </label>

          <FormControl component="fieldset" className="fs-block">
            <RadioGroup value={isAsset} id="isAsset" onChange={onIsAssetChange}>
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="An asset (e.g. cash, investments)"
                labelPlacement="end"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="A debt account (e.g. loans, credit card)"
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </Modal>
  );
};

export default TrackManuallyModal;
