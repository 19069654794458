import { useState, useEffect } from "react";

export const LOCAL_STORAGE_KEYS = {
  PLAID_LINK_TOKEN: "sw_plaid_link_token",
  PLAID_LINK_MODE: "sw_plaid_link_mode",
};

const useStateWithLocalStorage = (localStorageKey) => {
  const [value, setValue] = useState(
    localStorage.getItem(localStorageKey) || ""
  );

  useEffect(() => {
    localStorage.setItem(localStorageKey, value);
  }, [localStorageKey, value]);

  return [value, setValue];
};

export default useStateWithLocalStorage;
