import { apiGet, apiPost } from "./apiActions";
import helpers from "../../services/helpers";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  getAdditionalUserInfo,
  browserPopupRedirectResolver,
} from "firebase/auth";

import { AUTH_ACTIONS } from "../reducers/authReducer";
import {
  resetAnalyticsSession,
  trackIdentify,
  trackEvent,
  EVENTS,
} from "./analyticsActions";
import { clearError, setError, setLogoutError } from "./errorActions";

const addUser = (email, userUid, firstName) => {
  return (dispatch, getState) => {
    const endpoint = "/api/users/me";
    const data = {
      email: email,
      user_uid: userUid,
      first_name: firstName,
    };

    const onSuccess = (res) => {
      // Created user successfully
      const user = res.data.data;

      const onTrackSuccess = () => {
        dispatch({
          type: AUTH_ACTIONS.GET_SUCCESS,
          res,
        });
        dispatch(
          trackEvent(EVENTS.ACCOUNT_CREATED, { user_email: user.email })
        );
      };

      dispatch(
        trackIdentify(user.id, user.email, firstName, null, onTrackSuccess)
      );
    };

    const onError = (err) => {
      const errMessage = helpers.getMessageFromErrObj(err);
      if (errMessage === "Sorry. That user already exists.") {
        // if user already exists, means we had a race condition
        // try getting the user again
        dispatch(getCurrentUser());
      } else {
        console.error(err);
        dispatch(setError(err));
      }
    };

    dispatch(apiPost(endpoint, data, onSuccess, onError));
  };
};

/**
 * Gets details of logged in user
 */
export const getCurrentUser = () => {
  return (dispatch, getState) => {
    const endpoint = `/api/users/me`;

    const onSuccess = (res) => {
      const user = res.data.data;
      dispatch(clearError());

      const onTrackSuccess = () => {
        dispatch({
          type: AUTH_ACTIONS.GET_SUCCESS,
          res,
        });
      };

      dispatch(
        trackIdentify(
          user.id,
          user.email,
          user.first_name,
          null,
          onTrackSuccess()
        )
      );
    };

    const onError = (err) => {
      console.error(err);
      dispatch({ type: AUTH_ACTIONS.ERROR, err });
      // since this is an auth error, log user out from firebase.
      dispatch(signOutUser(err));
    };

    dispatch(apiGet(endpoint, onSuccess, onError));
  };
};

export const onAuthChangeListener = () => {
  return (dispatch, getState) => {
    // onAuthStateChanged is an observer that is triggered whenever
    // a user is signed in or signed out via firebase.
    // We changed this from onIdTokenStateChanged. The difference between
    // the two observers is that onIdTokenStateChanged also triggers when
    // a user's token changes like for example if it expires. We do not need
    // this extra functionality at this time but it might be useful later on.
    // See https://firebase.google.com/docs/reference/js/firebase.auth.Auth for more info.
    const auth = getAuth();
    return onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdToken().then((idToken) => {
          helpers.setCookie("snowball-jwt", idToken);

          const addLoading = getState().auth.addLoading;
          const googleLoading = getState().auth.googleLoading;

          if (!addLoading && !googleLoading) {
            // We should only do this if we're not:
            // adding a user or signing in from google
            dispatch(getCurrentUser());
          }
        });
      } else {
        // No user signed in -- make sure user is in signed out state
        dispatch({ type: AUTH_ACTIONS.SIGNOUT });
        helpers.deleteCookie("snowball-jwt");
        dispatch(resetAnalyticsSession());
      }
    });
  };
};

export const signIn = (email, password) => {
  return (dispatch, getState) => {
    dispatch({
      type: AUTH_ACTIONS.SIGN_IN,
      payload: {
        googleLoading: false,
      },
    });
    // clear errors if any
    dispatch(clearError());

    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // onAuthChangeListener will be triggered
        // all login actions handled from there
      })
      .catch((err) => {
        console.error(err);
        dispatch({ type: AUTH_ACTIONS.ERROR, err });
      });
  };
};

export const signInGoogle = () => {
  return (dispatch, getState) => {
    dispatch({
      type: AUTH_ACTIONS.SIGN_IN,
      payload: {
        googleLoading: true,
      },
    });
    // clear errors if any
    dispatch(clearError());

    const auth = getAuth();

    const provider = new GoogleAuthProvider();
    // provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
    // provider.setCustomParameters({
    //   prompt: "select_account",
    // });

    signInWithPopup(auth, provider, browserPopupRedirectResolver)
      .then((result) => {
        const user = result.user;

        // If new user
        const addtUserInfo = getAdditionalUserInfo(result);
        if (addtUserInfo.isNewUser) {
          const userName = addtUserInfo.profile.given_name || user.displayName;

          dispatch({
            type: AUTH_ACTIONS.ADD,
            payload: {
              firstName: userName,
              googleLoading: true,
            },
          });

          // firebase create success, now we need to
          // get id token so we can make a call to the
          // snowball database to create a new user
          user.getIdToken().then((idToken) => {
            helpers.setCookie("snowball-jwt", idToken);
            dispatch(addUser(user.email, user.uid, userName));
          });
        } else {
          // this is an existing user, but we need to handle auth manually
          // we block onAuthChangeListener since we don't know if it was
          // a new user or not on first auth change
          user.getIdToken().then((idToken) => {
            helpers.setCookie("snowball-jwt", idToken);
            dispatch(getCurrentUser());
          });
        }
      })
      .catch((err) => {
        console.error(err);

        if (err.code === "auth/account-exists-with-different-credential") {
          // TODO: Figure out if this ever happens
          // Step 3: Save the pending credential in temporary storage,
          // Step 4: Let the user know that they already have an account
          // but with a different provider, and let them choose another
          // sign-in method.
        }

        dispatch({ type: AUTH_ACTIONS.ERROR, err });
      });
  };
};

export const signUp = (email, password, firstName) => {
  return (dispatch, getState) => {
    dispatch({
      type: AUTH_ACTIONS.ADD,
      payload: { firstName: firstName },
    });

    // first, create a new user with firebase
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // firebase create success, now we need to
        // get id token so we can make a call to the
        // snowball database to create a new user
        userCredential.user.getIdToken().then((idToken) => {
          helpers.setCookie("snowball-jwt", idToken);
          dispatch(addUser(email, userCredential.user.uid, firstName));
        });

        // Note: onAuthChangeListener be triggered as well
      })
      .catch((err) => {
        console.error(err);
        dispatch({ type: AUTH_ACTIONS.ERROR, err });
      });
  };
};

/**
 * Called when user signs out. This triggers firebase
 *
 * @param withError - optional. If signing out with an error, pass error obj to show.
 */
export const signOutUser = (withError) => {
  return (dispatch, getState) => {
    // Set to sign out state now
    // we don't want to wait for firebase to set state
    dispatch({ type: AUTH_ACTIONS.SIGNOUT });

    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // onAuthChangeListener will handle rest of actions
        if (withError) {
          dispatch(setLogoutError(withError));
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch({ type: AUTH_ACTIONS.ERROR, err });
      });
  };
};

export const sendResetPasswordEmail = (email) => {
  return (dispatch, getState) => {
    dispatch({ type: AUTH_ACTIONS.RESET_PW });
    dispatch(trackEvent(EVENTS.PASSWORD_FORGET_CLICKED, { email: email }));
    dispatch(clearError());

    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then((res) => {
        dispatch({ type: AUTH_ACTIONS.RESET_PW_SUCCESS });
      })
      .catch((err) => {
        console.error(err);
        let errMessage =
          "Please try again or contact the Snowball team if the problem continues.";
        if (err && err.code === "auth/user-not-found") {
          errMessage = "We don't have this email address in our records.";
        }
        dispatch({ type: AUTH_ACTIONS.RESET_PW_ERROR, err });
        dispatch(setError(err, errMessage));
      });
  };
};

export const clearForm = () => {
  return (dispatch, getState) => {
    dispatch({ type: AUTH_ACTIONS.CLEAR_ERROR });
  };
};
