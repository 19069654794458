import React, { useState } from "react";

import Radio from "../../components/Input/Radio";

import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import { ControlMoneyInput } from "../Input/NumberInput";
import { useFormFields } from "../Form/useFormFields";
import { NextSubmitButton } from "../Buttons/NextButton";

import {
  CREDIT_SCORE_OPTIONS_MAP,
  CREDIT_SCORE_OPTIONS_MAP_DISPLAY,
} from "../../store/actions/profileActions";

const QuestionsPartTwoForm = ({ initialValues, onSubmit, onBack }) => {
  const [fields, handleFieldChange] = useFormFields({
    income: initialValues.income,
    personalDebt: initialValues.personalDebt,
  });

  const [creditScore, setCreditScore] = useState(initialValues.creditScore);

  const onSubmitForm = (event) => {
    event.preventDefault();

    const responses = {
      income: fields.income,
      personalDebt: fields.personalDebt,
      creditScore: creditScore,
    };

    onSubmit(responses);
  };

  const onCreditScoreChange = (event) => {
    setCreditScore(event.target.value);
  };

  return (
    <form onSubmit={(event) => onSubmitForm(event)}>
      <div className="sw-padding--bottom-xl">
        <div className="field sw-padding--top-lg">
          <label
            className="label sw-onboarding-questions__label"
            htmlFor="currentPayment"
          >
            What is your annual household income?
          </label>
          <ControlMoneyInput
            name="income"
            id="income"
            required
            className="is-medium fs-block"
            value={fields.income}
            onChange={handleFieldChange}
          ></ControlMoneyInput>
        </div>

        <div className="field sw-padding--top-lg">
          <label
            className="label sw-onboarding-questions__label"
            htmlFor="federalProgram"
          >
            How much personal debt do you have?
            <span className="help has-text-weight-normal sw-padding--bottom-md">
              Include any credit card debt, auto debt, etc. Do not include
              student debt or your mortgage. Enter $0 if you don't have any
              personal debt.
            </span>
          </label>
          <ControlMoneyInput
            name="personalDebt"
            id="personalDebt"
            required
            className="is-medium fs-block"
            value={fields.personalDebt}
            onChange={handleFieldChange}
          ></ControlMoneyInput>
        </div>

        <div className="field sw-padding--top-lg">
          <label
            className="label sw-onboarding-questions__label"
            htmlFor="creditScore"
          >
            What is your credit score range?
          </label>

          <FormControl component="fieldset" className="fs-block">
            <RadioGroup
              value={creditScore}
              id="creditScore"
              onChange={onCreditScoreChange}
            >
              <FormControlLabel
                value={CREDIT_SCORE_OPTIONS_MAP.excellent}
                control={<Radio />}
                label={
                  CREDIT_SCORE_OPTIONS_MAP_DISPLAY[
                    CREDIT_SCORE_OPTIONS_MAP.excellent
                  ]
                }
                labelPlacement="end"
              />
              <FormControlLabel
                value={CREDIT_SCORE_OPTIONS_MAP.good}
                control={<Radio />}
                label={
                  CREDIT_SCORE_OPTIONS_MAP_DISPLAY[
                    CREDIT_SCORE_OPTIONS_MAP.good
                  ]
                }
                labelPlacement="end"
              />
              <FormControlLabel
                value={CREDIT_SCORE_OPTIONS_MAP.fair}
                control={<Radio />}
                label={
                  CREDIT_SCORE_OPTIONS_MAP_DISPLAY[
                    CREDIT_SCORE_OPTIONS_MAP.fair
                  ]
                }
                labelPlacement="end"
              />
              <FormControlLabel
                value={CREDIT_SCORE_OPTIONS_MAP.bad}
                control={<Radio />}
                label={
                  CREDIT_SCORE_OPTIONS_MAP_DISPLAY[CREDIT_SCORE_OPTIONS_MAP.bad]
                }
                labelPlacement="end"
              />
              <FormControlLabel
                value={CREDIT_SCORE_OPTIONS_MAP.unknown}
                control={<Radio />}
                label={
                  CREDIT_SCORE_OPTIONS_MAP_DISPLAY[
                    CREDIT_SCORE_OPTIONS_MAP.unknown
                  ]
                }
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>

      <div className="sw-onboarding-questions__buttons">
        <button className="button is-medium is-light" onClick={onBack}>
          Back
        </button>

        <NextSubmitButton className="is-medium is-primary sw-onboarding-questions__buttons--next"></NextSubmitButton>
      </div>
    </form>
  );
};

export default QuestionsPartTwoForm;
