import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { ONBOARDING_PAGES } from "../../constants/routes";
import ROUTES from "../../constants/routes";

import {
  getMatchedLendersStudent,
  getAllLendersStudent,
} from "../../store/actions/lendersActions";

import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import LendersList from "./LendersList";
import LenderDisclaimerMessage from "./LenderDisclaimerMessage";
import InfoIcon from "../Messages/InfoIcon";

const InfoModalContent = ({ hasCosigner }) => {
  return (
    <div>
      <h1 className="title has-text-centered">Checking rates with lenders</h1>
      {hasCosigner && (
        <p className="sw-container--fixed-width">
          <span className="has-text-weight-semibold">
            Lenders will usually require a strong financial profile, including a
            credit score of at least 650. If you don't meet these requirements
            you can apply with a cosigner.
            <br />
            <br />
          </span>
          At Snowball, we’ve matched you with these "For you" lenders based on
          your unique financial profile. Each lender we choose goes through a
          rigorous <Link to={ROUTES.VALUES}>Snowball Values</Link> test. The
          lenders below give you the option to have a cosigner.
          <br />
          <br />
          If you think a cosigner is a good option for you, the next step is to
          get an estimated rate check from two to three lenders and then compare
          the offers! In order to keep Snowball free, please support us by using
          the links below.
        </p>
      )}
      {!hasCosigner && (
        <p className="sw-container--fixed-width">
          At Snowball, we’ve matched you with these "For you" lenders based on
          your unique financial profile. Each lender we choose goes through a
          rigorous <Link to={ROUTES.VALUES}>Snowball Values</Link> test.
          <br />
          <br />
          The next step is to get an estimated rate check from two to three
          lenders and then compare the offers! In order to keep Snowball free,
          please support us by using the links below.
        </p>
      )}
      <p>
        <br />
        If you'd like to update your profile you can click
        <Link to={ONBOARDING_PAGES.UPDATE_PROFILE}> here</Link> and refresh the
        page.
      </p>
    </div>
  );
};

class MatchedLendersStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAllLenders: true,
    };
  }

  componentDidMount() {
    this.props.getMatchedLendersStudent(
      this.props.creditScore,
      this.props.hasCosigner
    );
    this.props.getAllLendersStudent(
      this.props.creditScore,
      this.props.hasCosigner
    );
  }

  render() {
    if (this.props.loading) {
      return (
        <div className="sw-flex-layout--column-center">
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    if (!this.props.lenders) {
      return (
        <p>Sorry, we couldn't find any lenders that matched your profile.</p>
      );
    }

    let showOnboardingMessage = false;
    if (!this.props.creditScore && this.props.allLenders.length < 1) {
      showOnboardingMessage = true;
    }

    return (
      <div className="sw-lenders-section box sw-flex-layout--column-center">
        <div className="level sw-full-width is-mobile">
          <div className="level-item level-left">
            <h2 className="subtitle">Top lenders</h2>
          </div>
          <div className="level-item level-right">
            <InfoIcon
              modalContent={
                <InfoModalContent
                  hasCosigner={this.props.hasCosigner}
                ></InfoModalContent>
              }
            >
              About these lenders
            </InfoIcon>
          </div>
        </div>

        <LendersList lenders={this.props.allLenders}></LendersList>

        {showOnboardingMessage && (
          <div className="sw-flex-layout--column-center">
            In order to match you with lenders, we need more information from
            you.{" "}
            <Link to={ONBOARDING_PAGES.UPDATE_PROFILE}>
              Please answer these questions and try again.
            </Link>
          </div>
        )}

        <LenderDisclaimerMessage></LenderDisclaimerMessage>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMatchedLendersStudent: (creditScore, hasCosigner) =>
      dispatch(getMatchedLendersStudent(creditScore, hasCosigner)),
    getAllLendersStudent: (creditScore, hasCosigner) =>
      dispatch(getAllLendersStudent(creditScore, hasCosigner)),
  };
};

const mapStateToProps = (state, ownProps) => {
  let creditScore = ownProps.creditScore;
  const hasCosigner = ownProps.hasCosigner;
  let lenders = [];
  let allLenders = [];

  if (state.lenders.matchedLendersStudent) {
    lenders = state.lenders.matchedLendersStudent;
  }

  if (state.lenders.allLendersStudent) {
    allLenders = state.lenders.allLendersStudent;
  }

  return {
    loading: state.lenders.getLendersLoading,
    creditScore: creditScore,
    hasCosigner: hasCosigner,
    lenders: lenders,
    allLenders: allLenders,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MatchedLendersStudent);
