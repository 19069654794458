import { ALERTING_ACTIONS } from "../reducers/alertingReducer";

export const clearProgressBar = () => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({ type: ALERTING_ACTIONS.CLEAR_PROGRESS });
  };
};

export const setProgressBar = (progressFraction) => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: ALERTING_ACTIONS.SET_PROGRESS,
      progress: progressFraction * 100,
    });
  };
};

export const clearConfirmMessage = (onCancel) => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: ALERTING_ACTIONS.CLEAR_CONFIRM,
    });
  };
};

export const setConfirmMessage = (
  title,
  message,
  confirmText,
  onConfirm,
  isDanger
) => {
  return (dispatch, getState, { getFirebase }) => {
    const confirmMessageProps = {
      title: title,
      message: message,
      confirmText: confirmText,
      onConfirm: onConfirm,
      isDanger: isDanger || false,
    };

    dispatch({
      type: ALERTING_ACTIONS.SET_CONFIRM,
      confirmMessageProps: confirmMessageProps,
    });
  };
};

export const setIAPConfirmMessage = () => {
  return (dispatch, getState, { getFirebase }) => {
    const confirmMessageProps = {
      title: "Snowball Premium subscription on iOS",
      message: "Manage your subscription on iOS through your Apple ID",
      confirmText: "OK",
    };

    dispatch({
      type: ALERTING_ACTIONS.SET_CONFIRM,
      confirmMessageProps: confirmMessageProps,
    });
  };
};

export const setStickyBanner = (text, link) => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: ALERTING_ACTIONS.SET_STICKY,
      stickyBannerProps: {
        text: text,
        link: link,
      },
    });
  };
};

export const clearStickyBanner = () => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({ type: ALERTING_ACTIONS.CLEAR_STICKY });
  };
};
