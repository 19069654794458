import { setError } from "./errorActions";
import { apiGet, apiPost } from "./apiActions";
import { PREMIUM_ACTIONS } from "../reducers/premiumReducer";

const API_URL = process.env.REACT_APP_USERS_SERVICE_URL;

export const getPremiumPortal = (returnUrl) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/premium/customer_portal";
      const data = {};

      if (returnUrl) {
        data["return_url"] = `${API_URL}${returnUrl}`;
      }

      //   dispatch({ type: PREMIUM_ACTIONS.PRICES });
      const onSuccess = (res) => {
        // dispatch({ type: PREMIUM_ACTIONS.PRICES_SUCCESS, res });
        const portalUrl = res.data.portal_url;
        resolve(portalUrl);
      };

      const onError = (err) => {
        // dispatch({ type: PREMIUM_ACTIONS.PRICES_ERROR, err });
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};

export const getPricingPlans = () => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/premium/prices";

      dispatch({ type: PREMIUM_ACTIONS.PRICES });
      const onSuccess = (res) => {
        dispatch({ type: PREMIUM_ACTIONS.PRICES_SUCCESS, res });
        resolve();
      };

      const onError = (err) => {
        dispatch({ type: PREMIUM_ACTIONS.PRICES_ERROR, err });
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiGet(endpoint, onSuccess, onError));
    });
  };
};

export const getCheckoutLink = (
  premiumPrice,
  freeTrial,
  successUrl,
  cancelUrl
) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/premium/payment_link";
      const data = {
        price_id: premiumPrice,
        has_free_trial: freeTrial ? true : false,
      };

      if (successUrl) {
        data["success_url"] = `${API_URL}${successUrl}`;
      }
      if (cancelUrl) {
        data["cancel_url"] = `${API_URL}${cancelUrl}`;
      }

      //   dispatch({ type: PREMIUM_ACTIONS.PRICES });
      const onSuccess = (res) => {
        // dispatch({ type: PREMIUM_ACTIONS.PRICES_SUCCESS, res });
        resolve(res.data.checkout_url);
      };

      const onError = (err) => {
        // dispatch({ type: PREMIUM_ACTIONS.PRICES_ERROR, err });
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};
