import React from "react";
import helpers from "../../services/helpers";
import moment from "moment";

const StudentLoanDetails = (props) => {
  return (
    <>
      <div className="box is-vertical">
        <p className="sw-padding--bottom-md">Loan details</p>
        <table className="table is-hoverable is-fullwidth is-striped is-narrow fs-block">
          <tbody>
            <tr>
              <td>Loan Name</td>
              <td className="has-text-right has-text-weight-semibold is-capitalized">
                {props.name}
              </td>
            </tr>
            <tr>
              <td>Loan Status</td>
              <td className="has-text-right has-text-weight-semibold is-capitalized">
                {props.loanStatus}
              </td>
            </tr>
            <tr>
              <td>Original loan amount</td>
              <td className="has-text-right has-text-weight-semibold">
                {helpers.currencyFormat(
                  props.originalLoanAmount,
                  0,
                  props.isoCurrencyCode
                )}
              </td>
            </tr>
            <tr>
              <td>Disbursement dates</td>
              <td className="has-text-right has-text-weight-semibold">
                {props.disbursementDates &&
                  props.disbursementDates.map((disbursementDate, index) => {
                    return (
                      <span key={index}>
                        {index && ", "}
                        {moment(disbursementDate, "YYYY-MM-DD").format(
                          "M/D/YYYY"
                        )}
                      </span>
                    );
                  })}
              </td>
            </tr>
            <tr>
              <td>Interest rate</td>
              <td className="has-text-right has-text-weight-semibold">
                {props.interestRate}%
              </td>
            </tr>
            <tr>
              <td>Loan guarantor</td>
              <td className="has-text-right has-text-weight-semibold">
                {props.guarantor}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="box is-vertical">
        <p className="sw-padding--bottom-md">Payment details</p>
        <table className="table is-hoverable is-fullwidth is-striped is-narrow fs-block">
          <tbody>
            <tr>
              <td className="has-text-weight-normal">Loan servicer</td>
              <td className="has-text-right has-text-weight-semibold is-capitalized">
                {props.servicer}
              </td>
            </tr>
            <tr>
              <td>Payment plan</td>
              <td className="has-text-right has-text-weight-semibold is-capitalized">
                {props.paymentPlan}
              </td>
            </tr>
            <tr>
              <td>Minimum payment</td>
              <td className="has-text-right has-text-weight-semibold">
                {helpers.currencyFormat(
                  props.minimumPayment,
                  0,
                  props.isoCurrencyCode
                )}
              </td>
            </tr>
            <tr>
              <td>Last payment</td>
              <td className="has-text-right has-text-weight-semibold">
                {helpers.currencyFormat(
                  props.lastPayment,
                  0,
                  props.isoCurrencyCode
                )}
              </td>
            </tr>
            <tr>
              <td>Next payment date</td>
              <td className="has-text-right has-text-weight-semibold">
                {props.nextPayment &&
                  moment(props.nextPayment, "YYYY-MM-DD").format("MM/D/YYYY")}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default StudentLoanDetails;
