import React, { useState } from "react";
import { Link } from "react-router-dom";

import helpers from "../../services/helpers";
import StudentLoansHistoryChart from "./StudentLoansHistoryChart";
import NetWorthChartButtons from "../NetWorth/NetWorthChartButtons";
import { NW_CHART_BUTTONS } from "../../constants/constants";
import { ONBOARDING_PAGES } from "../../constants/routes";

const formatMoney = (value) => {
  return helpers.currencyFormat(value, 0);
};

const StudentLoansHistoryWidget = ({ snapshotData, aggregates }) => {
  let [timeRange, setTimeRange] = useState(NW_CHART_BUTTONS.ALL);
  const nextLink = ONBOARDING_PAGES.UPDATE_PROFILE;

  if (!snapshotData || snapshotData.snapshots.length < 1) {
    return <div>Add accounts</div>;
  }

  return (
    <div className="sw-student-loans-history-widget box fs-block">
      <div className="columns is-multiline sw-full-width">
        <span className="column is-narrow">
          <div className="columns is-mobile">
            <span className="column subtitle has-text-weight-semibold">
              Loan timeline
            </span>
            <span className="column is-narrow">
              <Link to={nextLink}>Edit info</Link>
            </span>
          </div>
        </span>
        <span className="column is-multiline">
          <NetWorthChartButtons
            selectedButton={timeRange}
            setSelectedButton={setTimeRange}
          ></NetWorthChartButtons>
        </span>
      </div>

      <StudentLoansHistoryChart
        snapshotData={snapshotData}
        timeRange={timeRange}
      ></StudentLoansHistoryChart>

      <div className="columns sw-full-width sw-padding--top-lg">
        <div className="column">
          <p className="sw-label--xs">Loan balance</p>
          <p className="title has-text-weight-normal">
            {formatMoney(aggregates.total_remaining_principal)}
          </p>
        </div>
        <div className="column">
          <p className="sw-label--xs">Monthly payment</p>
          <p className="title has-text-weight-normal">
            {formatMoney(aggregates.monthly_payment)}
          </p>
        </div>
        <div className="column">
          <p className="sw-label--xs">Average interest</p>
          <p className="title has-text-weight-normal">
            {helpers.interestFormat(aggregates.interest_rate)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default StudentLoansHistoryWidget;
