import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";
import { asyncAddReaction } from "../../store/actions/communityActions";

import ReactionPicker from "./ReactionPicker";
import UserIcon from "./UserIcon";
import CommentButton from "./CommentButton";
import ReactionsList from "./ReactionsList";
import CommentsSection from "./CommentsSection";
import FeedItemEditButton from "./FeedItemEditButton";

import OverflowParagraph from "../Input/OverflowParagraph";

import helpers from "../../services/helpers";
import ROUTES from "../../constants/routes";
import VideoPlayer from "../Blog/VideoPlayer";
import Poll from "../Polls/Poll";

const FeedItem = ({
  feedItem,
  currentUser,
  onDeletePost,
  showCommentDefault,
}) => {
  const dispatch = useDispatch();
  const reactions = feedItem.reactions;

  const [showCommentsSection, setToggleComments] = useState(
    showCommentDefault || false
  );

  const toggleReactionPicker = () => {
    setToggleComments(!showCommentsSection);
  };

  const onReactClick = (reaction_type) => {
    // if already clicked, want to delete
    let shouldDelete = false;
    if (reactions[reaction_type] && reactions[reaction_type]["has_reacted"]) {
      shouldDelete = true;
    }

    dispatch(
      asyncAddReaction(feedItem.feed_item_uuid, reaction_type, shouldDelete)
    );

    dispatch(
      trackEvent(EVENTS.ITEM_REACTION_CLICKED, {
        community_uuid: feedItem.community_uuid,
        feed_item_uuid: feedItem.feed_item_uuid,
        reaction_type: reaction_type,
        has_reacted: !shouldDelete,
      })
    );
  };

  const onClickInner = (e) => {
    e.stopPropagation(); //  <------ Don't let this bubble up and redirect
  };

  const isAuthor = currentUser.id === feedItem.author_id;

  const onClickOuter = (e, feedItemUuid) => {
    // redirect to feed item
    // e.preventDefault();
    // return navigate(`${ROUTES.COMMUNITY_POST}/${feedItemUuid}`);
  };

  if (!feedItem) {
    return <div></div>;
  }

  return (
    <div
      className="box"
      key={feedItem.feed_item_uuid}
      onClick={(e) => onClickOuter(e, feedItem.feed_item_uuid)}
    >
      <article className="sw-media media sw-reaction-picker-relative-container">
        <div className="media-left">
          <UserIcon authorProfile={feedItem.author_profile} />
        </div>
        <div className="media-content">
          <div className="content">
            <p>
              <strong>{feedItem.author_profile.display_name} &#8226;</strong>{" "}
              <small>{feedItem.community.name}</small>{" "}
              <small>{helpers.getDateFromTimestamp(feedItem.created_at)}</small>
              <br />
            </p>

            <OverflowParagraph text={feedItem.text} />

            {feedItem.image_url && (
              <figure className="sw-media__image">
                <img src={feedItem.image_url} alt="" />
              </figure>
            )}
            {feedItem.media_url && <div>{feedItem.media_url}</div>}
            {feedItem.video_url && (
              <VideoPlayer
                link={feedItem.video_url}
                className="sw-react-player"
                width="100%"
                height="100%"
              ></VideoPlayer>
            )}
            {feedItem.linked_challenge && (
              <Link
                className="button is-primary"
                onClick={onClickInner}
                to={`${ROUTES.CHALLENGE_PREFIX}${feedItem.linked_challenge.challenge_id}`}
              >
                View challenge
              </Link>
            )}
          </div>

          {feedItem.poll && <Poll poll={feedItem.poll}></Poll>}

          <div
            className="buttons is-inline-block sw-full-width"
            onClick={onClickInner}
          >
            <ReactionPicker onReactClick={onReactClick} />
            {reactions && (
              <ReactionsList
                reactions={reactions}
                onReactClick={onReactClick}
              />
            )}
            <CommentButton
              onClick={toggleReactionPicker}
              numComments={feedItem.num_comments}
            />
            <FeedItemEditButton
              className="is-pulled-right"
              feedItemUuid={feedItem.feed_item_uuid}
              isAuthor={isAuthor}
              onDeletePost={() =>
                onDeletePost(feedItem.community_uuid, feedItem.feed_item_uuid)
              }
            ></FeedItemEditButton>
          </div>
        </div>
      </article>
      {showCommentsSection && (
        <div onClick={onClickInner} className="sw-padding--top-md">
          <CommentsSection
            currentUser={currentUser}
            feedItemUuid={feedItem.feed_item_uuid}
            communityUuid={feedItem.community_uuid}
          />
        </div>
      )}
    </div>
  );
};

export default FeedItem;
