import { AUTH_ACTIONS } from "./authReducer";

const initState = {
  netWorthData: null,
  deposits: {
    plaid_accounts: [],
    me_accounts: [],
    total: 0,
  },
  investments: {
    plaid_accounts: [],
    me_accounts: [],
    total: 0,
  },
  liabilities: {
    error: [],
    total: 0,
    credit: {
      plaid_accounts: [],
      me_accounts: [],
      total: 0,
    },
    mortgage: {
      plaid_accounts: [],
      me_accounts: [],
      total: 0,
    },
    student: {
      plaid_accounts: [],
      me_accounts: [],
      total: 0,
    },
  },
  otherAssets: {
    me_accounts: [],
    total: 0,
  },
  otherDebts: {
    plaid_accounts: [],
    me_accounts: [],
    total: 0,
  },
  metadata: { iso_currency_code: "USD", plaid_errors: [] },
  aggregates: {
    total_assets_balance: 0,
    total_debts_balance: 0,
    num_asset_accounts: 0,
    num_debt_accounts: 0,
    num_total_accounts: 0,
  },
  isLoading: false,
  isLoadingAddItem: false,
};

const ACTION_PREFIX = "NETWORTH/";
export const NET_WORTH_ACTIONS = {
  ADD: ACTION_PREFIX + "ADD",
  ADD_ERROR: ACTION_PREFIX + "ADD_ERROR",
  ADD_SUCCESS: ACTION_PREFIX + "ADD_SUCCESS",
  CREATE_LINK_TOKEN: ACTION_PREFIX + "CREATE_LINK_TOKEN",
  CREATE_LINK_TOKEN_ERROR: ACTION_PREFIX + "CREATE_LINK_TOKEN_ERROR",
  CREATE_LINK_TOKEN_SUCCESS: ACTION_PREFIX + "CREATE_LINK_TOKEN_SUCCESS",
  GET: ACTION_PREFIX + "GET",
  GET_ERROR: ACTION_PREFIX + "GET_ERROR",
  GET_SUCCESS: ACTION_PREFIX + "GET_SUCCESS",
  MANUAL_ENTRY: ACTION_PREFIX + "MANUAL_ENTRY",
  MANUAL_ENTRY_ERROR: ACTION_PREFIX + "MANUAL_ENTRY_ERROR",
  MANUAL_ENTRY_SUCCESS: ACTION_PREFIX + "MANUAL_ENTRY_SUCCESS",
  UPDATE: ACTION_PREFIX + "UPDATE",
  UPDATE_ERROR: ACTION_PREFIX + "UPDATE_ERROR",
  UPDATE_SUCCESS: ACTION_PREFIX + "UPDATE_SUCCESS",
};

const netWorthReducer = (state = initState, action) => {
  switch (action.type) {
    case NET_WORTH_ACTIONS.ADD:
      return {
        ...state,
        isLoading: true,
        isLoadingAddItem: true,
      };
    case NET_WORTH_ACTIONS.GET:
    case NET_WORTH_ACTIONS.MANUAL_ENTRY:
    case NET_WORTH_ACTIONS.UPDATE:
      return {
        ...state,
        isLoading: true,
      };
    case NET_WORTH_ACTIONS.ADD_ERROR:
      return {
        ...state,
        isLoading: false,
        isLoadingAddItem: false,
      };
    case NET_WORTH_ACTIONS.GET_ERROR:
    case NET_WORTH_ACTIONS.MANUAL_ENTRY_ERROR:
    case NET_WORTH_ACTIONS.UPDATE_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case NET_WORTH_ACTIONS.ADD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingAddItem: false,
      };
    case NET_WORTH_ACTIONS.MANUAL_ENTRY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case NET_WORTH_ACTIONS.UPDATE_SUCCESS:
    case NET_WORTH_ACTIONS.GET_SUCCESS:
      const resData = action.res.data;
      return {
        ...state,
        isLoading: false,
        netWorthData: resData,
        deposits: resData.deposits,
        investments: resData.investments,
        liabilities: resData.liabilities,
        otherAssets: resData.other_assets,
        otherDebts: resData.other_debts,
        aggregates: resData.aggregates,
        metadata: resData.metadata,
      };
    case NET_WORTH_ACTIONS.CREATE_LINK_TOKEN:
      return {
        ...state,
      };
    case NET_WORTH_ACTIONS.CREATE_LINK_TOKEN_ERROR:
      return {
        ...state,
      };
    case NET_WORTH_ACTIONS.CREATE_LINK_TOKEN_SUCCESS:
      return {
        ...state,
      };
    case AUTH_ACTIONS.SIGNOUT:
      return initState; // Always return the initial state
    default:
      return state;
  }
};

export default netWorthReducer;
