import React, { useEffect } from "react";
import { connect } from "react-redux";
import { signOutUser } from "../../store/actions/authActions";
import { useLocation, useNavigate } from "react-router-dom";

import ROUTES from "../../constants/routes";

const SignoutPage = ({ signOutUserFn }) => {
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    signOutUserFn();
    // only redirect after triggering sign out

    // Redirect them to the /login page, unless a different
    // login page is sent via state
    const loggedOutPage = location.state?.loginRoute || ROUTES.SIGN_IN;

    navigate(loggedOutPage);
  }, [signOutUserFn, navigate]);

  return <div></div>;
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOutUserFn: () => dispatch(signOutUser()),
  };
};

export default connect(null, mapDispatchToProps)(SignoutPage);
