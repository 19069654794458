import React from "react";
import PlaidInstitution from "./PlaidInstitution";

const InstitutionsSection = ({ institutions, onDelete }) => {
  if (institutions) {
    const sortedItems = []
      .concat(institutions)
      .sort((a, b) => (a.display_name > b.display_name ? 1 : -1))
      .map((account) => (
        <PlaidInstitution
          key={account.item_id}
          account={account}
          showReconnect={true}
          onDelete={onDelete}
        ></PlaidInstitution>
      ));

    return <div>{sortedItems}</div>;
  }

  return <div></div>;
};

export default InstitutionsSection;
