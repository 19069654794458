import { AUTH_ACTIONS } from "./authReducer";

const initState = {
  challenges: null,
  err: null,
  isLoading: false,
};

const ACTION_PREFIX = "CHALLENGES/";

export const CHALLENGES_ACTIONS = {
  GET: ACTION_PREFIX + "GET",
  GET_ERROR: ACTION_PREFIX + "GET_ERROR",
  GET_SUCCESS: ACTION_PREFIX + "GET_SUCCESS",
  JOIN: ACTION_PREFIX + "JOIN",
  JOIN_ERROR: ACTION_PREFIX + "JOIN_ERROR",
  JOIN_SUCCESS: ACTION_PREFIX + "JOIN_SUCCESS",
  UPDATE: ACTION_PREFIX + "UPDATE",
  UPDATE_ERROR: ACTION_PREFIX + "UPDATE_ERROR",
  UPDATE_SUCCESS: ACTION_PREFIX + "UPDATE_SUCCESS",
};

const challengesReducer = (state = initState, action) => {
  switch (action.type) {
    case CHALLENGES_ACTIONS.GET:
    case CHALLENGES_ACTIONS.UPDATE:
      return {
        ...state,
        err: null,
        isLoading: true,
      };
    case CHALLENGES_ACTIONS.GET_SUCCESS:
    case CHALLENGES_ACTIONS.UPDATE_SUCCESS:
      return {
        ...state,
        challenges: action.challenges,
        err: null,
        isLoading: false,
      };
    case CHALLENGES_ACTIONS.JOIN:
      return {
        ...state,
        err: null,
        isLoading: true,
      };
    case CHALLENGES_ACTIONS.JOIN_SUCCESS:
      // need to update the challenge object that matches id
      const newChallenge = action.res.data.challenge;
      const newChallengesArray = state.challenges.map((content) =>
        content.challenge_id === newChallenge.challenge_id
          ? newChallenge
          : content
      );
      return {
        ...state,
        challenges: newChallengesArray,
        err: null,
        isLoading: false,
      };
    case CHALLENGES_ACTIONS.GET_ERROR:
    case CHALLENGES_ACTIONS.JOIN_ERROR:
    case CHALLENGES_ACTIONS.UPDATE_ERROR:
      return {
        ...state,
        err: action.err.message,
        isLoading: false,
      };

    case AUTH_ACTIONS.SIGNOUT:
      return initState; //Always return the initial state
    default:
      return state;
  }
};

export default challengesReducer;
