import React from "react";
import { PieChart, Pie, Cell, Label, Tooltip } from "recharts";

import AddAccountButton from "../Buttons/AddAccountButton";

import helpers from "../../services/helpers";

const BAR_COLORS = [
  "#013642",
  "#0B4652",
  "#246C7B",
  "#48A2B5",
  "#71C1D2",
  "#BCF3FF",
];

const getLoanColor = (index) => {
  return BAR_COLORS[index % 6];
};

const formatMoney = (value) => {
  return helpers.currencyFormat(value, 0);
};

const tooltipFormatter = (value, name, props) => {
  let formattedValue = "";
  if (helpers.notNullnotUndefined(value)) {
    formattedValue = formatMoney(value);
  }
  return [formattedValue, name];
};

const getBulletStyle = (index) => {
  const backgroundColor = getLoanColor(index);
  return {
    background: backgroundColor,
    borderRadius: "50%",
    width: "10px",
    height: "10px",
    display: "flex",
    alignSelf: "center",
  };
};

const StudentLoansListChart = ({
  accountsArray,
  loanBalance,
  onAddAccountSuccess,
}) => {
  if (!accountsArray || accountsArray.length < 1) {
    return <></>;
  }

  const total = formatMoney(loanBalance, 0);
  return (
    <div className="sw-student-loans-list-chart box fs-block">
      <div className="sw-student-loans-list-chart__headings level is-mobile sw-full-width">
        <span className="level-left">
          <p className="level-item subtitle has-text-weight-semibold">
            Loan balance
          </p>
        </span>
        <div className="level-right">
          <div className="level-item">
            <AddAccountButton
              onAddAccountSuccess={onAddAccountSuccess}
              className="button is-ghost"
            ></AddAccountButton>
          </div>
        </div>
      </div>

      <div className="sw-flex-layout--centered">
        <PieChart width={289} height={289}>
          <Tooltip formatter={tooltipFormatter} separator=": " />

          <Pie
            data={accountsArray}
            dataKey="current_balance"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={80}
            outerRadius={120}
            fill="#82ca9d"
          >
            {accountsArray.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={getLoanColor(index)} />
            ))}
            <Label value={total} offset={0} position="center" />
          </Pie>
        </PieChart>
      </div>

      <ul>
        {accountsArray.map((entry, index) => (
          <li key={index} className="columns is-mobile">
            <span style={getBulletStyle(index)}></span>
            <span className="column"> {entry.name}</span>
            <span className="column is-narrow">
              {formatMoney(entry.current_balance)}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default StudentLoansListChart;
