import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useCourseRoutes } from "./useCourseRouting";
import TasksContainer from "./TasksContainer";
import { ToggleCircleButton } from "../Buttons/CircleButtons";
import UpgradePremiumButton from "../Buttons/UpgradePremiumButton";
import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";

const GroupContainer = ({ course, group, startExpanded }) => {
  const [isExpanded, setIsExpanded] = useState(startExpanded || false);
  const [showUpsell, setShowUpsell] = useState(false);
  const dispatch = useDispatch();
  const { getCourseRoute } = useCourseRoutes();
  const COURSE_ROUTE = getCourseRoute(course);

  const groupContent = group.content;
  const completedTasks = group.num_completed_tasks;
  const totalTasks = group.num_total_tasks;
  const hasFreeContent = groupContent.has_free_content;

  const toggleButton = () => {
    setIsExpanded(!isExpanded);
  };

  const { hasPremium } = useSelector((state) => {
    return state.auth;
  });

  // event metadata to send if click premium button
  const eventMetadata = {
    plan_slug: course.course.slug,
    course_type: course.course.course_type,
    cohort_uuid: course.cohort.cohort_uuid,
  };

  const onPremiumClick = () => {
    dispatch(
      trackEvent(EVENTS.PREMIUM_LEVEL_CLICKED, {
        plan_slug: course.course.slug,
        course_type: course.course.course_type,
        cohort_uuid: course.cohort.cohort_uuid,
        level_slug: group.position,
      })
    );
  };

  useEffect(() => {
    if (hasPremium || hasFreeContent) {
      setShowUpsell(false);
    } else {
      setShowUpsell(true);
      setIsExpanded(false); // if premium should not be expanded
    }
  }, [hasPremium, hasFreeContent]);

  let progressString = "Not started";
  if (completedTasks > 0 && completedTasks < totalTasks) {
    progressString = `✔ ${completedTasks} / ${totalTasks} `;
    progressString += totalTasks === 1 ? "task completed" : "tasks completed";
  } else if (completedTasks >= totalTasks) {
    progressString = "✔ Completed";
  }

  return (
    <div className="sw-container-box no-border-radius-mobile has-border">
      <div className="level is-mobile mb-0">
        <p className="level-item level-left is-uppercase">
          {groupContent.header}
        </p>
        {showUpsell ? (
          <UpgradePremiumButton
            returnUrl={COURSE_ROUTE}
            onClick={onPremiumClick}
            eventMetadata={eventMetadata}
          ></UpgradePremiumButton>
        ) : (
          <span className="level-item level-right">
            <ToggleCircleButton
              onToggle={toggleButton}
              isExpandedInit={isExpanded}
            ></ToggleCircleButton>
          </span>
        )}
      </div>
      <p className="sw-padding--bottom-md">
        <strong>{groupContent.title}</strong>
        <br />
        {groupContent.description}
      </p>
      <p className="is-size-7 sw-padding--bottom-lg">{progressString}</p>
      {isExpanded && (
        <TasksContainer
          course={course}
          tasks={group.tasks}
          hasPremium={hasPremium}
        ></TasksContainer>
      )}
    </div>
  );
};

export default GroupContainer;
