import { AUTH_ACTIONS } from "./authReducer";

const initState = {
  title: "",
  message: "",
  errorIsLogout: false,
  errorObj: null,
  warningTitle: null,
  warningMessage: null,
  warningRedirectRoute: null,
  warningClearedNum: 0,
};

const ACTION_PREFIX = "ERROR/";
export const ERROR_ACTIONS = {
  ERROR: ACTION_PREFIX + "ERROR",
  CLEAR: ACTION_PREFIX + "CLEAR",
  WARNING: ACTION_PREFIX + "WARNING",
  CLEAR_WARNING: ACTION_PREFIX + "CLEAR_WARNING",
  ERROR_LOGOUT: ACTION_PREFIX + "ERROR_LOGOUT",
};

// Right now we only show warnings for 1 thing, so this works for now.
// To avoid being too annoying, allow user clearing warning 2 times before we stop showing.
// const MAX_COUNT_WARNING_CLEARED = 2;

const studentLoansReducer = (state = initState, action) => {
  switch (action.type) {
    case ERROR_ACTIONS.ERROR:
      return {
        ...state,
        title: action.title || "",
        message: action.message || "",
        errorIsLogout: false,
        errorObj: action.err,
      };
    case ERROR_ACTIONS.ERROR_LOGOUT:
      return {
        ...state,
        title: action.title || "",
        message: action.message || "",
        errorIsLogout: true,
        errorObj: action.err,
      };
    case ERROR_ACTIONS.CLEAR:
      return {
        ...state,
        title: "",
        message: "",
        errorIsLogout: false,
        errorObj: null,
      };
    case ERROR_ACTIONS.WARNING:
      // If already cleared warning,don't show again
      // if (state.warningClearedNum >= MAX_COUNT_WARNING_CLEARED) {
      //   return state;
      // }
      return {
        ...state,
        warningTitle: action.title || "Oops! Something went wrong",
        warningMessage: action.message || "",
        warningRedirectRoute: action.redirectRoute || null,
      };
    case ERROR_ACTIONS.CLEAR_WARNING:
      return {
        ...state,
        warningTitle: "",
        warningMessage: "",
        warningRedirectRoute: null,
        warningClearedNum: state.warningClearedNum + 1,
      };
    case AUTH_ACTIONS.SIGNOUT:
      // We usually return init state.
      // In case of "logout error", we want to display the error
      // but return non-error fields back to normal.
      if (state.errorIsLogout) {
        let newState = Object.assign(initState, {
          title: state.title,
          message: state.message,
          errorObj: state.errorObj,
        });
        return newState;
      }

      return initState;
    default:
      return state;
  }
};

export default studentLoansReducer;
