import React from "react";
import PlanBox, { GenericPlanBox } from "../../components/Premium/PlanBox";
import { useDispatch, useSelector, connect } from "react-redux";

import { trackEvent, EVENTS } from "../../store/actions/analyticsActions";
import { getPremiumPortal } from "../../store/actions/premiumActions";

import { setIAPConfirmMessage } from "../../store/actions/alertingActions";
import { usePremiumPlanCheckout } from "../Premium/usePremiumPlanCheckout";
import ROUTES from "../../constants/routes";

const PremiumPricesSection = ({
  priceList,
  currentPlan,
  learnMoreLink,
  includeBasic,
  onBasicClick, // only needed if includeBasic
  setIAPConfirmMessageFn,
}) => {
  const dispatch = useDispatch();
  const onPremiumCheckout = usePremiumPlanCheckout(ROUTES.PREMIUM_PRICING);

  const { currentUser } = useSelector((state) => {
    return state.auth;
  });

  const goToPortal = () => {
    dispatch(getPremiumPortal()).then((res) => {
      if (res) {
        // similar behavior as an HTTP redirect
        window.location.replace(res);
      }
    });
  };

  const onClickBasic = () => {
    const has_active_iap_subscription = currentUser.has_active_iap_subscription;
    dispatch(trackEvent(EVENTS.PREMIUM_PRICING_CLICKED), {
      product_clicked: "free",
      product_current: currentPlan,
      has_active_iap_subscription: has_active_iap_subscription,
    });

    if (has_active_iap_subscription) {
      setIAPConfirmMessageFn();
      return;
    }

    if (currentPlan) {
      // if user already has plan, take them to manage plans
      // since this likely means they want to cancel
      goToPortal();
    } else {
      // take them to next page
      onBasicClick();
    }
  };

  return (
    <div className="columns">
      {includeBasic && (
        <div className="column">
          <GenericPlanBox
            title="Basic"
            priceString="Free"
            planFeatures={[
              "Net worth tracker",
              "Free resources",
              "Community access",
            ]}
            additionalClassName={"is-free"}
            buttonClassName={"is-light"}
            onButtonClicked={onClickBasic}
            disabled={false}
            buttonText={currentPlan ? "Change plan" : "Stay on basic"}
          />
        </div>
      )}
      {priceList &&
        priceList.map((priceObj) => {
          return (
            <div className="column" key={priceObj.price.id}>
              <PlanBox
                priceId={priceObj.price.id}
                productId={priceObj.price.product.id}
                title={priceObj.price.product.name}
                priceUnit={priceObj.price.unit_amount}
                priceInterval={priceObj.price.recurring.interval}
                planFeatures={priceObj.features}
                currentPlan={currentPlan}
                additionalClassName={priceObj.className}
                learnMoreLink={learnMoreLink}
                freeTrial={priceObj.free_trial}
                onButtonClicked={onPremiumCheckout}
              />
            </div>
          );
        })}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIAPConfirmMessageFn: () => dispatch(setIAPConfirmMessage()),
  };
};

export default connect(null, mapDispatchToProps)(PremiumPricesSection);
