import React from "react";

const InfoBox = ({ title, textComponent, buttonText, onClick }) => {
  const buttonOnClick =
    onClick ||
    (() => {
      return;
    });

  return (
    <div className="box">
      <p className="subtitle has-text-weight-semibold">{title}</p>
      {textComponent && (
        <div className="sw-padding--bottom-lg">{textComponent}</div>
      )}
      {buttonText && (
        <button className="button is-small" onClick={buttonOnClick}>
          {buttonText}
        </button>
      )}
    </div>
  );
};

export default InfoBox;
