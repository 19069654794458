import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { postAdminRecommendation } from "../../store/actions/recommendationActions";
import RecommendationSummary from "../../components/Recommendation/RecommendationSummary";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

const AdminRecommendationPage = ({
  postAdminRecommendationFn,
  gettingRecs,
  recIsVisible,
  recs,
  recMessages,
  recModelType,
}) => {
  const { userId } = useParams();

  useEffect(() => {
    if (userId) {
      postAdminRecommendationFn(userId);
    }
  }, [postAdminRecommendationFn, userId]);

  if (!userId || gettingRecs) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  return (
    <div className="sw-flex-layout--column-center">
      <h1 className="sw-padding--bottom-xl">
        {recIsVisible ? "✅ Rec VISIBLE " : "🛑 Rec HIDDEN"}
      </h1>

      <RecommendationSummary
        recs={recs}
        messages={recMessages}
        baseRoute={`/admin/user/${userId}/rec`}
        recVisible={recIsVisible}
        recModelType={recModelType}
      ></RecommendationSummary>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    postAdminRecommendationFn: (userId) =>
      dispatch(postAdminRecommendation(userId)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    recs: state.recommendations.recommendations,
    recMessages: state.recommendations.recMessages,
    recIsVisible: state.recommendations.recIsVisible,
    gettingRecs: state.recommendations.getLoading,
    recModelType: state.recommendations.recModelType,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminRecommendationPage);
