import React, { useState } from "react";
import { Link } from "react-router-dom";

import helpers from "../../services/helpers";
import LoansDropdownMenu from "../Buttons/LoansDropdownMenu";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { NW_TABS } from "../../constants/constants";
import NetWorthChart from "./NetWorthChart";

const NetWorthTabs = ({ baseRoute, activeTab }) => {
  let defaultClass = "";

  if (activeTab === NW_TABS.DEBT) {
    defaultClass += " is-debt";
  } else if (activeTab === NW_TABS.ASSETS) {
    defaultClass += " is-assets";
  } else {
    defaultClass += " is-primary";
  }

  return (
    <div className="sw-net-worth-tabs tabs is-toggle">
      <ul>
        <li className={activeTab === NW_TABS.NET_WORTH ? "is-active" : ""}>
          <Link
            to={`${baseRoute}?filter=${NW_TABS.NET_WORTH}`}
            className={defaultClass}
          >
            <span>Net worth</span>
          </Link>
        </li>
        <li className={activeTab === NW_TABS.DEBT ? "is-active" : ""}>
          <Link
            to={`${baseRoute}?filter=${NW_TABS.DEBT}`}
            className={defaultClass}
          >
            <span>Debt</span>
          </Link>
        </li>
        <li className={activeTab === NW_TABS.ASSETS ? "is-active" : ""}>
          <Link
            to={`${baseRoute}?filter=${NW_TABS.ASSETS}`}
            className={defaultClass}
          >
            <span>Assets</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

const NetWorthHeader = ({
  activeTab,
  baseRoute,
  graphData,
  isLoading,
  totalAmount,
  isoCurrencyCode,
  updateLoans, // optional
}) => {
  let [showGrid, setShowGrid] = useState(false);

  const toggleGrid = () => {
    setShowGrid(!showGrid);
  };

  return (
    <div className="sw-net-worth-header">
      <NetWorthTabs baseRoute={baseRoute} activeTab={activeTab}></NetWorthTabs>

      {isLoading && <LoadingSpinner></LoadingSpinner>}

      {!isLoading && (
        <div className="sw-net-worth-chart-container">
          <h2 className="fs-block">
            {helpers.currencyFormat(totalAmount, 0, isoCurrencyCode)}
          </h2>
          {graphData && (
            <NetWorthChart
              activeTab={activeTab}
              graphData={graphData}
              showGrid={showGrid}
            ></NetWorthChart>
          )}
        </div>
      )}

      {updateLoans && (
        <LoansDropdownMenu
          className="sw-net-worth-extra-menu sw-padding--right-md"
          updateLoans={updateLoans}
          showGrid={showGrid}
          toggleGrid={toggleGrid}
        ></LoansDropdownMenu>
      )}
    </div>
  );
};

export default NetWorthHeader;
