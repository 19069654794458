import React, { Component } from "react";

class ExpandableMessageBox extends Component {
  constructor(props) {
    super(props);
    this.state = { isExpanded: false };
    this.toggleExpand = this.toggleExpand.bind(this);
  }

  toggleExpand() {
    this.setState({ isExpanded: !this.state.isExpanded });
  }

  render() {
    const expandedIcon = <i className="fas fa-plus" aria-hidden="true"></i>;
    const notExpandedIcon = <i className="fas fa-minus" aria-hidden="true"></i>;
    const buttonText = this.props.text;

    return (
      <div className="sw-expandable-message-box">
        <button
          className="button is-medium is-fullwidth level is-mobile"
          onClick={this.toggleExpand}
        >
          <span className="is-size-6 column level-left has-text-weight-bold">
            {buttonText}
          </span>
          {!this.state.isExpanded && (
            <span className="icon is-small column-narrow level-right sw-padding--left-lg sw-padding--right-lg">
              {expandedIcon}
            </span>
          )}
          {this.state.isExpanded && (
            <span className="icon is-small level-right sw-padding--left-lg sw-padding--right-lg">
              {notExpandedIcon}
            </span>
          )}
        </button>
        {this.state.isExpanded && (
          <div className="sw-expandable-message-box--content content">
            {this.props.content}
          </div>
        )}
      </div>
    );
  }
}

export default ExpandableMessageBox;
