import React from "react";

import PlaidReconnectButton from "../PlaidIntegration/PlaidReconnectButton";
import DropdownButtonMenu from "../Buttons/DropdownButtonMenu";

const PlaidInstitution = (props) => {
  const account = props.account;
  const onDelete = props.onDelete; // optional

  return (
    <div className="tile box sw-box--li columns is-gapless is-mobile">
      <div className="column">
        <p className="is-size-6 has-text-weight-bold fs-block">
          {account.display_name}
          {account.plaid_error_code && (
            <span className="help is-danger has-text-weight-normal">
              {" "}
              Error updating account, please reconnect.
            </span>
          )}
        </p>
      </div>

      <div className="column is-narrow">
        <div className="level buttons">
          {props.showReconnect && account.plaid_error_code && (
            <PlaidReconnectButton
              className="button is-small is-warning"
              itemId={account.item_id}
            >
              <span className="icon is-small is-pulled-left">
                <i className="fas fa-link"></i>
              </span>
              <span>Reconnect</span>
            </PlaidReconnectButton>
          )}

          <DropdownButtonMenu>
            {onDelete && (
              <button
                className="button is-hoverable is-small is-white dropdown-item"
                onClick={() => onDelete(account.item_id)}
              >
                <span className="icon is-small is-pulled-left">
                  <i className="fas fa-times"></i>
                </span>
                <span>Delete</span>
              </button>
            )}
            {props.showReconnect && !account.plaid_error_code && (
              <PlaidReconnectButton
                className="button is-hoverable is-small is-white dropdown-item"
                itemId={account.item_id}
              >
                <span className="icon is-small is-pulled-left">
                  <i className="fas fa-link"></i>
                </span>
                <span>Update connection</span>
              </PlaidReconnectButton>
            )}
          </DropdownButtonMenu>
        </div>
      </div>
    </div>
  );
};

export default PlaidInstitution;
