import React, { useCallback, useState, useMemo } from "react";

import { useFormFields } from "../Form/useFormFields";
import { AdminDatePicker } from "../Input/AdminFormFields";
import { COURSE_TYPES_MAP } from "../../constants/constants";
import { AdminTextField } from "../Input/AdminFormFields";

import helpers from "../../services/helpers";

const AddEditCohort = ({ cohort, courseUuid, onAddCohort }) => {
  const defaultFields = useMemo(() => {
    const today = new Date();

    if (cohort) {
      return {
        cohortUuid: cohort.cohort_uuid,
        courseUuid: courseUuid,
        communityUuid: cohort.community_uuid,
        startDate: new Date(cohort.start_date * 1000),
      };
    } else {
      return {
        courseUuid: courseUuid,
        startDate: today,
      };
    }
  }, [courseUuid]);

  const [fields, handleFieldChange, setValues] = useFormFields(defaultFields);

  const onButtonClick = (event) => {
    event.preventDefault();
    onAddCohort(fields).then(() => {
      if (!cohort) {
        // if new cohort, reset to default fields
        setValues(defaultFields);
      }
    });
  };

  const cohortValidated = useMemo(() => {
    let validationState = {
      startDate: fields.startDate > 0,
    };

    return !Object.values(validationState).includes(false);
  }, [fields]);

  return (
    <tr
      key={cohort ? cohort.cohortUuid : "new"}
      style={{
        backgroundColor: "#eef0f3",
      }}
    >
      <td>
        <AdminDatePicker
          label="Start Date"
          fields={fields}
          fieldName="startDate"
          onChange={handleFieldChange}
          showTimeInput={true}
          disabled={false}
          minDate={null}
        ></AdminDatePicker>
      </td>
      <td></td>
      <td></td>
      <td className="is-size-7">
        {cohort && (
          <AdminTextField
            label="Community UUID"
            fields={fields}
            fieldName="communityUuid"
            onChange={handleFieldChange}
          ></AdminTextField>
        )}
      </td>
      <td className="is-size-7">{fields.cohortUuid}</td>
      <td>
        <button
          className={
            false
              ? "button is-primary is-outline is-small is-loading"
              : "button is-primary is-outline is-small"
          }
          onClick={onButtonClick}
          disabled={!cohortValidated}
        >
          {cohort ? "Update" : "Add New"}
        </button>
      </td>
    </tr>
  );
};

const CohortRow = ({ cohort, courseUuid, onAddCohort }) => {
  const [isEditing, setIsEditing] = useState(false);

  const onClickUpdate = useCallback(
    async (fields) => {
      setIsEditing(false);
      return onAddCohort(fields);
    },
    [onAddCohort]
  );

  if (isEditing) {
    return (
      <AddEditCohort
        cohort={cohort}
        courseUuid={courseUuid}
        onAddCohort={onClickUpdate}
      ></AddEditCohort>
    );
  }
  return (
    <tr key={cohort.cohort_uuid}>
      <td>
        {cohort.start_date
          ? helpers.getDateFromTimestamp(cohort.start_date)
          : ""}
      </td>
      <td>
        {cohort.end_date ? helpers.getDateFromTimestamp(cohort.end_date) : ""}
      </td>
      <td>{cohort.num_members}</td>
      <td className="is-size-7">{cohort.community_uuid}</td>
      <td className="is-size-7">{cohort.cohort_uuid}</td>
      <td>
        <button
          className="button is-text is-small"
          onClick={() => setIsEditing(true)}
        >
          Edit
        </button>
      </td>
    </tr>
  );
};

const AdminCourseCohorts = ({
  cohorts,
  courseUuid,
  courseType,
  onAddCohort,
}) => {
  const numCohorts = cohorts ? cohorts.length : 0;

  return (
    <div className="box">
      <h2 className="subtitle">Cohorts (course type: {courseType})</h2>
      <p>
        IMPORTANT: Every course must have a cohort. Self-guided courses have 1
        giant cohort (this creates the community) vs. cohort-courses, which have
        multiple cohorts.
        <br />
        <br />
        NOTE: End date cannot be edited. This is automatically generated based
        on course duration which is set above.
        <br />
        <br />
      </p>

      <table className="table is-hoverable is-fullwidth fs-block">
        <thead>
          <tr>
            <th>Start date</th>
            <th>End date</th>
            <th>Num members</th>
            <th>Community UUID</th>
            <th>Cohort UUID</th>
          </tr>
        </thead>
        <tbody>
          {cohorts &&
            cohorts.map((cohort, index) => {
              return (
                <CohortRow
                  key={cohort.cohort_uuid}
                  cohort={cohort}
                  courseUuid={courseUuid}
                  onAddCohort={onAddCohort}
                />
              );
            })}

          <tr>
            <th></th>
          </tr>

          {(numCohorts < 1 || courseType === COURSE_TYPES_MAP.cohort) && (
            <AddEditCohort
              cohort={null}
              courseUuid={courseUuid}
              onAddCohort={onAddCohort}
            ></AddEditCohort>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AdminCourseCohorts;
