import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "../Modal/Modal";
import { useFormFields } from "../Form/useFormFields";

import NumberInput, {
  NUMBER_INPUT_TYPES,
} from "../../components/Input/NumberInput";

import { asyncUpdateChallengeProgress } from "../../store/actions/challengesActions";
import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";

import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const EditChallengeGoalModal = ({
  onClose,
  isOpen,
  challengeObj,
  challengeId,
  onSuccess,
}) => {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => {
    return state.challenges;
  });

  const userProgress = challengeObj.progress || {};

  const [fields, handleFieldChange] = useFormFields({
    startAccountBalance: userProgress.account_balance_start || "",
    goalAmount: userProgress.goal_amount || "",
  });

  const onSubmitForm = (event) => {
    dispatch(
      trackEvent(EVENTS.CHALLENGE_SAVED, {
        challengeId: challengeId,
        goal_amount: fields.goalAmount,
      })
    );
    dispatch(
      asyncUpdateChallengeProgress(
        challengeId,
        null,
        fields.goalAmount,
        fields.startAccountBalance
      )
    ).then((res) => {
      onSuccess();
    });
  };

  const footerObj = (
    <button
      form="sw-track-debt-form"
      type="submit"
      className="button is-primary"
    >
      Save
    </button>
  );

  const goalLabel = `Goal amount (${challengeObj.goal_type})`;

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      title="Edit your challenge goal"
      footer={footerObj}
    >
      {isLoading && <LoadingSpinner></LoadingSpinner>}

      <form id="sw-track-debt-form" onSubmit={(event) => onSubmitForm(event)}>
        <div className="sw-padding--left-lg sw-padding--right-lg">
          <div className="field">
            <NumberInput
              label="Starting account balance"
              name="startAccountBalance"
              className="fs-block"
              value={fields.startAccountBalance}
              onChange={handleFieldChange}
              numberType={NUMBER_INPUT_TYPES.CURRENCY}
              required={true}
            ></NumberInput>
          </div>
          <div className="field">
            <NumberInput
              label={goalLabel}
              name="goalAmount"
              className="fs-block"
              value={fields.goalAmount}
              onChange={handleFieldChange}
              numberType={NUMBER_INPUT_TYPES.CURRENCY}
              required={true}
            ></NumberInput>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default EditChallengeGoalModal;
