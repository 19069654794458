import React from "react";

import RecommendationCarousel from "./RecommendationCarousel";
import RecIcon from "../Icon/RecIcon";
import {
  RecStudentContentIntro,
  RecStudentContentBody,
} from "./RecStudentContent";

const RecDetailsStudent = (props) => {
  const rec = props.rec;
  const baseCase = props.baseCase;

  return (
    <div className="sw-container--fixed-width sw-padding--bottom-xl">
      <h1 className="title has-text-centered">
        <RecIcon recType={rec.type}></RecIcon> Your Recommendation
      </h1>
      <div className="content fs-block">
        <RecStudentContentIntro
          rec={rec}
          baseCase={baseCase}
        ></RecStudentContentIntro>
        <RecStudentContentBody
          rec={rec}
          baseCase={baseCase}
        ></RecStudentContentBody>
      </div>
      <br />
      <RecommendationCarousel
        baseCase={baseCase}
        rec={rec}
      ></RecommendationCarousel>
    </div>
  );
};

export default RecDetailsStudent;
