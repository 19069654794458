import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Logo from "../Logo/Logo";
import ROUTES from "../../constants/routes";
import { MenuItem, MenuItemHref } from "./MenuItems";
import { MENU_ITEMS } from "../../constants/images";
import { hideSideMenuNavbar } from "./menuHelpers";

import { trackEvent, EVENTS } from "../../store/actions/analyticsActions";

export const MAIN_MENU_LIST = [
  {
    text: "Community",
    to: ROUTES.COMMUNITY,
    iconSrc: MENU_ITEMS.community,
    isNew: false,
  },
  {
    text: "Challenges",
    to: ROUTES.CHALLENGES,
    iconSrc: MENU_ITEMS.challenges,
  },
  {
    text: "Courses",
    to: ROUTES.COURSES,
    iconSrc: MENU_ITEMS.resources,
  },
  {
    text: "Net worth",
    to: ROUTES.NET_WORTH,
    iconSrc: MENU_ITEMS.netWorth,
  },
  {
    text: "Student loans",
    to: ROUTES.STUDENT_LOAN_DASHBOARD,
    iconSrc: MENU_ITEMS.studentLoans,
  },
  {
    text: "Federal programs",
    to: ROUTES.FEDERAL_PROGRAMS,
    iconSrc: MENU_ITEMS.federal,
    isNew: true,
  },
];

const getMenuItems = (hasPremium) => {
  let thisMenuList = MAIN_MENU_LIST;
  if (hasPremium) {
    let menuItemList = thisMenuList.slice();
    menuItemList.push({
      text: "Premium",
      to: ROUTES.PREMIUM,
      iconSrc: MENU_ITEMS.premium,
      className: "is-premium",
    });

    return menuItemList;
  }

  return thisMenuList;
};

const SideMenu = () => {
  const dispatch = useDispatch();
  const onClickPremium = () => {
    dispatch(trackEvent(EVENTS.PREMIUM_CLICKED));
  };

  const { hasPremium } = useSelector((state) => {
    return state.auth;
  });

  const menuItems = getMenuItems(hasPremium);

  return (
    <nav className="sw-layout-side-nav">
      <aside className="sw-side-menu">
        <NavLink
          className="sw-side-menu__logo"
          to={ROUTES.HOME}
          onClick={hideSideMenuNavbar}
        >
          <Logo altText="Snowball Wealth - Go Home" />
        </NavLink>

        <ul className="sw-side-menu__items">
          {menuItems.map((menuObj, index) => {
            return (
              <MenuItem
                key={index}
                text={menuObj.text}
                to={menuObj.to}
                iconSrc={menuObj.iconSrc}
                className={menuObj.className}
                isNew={menuObj.isNew ? true : false}
                onClickFn={hideSideMenuNavbar}
              ></MenuItem>
            );
          })}
        </ul>
        {false && !hasPremium && (
          <div className="sw-side-menu__items--bottom">
            <MenuItemHref
              text="Try Snowball Premium"
              href="https://premium.snowballwealth.com/"
              onClickFn={onClickPremium}
              iconSrc={MENU_ITEMS.premium}
              className="is-premium"
            ></MenuItemHref>
          </div>
        )}
      </aside>
    </nav>
  );
};

export default SideMenu;
