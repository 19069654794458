import { ADMIN_COMMUNITY_ACTIONS } from "../reducers/adminCommunitiesReducer";
import { apiGet, apiPost } from "./apiActions";
import { setError } from "./errorActions";

const getFormDataFromFields = (fields) => {
  return {
    name: fields.title,
    is_private: fields.isPrivate,
    is_global: fields.isGlobal,
    description: fields.description,
    image_url: fields.imageUrl,
    thumbnail_image_url: fields.thumbnailImageUrl,
  };
};

export const updateCommunity = (communityId, fields) => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      const endpoint = `/api/admin/feed/communities`;

      const formFields = getFormDataFromFields(fields);
      const data = {
        community_uuid: communityId,
        ...formFields,
      };

      dispatch({ type: ADMIN_COMMUNITY_ACTIONS.EDIT_COMMUNITY });
      const onSuccess = (res) => {
        dispatch({
          type: ADMIN_COMMUNITY_ACTIONS.EDIT_COMMUNITY_SUCCESS,
          res,
        });
        resolve(res);
      };

      const onError = (err) => {
        dispatch({ type: ADMIN_COMMUNITY_ACTIONS.EDIT_COMMUNITY_ERROR, err });
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};

export const getAllCommunities = () => {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/admin_coach/feed/communities";

      dispatch({ type: ADMIN_COMMUNITY_ACTIONS.GET_COMMUNITIES });

      const onSuccess = (res) => {
        dispatch({
          type: ADMIN_COMMUNITY_ACTIONS.GET_COMMUNITIES_SUCCESS,
          res,
        });
        resolve(res);
      };

      const onError = (err) => {
        dispatch({ type: ADMIN_COMMUNITY_ACTIONS.GET_COMMUNITIES_ERROR, err });
        reject(err);
      };

      dispatch(apiGet(endpoint, onSuccess, onError));
    });
  };
};

export const createCommunity = (fields) => {
  return (dispatch) => {
    const data = getFormDataFromFields(fields);

    return new Promise(function (resolve, reject) {
      const endpoint = "/api/admin/feed/communities";

      dispatch({ type: ADMIN_COMMUNITY_ACTIONS.CREATE_COMMUNITY });

      const onSuccess = (res) => {
        dispatch({
          type: ADMIN_COMMUNITY_ACTIONS.CREATE_COMMUNITY_SUCCESS,
          payload: { res: res },
        });
        resolve(res);
      };

      const onError = (err) => {
        dispatch({ type: ADMIN_COMMUNITY_ACTIONS.CREATE_COMMUNITY_ERROR, err });
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};

export const asyncGetAllFeedItems = (pageKey) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      let endpoint = "/api/admin/feed";
      if (pageKey) {
        endpoint += `?page_key=${pageKey}`;
      }

      dispatch({ type: ADMIN_COMMUNITY_ACTIONS.GET_FEED });
      const onSuccess = (res) => {
        dispatch({
          type: ADMIN_COMMUNITY_ACTIONS.GET_FEED_SUCCESS,
          res,
        });
        resolve();
      };

      const onError = (err) => {
        dispatch({ type: ADMIN_COMMUNITY_ACTIONS.GET_FEED_ERROR, err });
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiGet(endpoint, onSuccess, onError));
    });
  };
};

export const asyncDeletePost = (feedItemUuid) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/admin/feed/feed_item/delete";
      const data = {
        feed_item_uuid: feedItemUuid,
      };

      const onSuccess = (res) => {
        resolve();
      };

      const onError = (err) => {
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};

export const asyncNotifyCommunityItemUsers = (
  feedItemUuid,
  notification_type
) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/admin/feed/feed_item/notify";
      const data = {
        feed_item_uuid: feedItemUuid,
        notification_type: notification_type,
      };
      const onSuccess = (res) => {
        resolve();
      };
      const onError = (err) => {
        dispatch(setError(err));
        reject(err);
      };
      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};

export const asyncHideItem = (feedItemUuid, hide) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/admin/feed/feed_item/hide";
      const data = {
        feed_item_uuid: feedItemUuid,
        is_hidden: hide,
      };
      const onSuccess = (res) => {
        resolve();
      };
      const onError = (err) => {
        dispatch(setError(err));
        reject(err);
      };
      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};

export const asyncGetFeedItem = (feedItemUuid) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = `/api/feed/feed_item?feed_item_uuid=${feedItemUuid}`;
      dispatch({ type: ADMIN_COMMUNITY_ACTIONS.GET_FEED_ITEM });
      const onSuccess = (res) => {
        dispatch({
          type: ADMIN_COMMUNITY_ACTIONS.GET_FEED_ITEM_SUCCESS,
          payload: { res: res },
        });
        resolve();
      };
      const onError = (err) => {
        dispatch({ type: ADMIN_COMMUNITY_ACTIONS.GET_FEED_ITEM_ERROR, err });
        reject(err);
      };
      dispatch(apiGet(endpoint, onSuccess, onError));
    });
  };
};

export const asyncEditUniversalLinkAttachment = (
  feedItemUuid,
  basePath,
  displayText,
  pathExtension = null
) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/admin/feed/feed_item/universal_link";
      const data = {
        feed_item_uuid: feedItemUuid,
        base_path: basePath,
        display_text: displayText,
        path_extension: pathExtension,
      };
      dispatch({
        type: ADMIN_COMMUNITY_ACTIONS.EDIT_UNIVERSAL_LINK_ATTACHMENT,
      });
      const onSuccess = (res) => {
        dispatch({
          type: ADMIN_COMMUNITY_ACTIONS.EDIT_UNIVERSAL_LINK_ATTACHMENT_SUCCESS,
          payload: { res: res },
        });
        resolve();
      };
      const onError = (err) => {
        dispatch({
          type: ADMIN_COMMUNITY_ACTIONS.EDIT_UNIVERSAL_LINK_ATTACHMENT_ERROR,
          err,
        });
        reject(err);
      };
      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};

export const asyncEditFeedItem = (feedItemUuid, videoUrl, pollUuid) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/feed/feed_item";
      const data = {
        feed_item_uuid: feedItemUuid,
        video_url: videoUrl,
        poll_uuid: pollUuid,
      };
      dispatch({
        type: ADMIN_COMMUNITY_ACTIONS.EDIT_FEED_ITEM,
      });
      const onSuccess = (res) => {
        dispatch({
          type: ADMIN_COMMUNITY_ACTIONS.EDIT_FEED_ITEM_SUCCESS,
          payload: { res: res },
        });
        resolve();
      };
      const onError = (err) => {
        dispatch({
          type: ADMIN_COMMUNITY_ACTIONS.EDIT_FEED_ITEM_ERROR,
          err,
        });
        reject(err);
      };
      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};

export const asyncDeleteUniversalLinkAttachment = (feedItemUuid) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/admin/feed/feed_item/universal_link/delete";
      const data = {
        feed_item_uuid: feedItemUuid,
      };
      dispatch({
        type: ADMIN_COMMUNITY_ACTIONS.EDIT_UNIVERSAL_LINK_ATTACHMENT,
      });
      const onSuccess = (res) => {
        dispatch({
          type: ADMIN_COMMUNITY_ACTIONS.EDIT_UNIVERSAL_LINK_ATTACHMENT_SUCCESS,
          payload: { res: res },
        });
        resolve();
      };
      const onError = (err) => {
        dispatch({
          type: ADMIN_COMMUNITY_ACTIONS.EDIT_UNIVERSAL_LINK_ATTACHMENT_ERROR,
          err,
        });
        reject(err);
      };
      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};

export const asyncGetUserCommunities = (userId) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = `/api/admin_coach/communities/users/${userId}`;
      dispatch({ type: ADMIN_COMMUNITY_ACTIONS.USER_COMMUNITIES });

      const onSuccess = (res) => {
        dispatch({
          type: ADMIN_COMMUNITY_ACTIONS.USER_COMMUNITIES_SUCCESS,
          res,
        });
        resolve(res);
      };

      const onError = (err) => {
        dispatch({ type: ADMIN_COMMUNITY_ACTIONS.USER_COMMUNITIES_ERROR, err });
        reject(err);
      };

      dispatch(apiGet(endpoint, onSuccess, onError));
    });
  };
};

export const asyncUpdateUserCommunities = (
  userId,
  communityUuid,
  shouldDelete = false
) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = `/api/admin_coach/communities/users/${userId}`;
      const data = {
        community_uuid: communityUuid,
      };

      if (shouldDelete) {
        data["to_delete"] = true;
      }

      dispatch({ type: ADMIN_COMMUNITY_ACTIONS.USER_COMMUNITIES });

      const onSuccess = (res) => {
        dispatch({
          type: ADMIN_COMMUNITY_ACTIONS.USER_COMMUNITIES_SUCCESS,
          res,
        });
        resolve(res);
      };

      const onError = (err) => {
        dispatch({ type: ADMIN_COMMUNITY_ACTIONS.USER_COMMUNITIES_ERROR, err });
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};
