import React from "react";
import MaterialRadio from "@material-ui/core/Radio";

import { withStyles } from "@material-ui/core/styles";

const Radio = withStyles({
  root: {
    color: "#979797",
    "&$checked": {
      color: "#00A3B7",
    },
  },
  checked: {},
})((props) => <MaterialRadio color="default" {...props} />);

export default Radio;
