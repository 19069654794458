import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";

// components and helpers
import { USER_ROLES } from "../../constants/constants";
import AdminLayout from "../PageLayouts/AdminLayout";
import RequireAdminAuth from "../RouteWrappers/RequireAdminAuth";

import NotFoundPage from "../../pages/NotFoundPage/NotFoundPage";

// admin pages
import AdminUsersPage from "../../pages/AdminPages/AdminUsersPage";
import AdminUserRootPage from "../../pages/AdminUserDetails/AdminUserRootPage";
import AdminToolsPage from "../../pages/AdminPages/AdminToolsPage";

// challenges
import AdminChallengesPage from "../../pages/AdminChallengesPages/AdminChallengesPage";
import AdminCreateChallengePage from "../../pages/AdminChallengesPages/AdminCreateChallengePage";
import AdminEditChallengePage from "../../pages/AdminChallengesPages/AdminEditChallengePage";

// communities
import AdminCommunitiesPage from "../../pages/AdminCommunityPages/AdminCommunitiesPage";
import AdminCreateCommunityPage from "../../pages/AdminCommunityPages/AdminCreateCommunityPage";
import AdminEditCommunityPage from "../../pages/AdminCommunityPages/AdminEditCommunityPage";
import AdminFeedPage from "../../pages/AdminCommunityPages/AdminFeedPage";
import AdminEditUniversalLinkAttachmentPage from "../../pages/AdminCommunityPages/AdminEditUniversalLinkAttachmentPage";

// courses
import AdminCoursesPage from "../../pages/AdminCoursesPages/AdminCoursesPage";
import AdminEditCoursePage from "../../pages/AdminCoursesPages/AdminEditCoursePage";
import AdminNewCoursePage from "../../pages/AdminCoursesPages/AdminNewCoursePage";

// polls
import AdminCreatePollPage from "../../pages/AdminChallengesPages/AdminCreatePollPage";
import AdminPollsPage from "../../pages/AdminChallengesPages/AdminPollsPage";
import AdminEditFeedItemPage from "../../pages/AdminCommunityPages/AdminEditFeedItemPage";

// Allows coach or support roles
const allowsCoachOrSupport = [
  USER_ROLES.admin,
  USER_ROLES.coach,
  USER_ROLES.support,
];
// Allows support
const allowsSupport = [USER_ROLES.admin, USER_ROLES.support];

// Allows admin only
const allowAdminOnly = [USER_ROLES.admin];

const AdminWrapper = ({ allowedRoles }) => {
  return (
    <RequireAdminAuth allowedRoles={allowedRoles}>
      <AdminLayout hideAllNav={false} hideSideMenu={false}>
        <Outlet />
      </AdminLayout>
    </RequireAdminAuth>
  );
};

const RoutesAdmin = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<AdminWrapper allowedRoles={allowsCoachOrSupport} />}
      >
        <Route index element={<AdminUsersPage />} />
        <Route path="users" element={<AdminUsersPage />} />
        <Route path="user/:userId/*" element={<AdminUserRootPage />} />

        <Route path="*" element={<NotFoundPage />} />
      </Route>

      <Route path="/" element={<AdminWrapper allowedRoles={allowsSupport} />}>
        <Route path="challenges" element={<AdminChallengesPage />} />
        <Route path="challenges/new" element={<AdminCreateChallengePage />} />
        <Route
          path="challenges/:challengeId"
          element={<AdminEditChallengePage />}
        />

        <Route path="feed" element={<AdminFeedPage />} />
        <Route
          path="feed/universal_link/:feedItemUuid"
          element={<AdminEditUniversalLinkAttachmentPage />}
        />
        <Route
          path="feed/edit/:feedItemUuid"
          element={<AdminEditFeedItemPage />}
        />

        <Route path="communities" element={<AdminCommunitiesPage />} />
        <Route path="communities/new" element={<AdminCreateCommunityPage />} />
        <Route
          path="communities/:communityId"
          element={<AdminEditCommunityPage />}
        />

        <Route path="courses" element={<AdminCoursesPage />} />
        <Route path="courses/new" element={<AdminNewCoursePage />} />
        <Route path="courses/:courseId" element={<AdminEditCoursePage />} />

        <Route path="polls" element={<AdminPollsPage />} />
        <Route path="polls/new" element={<AdminCreatePollPage />} />
      </Route>

      <Route path="/" element={<AdminWrapper allowedRoles={allowAdminOnly} />}>
        <Route path="tools" element={<AdminToolsPage />} />
      </Route>
    </Routes>
  );
};

export default RoutesAdmin;
