import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";
import { asyncAddCommentReaction } from "../../store/actions/communityActions";
import ReactionPicker from "./ReactionPicker";
import UserIcon from "./UserIcon";
import ReactionsList from "./ReactionsList";
import CommentItemEditButton from "./CommentItemEditButton";

import OverflowParagraph from "../Input/OverflowParagraph";

import helpers from "../../services/helpers";

const CommentItem = ({ comment, communityUuid, onDeleteComment }) => {
  const dispatch = useDispatch();
  const reactions = comment.reactions;

  const onReactClick = (reaction_type) => {
    // if already clicked, want to delete
    let shouldDelete = false;
    if (reactions[reaction_type] && reactions[reaction_type]["has_reacted"]) {
      shouldDelete = true;
    }

    dispatch(
      asyncAddCommentReaction(comment.comment_uuid, reaction_type, shouldDelete)
    );

    dispatch(
      trackEvent(EVENTS.COMMENT_REACTION_CLICKED, {
        community_uuid: communityUuid,
        feed_item_uuid: comment.feed_item_uuid,
        comment_uuid: comment.comment_uuid,
        reaction_type: reaction_type,
        has_reacted: !shouldDelete,
      })
    );
  };

  const { currentUser } = useSelector((state) => {
    return state.auth;
  });

  const isAuthor = currentUser.id === comment.author_id;

  if (!comment) {
    return <></>;
  }

  return (
    <article
      key={comment.comment_uuid}
      className="sw-media media sw-reaction-picker-relative-container"
    >
      <div className="media-left">
        <UserIcon authorProfile={comment.author_profile} sizeClass="is-32x32" />
      </div>
      <div className="media-content">
        <div className="content">
          <p>
            <strong>{comment.author_profile.display_name} &#8226;</strong>{" "}
            <small>{helpers.getDateFromTimestamp(comment.created_at)}</small>
            <br />
            <OverflowParagraph text={comment.text} />
          </p>
        </div>

        <div>
          <ReactionPicker onReactClick={onReactClick} />
          {reactions && (
            <ReactionsList reactions={reactions} onReactClick={onReactClick} />
          )}
        </div>
      </div>
      {isAuthor && (
        <div className="level">
          <CommentItemEditButton
            className="level-item level-right"
            onDeleteComment={() => onDeleteComment(comment)}
          ></CommentItemEditButton>
        </div>
      )}
    </article>
  );
};

export default CommentItem;
