import React from "react";

import helpers from "../../services/helpers";

import ProgressSummaryCard from "./ProgessSummaryCard";
import SelectAccountButton from "../Buttons/SelectAccountButton";

const ChallengeCTA = ({ challengeObj }) => {
  const progressObj = challengeObj.progress;
  if (!progressObj.account_id) {
    // joined, but has not selected account
    // show selected account or add account ...
    return (
      <SelectAccountButton
        challengeId={challengeObj.challenge_id}
        challengeType={challengeObj.goal_type}
      >
        Select account
      </SelectAccountButton>
    );
  } else if (progressObj.account) {
    // joined, selected account. Show account info
    const accountObj = progressObj.account;
    // has account
    return (
      <div className="sw-challenge-account-info">
        <div className="level is-mobile has-text-weight-bold">
          <span className="level-item level-left">
            {accountObj.display_name}
          </span>
          <span className="level-item level-right">
            {helpers.currencyFormat(accountObj.current_balance, 2)}
          </span>
        </div>
        <div className="sw-challenge-account-info__edit">
          <SelectAccountButton
            challengeId={challengeObj.challenge_id}
            challengeType={challengeObj.goal_type}
            className="button is-ghost is-size-7"
          >
            Edit
          </SelectAccountButton>
        </div>
      </div>
    );
  }

  // should never get here
  return <div></div>;
};

const ChallengeProgress = ({ challengeObj, joinFn }) => {
  const title = challengeObj.title;
  const subtitle = challengeObj.subtitle;
  const progressObj = challengeObj.progress;

  return (
    <div className="sw-container--fixed-width sw-is-narrow">
      <div className="sw-challenge-card__content">
        <div>
          <p className="sw-challenge-card__title">{title}</p>
          <p className="sw-challenge-card__subtitle">{subtitle}</p>
        </div>
        <ProgressSummaryCard
          challengeObj={challengeObj}
          joinFn={joinFn}
        ></ProgressSummaryCard>
        {progressObj && progressObj.has_joined && (
          <ChallengeCTA challengeObj={challengeObj}></ChallengeCTA>
        )}
      </div>
      <div className="sw-challenge-card__details">
        <ul>
          {challengeObj.participant_count > 79 && (
            <li>
              <span className="icon is-small sw-padding--right-md">
                <i className="far fa-user" aria-hidden="true"></i>
              </span>{" "}
              {challengeObj.participant_count}
            </li>
          )}
          <li>
            <span className="icon is-small sw-padding--right-md">
              <i className="far fa-calendar" aria-hidden="true"></i>
            </span>{" "}
            {helpers.getDateFromTimestamp(challengeObj.start_date)} -{" "}
            {helpers.getDateFromTimestamp(challengeObj.end_date)}{" "}
          </li>

          <li>
            <span className="icon is-small sw-padding--right-md">
              <i className="far fa-lightbulb" aria-hidden="true"></i>
            </span>{" "}
            {challengeObj.description}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ChallengeProgress;
