import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import PlaidAddAccountButton from "../../components/PlaidIntegration/PlaidAddAccountButton";
import { ONBOARDING_PAGES } from "../../constants/routes";
import { asyncGetNetWorthItems } from "../../store/actions/netWorthActions";

const PlaidConnectPage = (props) => {
  const navigate = useNavigate();
  const asyncGetNetWorthItemsFn = props.asyncGetNetWorthItems;
  const totalLoans = Math.abs(props.studentLoans.total);

  useEffect(() => {
    if (!totalLoans) {
      asyncGetNetWorthItemsFn();
    }
  }, [totalLoans, asyncGetNetWorthItemsFn]);

  const onNextSync = () => {
    asyncGetNetWorthItemsFn();
    onNext();
  };

  const onNext = () => {
    navigate(ONBOARDING_PAGES.DONE);
  };

  const onBack = () => {
    navigate(ONBOARDING_PAGES.STEP_TWO);
  };

  return (
    <section className="sw-onboarding-page-container section">
      <div className="container">
        <div className="sw-flex-layout--column-center">
          <p className="has-text-grey sw-padding--bottom-xl">Step 3 of 3</p>
          <div className="sw-container--fixed-width sw-padding--bottom-xl">
            <h2 className="sw-onboarding__title">Connect your student loans</h2>
            <p className="sw-onboarding__subtitle">
              See all your loans in one place and get a personalized plan.
            </p>

            <div className="sw-onboarding-questions__buttons sw-padding--top-xl">
              <button className="button is-medium is-light" onClick={onBack}>
                Back
              </button>

              {totalLoans > 0 && (
                <button
                  className="button is-medium is-primary sw-onboarding-questions__buttons--next"
                  onClick={onNext}
                >
                  Next
                </button>
              )}
              {totalLoans <= 0 && (
                <PlaidAddAccountButton
                  className="button is-medium is-primary sw-onboarding-questions__buttons--next"
                  onSyncSuccess={onNextSync}
                >
                  Next
                </PlaidAddAccountButton>
              )}
            </div>
            <div className="sw-padding--top-lg is-pulled-right">
              <button className="button is-text" onClick={onNext}>
                Skip
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    asyncGetNetWorthItems: () => dispatch(asyncGetNetWorthItems()),
  };
};

const mapStateToProps = (state) => {
  return {
    studentLoans: state.netWorth.liabilities.student,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaidConnectPage);
