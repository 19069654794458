import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import QuestionsPartTwoForm from "../../components/Onboarding/QuestionsPartTwoForm";

import {
  getUserFinancialProfile,
  asyncUpdateQuestionsPartTwo,
} from "../../store/actions/profileActions";
import ROUTES, { ONBOARDING_PAGES } from "../../constants/routes";

const QuestionsTwo = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // note: used for "update_profile", in which case we go back to profile
  let mode = searchParams.get("mode");

  const getFinancialProfile = props.getUserFinancialProfile;
  useEffect(() => {
    getFinancialProfile();
  }, [getFinancialProfile]);

  const onSubmit = (profileObj) => {
    props.asyncUpdateQuestionsPartTwo(profileObj).then(() => {
      if (mode === "update_profile") {
        navigate(ROUTES.STUDENT_LOAN_DASHBOARD);
      } else {
        navigate(ONBOARDING_PAGES.STEP_THREE);
      }
    });
  };

  const onBack = () => {
    let nextRoute = ONBOARDING_PAGES.STEP_ONE;
    if (mode === "update_profile") {
      nextRoute += "?mode=update_profile";
    }
    navigate(nextRoute);
  };

  if (props.isLoading) {
    return (
      <section className="sw-onboarding-page-container section">
        <div className="container">
          <div className="sw-flex-layout--column-center">
            <LoadingSpinner></LoadingSpinner>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="sw-onboarding-page-container section">
      <div className="container">
        <div className=" sw-flex-layout--column-center">
          <p className="has-text-grey sw-padding--bottom-xl">Step 2 of 3</p>
          <div className="sw-container--fixed-width sw-padding--bottom-xl">
            <QuestionsPartTwoForm
              onSubmit={onSubmit}
              onBack={onBack}
              initialValues={props.initialValues}
            ></QuestionsPartTwoForm>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserFinancialProfile: () => dispatch(getUserFinancialProfile()),
    asyncUpdateQuestionsPartTwo: (responsesObj) =>
      dispatch(asyncUpdateQuestionsPartTwo(responsesObj)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const initialValues = {
    income: "",
    personalDebt: "",
    creditScore: "",
  };

  if (state.profile.profile) {
    if (
      state.profile.profile.personal_debt !== undefined &&
      state.profile.profile.personal_debt !== null
    ) {
      initialValues.personalDebt = state.profile.profile.personal_debt;
    }

    if (
      state.profile.profile.income !== undefined &&
      state.profile.profile.income !== null
    ) {
      initialValues.income = state.profile.profile.income;
    }

    initialValues.creditScore = state.profile.profile.credit_score || "";
  }

  return {
    isLoading: state.profile.getLoading || state.profile.updateLoading,
    initialValues,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsTwo);
