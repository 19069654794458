import React from "react";

const UserIcon = ({ authorProfile, sizeClass }) => {
  const imageClassName = sizeClass ? `image ${sizeClass}` : "image is-48x48";

  let nameString = authorProfile.display_name
    ? authorProfile.display_name
    : authorProfile.first_name;

  if (authorProfile.profile_img_url) {
    return (
      <figure className={imageClassName}>
        <img
          className="is-rounded"
          src={authorProfile.profile_img_url}
          alt={`${nameString} icon`}
        />
      </figure>
    );
  }

  const initialLetter = nameString ? nameString.charAt(0) : "";

  // TODO: make colors different
  return (
    <figure className={imageClassName}>
      <div
        className={`sw-user-icon ${imageClassName}`}
        // style={{ backgroundColor: "" }}
        src={authorProfile.profile_img_url}
        alt={`${nameString} icon`}
      >
        {initialLetter}
      </div>
    </figure>
  );
};

export default UserIcon;
