import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import PlaidAddAccountButton from "../../components/PlaidIntegration/PlaidAddAccountButton";
import { asyncGetNetWorthItems } from "../../store/actions/netWorthActions";
import helpers from "../../services/helpers";
import ROUTES, { ONBOARDING_PAGES } from "../../constants/routes";
import { trackEvent, EVENTS } from "../../store/actions/analyticsActions";

const PlaidConnectSuccess = (props) => {
  const navigate = useNavigate();
  const asyncGetNetWorthItemsFn = props.asyncGetNetWorthItems;
  const totalLoans = Math.abs(props.studentLoans.total);

  useEffect(() => {
    if (!totalLoans) {
      asyncGetNetWorthItemsFn();
    }
  }, [totalLoans, asyncGetNetWorthItemsFn]);

  const onBack = () => {
    navigate(ONBOARDING_PAGES.STEP_THREE);
  };

  const onNext = () => {
    props.trackEvent(EVENTS.ONBOARDING_REC_COMPLETED);
    navigate(ROUTES.STUDENT_LOAN_DASHBOARD);
  };

  if (props.isLoading) {
    return (
      <section className="sw-onboarding-page-container section">
        <div className="container">
          <div className="sw-flex-layout--column-center">
            <LoadingSpinner></LoadingSpinner>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="sw-onboarding-page-container section">
      <div className="container">
        <div className=" sw-flex-layout--column-center">
          <div className="sw-container--fixed-width sw-padding--bottom-xl">
            <h2 className="sw-onboarding__title">
              Success! You are part of the Snowball family.
            </h2>
            <p className="sw-onboarding__subtitle">
              We have {helpers.currencyFormat(totalLoans, 0)} of your student
              debt linked.
            </p>
            <div className="sw-padding--top-md has-text-centered sw-padding--bottom-lg">
              <PlaidAddAccountButton onSyncSuccess={asyncGetNetWorthItemsFn}>
                + Add Another Account
              </PlaidAddAccountButton>
            </div>

            <div className="sw-onboarding-questions__buttons sw-padding--top-xl">
              <button className="button is-medium is-light" onClick={onBack}>
                Back
              </button>

              <button
                className="button is-primary is-medium sw-onboarding-questions__buttons--next"
                onClick={onNext}
              >
                Let's do this!
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    asyncGetNetWorthItems: () => dispatch(asyncGetNetWorthItems()),
    trackEvent: (event, properties) => dispatch(trackEvent(event, properties)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.netWorth.isLoading,
    studentLoans: state.netWorth.liabilities.student,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlaidConnectSuccess);
