import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";

import adminReducer from "./adminReducer";
import adminChallengesReducer from "./adminChallengesReducer";
import adminCommunitiesReducer from "./adminCommunitiesReducer";
import authReducer from "./authReducer";
import blogReducer from "./blogReducer";
import challengesReducer from "./challengesReducer";
import communitiesReducer from "./communityReducer";
import errorReducer from "./errorReducer";
import institutionReducer from "./institutionReducers";
import courseReducer from "./courseReducer";
import lendersReducer from "./lendersReducer";
import orgReducer from "./orgReducer";
import payitoffReducer from "./payitoffReducer";
import premiumReducer from "./premiumReducer";
import profileReducer from "./profileReducer";
import alertingReducer from "./alertingReducer";
import recommendationsReducer from "./recommendationsReducer";
import studentLoansReducer from "./studentLoansReducer";
import studentDashboardReducer from "./studentDashboardReducer";
import netWorthReducer from "./netWorthReducer";
import netWorthGraphReducer from "./netWorthGraphReducer";
import adminPollsReducer from "./adminPollsReducer";
import adminCoursesReducer from "./adminCoursesReducer";

const rootReducer = combineReducers({
  admin: adminReducer,
  adminChallenges: adminChallengesReducer,
  adminCommunities: adminCommunitiesReducer,
  adminCourses: adminCoursesReducer,
  adminPolls: adminPollsReducer,
  auth: authReducer,
  blog: blogReducer,
  challenges: challengesReducer,
  communities: communitiesReducer,
  error: errorReducer,
  courses: courseReducer,
  institution: institutionReducer,
  lenders: lendersReducer,
  netWorth: netWorthReducer,
  netWorthGraph: netWorthGraphReducer,
  org: orgReducer,
  payitoff: payitoffReducer,
  premium: premiumReducer,
  profile: profileReducer,
  alerting: alertingReducer,
  recommendations: recommendationsReducer,
  studentLoans: studentLoansReducer,
  studentDashboard: studentDashboardReducer,
  firebase: firebaseReducer,
});

export default rootReducer;
