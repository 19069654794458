import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getAllCommunities } from "../../store/actions/adminCommunitiesActions";
import AdminCommunitiesList from "../../components/Communities/AdminCommunitiesList";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

const AdminCommunitiesPage = (props) => {
  const dispatch = useDispatch();
  const { communities, adminError, getCommunitiesLoading } = useSelector(
    (state) => {
      return state.adminCommunities;
    }
  );

  useEffect(() => {
    dispatch(getAllCommunities());
  }, [dispatch]);

  return (
    <div className="fs-block">
      <h1 className="title">Admin Communities</h1>
      {getCommunitiesLoading && <LoadingSpinner></LoadingSpinner>}
      {communities && <AdminCommunitiesList communities={communities} />}
      {adminError && <p className="help is-danger">Error: {adminError}</p>}
      <br />
      <Link className="button is-primary" to="new">
        Add a community
      </Link>
    </div>
  );
};

export default AdminCommunitiesPage;
