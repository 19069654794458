import React from "react";

import ExpandableMessage from "../Messages/ExpandableMessage";

const LenderDisclaimerMessage = (props) => {
  const disclaimerContent = (
    <div>
      <p>
        At Snowball we are committed to being honest and transparent. We will
        not allow advertisers to influence our opinion of offers that appear on
        our site. We do receive compensation from some partners whose offers
        appear here. It is this compensation which enables us to provide you
        with free services. We also may not cover all offers in the market. We
        are firm believers that if we wouldn’t recommend an offer to a close
        friend or family, we wouldn’t recommend it on Snowball either.
        <br />
        <br />
        Updated as of 11/29/2021. (1) Annual Percentage Rates (APR), loan term
        and monthly payments are estimated based on analysis of information
        provided by you, data provided by lenders, and publicly available
        information. All loan information is presented without warranty, and the
        estimated APR and other terms are not binding in any way. Lenders
        provide loans with a range of APRs depending on borrowers' credit and
        other factors. Keep in mind that only borrowers with excellent credit
        will qualify for the lowest rate available. Your actual APR will depend
        on factors like credit score, requested loan amount, loan term, and
        credit history. All loans are subject to credit review and approval. (2)
        Some of these lenders do a soft pull on your credit, while others may
        give you an estimate based on verifying your identity. (3) Includes
        autopay and loyalty discounts when applicable.
      </p>
    </div>
  );

  return (
    <ExpandableMessage
      text="Advertiser Disclosure and Disclaimers"
      content={disclaimerContent}
    ></ExpandableMessage>
  );
};

export default LenderDisclaimerMessage;
