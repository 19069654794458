import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import Logo from "../Logo/Logo";
import ROUTES, { ADMIN_ROUTES } from "../../constants/routes";
import { MenuItem } from "./MenuItems";

import { hideSideMenuNavbar } from "./menuHelpers";
import { USER_ROLES } from "../../constants/constants";

export const ADMIN_MENU_LIST = [
  {
    text: "Users",
    to: ADMIN_ROUTES.ALL_USERS,
  },
  {
    text: "Admin Tools",
    to: ADMIN_ROUTES.TOOLS,
  },
  {
    text: "Challenges",
    to: ADMIN_ROUTES.CHALLENGES,
  },
  {
    text: "Communities",
    to: ADMIN_ROUTES.COMMUNITIES,
  },
  {
    text: "Feed Items",
    to: ADMIN_ROUTES.FEED_ITEMS,
  },
  {
    text: "Courses",
    to: ADMIN_ROUTES.COURSES,
  },
  {
    text: "Polls",
    to: ADMIN_ROUTES.POLLS,
  },
];

export const COACH_MENU_LIST = [
  {
    text: "Users",
    to: ADMIN_ROUTES.ALL_USERS,
  },
];

export const SUPPORT_MENU_LIST = [
  {
    text: "Users",
    to: ADMIN_ROUTES.ALL_USERS,
  },
  {
    text: "Challenges",
    to: ADMIN_ROUTES.CHALLENGES,
  },
  {
    text: "Communities",
    to: ADMIN_ROUTES.COMMUNITIES,
  },
  {
    text: "Feed Items",
    to: ADMIN_ROUTES.FEED_ITEMS,
  },
  {
    text: "Courses",
    to: ADMIN_ROUTES.COURSES,
  },
  {
    text: "Polls",
    to: ADMIN_ROUTES.POLLS,
  },
];

const getMenuItems = (userRoles) => {
  if (userRoles.includes(USER_ROLES.coach)) {
    return COACH_MENU_LIST;
  }

  if (userRoles.includes(USER_ROLES.admin)) {
    return ADMIN_MENU_LIST;
  }

  if (userRoles.includes(USER_ROLES.support)) {
    return SUPPORT_MENU_LIST;
  }

  return [];
};

const AdminSideMenu = () => {
  const { userRoles } = useSelector((state) => {
    return state.auth;
  });

  const menuItems = getMenuItems(userRoles);

  return (
    <nav className="sw-layout-side-nav sw-layout-side-nav--is-admin">
      <aside className="sw-side-menu">
        <NavLink
          className="sw-side-menu__logo"
          to={ROUTES.HOME}
          onClick={hideSideMenuNavbar}
        >
          <Logo altText="Snowball Wealth - Go Home" />
        </NavLink>

        <ul className="sw-side-menu__items">
          {menuItems.map((menuObj, index) => {
            return (
              <MenuItem
                key={index}
                text={menuObj.text}
                to={menuObj.to}
                iconSrc={menuObj.iconSrc}
                className={menuObj.className}
                isNew={menuObj.isNew ? true : false}
                onClickFn={hideSideMenuNavbar}
              ></MenuItem>
            );
          })}
        </ul>
      </aside>
    </nav>
  );
};

export default AdminSideMenu;
