import { AUTH_ACTIONS } from "./authReducer";

const initState = {
  userStats: null,
  userLoans: {},
  userInsights: {},
  userPlaidItems: {},
  calculatorResults: null,
  adminError: null,
  userProfiles: {},
  badgesInfo: {},
  usersList: null,
  userProfileLoading: null,
  getUsersLoading: null,
  getUserItemsLoading: false,
  updateUserLoansLoading: false,
  updateUserItemsLoading: false,
  updateAllLoansLoading: false,
  updateAllLoansResults: null,
  isLoading: false, // generic loaind for generic requets
};

const ADMIN_PREFIX = "ADMIN/";

export const ADMIN_ACTIONS = {
  ERROR: ADMIN_PREFIX + "ERROR",
  GET_USERS: ADMIN_PREFIX + "GET_USERS",
  GET_USERS_SUCCESS: ADMIN_PREFIX + "GET_USERS_SUCCESS",
  GET_USER_PROFILE: ADMIN_PREFIX + "GET_USER_PROFILE",
  GET_USER_PROFILE_SUCCESS: ADMIN_PREFIX + "GET_USER_PROFILE_SUCCESS",
  GET_USER_BADGES_SUCCESS: ADMIN_PREFIX + "GET_USER_BADGES_SUCCESS",
  UDPATE_USER_LOANS: ADMIN_PREFIX + "POST_UPDATE_LOANS",
  UDPATE_USER_LOANS_SUCCESS: ADMIN_PREFIX + "POST_UPDATE_LOANS_SUCCESS",
  UDPATE_USER_LOANS_ERROR: ADMIN_PREFIX + "POST_UPDATE_LOANS_ERROR",
  GET_USER_ITEMS: ADMIN_PREFIX + "GET_USER_ITEMS",
  GET_USER_ITEMS_SUCCESS: ADMIN_PREFIX + "GET_USER_ITEMS_SUCCESS",
  GET_USER_ITEMS_ERROR: ADMIN_PREFIX + "GET_USER_ITEMS_ERROR",
  UPDATE_USER_ITEMS: ADMIN_PREFIX + "UPDATE_USER_ITEMS",
  UPDATE_USER_ITEMS_SUCCESS: ADMIN_PREFIX + "UPDATE_USER_ITEMS_SUCCESS",
  UPDATE_USER_ITEMS_ERROR: ADMIN_PREFIX + "UPDATE_USER_ITEMS_ERROR",
  DELETE_USER_ITEM: ADMIN_PREFIX + "DELETE_USER_ITEM",
  DELETE_USER_ITEM_SUCCESS: ADMIN_PREFIX + "DELETE_USER_ITEM_SUCCESS",
  DELETE_USER_ITEM_ERROR: ADMIN_PREFIX + "DELETE_USER_ITEM_ERROR",
  DELETE_USER: ADMIN_PREFIX + "DELETE_USER",
  DELETE_USER_SUCCESS: ADMIN_PREFIX + "DELETE_USER_SUCCESS",
  DELETE_USER_ERROR: ADMIN_PREFIX + "ACTION_ERROR",
  UPDATE_ALL_LOANS: ADMIN_PREFIX + "UPDATE_ALL_LOANS",
  UPDATE_ALL_LOANS_SUCCESS: ADMIN_PREFIX + "UPDATE_ALL_LOANS_SUCCESS",
  GET_STATS: ADMIN_PREFIX + "GET_STATS",
  GET_STATS_SUCCESS: ADMIN_PREFIX + "GET_STATS_SUCCESS",
};

const adminReducer = (state = initState, action) => {
  let userId = null;
  if (action.payload && action.payload.userId) {
    userId = action.payload.userId;
  }
  let newUserLoans = {};
  let newPlaidItems = {};

  switch (action.type) {
    case "ADMIN_GET_USER_LOANS":
      // update user loans with response data for this user
      newUserLoans[userId] = action.payload.res.data.data;
      return {
        ...state,
        userLoans: newUserLoans,
        adminError: null,
      };
    case ADMIN_ACTIONS.UDPATE_USER_LOANS:
      return {
        ...state,
        updateUserLoansLoading: true,
      };
    case ADMIN_ACTIONS.UDPATE_USER_LOANS_SUCCESS:
      // update user loans with response data for this user
      newUserLoans[userId] = action.payload.res.data.data;
      return {
        ...state,
        updateUserLoansLoading: false,
        userLoans: newUserLoans,
        adminError: null,
      };
    case ADMIN_ACTIONS.UDPATE_USER_LOANS_ERROR:
      return {
        ...state,
        updateUserLoansLoading: false,
        adminError: action.err.message,
      };
    case ADMIN_ACTIONS.GET_USER_ITEMS:
      return {
        ...state,
        getUserItemsLoading: true,
      };
    case ADMIN_ACTIONS.GET_USER_ITEMS_SUCCESS:
      newPlaidItems[userId] = action.payload.res.data.accounts;
      return {
        ...state,
        userPlaidItems: newPlaidItems,
        getUserItemsLoading: false,
        adminError: null,
      };
    case ADMIN_ACTIONS.GET_USER_ITEMS_ERROR:
      return {
        ...state,
        getUserItemsLoading: false,
        adminError: action.err.message,
      };
    case ADMIN_ACTIONS.UPDATE_USER_ITEMS:
      return {
        ...state,
        updateUserItemsLoading: true,
      };

    case ADMIN_ACTIONS.UPDATE_USER_ITEMS_SUCCESS:
      newPlaidItems[userId] = action.payload.res.data.accounts;
      return {
        ...state,
        userPlaidItems: newPlaidItems,
        updateUserItemsLoading: false,
        adminError: null,
      };
    case ADMIN_ACTIONS.UPDATE_USER_ITEMS_ERROR:
      return {
        ...state,
        updateUserItemsLoading: false,
        adminError: action.err.message,
      };
    case ADMIN_ACTIONS.DELETE_USER:
    case ADMIN_ACTIONS.DELETE_USER_ITEM:
      return {
        ...state,
        isLoading: true,
      };
    case ADMIN_ACTIONS.DELETE_USER_SUCCESS:
    case ADMIN_ACTIONS.DELETE_USER_ITEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case "CALCULATION_UPDATE":
      return {
        ...state,
        calculatorResults: action.res.data.payoff_calculations,
      };
    case ADMIN_ACTIONS.GET_USERS:
      return {
        ...state,
        getUsersLoading: true,
      };
    case ADMIN_ACTIONS.GET_USERS_SUCCESS:
      return {
        ...state,
        usersList: action.res.data.data.users,
        getUsersLoading: false,
      };
    case ADMIN_ACTIONS.GET_USER_PROFILE:
      return {
        ...state,
        userProfileLoading: true,
      };
    case ADMIN_ACTIONS.GET_USER_PROFILE_SUCCESS:
      // update user profile with response data for this user
      let newUserProfiles = {};
      newUserProfiles[action.payload.userId] =
        action.payload.res.data.data.profile;

      return {
        ...state,
        userProfiles: newUserProfiles,
        userProfileLoading: false,
      };
    case ADMIN_ACTIONS.GET_USER_BADGES_SUCCESS:
      let newBadgesInfo = {};
      newBadgesInfo[action.payload.userId] =
        action.payload.res.data.data.badges_info;
      return {
        ...state,
        badgesInfo: newBadgesInfo,
      };
    case ADMIN_ACTIONS.DELETE_USER_ITEM_ERROR:
    case ADMIN_ACTIONS.DELETE_ERROR:
    case ADMIN_ACTIONS.ERROR:
      return {
        ...state,
        adminError: action.err.message,
        isLoading: false,
      };
    case ADMIN_ACTIONS.UPDATE_ALL_LOANS:
      return {
        ...state,
        updateAllLoansLoading: true,
        updateAllLoansResults: null,
      };
    case ADMIN_ACTIONS.UPDATE_ALL_LOANS_SUCCESS:
      return {
        ...state,
        updateAllLoansLoading: false,
        updateAllLoansResults: action.res.data,
      };
    case ADMIN_ACTIONS.GET_STATS:
      return {
        ...state,
        isLoading: true,
      };
    case ADMIN_ACTIONS.GET_STATS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userStats: action.res.data,
      };
    case AUTH_ACTIONS.SIGNOUT:
      return initState; //Always return the initial state
    default:
      return state;
  }
};

export default adminReducer;
