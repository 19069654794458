import React from "react";
import { useLocation, Navigate } from "react-router-dom";

import ROUTES from "../../constants/routes";

const RedirectPage = () => {
  let { pathname } = useLocation();
  const NEW_PATH = pathname.replace(ROUTES.APP_REDIRECT, "");

  // Remore redirect from the url and take them to the desired page
  return <Navigate to={NEW_PATH} replace />;
};

export default RedirectPage;
