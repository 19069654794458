import React from "react";

import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
} from "recharts";

import { NW_CHART_BUTTONS } from "../../constants/constants";
import helpers from "../../services/helpers";

const formatMoneyRoundedInKs = (value) => {
  return helpers.currencyFormatInKs(value, true);
};

const formatMoney = (value) => {
  return helpers.currencyFormat(value, 0);
};

const tooltipFormatter = (value, name, props) => {
  let formattedValue = "";
  if (helpers.notNullnotUndefined(value)) {
    formattedValue = formatMoney(value);
  }
  return [formattedValue];
};

const getStartTimestamp = (timeRange, datesObj) => {
  switch (timeRange) {
    case NW_CHART_BUTTONS.M_ONE:
      return datesObj.one_month;
    case NW_CHART_BUTTONS.M_THREE:
      return datesObj.three_month;
    case NW_CHART_BUTTONS.M_SIX:
      return datesObj.six_month;
    case NW_CHART_BUTTONS.Y_ONE:
      return datesObj.one_year;
    default:
      // Case ALL, and default. Set start to earliest date in data
      return datesObj.all_time;
  }
};

const StudentLoansHistoryChart = ({ snapshotData, timeRange }) => {
  if (!snapshotData || snapshotData.snapshots.length < 1) {
    return <div>Add accounts</div>;
  }
  const snapshotDataArray = snapshotData.snapshots;
  const startDate = getStartTimestamp(timeRange, snapshotData.dates);
  const xDomain = [() => startDate, "auto"];

  return (
    <div className="sw-student-loans-history-chart fs-block">
      <ResponsiveContainer width="100%" height="100%" className="fs-block">
        <BarChart data={snapshotDataArray}>
          <CartesianGrid
            strokeDasharray="3 3"
            vertical={false}
            horizontal={true}
          />
          <YAxis
            tickFormatter={formatMoneyRoundedInKs}
            width={80}
            tick={{ fontSize: 12 }}
            axisLine={false}
            domain={[0, "auto"]}
          />
          <XAxis
            dataKey="timestamp"
            tickFormatter={helpers.getDateFromTimestamp}
            type="number"
            domain={xDomain}
            // scale="time"
            // interval="preserveEnd"
            scale="utc"
            tickLine={false}
            tick={{ fontSize: 12 }}
            axisLine={false}
            padding={{ left: 15, right: 15 }}
          />
          <Tooltip
            formatter={tooltipFormatter}
            labelFormatter={helpers.getDateFromTimestamp}
          />
          <Bar
            dataKey="debts"
            fill="#357887"
            barSize={14}
            radius={[10, 10, 10, 10]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StudentLoansHistoryChart;
