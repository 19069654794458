import React from "react";

import { NW_CHART_BUTTONS, NW_TABS } from "../../constants/constants";

const NetWorthChartButtons = ({
  selectedButton,
  setSelectedButton,
  activeTab,
}) => {
  let defaultButtonClass =
    "sw-net-worth-buttons__button button is-small is-inverted";
  let selectedButtonClass = "sw-net-worth-buttons__button button is-small";

  if (activeTab === NW_TABS.DEBT) {
    defaultButtonClass += " is-debt";
    selectedButtonClass += " is-debt";
  } else if (activeTab === NW_TABS.ASSETS) {
    defaultButtonClass += " is-assets";
    selectedButtonClass += " is-assets";
  } else {
    defaultButtonClass += " is-primary";
    selectedButtonClass += " is-primary";
  }

  return (
    <div className="sw-net-worth-buttons buttons">
      <ul className="buttons">
        <li>
          <button
            className={
              selectedButton === NW_CHART_BUTTONS.ALL
                ? selectedButtonClass
                : defaultButtonClass
            }
            onClick={() => setSelectedButton(NW_CHART_BUTTONS.ALL)}
          >
            ALL
          </button>
        </li>
        <li>
          <button
            className={
              selectedButton === NW_CHART_BUTTONS.M_ONE
                ? selectedButtonClass
                : defaultButtonClass
            }
            onClick={() => setSelectedButton(NW_CHART_BUTTONS.M_ONE)}
          >
            1M
          </button>
        </li>
        <li>
          <button
            className={
              selectedButton === NW_CHART_BUTTONS.M_THREE
                ? selectedButtonClass
                : defaultButtonClass
            }
            onClick={() => setSelectedButton(NW_CHART_BUTTONS.M_THREE)}
          >
            3M
          </button>
        </li>
        <li>
          <button
            className={
              selectedButton === NW_CHART_BUTTONS.M_SIX
                ? selectedButtonClass
                : defaultButtonClass
            }
            onClick={() => setSelectedButton(NW_CHART_BUTTONS.M_SIX)}
          >
            6M
          </button>
        </li>
        <li>
          <button
            className={
              selectedButton === NW_CHART_BUTTONS.Y_ONE
                ? selectedButtonClass
                : defaultButtonClass
            }
            onClick={() => setSelectedButton(NW_CHART_BUTTONS.Y_ONE)}
          >
            1Y
          </button>
        </li>
      </ul>
    </div>
  );
};

export default NetWorthChartButtons;
