import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className="sw-flex-layout--column-center">
      <h1 className="title has-text-centered sw-container--fixed-width">
        <span role="img" aria-label="Face with hand over mouth emoji">
          🤭
        </span>{" "}
        This is not the page you were looking for...
      </h1>
      <Link to="/">Return to Home Page</Link>
    </div>
  );
};

export default NotFoundPage;
