import React from "react";
import { connect } from "react-redux";

import { trackEvent, EVENTS } from "../../store/actions/analyticsActions";

const LinkPreview = (props) => {
  const postLink = props.link;
  const image = props.image;
  const title = props.title;
  const imageAlt = props.imageAlt;
  const name = props.name;

  return (
    <div className="tile is-child sw-blog-preview">
      <a
        className="content"
        href={postLink}
        onClick={() =>
          props.trackEvent(EVENTS.RESOURCE_TEMPLATE_CLICKED, {
            link: postLink,
            name: name,
          })
        }
      >
        {image && (
          <div>
            <img src={image} alt={imageAlt} />
          </div>
        )}
        <div className="is-size-6-desktop sw-blog-subtitle">{title}</div>
      </a>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackEvent: (eventName, properties) =>
      dispatch(trackEvent(eventName, properties)),
  };
};

export default connect(null, mapDispatchToProps)(LinkPreview);
