import React from "react";
import { Link } from "react-router-dom";

import { ADMIN_ROUTES } from "../../constants/routes";

const AdminCoursesList = ({ courses }) => {
  return (
    <div>
      <table className="table is-hoverable is-fullwidth fs-block">
        <thead>
          <tr>
            <th></th>
            <th>Reference Name</th>
            <th>Slug</th>
            <th>Type</th>
            <th>Access</th>
            <th>Linked Community UUID</th>
            <th>Enrolled</th>
            <th>Tasks</th>
            <th>Cohorts</th>
            <th>Active</th>
          </tr>
        </thead>
        <tbody>
          {courses.map((course) => {
            return (
              <tr key={course.course_uuid}>
                <td>
                  <Link
                    state={course}
                    to={`${ADMIN_ROUTES.COURSES}/${course.course_uuid}`}
                  >
                    Edit
                  </Link>
                </td>
                <td>{course.reference_name}</td>
                <td>{course.slug}</td>
                <td>{course.course_type}</td>
                <td>{course.course_access}</td>
                <td>{course.linked_community_uuid}</td>
                <td>{course.total_enrolled_count}</td>
                <td>{course.num_tasks}</td>
                <td>{course.cohorts ? course.cohorts.length : 0}</td>
                <td>{course.is_active ? "True" : "False"}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AdminCoursesList;
