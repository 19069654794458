import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ROUTES from "../../constants/routes";
import { GRAPHICS } from "../../constants/images";
import { trackEvent, EVENTS } from "../../store/actions/analyticsActions";
import useStateWithLocalStorage from "../../services/useStateWithLocalStorage";

const PremiumWaitlist = () => {
  const dispatch = useDispatch();
  const NOTIFY_ME_KEY_LS = "sw_premium_waitlist";
  const [notifyMeValue, setValue] = useStateWithLocalStorage(NOTIFY_ME_KEY_LS);

  useEffect(() => {
    dispatch(trackEvent(EVENTS.PREMIUM_WAITLIST_VIEWED));
  }, [dispatch]);

  const onNotifyMe = (event) => {
    dispatch(trackEvent(EVENTS.PREMIUM_WAITLIST_CLICKED));
    setValue("true");
  };

  const navigate = useNavigate();

  const { hasPremium } = useSelector((state) => {
    return state.auth;
  });

  if (hasPremium) {
    navigate(ROUTES.PREMIUM);
  }

  return (
    <section className="hero is-halfheight">
      <div className="hero-body">
        <div className="columns reverse-columns">
          <div className="column">
            <h1 className="title is-size-2">Introducing Snowball Premium</h1>
            <h2 className="subtitle has-text-weight-normal">
              Work with a Certified Financial Planner to make a long-lasting
              shift in your money mindset and habits
            </h2>
            {notifyMeValue === "true" ? (
              <p>You're on the waitlist!</p>
            ) : (
              <button
                className="button is-rounded is-dark sw-is-wide"
                onClick={onNotifyMe}
              >
                Join waitlist
              </button>
            )}
          </div>
          <div className="column">
            <img
              src={GRAPHICS.PREMIUM_GRAPHIC}
              alt="pie chart example of loan breakdown"
              width="428"
              height="428"
            />
          </div>
        </div>
      </div>
      <div className="sw-layout-container--no-padding no-margin-bottom is-background-blue">
        <ul>
          <li>
            <p className="subtitle sw-unset-margin">Meet your Coach (CFP)</p>
            <p className="has-text-black sw-padding--top-md">
              Work with an experienced Certified Financial Planner during 1:1
              sessions. You'll get organized on your finances, talk about
              financial money habits, and assess your options for paying down
              debt or building wealth.
            </p>
          </li>
          <li className="sw-padding--top-lg">
            <p className="subtitle sw-unset-margin">Get a Financial Plan</p>
            <p className="has-text-black sw-padding--top-md">
              Build a financial roadmap that works for you! Snowball coaches are
              here to help you make tangible progress in your finances.
            </p>
          </li>
          <li className="sw-padding--top-lg">
            <p className="subtitle sw-unset-margin">
              Access to exclusive community, events, and workshops
            </p>
            <p className="has-text-black sw-padding--top-md">
              You'll have plenty of support and join like-minded individuals in
              making progress through our workshops, events, and community.
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default PremiumWaitlist;
