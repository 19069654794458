import React from "react";

import { ACCOUNT_SUBTYPES } from "../../constants/constants";
import StudentLoanDetails from "./StudentLoanDetails";
import CreditDetails from "./CreditDetails";
import MortgageDetails from "./MortgageDetails";

const AccountDetailsContainer = ({ subtype, loanInfo }) => {
  const currentLoanDetails = loanInfo.account_details;
  const currentLoanAccount = loanInfo;
  const institutionName = loanInfo.name;

  return (
    <div>
      {subtype === ACCOUNT_SUBTYPES.STUDENT && (
        <StudentLoanDetails
          name={currentLoanAccount.name}
          loanStatus={
            currentLoanDetails.loan_status
              ? currentLoanDetails.loan_status.type
              : ""
          }
          originalLoanAmount={currentLoanDetails.origination_principal_amount}
          disbursementDates={currentLoanDetails.disbursement_dates}
          interestRate={currentLoanDetails.interest_rate_percentage}
          servicer={institutionName}
          paymentPlan={currentLoanDetails.repayment_plan.type}
          minimumPayment={currentLoanDetails.minimum_payment_amount}
          lastPayment={currentLoanDetails.last_payment_amount}
          nextPayment={currentLoanDetails.next_payment_due_date}
          isoCurrencyCode={currentLoanAccount.iso_currency_code}
          guarantor={currentLoanDetails.guarantor}
        />
      )}
      {subtype === ACCOUNT_SUBTYPES.CREDIT && (
        <CreditDetails loanInfo={loanInfo}></CreditDetails>
      )}
      {subtype === ACCOUNT_SUBTYPES.MORTGAGE && (
        <MortgageDetails loanInfo={loanInfo}></MortgageDetails>
      )}
    </div>
  );
};

export default AccountDetailsContainer;
