import { AUTH_ACTIONS } from "./authReducer";

const initState = {
  polls: null,
  newPoll: null,
  getPollsLoading: false,
  createPollLoading: false,
  editPollLoading: false,
  adminError: null,
};

const ADMIN_PREFIX = "ADMIN/";

export const ADMIN_POLL_ACTIONS = {
  CREATE_POLL: ADMIN_PREFIX + "CREATE_POLL",
  CREATE_POLL_SUCCESS: ADMIN_PREFIX + "CREATE_POLL_SUCCESS",
  CREATE_POLL_ERROR: ADMIN_PREFIX + "CREATE_POLL_ERROR",
  GET_POLLS: ADMIN_PREFIX + "GET_POLLS",
  GET_POLLS_SUCCESS: ADMIN_PREFIX + "GET_POLLS_SUCCESS",
  GET_POLLS_ERROR: ADMIN_PREFIX + "GET_POLLS_ERROR",
  EDIT_POLL: ADMIN_PREFIX + "EDIT_POLL",
  EDIT_POLL_SUCCESS: ADMIN_PREFIX + "EDIT_POLL_SUCCESS",
  EDIT_POLL_ERROR: ADMIN_PREFIX + "EDIT_POLL_ERROR",
};

const adminPollsReducer = (state = initState, action) => {
  switch (action.type) {
    case ADMIN_POLL_ACTIONS.CREATE_POLL:
      return {
        ...state,
        adminError: null,
        newPoll: null,
        createPollLoading: true,
      };
    case ADMIN_POLL_ACTIONS.CREATE_POLL_SUCCESS:
      return {
        ...state,
        newPoll: action.payload.res.data,
        createPollLoading: false,
      };
    case ADMIN_POLL_ACTIONS.CREATE_POLL_ERROR:
      return {
        ...state,
        adminError: action.err.message,
        createPollLoading: false,
      };
    case ADMIN_POLL_ACTIONS.GET_POLLS:
      return {
        ...state,
        adminError: null,
        polls: null,
        getPollsLoading: true,
      };
    case ADMIN_POLL_ACTIONS.GET_POLLS_SUCCESS:
      return {
        ...state,
        polls: action.res.data.polls,
        getPollsLoading: false,
      };
    case ADMIN_POLL_ACTIONS.GET_POLLS_ERROR:
      return {
        ...state,
        adminError: action.err.message,
        getPollsLoading: false,
      };
    case ADMIN_POLL_ACTIONS.EDIT_POLL:
      return {
        ...state,
        adminError: null,
        editPollsLoading: true,
      };
    case ADMIN_POLL_ACTIONS.EDIT_POLL_SUCCESS:
      return {
        ...state,
        editPollsLoading: false,
      };
    case ADMIN_POLL_ACTIONS.EDIT_POLL_ERROR:
      return {
        ...state,
        adminError: action.err.message,
        editPollLoading: false,
      };

    case AUTH_ACTIONS.SIGNOUT:
      return initState; //Always return the initial state
    default:
      return state;
  }
};

export default adminPollsReducer;
