import React from "react";
import { Link } from "react-router-dom";

const UsersList = (props) => {
  return (
    <div>
      <table className="table is-hoverable is-fullwidth fs-block">
        <thead>
          <tr>
            <th>Details</th>
            <th>ID</th>
            <th>Name</th>
            <th>Username</th>
            <th>Email</th>
            <th>Has premium</th>
            <th>Roles</th>
          </tr>
        </thead>
        <tbody>
          {props.users.map((user) => {
            return (
              <tr key={user.id}>
                <td>
                  <Link to={`/admin/user/${user.id}`}>See User</Link>
                </td>
                <td>{user.id}</td>
                <td>{user.first_name}</td>
                <td>{user.display_name}</td>
                <td>{user.email}</td>
                <td>{String(user.has_premium)}</td>
                <td>{String(user.roles)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default UsersList;
