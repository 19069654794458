import React from "react";

export const RecommendedTag = (props) => {
  return <div className="sw-box-tag--upper-left">Recommended</div>;
};

export const MatchedLenderTag = (props) => {
  return <div className="sw-box-tag--incorner is-primary">For you</div>;
};

export const BasicTag = ({ children }) => {
  return (
    <div className="sw-box-tag-container">
      <div className="sw-box-tag--basic">{children}</div>
    </div>
  );
};

export const CovidTag = () => {
  return (
    <div className="sw-box-tag-container">
      <div className="sw-box-tag--covid">COVID relief</div>
    </div>
  );
};

export const StudentLoanTag = () => {
  return (
    <div className="sw-box-tag-container">
      <div className="sw-box-tag--student">Student loan</div>
    </div>
  );
};

export const CreditTag = () => {
  return (
    <div className="sw-box-tag-container">
      <div className="sw-box-tag--credit">Credit</div>
    </div>
  );
};

export const MortgageTag = () => {
  return (
    <div className="sw-box-tag-container">
      <div className="sw-box-tag--mortgage">Mortgage</div>
    </div>
  );
};

export const ManualEntryTag = () => {
  return (
    <div className="sw-box-tag-container">
      <div className="sw-box-tag--mortgage">Manual entry</div>
    </div>
  );
};

export const PremiumTag = () => {
  return (
    <div className="sw-box-tag-container">
      <div className="sw-box-tag--premium">
        <span className="level">
          <span className="level-item icon">
            <i className="fa fa-lock"></i>
          </span>{" "}
          <span className="level-item">Premium</span>
        </span>
      </div>
    </div>
  );
};
