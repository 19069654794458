import React from "react";
import { REC_TYPES } from "../../constants/constants";

const RecIcon = (props) => {
  let classNameString = "sw-rec-emoji";

  let emoji = (
    <span role="img" aria-label="Waving Hand Emoji">
      💰
    </span>
  );

  switch (props.recType) {
    case REC_TYPES.INCREASE_MODERATE:
    case REC_TYPES.REPAYMENT_TWENTYFIVE:
    case REC_TYPES.COVID_POST_INCREASE:
      classNameString += " is-yellow";
      break;
    case REC_TYPES.LOWER_INTEREST:
    case REC_TYPES.LOWER_INTEREST_REACH:
    case REC_TYPES.REPAYMENT_LOWER_INTEREST:
    case REC_TYPES.COVID_POST_LOWER_INTEREST:
      classNameString += " is-pink";
      emoji = (
        <span role="img" aria-label="Waving Hand Emoji">
          📉
        </span>
      );
      break;
    case REC_TYPES.INCREASE_REACH:
    case REC_TYPES.REPAYMENT_TEN:
    case REC_TYPES.COVID_PAY:
      classNameString += " is-turquoise";
      emoji = (
        <span role="img" aria-label="Waving Hand Emoji">
          🚀
        </span>
      );
      break;
    case REC_TYPES.INTEREST_GOOD:
      classNameString += " is-pink";
      emoji = (
        <span role="img" aria-label="OK Sign Hand Emoji">
          👌
        </span>
      );
      break;
    case REC_TYPES.REPAYMENT_INCOME_BASED:
      emoji = (
        <span role="img" aria-label="Person Tipping Hand Emoji">
          💁
        </span>
      );
      break;
    case REC_TYPES.PERSONAL_DEBT:
      classNameString += " is-turquoise";
      emoji = (
        <span role="img" aria-label="Credit Card Emoji">
          💳
        </span>
      );
      break;
    case REC_TYPES.FEDERAL_OVERVIEW:
      classNameString += " is-turquoise";
      emoji = (
        <span role="img" aria-label="Credit Card Emoji">
          📜
        </span>
      );
      break;
    case REC_TYPES.PRIVATE_OVERVIEW:
      classNameString += " is-turquoise";
      emoji = (
        <span role="img" aria-label="Bank Emoji">
          🏦
        </span>
      );
      break;
    default:
      break;
  }

  return (
    <figure className={classNameString} width="50px" height="50px">
      {emoji}
    </figure>
  );
};

export default RecIcon;
