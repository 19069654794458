import React from "react";

import helpers from "../../services/helpers";
import { CovidTag, StudentLoanTag, MortgageTag, CreditTag } from "../Box/Tags";
import { ACCOUNT_SUBTYPES } from "../../constants/constants";

const TypeTag = ({ subtype }) => {
  if (subtype === ACCOUNT_SUBTYPES.STUDENT) {
    return <StudentLoanTag></StudentLoanTag>;
  } else if (subtype === ACCOUNT_SUBTYPES.CREDIT) {
    return <CreditTag></CreditTag>;
  } else if (subtype === ACCOUNT_SUBTYPES.MORTGAGE) {
    return <MortgageTag></MortgageTag>;
  }

  return <div></div>;
};

const AccountHeader = (props) => {
  return (
    <div className="box is-vertical">
      <div className="">
        <p className="has-text-centered	sw-padding--bottom-lg fs-block">
          {props.subtitle}
        </p>
        <p className="has-text-centered has-text-weight-bold is-size-4 fs-block">
          {helpers.currencyFormat(props.totalAmount, 2, props.isoCurrencyCode)}
        </p>
        <p className="has-text-centered has-text-grey is-size-7 sw-padding--bottom-lg">
          Current Balance
        </p>
      </div>

      <div className="box-footer is-justify-content-center">
        <TypeTag subtype={props.subtype}></TypeTag>
        {props.hasCovidRelief && (
          <div className="sw-padding--left-md">
            <CovidTag></CovidTag>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountHeader;
