import ROUTES from "../../constants/routes";

const BRANCH_KEY = process.env.REACT_APP_BRANCH_KEY;

const BRANCH_ALIASES = {
  challengeDetails: "challenge_details",
  userProfile: "user_profile",
  community: "community",
  premium: "premium",
  communityItem: "community_item",
  courseDetails: "course_details",
  enrollCourseDetails: "enroll_course_details",
};

// const baseBranchDeepLinkUrl = "snowballwealth.app.link";

export const getBranchData = () => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      window.branch.data(BRANCH_KEY, {}, (error, data) => {
        if (error !== null) {
          // TODO: handle error
          reject(error);
        } else {
          let redirectPath = "";

          if (data?.data_parsed?.$desktop_deeplink_path) {
            const branchPath = data.data_parsed.$desktop_deeplink_path;

            // Check if branch path is community_item
            if (branchPath === BRANCH_ALIASES.communityItem) {
              if (data.data_parsed.item_uuid) {
                const feedItemUuid = data.data_parsed.item_uuid;
                //${ROUTES.COMMUNITY_POST}/:feedItemUuid`
                redirectPath = `${ROUTES.COMMUNITY_POST}/${feedItemUuid}`;
              }
            }

            // Check if branch path is a cohort
            if (branchPath === BRANCH_ALIASES.courseDetails) {
              if (data.data_parsed.cohort_uuid) {
                const cohortUuid = data.data_parsed.cohort_uuid;
                //`${ROUTES.COURSES}/:cohortUuid`
                redirectPath = `${ROUTES.COURSES}/${cohortUuid}`;
              }
            }
            if (branchPath === BRANCH_ALIASES.enrollCourseDetails) {
              if (data.data_parsed.cohort_uuid) {
                const cohortUuid = data.data_parsed.cohort_uuid;
                //`${ROUTES.COURSES}/:cohortUuid`
                redirectPath = `${ROUTES.COURSES}/${cohortUuid}?shouldEnroll=true`;
              }
            }
          }

          resolve(redirectPath);
        }
      });
    });
  };
};

// export const createBranchLink = (branchIdentifier, userId, queryParams) => {
//   return (dispatch, getState) => {
//     return new Promise(function (resolve, reject) {
//       const linkData = {
//         campaign: "content 123",
//         channel: "facebook",
//         feature: "dashboard",
//         stage: "new user",
//         tags: ["tag1", "tag2", "tag3"],
//         alias: "",
//         data: {
//           $og_title: "Title",
//           $og_description: "Description",
//           $og_image_url: "http://lorempixel.com/400/400",
//         },
//       };

//       window.branch.link(linkData, function (err, link) {
//         console.log(link);
//       });
//     });
//   };
// };
