import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { LeafPoll } from "react-leaf-polls";
import "react-leaf-polls/dist/index.css";

import { asyncPollVote } from "../../store/actions/communityActions";

// Object keys may vary on the poll type (see the 'Theme options' table below)
const customTheme = {
  textColor: "black",
  mainColor: "#D2D5FF",
  backgroundColor: "#F2F2F6",
  alignment: "start",
};

const formatPollData = (pollOptions) => {
  // Expected data format:
  // const testData = [
  //   { id: 0, text: "Answer part 1", votes: 3, percentage: null },
  //   { id: 1, text: "Answer part 2", votes: 1, percentage: null },
  //   { id: 2, text: "Answer part 3", votes: 0, percentage: null },
  // ];

  // we want to keep track of formatted data in a dict for easy reference
  let dataUuidMap = {};
  let votedId = null;

  const formattedData = pollOptions.map((option, index) => {
    // also save in map while iterating
    dataUuidMap[index] = option.poll_option_uuid;
    if (option.did_vote) {
      votedId = index;
    }

    return {
      id: index, // the id needs to be an int
      text: option.text,
      poll_option_uuid: option.poll_option_uuid,
      votes: option.vote_count,
    };
  });

  return [formattedData, dataUuidMap, votedId];
};

const Poll = ({ poll }) => {
  const dispatch = useDispatch();
  const [pollData, dataUuidMap, votedId] = useMemo(() => {
    return formatPollData(poll.poll_options);
  }, [poll]);

  const clickVote = (item, results) => {
    const votedUuid = dataUuidMap[item.id];
    dispatch(asyncPollVote([votedUuid]));
  };

  return (
    <div className="sw-poll">
      <LeafPoll
        type="multiple"
        question={poll.prompt}
        results={pollData}
        theme={customTheme}
        onVote={clickVote}
        isVoted={poll.did_vote}
        isVotedId={poll.did_vote ? votedId : null}
      />
    </div>
  );
};

export default Poll;
