import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useOutletContext } from "react-router-dom";

import AccountDetailsContainer from "../../components/AccountSummary/AccountDetailsContainer";
import AccountHeader from "../../components/AccountSummary/AccountHeader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { BackLink } from "../../components/Buttons/BackButtons";

import ROUTES from "../../constants/routes";
import { ACCOUNT_SUBTYPES, EMAILS } from "../../constants/constants";
import { ICONS } from "../../constants/images";
import { asyncGetNetWorthItems } from "../../store/actions/netWorthActions";
import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";

const SomethingWrongBox = () => {
  return (
    <div className="box is-vertical sw-flex-layout--column-center">
      <img src={ICONS.EMAIL_ICON} alt="email" width="40" height="40" />
      <p className="sw-padding--top-md">Is something incorrect?</p>
      <a href={`mailto:${EMAILS.SUPPORT}`}>Let us know</a>
    </div>
  );
};

const getAccountInfo = (liabilities, subtype, loanId) => {
  if (!liabilities || !subtype || !loanId) {
    return null;
  }

  let accountsList = [];
  if (subtype === ACCOUNT_SUBTYPES.STUDENT) {
    accountsList = liabilities.student.plaid_accounts;
  } else if (subtype === ACCOUNT_SUBTYPES.MORTGAGE) {
    accountsList = liabilities.mortgage.plaid_accounts;
  } else if (subtype === ACCOUNT_SUBTYPES.CREDIT) {
    accountsList = liabilities.credit.plaid_accounts;
  }

  for (var i = 0; i < accountsList.length; i++) {
    // note: convert to string so we can compare to loanId from params
    if (
      accountsList[i].account_details.account_id !== null &&
      accountsList[i].account_details.account_id.toString() === loanId
    ) {
      return accountsList[i];
    }
  }

  return null;
};

const LiabilityAccountPage = ({
  liabilities,
  asyncGetNetWorthItemsFn,
  trackEventFn,
}) => {
  const setPageTitle = useOutletContext();
  useEffect(() => {
    setPageTitle("Account details");
  }, [setPageTitle]);

  let { subtype, loanId } = useParams();

  // on page load, get net worth items for account info
  useEffect(() => {
    asyncGetNetWorthItemsFn();
  }, [asyncGetNetWorthItemsFn]);

  const loanInfo = getAccountInfo(liabilities, subtype, loanId);

  // track page
  useEffect(() => {
    if (loanInfo) {
      trackEventFn(EVENTS.ACCOUNT_DETAILS_VIEWED, {
        type: loanInfo.account_type,
        subtype: subtype,
      });
    }
  }, [trackEventFn, subtype, loanInfo]);

  if (loanInfo) {
    return (
      <div className="sw-flex-layout--column-center">
        <div className="sw-container--fixed-width sw-is-wide">
          <BackLink routeLink={ROUTES.NET_WORTH} text="Back"></BackLink>

          <div className="columns reverse-columns">
            <div className="column">
              <AccountDetailsContainer
                subtype={subtype}
                loanInfo={loanInfo}
              ></AccountDetailsContainer>
              <div className="is-hidden-tablet sw-padding--top-lg">
                <SomethingWrongBox></SomethingWrongBox>
              </div>
            </div>
            <div className="column is-4">
              <AccountHeader
                totalAmount={loanInfo.total}
                isoCurrencyCode={loanInfo.iso_currency_code}
                subtitle={loanInfo.display_name}
                hasCovidRelief={loanInfo.covid_relief}
                subtype={subtype}
              />
              <div className="is-hidden-mobile">
                <SomethingWrongBox></SomethingWrongBox>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="sw-container--fixed-width">
        <BackLink routeLink={ROUTES.NET_WORTH} text="Back"></BackLink>
        <LoadingSpinner></LoadingSpinner>
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    liabilities: state.netWorth.liabilities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    asyncGetNetWorthItemsFn: () => dispatch(asyncGetNetWorthItems()),
    trackEventFn: (event, properties) =>
      dispatch(trackEvent(event, properties)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiabilityAccountPage);
