import { AUTH_ACTIONS } from "./authReducer";

const initState = {
  courses: {
    active: [],
    available: [],
    completed: [],
  },
  course: null,
  tasks: null,
  taskMap: null,
};

const ACTION_PREFIX = "FINANCIAL_PLAN/";

export const COURSES_ACTIONS = {
  GET: ACTION_PREFIX + "GET",
  GET_SUCCESS: ACTION_PREFIX + "GET_SUCCESS",
  BROWSE: ACTION_PREFIX + "BROWSE",
  BROWSE_SUCCESS: ACTION_PREFIX + "BROWSE_SUCCESS",
  TASKS: ACTION_PREFIX + "TASKS",
  TASKS_SUCCESS: ACTION_PREFIX + "TASKS_SUCCESS",
  ENROLL: ACTION_PREFIX + "ENROLL",
  ENROLL_SUCCESS: ACTION_PREFIX + "ENROLL_SUCCESS",
  ERROR: ACTION_PREFIX + "ERROR",
};

const courseReducer = (state = initState, action) => {
  switch (action.type) {
    case COURSES_ACTIONS.BROWSE:
    case COURSES_ACTIONS.ENROLL:
      return {
        ...state,
        err: null,
        isLoading: true,
      };
    case COURSES_ACTIONS.GET:
      return {
        ...state,
        err: null,
        isLoading: true,
        course: null, // reset the course
      };
    case COURSES_ACTIONS.TASKS:
      return {
        ...state,
        err: null,
        isLoading: true,
        tasks: null, // reset tasks
        taskMap: null, // reset task map
      };
    case COURSES_ACTIONS.GET_SUCCESS:
      return {
        ...state,
        err: null,
        isLoading: false,
        course: action.course,
      };
    case COURSES_ACTIONS.TASKS_SUCCESS:
      // Create task map, this makes it easier to grab later
      let taskMap = {};
      if (action.tasks) {
        let allGroups = [];
        if (action.tasks.current_groupings) {
          allGroups = allGroups.concat(action.tasks.current_groupings);
        }
        if (action.tasks.past_groupings) {
          allGroups = allGroups.concat(action.tasks.past_groupings);
        }

        allGroups.map((taskGroup) => {
          taskGroup.tasks.map((taskObj) => {
            taskMap[`${taskObj.task.course_task_uuid}`] = taskObj;
          });
        });
      }

      return {
        ...state,
        err: null,
        isLoading: false,
        tasks: action.tasks,
        taskMap: taskMap,
      };
    case COURSES_ACTIONS.BROWSE_SUCCESS:
      return {
        ...state,
        courses: {
          active: action.courses.in_progress,
          available: action.courses.available,
          completed: action.courses.completed,
        },
        err: null,
        isLoading: false,
      };
    case COURSES_ACTIONS.ENROLL_SUCCESS:
      let newCourse = {
        ...state.course,
      };

      if (
        state.course &&
        state.course.cohort.cohort_uuid === action.progress.cohort_uuid
      ) {
        // Update existing course to include progress
        newCourse = {
          ...newCourse,
          course_progress: action.progress,
        };
      }

      return {
        ...state,
        err: null,
        isLoading: false,
        course: newCourse,
      };

    case AUTH_ACTIONS.SIGNOUT:
      return initState; //Always return the initial state
    default:
      return state;
  }
};

export default courseReducer;
