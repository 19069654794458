import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useFormFields } from "../../components/Form/useFormFields";
import {
  getAllCommunities,
  updateCommunity,
} from "../../store/actions/adminCommunitiesActions";
import { BackLink } from "../../components/Buttons/BackButtons";
import { ADMIN_ROUTES } from "../../constants/routes";

import AdminCommunityForm from "../../components/Communities/AdminCommunityForm";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

const DEFAULT_FIELDS = {
  title: "",
  isPrivate: false,
  isGlobal: false,
  description: "",
  imageUrl: "",
  thumbnailImageUrl: "",
};

const AdminEditCommunityPage = (props) => {
  const dispatch = useDispatch();

  let { communityId } = useParams();
  const {
    getCommunitiesLoading,
    editCommunityLoading,
    adminError,
    communities,
  } = useSelector((state) => {
    return state.adminCommunities;
  });

  useEffect(() => {
    dispatch(getAllCommunities());
  }, [dispatch]);

  const [fields, handleFieldChange, setValues] = useFormFields(DEFAULT_FIELDS);

  useEffect(() => {
    let thisCommunity = null;

    // check from communities array
    if (communities && communities.length > 0) {
      const filteredCommunities = communities.filter(
        (el) => el.community_uuid === communityId
      );
      thisCommunity = filteredCommunities[0];
    }

    if (thisCommunity) {
      const fields = {
        title: thisCommunity.name,
        isPrivate: thisCommunity.is_private,
        isGlobal: thisCommunity.is_global,
        description: thisCommunity.description || "",
        imageUrl: thisCommunity.image_url || "",
        thumbnailImageUrl: thisCommunity.thumbnail_image_url || "",
      };
      setValues(fields);
    }
  }, [setValues, communities, communityId]);

  const onSubmitForm = useCallback(
    async (event) => {
      event.preventDefault();
      await dispatch(updateCommunity(communityId, fields)).then((res) => {
        // navigate back to main
        // navigate(ADMIN_ROUTES.CHALLENGES);
      });
    },
    [fields, dispatch, communityId]
  );

  if (getCommunitiesLoading || editCommunityLoading) {
    return (
      <div>
        <LoadingSpinner></LoadingSpinner>
      </div>
    );
  }

  return (
    <div className="fs-block">
      <BackLink
        routeLink={ADMIN_ROUTES.COMMUNITIES}
        text={"Go back"}
      ></BackLink>

      <h1 className="title">Admin Communities</h1>
      <AdminCommunityForm
        onSubmitForm={onSubmitForm}
        fields={fields}
        handleFieldChange={handleFieldChange}
        submitLoading={editCommunityLoading}
      ></AdminCommunityForm>
      <br />
      {adminError && <div className="has-text-danger">Error: {adminError}</div>}
    </div>
  );
};

export default AdminEditCommunityPage;
