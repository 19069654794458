import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { useFormFields } from "../../components/Form/useFormFields";
import {
  asyncEditUniversalLinkAttachment,
  asyncDeleteUniversalLinkAttachment,
  asyncGetFeedItem,
} from "../../store/actions/adminCommunitiesActions";
import { BackLink } from "../../components/Buttons/BackButtons";
import { ADMIN_ROUTES } from "../../constants/routes";

import AdminUniversalLinkAttachmentForm from "../../components/Communities/AdminUniversalLinkAttachmentForm";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { UNIVERSAL_LINK_BASE_PATHS } from "../../constants/constants";

const DEFAULT_FIELDS = {
  feedItemUuid: "",
  basePath: UNIVERSAL_LINK_BASE_PATHS[0],
  displayText: "",
  pathExtension: "",
};

const AdminEditUniversalLinkAttachmentPage = (props) => {
  let { feedItemUuid } = useParams();
  const dispatch = useDispatch();
  const {
    editUniversalLinkAttachmentLoading,
    adminError,
    updatedFeedItemWithUniversalLink,
    feedItem,
    getFeedItemLoading,
  } = useSelector((state) => {
    return state.adminCommunities;
  });

  const [fields, handleFieldChange, setValues] = useFormFields(DEFAULT_FIELDS);

  const linkAttachment =
    feedItem == null ? null : feedItem.universal_link_attachment;

  useEffect(() => {
    if (linkAttachment == null) {
      setValues({
        ...DEFAULT_FIELDS,
        feedItemUuid: feedItemUuid,
      });
    } else {
      setValues({
        basePath: linkAttachment.base_path,
        displayText: linkAttachment.display_text,
        pathExtension: linkAttachment.path_extension ?? "",
        feedItemUuid: feedItemUuid,
      });
    }
  }, [setValues, updatedFeedItemWithUniversalLink, feedItem, linkAttachment]);

  useEffect(() => {
    dispatch(asyncGetFeedItem(feedItemUuid));
  }, [dispatch, asyncGetFeedItem]);

  const navigate = useNavigate();
  const onSubmitForm = useCallback(
    async (event) => {
      event.preventDefault();
      await dispatch(
        asyncEditUniversalLinkAttachment(
          fields.feedItemUuid,
          fields.basePath,
          fields.displayText,
          fields.pathExtension
        )
      ).then((res) => {
        // navigate back to main
        navigate(ADMIN_ROUTES.FEED_ITEMS);
      });
    },
    [fields, dispatch, navigate]
  );

  const onDelete = useCallback(
    async (event) => {
      event.preventDefault();
      await dispatch(
        asyncDeleteUniversalLinkAttachment(fields.feedItemUuid)
      ).then((res) => {
        // navigate back to main
        navigate(ADMIN_ROUTES.FEED_ITEMS);
      });
    },
    [fields, dispatch, navigate]
  );

  if (editUniversalLinkAttachmentLoading || getFeedItemLoading) {
    return (
      <div>
        <LoadingSpinner></LoadingSpinner>
      </div>
    );
  }

  return (
    <div className="fs-block">
      <BackLink routeLink={ADMIN_ROUTES.FEED_ITEMS} text={"Go back"}></BackLink>
      <h1 className="title">Admin Item Universal Link</h1>
      <AdminUniversalLinkAttachmentForm
        onSubmitForm={onSubmitForm}
        fields={fields}
        handleFieldChange={handleFieldChange}
        submitLoading={editUniversalLinkAttachmentLoading}
      />
      <br />
      {linkAttachment != null && (
        <button className="button is-danger" onClick={onDelete}>
          Delete
        </button>
      )}
      <br />
      {adminError && <div className="has-text-danger">Error: {adminError}</div>}
    </div>
  );
};

export default AdminEditUniversalLinkAttachmentPage;
