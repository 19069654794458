import React from "react";
import { COURSE_TYPES_MAP } from "../../constants/constants";

import helpers from "../../services/helpers";
import { isInProgress } from "./courseHelpers";
import ContentMarkdown from "../Markdown/ContentMarkdown";

const CourseInfo = ({ course }) => {
  const courseDetail = course.content.course_detail;
  const courseType = course.course.course_type;
  const isCohort = courseType === COURSE_TYPES_MAP.cohort;
  const courseInProgress = isInProgress(course);

  return (
    <div className="sw-padding--left-lg sw-padding--right-lg">
      <div className="sw-padding--top-lg sw-padding--bottom-md">
        <ContentMarkdown>{courseDetail.description}</ContentMarkdown>
      </div>
      <p className="subtitle sw-padding--top-lg">Details</p>
      <ul>
        <li>
          {"• "}
          {course.course.num_tasks} tasks
        </li>
        {isCohort && (
          <li>
            {"• "}
            {courseDetail.expected_time_per_week}
          </li>
        )}
        {isCohort && (
          <li>
            {"• "}
            {courseDetail.number_of_activities_per_week} activities a week
          </li>
        )}
        {isCohort && (
          <li>
            {"• "}
            {helpers.getDateFromTimestamp(
              course.cohort.start_date,
              helpers.DATE_OPTIONS.M_D
            )}{" "}
            -{" "}
            {helpers.getDateFromTimestamp(
              course.cohort.end_date,
              helpers.DATE_OPTIONS.M_D
            )}
            {", "}
            {courseDetail.zoom_meeting_time_description}
          </li>
        )}
        {isCohort && courseInProgress && courseDetail.zoom_link && (
          <li>
            {"• "}{" "}
            <a
              href={courseDetail.zoom_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Join Zoom Link
            </a>
          </li>
        )}
        <li>
          {"• "}
          {course.cohort.num_members} Participants
        </li>
        {!isCohort && (
          <li>
            {"• "}
            Self-guided{" "}
          </li>
        )}
      </ul>
    </div>
  );
};

export default CourseInfo;
