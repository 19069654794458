import React from "react";
import { Link } from "react-router-dom";

import { COURSE_TYPES_MAP } from "../../constants/constants";
import { BasicTag } from "../Box/Tags";
import helpers from "../../services/helpers";
import { isInProgress } from "./courseHelpers";
import { useCourseRoutes } from "./useCourseRouting";

const CohortTags = ({ course }) => {
  const courseInProgress = isInProgress(course);

  return (
    <div className="is-flex">
      <BasicTag>
        {helpers.getDateFromTimestamp(
          course.cohort.start_date,
          helpers.DATE_OPTIONS.MMM_D
        )}{" "}
        -{" "}
        {helpers.getDateFromTimestamp(
          course.cohort.end_date,
          helpers.DATE_OPTIONS.MMM_D
        )}
      </BasicTag>
      {!courseInProgress && (
        <BasicTag>{course.course.duration_in_days} days</BasicTag>
      )}
    </div>
  );
};

const SelfGuidedTags = ({ course }) => {
  const courseInProgress = isInProgress(course);

  let tasksString = `${course.course.num_tasks} tasks`;
  if (courseInProgress) {
    const taskRemaining =
      course.course_progress.total_tasks -
      course.course_progress.tasks_completed;
    if (taskRemaining === 1) {
      tasksString = `${taskRemaining} task remaining`;
    } else {
      tasksString = `${taskRemaining} tasks remaining`;
    }
  }

  return (
    <div className="is-flex">
      <BasicTag>{tasksString}</BasicTag>
    </div>
  );
};

const CourseOverviewBox = ({ course }) => {
  const { getCourseRoute } = useCourseRoutes();
  const content = course.content.course_overview;
  const courseType = course.course.course_type;
  const COURSE_ROUTE = getCourseRoute(course);

  let courseTypeString = "Self-guided";
  if (courseType === COURSE_TYPES_MAP.cohort) {
    courseTypeString = "Cohort-course";
  }

  return (
    <div className="sw-course-overview-box">
      <Link to={COURSE_ROUTE}>
        <div className="sw-course-cover-img">
          <BasicTag>{courseTypeString}</BasicTag>
          <img src={content.cover_photo.filename} alt="" />
        </div>
        <div className="sw-course-overview-box__content">
          <h4 className="sw-course-overview-box__title">{content.title}</h4>
          <p>{content.description}</p>

          {courseType === COURSE_TYPES_MAP.cohort ? (
            <CohortTags course={course}></CohortTags>
          ) : (
            <SelfGuidedTags course={course}></SelfGuidedTags>
          )}
        </div>
      </Link>
    </div>
  );
};

export default CourseOverviewBox;
