import { trackIdentify, resetAnalyticsSession } from "./analyticsActions";
import { RECOMMENDATION_ACTIONS } from "../reducers/recommendationsReducer";
import { setError } from "./errorActions";
import { apiPost } from "./apiActions";
import { isOnboardingComplete } from "./profileActions";
import helpers from "../../services/helpers";

// import { trackEvent, EVENTS } from "./analyticsActions";

export const isRecVisible = (balance, profileObj, recVisibleBackend) => {
  if (!recVisibleBackend) {
    return false;
  }

  if (balance > 0 && profileObj && isOnboardingComplete(profileObj)) {
    return true;
  }

  return false;
};

const getRecTraitsFromResponse = (res) => {
  const resAttributes = res.data.recommendations.attributes;
  let returnObj = Object.assign({}, resAttributes);

  // intercom doesn't support arrays, convert to comma separated string and sort
  returnObj.rec_types = "";
  if (resAttributes && resAttributes.rec_types) {
    returnObj.rec_types = resAttributes.rec_types.sort().join();
  }

  // send years left in addition to months
  returnObj.increase_years_left = null;
  if (resAttributes && resAttributes.increase_months_left) {
    returnObj.increase_years_left = helpers.convertMonthsToYears(
      resAttributes.increase_months_left
    );
  }

  returnObj.refi_years_left = null;
  if (resAttributes && resAttributes.refi_months_left) {
    returnObj.refi_years_left = helpers.convertMonthsToYears(
      resAttributes.refi_months_left
    );
  }

  return returnObj;
};

const onPostRecSuccess = (dispatch, getState, res) => {
  dispatch({
    type: RECOMMENDATION_ACTIONS.GET_SUCCESS,
    res,
  });

  const recTraits = getRecTraitsFromResponse(res);

  const userObj = getState().auth.currentUser;
  if (userObj) {
    dispatch(
      trackIdentify(
        userObj.id,
        userObj.email,
        userObj.first_name,
        recTraits,
        null
      )
    );
  }
};

export const asyncPostRecommendation = (saveSummary = false) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/recommendation";

      dispatch({ type: RECOMMENDATION_ACTIONS.GET });

      const onSuccess = (res) => {
        onPostRecSuccess(dispatch, getState, res);
        resolve();
      };

      const onError = (err) => {
        dispatch(setError(err));
        dispatch({ type: RECOMMENDATION_ACTIONS.GET_ERROR, err });
        reject(err);
      };

      dispatch(
        apiPost(endpoint, { save_summary: saveSummary }, onSuccess, onError)
      );
    });
  };
};

export const postRecommendation = (saveSummary = false) => {
  return (dispatch, getState) => {
    const endpoint = "/api/recommendation";

    dispatch({ type: RECOMMENDATION_ACTIONS.GET });

    const onSuccess = (res) => {
      onPostRecSuccess(dispatch, getState, res);
    };

    const onError = (err) => {
      dispatch(setError(err));
      dispatch({ type: RECOMMENDATION_ACTIONS.GET_ERROR, err });
    };

    dispatch(
      apiPost(endpoint, { save_summary: saveSummary }, onSuccess, onError)
    );
  };
};

export const postAdminRecommendation = (userId) => {
  return (dispatch, getState) => {
    const endpoint = "/api/admin/recommendation";
    const data = { user_id: userId, save_summary: false };

    dispatch({ type: RECOMMENDATION_ACTIONS.GET });

    const onSuccess = (res) => {
      dispatch({
        type: RECOMMENDATION_ACTIONS.GET_SUCCESS,
        res,
      });
    };

    const onError = (err) => {
      dispatch(setError(err));
      dispatch({ type: RECOMMENDATION_ACTIONS.GET_ERROR, err });
    };

    dispatch(apiPost(endpoint, data, onSuccess, onError));
  };
};

export const adminBackfillRec = (userObj) => {
  return (dispatch, getState) => {
    const endpoint = "/api/admin/recommendation";
    const data = { user_id: userObj.id, save_summary: false };

    const onSuccess = (res) => {
      const recTraits = getRecTraitsFromResponse(res);
      const onIdentifyDone = () => {
        dispatch(resetAnalyticsSession());
      };

      if (recTraits) {
        dispatch(
          trackIdentify(
            userObj.id,
            userObj.email,
            userObj.first_name,
            recTraits,
            onIdentifyDone
          )
        );
      }
    };

    const onError = (err) => {
      dispatch(setError(err));
      dispatch({ type: RECOMMENDATION_ACTIONS.GET_ERROR, err });
    };

    dispatch(apiPost(endpoint, data, onSuccess, onError));
  };
};
