import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useFormFields } from "../../components/Form/useFormFields";
import { createChallenge } from "../../store/actions/adminChallengesActions";
import "react-datepicker/dist/react-datepicker.css";
import {
  CHALLENGE_GOAL_TYPES,
  CHALLENGE_GOAL_STATUS,
  DEFAULT_CHALLENGE_PROGRESS_COLOR,
} from "../../constants/constants";
import { BackLink } from "../../components/Buttons/BackButtons";
import { ADMIN_ROUTES } from "../../constants/routes";

import AdminChallengesForm from "../../components/Challenges/AdminChallengeForm";

const AdminCreateChallengePage = (props) => {
  const dispatch = useDispatch();
  const { createChallengeLoading, adminError, newChallenge } = useSelector(
    (state) => {
      return state.adminChallenges;
    }
  );

  const tomorrow = useMemo(() => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    return tomorrow;
  });

  const defaultFields = useMemo(() => {
    return {
      title: "",
      subtitle: "",
      description: "",
      goalStatus: CHALLENGE_GOAL_STATUS[0],
      goalType: CHALLENGE_GOAL_TYPES[0],
      startDate: new Date(),
      endDate: tomorrow,
      goalSuggestedAmount: "",
      progressColor: DEFAULT_CHALLENGE_PROGRESS_COLOR,
      badgeGradientStartColor: DEFAULT_CHALLENGE_PROGRESS_COLOR,
      badgeGradientEndColor: DEFAULT_CHALLENGE_PROGRESS_COLOR,
      imageUrl: "",
      coverPhotoUrl: "",
      communityUuid: "",
    };
  });

  const [fields, handleFieldChange, setValues] = useFormFields(defaultFields);

  useEffect(() => {
    setValues(defaultFields);
  }, [setValues, newChallenge]);

  const navigate = useNavigate();
  const onSubmitForm = useCallback(
    async (event) => {
      event.preventDefault();
      await dispatch(
        createChallenge(
          fields.title,
          fields.subtitle,
          fields.description,
          fields.goalStatus,
          fields.goalType,
          Math.round(fields.startDate.getTime() / 1000),
          Math.round(fields.endDate.getTime() / 1000),
          fields.imageUrl,
          fields.coverPhotoUrl,
          fields.goalSuggestedAmount,
          fields.progressColor,
          fields.badgeGradientStartColor,
          fields.badgeGradientEndColor,
          fields.communityUuid
        )
      ).then((res) => {
        // navigate back to main
        navigate(ADMIN_ROUTES.CHALLENGES);
      });
    },
    [fields, dispatch, navigate]
  );

  return (
    <div className="fs-block">
      <BackLink routeLink={ADMIN_ROUTES.CHALLENGES} text={"Go back"}></BackLink>

      <h1 className="title">Admin Challenges</h1>
      <AdminChallengesForm
        onSubmitForm={onSubmitForm}
        fields={fields}
        handleFieldChange={handleFieldChange}
        submitLoading={createChallengeLoading}
        tomorrow={tomorrow}
      ></AdminChallengesForm>
      <br />
      {adminError && <div className="has-text-danger">Error: {adminError}</div>}
    </div>
  );
};

export default AdminCreateChallengePage;
