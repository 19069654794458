import React from "react";
import { connect } from "react-redux";

import NavBar from "../NavBar/NavBar";
import AdminSideMenu from "../NavBar/AdminSideMenu";
import ConfirmationMessage from "../Messages/ConfirmationMessage";
import ErrorMessage from "../Messages/ErrorMessage";
import WarningMessage from "../Messages/WarningMessage";
import StickyBanner from "../Messages/StickyBanner";

const MessageContainer = ({
  errMessage,
  errTitle,
  showConfirmMessage,
  confirmMessageProps,
  warningTitle,
  warningMessage,
  warningRedirectRoute,
}) => {
  return (
    <>
      {showConfirmMessage && (
        <ConfirmationMessage {...confirmMessageProps}></ConfirmationMessage>
      )}

      {errMessage && (
        <ErrorMessage title={errTitle} message={errMessage}></ErrorMessage>
      )}

      {warningTitle && (
        <WarningMessage
          title={warningTitle}
          message={warningMessage}
          redirectRoute={warningRedirectRoute}
        ></WarningMessage>
      )}
    </>
  );
};

const AdminLayout = ({
  pageTitle, // passed param
  hideSideMenu, // passed param - hides side menu
  topNavTransparent, // passed param - removes white color from top nav
  messageProps,
  showSticky,
  stickyBannerProps,
  ffFlags,
  children,
}) => {
  if (hideSideMenu) {
    return (
      <>
        <div className="bd-docs bd-docs--hide-side-nav">
          <main className="bd-docs-main">
            <NavBar
              pageTitle={pageTitle}
              ffFlags={ffFlags}
              isWhite={topNavTransparent ? false : true}
              hideSideMenu={hideSideMenu}
            />
            <div className="bd-docs-main__container">
              <MessageContainer {...messageProps}></MessageContainer>
              {children}
            </div>
          </main>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="bd-docs">
        <AdminSideMenu ffFlags={ffFlags}></AdminSideMenu>

        <main className="bd-docs-main">
          <NavBar
            pageTitle={pageTitle}
            ffFlags={ffFlags}
            isWhite={topNavTransparent ? false : true}
            hideSideMenu={hideSideMenu}
          />
          {showSticky && (
            <div className="bd-docs__sticky">
              <StickyBanner
                text={stickyBannerProps.text}
                link={stickyBannerProps.link}
              />
            </div>
          )}

          <div className="bd-docs-main__container">
            <MessageContainer {...messageProps}></MessageContainer>
            {children}
          </div>
        </main>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const messageProps = {
    errMessage: state.error.message,
    errTitle: state.error.title,
    showConfirmMessage: state.alerting.showConfirmMessage,
    confirmMessageProps: state.alerting.confirmMessageProps,
    warningTitle: state.error.warningTitle,
    warningMessage: state.error.warningMessage,
    warningRedirectRoute: state.error.warningRedirectRoute,
  };

  return {
    ffFlags: state.auth.flags,
    messageProps: messageProps,
    showSticky: state.alerting.showSticky,
    stickyBannerProps: state.alerting.stickyBannerProps,
  };
};

export default connect(mapStateToProps, null)(AdminLayout);
