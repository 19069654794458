import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import { REC_TYPES } from "../../constants/constants";

import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";
import { asyncPostRecommendation } from "../../store/actions/recommendationActions";
import RecDetailsStudent from "../../components/Recommendation/RecDetailsStudent";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import RecDisclaimerMessage from "../../components/Recommendation/RecDisclaimerMessage";

import { BackLink } from "../../components/Buttons/BackButtons";
import ROUTES from "../../constants/routes";

const RecPageStudentDebt = ({
  gettingRecs,
  trackEventFn,
  allRecs,
  baseCase,
}) => {
  const navigate = useNavigate();
  let { recType } = useParams();

  let recObj = null;
  let isRefi = false;
  // let isIncrease = false;
  let canSeeContinue = true;

  if (allRecs) {
    const filteredRecs = allRecs.filter((rec) => rec.type === recType);
    if (filteredRecs && filteredRecs.length > 0) {
      recObj = filteredRecs[0];
      if (
        recObj.type === REC_TYPES.LOWER_INTEREST ||
        recObj.type === REC_TYPES.LOWER_INTEREST_REACH ||
        recObj.type === REC_TYPES.REPAYMENT_LOWER_INTEREST ||
        recObj.type === REC_TYPES.COVID_POST_LOWER_INTEREST
      ) {
        isRefi = true;
      } else if (
        recObj.type === REC_TYPES.INCREASE_REACH ||
        recObj.type === REC_TYPES.INCREASE_MODERATE ||
        recObj.type === REC_TYPES.COVID_POST_INCREASE ||
        recObj.type === REC_TYPES.COVID_PAY
      ) {
        // isIncrease = true;
      } else if (
        recObj.type === REC_TYPES.REPAYMENT_TEN ||
        recObj.type === REC_TYPES.REPAYMENT_TWENTYFIVE
      ) {
        canSeeContinue = false;
      }
    }
  }

  // track page with rec obj data
  useEffect(() => {
    const properties = { rec_type: "", is_recommended: "" };
    if (recObj) {
      properties.rec_category = recObj.rec_category;
      properties.rec_type = recObj.type;
      properties.is_recommended = recObj.is_recommended;
    }
    trackEventFn(EVENTS.REC_DETAILS_VIEWED, properties);
  }, [trackEventFn, recObj]);

  const goToRefiFlow = () => {
    navigate(ROUTES.STUDENT_REFI_STEPS);
  };

  // set page title
  const setPageTitle = useOutletContext();
  useEffect(() => {
    setPageTitle(""); // set blank for now, should revisit at some point
  }, [setPageTitle]);

  if (gettingRecs) {
    return (
      <div className="sw-flex-layout--column-center">
        <LoadingSpinner></LoadingSpinner>
      </div>
    );
  }

  return (
    <div className="sw-flex-layout--column-center">
      <div className="sw-container--fixed-width sw-is-wide">
        <BackLink
          routeLink={ROUTES.STUDENT_LOAN_DASHBOARD}
          text="Back"
        ></BackLink>
      </div>

      {recObj && baseCase && (
        <RecDetailsStudent rec={recObj} baseCase={baseCase}></RecDetailsStudent>
      )}

      {isRefi && canSeeContinue && (
        <button onClick={goToRefiFlow} className="button is-primary is-medium">
          Learn More
        </button>
      )}

      <div className="sw-padding--top-lg">
        <RecDisclaimerMessage></RecDisclaimerMessage>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackEventFn: (event, properties) =>
      dispatch(trackEvent(event, properties)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const allRecs = state.recommendations.recommendations;
  const baseCase = state.recommendations.current;

  return {
    allRecs: allRecs,
    baseCase: baseCase,
    gettingRecs: state.recommendations.getLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecPageStudentDebt);
