import React from "react";
import { connect } from "react-redux";
import { clearWarning } from "../../store/actions/errorActions";
import { useNavigate } from "react-router-dom";

const WarningMessage = (props) => {
  const navigate = useNavigate();
  let onClickMessage = () => {};

  if (props.redirectRoute) {
    onClickMessage = () => {
      navigate(props.redirectRoute);
      props.clearWarning();
    };
  }

  return (
    <article className="message is-warning">
      <div className="message-header">
        <p>{props.title}</p>
        <button
          className="delete is-pulled-right"
          aria-label="delete"
          onClick={props.clearWarning}
        ></button>
      </div>

      <div className="message-body" onClick={onClickMessage}>
        {props.message}
      </div>
    </article>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearWarning: () => dispatch(clearWarning()),
  };
};

export default connect(null, mapDispatchToProps)(WarningMessage);
