import { PROFILE_ACTIONS } from "../reducers/profileReducer";
import { setError } from "./errorActions";
import { apiGet, apiPost } from "./apiActions";
import { trackEvent, EVENTS } from "./analyticsActions";
import { trackIdentify } from "./analyticsActions";

import helpers from "../../services/helpers";

export const MAX_CHARS_OTHER = 250;

export const GOAL_OPTIONS = {
  payDown: "pay_down",
  repaymentOptions: "repayment_options",
  lowerInterest: "lower_interest",
  lowerPayment: "lower_payment",
  stopPayment: "stop_payment",
};
export const GOAL_OPTIONS_MAP = {
  pay_down: "payDown",
  repayment_options: "repaymentOptions",
  lower_interest: "lowerInterest",
  lower_payment: "lowerPayment",
  stop_payment: "stopPayment",
};

export const GOAL_OPTIONS_MAP_DISPLAY = {
  pay_down: "Pay down debt faster",
  repayment_options: "Explore different repayment options",
  lower_interest: "Lower my interest",
  lower_payment: "Lower payments",
  stop_payment: "Stop payments",
};

export const CREDIT_SCORE_OPTIONS_MAP = {
  excellent: "excellent",
  good: "good",
  fair: "fair",
  bad: "bad",
  unknown: "unknown",
};

export const CREDIT_SCORE_OPTIONS_MAP_DISPLAY = {
  excellent: "Above 750",
  good: "700-749",
  fair: "650-699",
  bad: "Below 650",
  unknown: "I'm not sure",
};

export const LOAN_TYPES_MAP = {
  PRIVATE: "private",
  FEDERAL: "federal",
  BOTH: "both",
};

export const LOAN_TYPES_MAP_DISPLAY = {
  private: "Private",
  federal: "Federal",
  both: "Federal and Private",
};

export const FEDERAL_PROGRAM_OPTIONS = [
  "N/A",
  "Standard repayment",
  "Graduated",
  "REPAYE",
  "PAYE",
  "IBR",
  "IBR for new borrowers",
  "ICR",
  "Other",
  "I don’t know",
];

export const FEDERAL_PROGRAM_OPTIONS_MAP = {
  "N/A": "",
  "Standard repayment": "standard_repayment",
  Graduated: "graduated",
  REPAYE: "repaye",
  PAYE: "paye",
  IBR: "ibr",
  "IBR for new borrowers": "ibr_new_borrowers",
  ICR: "icr",
  Other: "other",
  "I don’t know": "unknown",
};

export const FEDERAL_PROGRAM_OPTIONS_MAP_DISPLAY = {
  standard_repayment: "Standard repayment",
  graduated: "Graduated",
  repaye: "REPAYE",
  paye: "PAYE",
  ibr: "IBR",
  ibr_new_borrowers: "IBR for new borrowers",
  icr: "ICR",
  other: "Other",
  unknown: "I don’t know",
};

export const isOnboardingComplete = (profileObj) => {
  if (profileObj) {
    if (
      helpers.notNullnotUndefined(profileObj.credit_score) &&
      helpers.notNullnotUndefined(profileObj.goals)
    ) {
      return true;
    }
  }
  return false;
};

const getGoalsTraitsFromResponse = (res) => {
  const profileObj = res.data.data.profile;
  let returnObj = {};

  returnObj.goals = "";

  // intercom doesn't support arrays, convert to comma separated string and sort
  if (profileObj.goals) {
    returnObj.goals = profileObj.goals.sort().join();
  }
  return returnObj;
};


export const getUserFinancialProfile = () => {
  return (dispatch, getState) => {
    const endpoint = "/api/users/profile";

    dispatch({ type: PROFILE_ACTIONS.GET });

    const onSuccess = (res) => {
      dispatch({ type: PROFILE_ACTIONS.GET_SUCCESS, res });

      // update intercom with traits, only sending goals right now
      const goalTraits = getGoalsTraitsFromResponse(res);
      const userObj = getState().auth.currentUser;

      // userObj will be null if we have logged out while making this request
      // and in this case we cannot want to track anything
      if (userObj) {
        dispatch(
          trackIdentify(
            userObj.id,
            userObj.email,
            userObj.first_name,
            goalTraits,
            null
          )
        );
      }
    };

    const onError = (err) => {
      dispatch({ type: PROFILE_ACTIONS.GET_ERROR, err });

      const message =
        "We ran into an error getting your information. Please contact the Snowball team and we'll help you resolve this.";
      dispatch(setError(err, message));
    };

    dispatch(apiGet(endpoint, onSuccess, onError));
  };
};

const updateUserFinancialProfile = (profileObj) => {
  return (dispatch, getState) => {
    const endpoint = "/api/users/profile";

    dispatch({ type: PROFILE_ACTIONS.UPDATE });
    // removed metadata on what was saved
    dispatch(trackEvent(EVENTS.ONBOARDING_REC_SAVED));

    const onSuccess = (res) => {
      dispatch({ type: PROFILE_ACTIONS.UPDATE_SUCCESS, res });
    };

    const onError = (err) => {
      dispatch({ type: PROFILE_ACTIONS.UPDATE_ERROR, err });
      const message =
        "We ran into an error updating your information. Please contact the Snowball team and we'll help you resolve this.";
      dispatch(setError(err, message));
    };

    dispatch(apiPost(endpoint, profileObj, onSuccess, onError));
  };
};

const asyncUpdateUserFinancialProfile = (profileObj) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/users/profile";

      dispatch({ type: PROFILE_ACTIONS.UPDATE });
      // removed metadata on what was saved
      dispatch(trackEvent(EVENTS.ONBOARDING_REC_SAVED));

      const onSuccess = (res) => {
        dispatch({ type: PROFILE_ACTIONS.UPDATE_SUCCESS, res });
        resolve();
      };

      const onError = (err) => {
        dispatch({ type: PROFILE_ACTIONS.UPDATE_ERROR, err });
        const message =
          "We ran into an error updating your information. Please contact the Snowball team and we'll help you resolve this.";
        dispatch(setError(err, message));
        reject(err);
      };

      dispatch(apiPost(endpoint, profileObj, onSuccess, onError));
    });
  };
};

export const updateUserProfileMonthlyPayments = (
  monthlyMinPayment,
  monthlyMaxPayment
) => {
  return (dispatch, getState) => {
    const profileObj = {
      monthly_min_payment: monthlyMinPayment,
      monthly_max_payment: monthlyMaxPayment,
    };
    dispatch(updateUserFinancialProfile(profileObj));
  };
};

export const asyncUpdateUserProfileMonthlyPayments = (
  monthlyMinPayment,
  monthlyMaxPayment
) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const profileObj = {
        monthly_min_payment: monthlyMinPayment,
        monthly_max_payment: monthlyMaxPayment,
      };

      dispatch(asyncUpdateUserFinancialProfile(profileObj)).finally(() =>
        resolve()
      );
    });
  };
};

export const updateUserProfileIcomeMaxPayment = (income, maxPayment) => {
  return (dispatch, getState) => {
    const profileObj = {
      income: income,
      monthly_max_payment: maxPayment,
    };
    dispatch(updateUserFinancialProfile(profileObj));
  };
};

export const updateUserProfileIncomeSavingsDebt = (
  income,
  savings,
  personalDebt
) => {
  return (dispatch, getState) => {
    const profileObj = {
      income: income,
      savings: savings,
      personal_debt: personalDebt,
    };
    dispatch(updateUserFinancialProfile(profileObj));
  };
};

export const updateUserProfileCreditScore = (creditScore) => {
  return (dispatch, getState) => {
    const profileObj = {
      credit_score: creditScore,
    };
    dispatch(updateUserFinancialProfile(profileObj));
  };
};

export const updateUserProfileGoals = (goals) => {
  return (dispatch, getState) => {
    // goals need to be array
    const profileObj = {
      goals: goals,
    };
    dispatch(updateUserFinancialProfile(profileObj));
  };
};

export const updateUserOther = (other) => {
  return (dispatch, getState) => {
    const profileObj = {
      other: other,
    };
    dispatch(updateUserFinancialProfile(profileObj));
  };
};

export const updateUserProfileFederalPrograms = (loanType, federalProgram) => {
  return (dispatch, getState) => {
    let program = "";
    if (federalProgram) {
      program = federalProgram;
    }
    const profileObj = {
      loan_type: loanType,
      federal_program: program,
    };
    dispatch(updateUserFinancialProfile(profileObj));
  };
};

export const asyncUpdateQuestionsPartOne = ({
  goals,
  currentPayment,
  federalProgram,
  employmentLoanForgiveness,
}) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const profileObj = {
        goals: goals, // needs to be array
        monthly_min_payment: currentPayment,
        federal_program: federalProgram,
        employment_loan_forgiveness: employmentLoanForgiveness,
      };

      dispatch(asyncUpdateUserFinancialProfile(profileObj)).finally(() =>
        resolve()
      );
    });
  };
};

export const asyncUpdateQuestionsPartTwo = ({
  income,
  personalDebt,
  creditScore,
}) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const profileObj = {
        income: income,
        personal_debt: personalDebt,
        credit_score: creditScore,
      };

      dispatch(asyncUpdateUserFinancialProfile(profileObj)).finally(() =>
        resolve()
      );
    });
  };
};
