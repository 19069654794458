import React, { useCallback } from "react";

import FeedItem from "./FeedItem";
import useInfiniteScroll from "../Pagination/useInfiniteScroll";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

const FeedSection = ({
  feedItems,
  pagination,
  currentUser,
  getPageContent,
  onDeletePost,
  isFeedItemsLoading,
}) => {
  const fetchMoreListItems = useCallback(() => {
    if (pagination && pagination.next_num) {
      // only fetch more if have more pages
      getPageContent(pagination.next_num);
    }
  }, [getPageContent, pagination]);

  // automatically fetches more posts when hit the bottom of the page
  useInfiniteScroll(fetchMoreListItems);

  return (
    <div className="sw-max-screen-width">
      {feedItems &&
        feedItems.map((feedItem, index) => {
          return (
            <FeedItem
              key={index}
              feedItem={feedItem}
              currentUser={currentUser}
              onDeletePost={onDeletePost}
            ></FeedItem>
          );
        })}
      <div className="has-text-centered">
        {isFeedItemsLoading && <LoadingSpinner></LoadingSpinner>}
        {pagination && (
          <button
            className="button is-rounded is-small"
            disabled={!pagination.has_next}
            onClick={fetchMoreListItems}
          >
            Load more
          </button>
        )}
      </div>
    </div>
  );
};

export default FeedSection;
