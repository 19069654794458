import React from "react";

import usePremiumUpsell from "../FinancialPlan/usePremiumUpsell";

const UpgradePremiumButton = ({ returnUrl, onClick, eventMetadata }) => {
  const triggerPremiumUpsell = usePremiumUpsell(returnUrl, eventMetadata);

  const onPremiumClick = () => {
    triggerPremiumUpsell();
    if (onClick) {
      onClick();
    }
  };

  return (
    <button className="button is-small is-info" onClick={onPremiumClick}>
      <span className="icon">
        <i className="fa fa-lock"></i>{" "}
      </span>
      <span className="sw-padding--left-sm">Premium</span>
    </button>
  );
};

export default UpgradePremiumButton;
