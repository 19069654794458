import { useCallback } from "react";
import { usePlaidLink } from "react-plaid-link";
import { useDispatch } from "react-redux";

import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";
import { updateAccountItems } from "../../store/actions/institutionActions";
import { asyncAddNetWorthPlaidItem } from "../../store/actions/netWorthActions";

export const LINK_MODE_TYPES = {
  ADD: "add",
  UPDATE: "update",
};

/**
 * Bug alert: There is an issue with having multiple plaid links
 * in one page. Leading to 'Error loading Plaid null'.
 * Workaround is to include the plaid script in index.html.
 * Which we are doing until a longer term fix is implemented.
 * See: https://github.com/plaid/react-plaid-link/issues/121
 */

const usePlaidLinkWrapper = ({
  onSuccess,
  linkMode,
  linkToken,
  receivedRedirectUri,
}) => {
  const dispatch = useDispatch();

  const handleOnSuccess = useCallback(
    (token, metadata) => {
      dispatch(
        trackEvent(EVENTS.PLAID_INTEGRATION_COMPLETED, {
          mode: linkMode,
          oauth: true, // we're in oauth
        })
      );

      if (linkMode === LINK_MODE_TYPES.UPDATE) {
        dispatch(updateAccountItems());
        // redirect back to Linked Accounts view
        if (onSuccess) onSuccess();
      } else {
        // assume type "add"
        dispatch(asyncAddNetWorthPlaidItem(token, metadata)).then((jobId) => {
          // redirect back to net worth view
          if (onSuccess) onSuccess();
          // this means we need to poll in the background
          // check status in 20 seconds
          //   setTimeout(() => {
          //     checkAddItemStatusFn(jobId);
          //   }, 20000);
        });
      }
    },
    [dispatch, linkMode, onSuccess]
  );

  const handleOnExit = useCallback(
    (err, metadata) => {
      let eventProperties = {};

      if (err != null) {
        // The user encountered a Plaid API error prior
        // to exiting.
        console.error("error", err);
        console.error("metadata", metadata);
        eventProperties = { error: err };
      }

      dispatch(trackEvent(EVENTS.PLAID_INTEGRATION_CANCELLED, eventProperties));
    },
    [dispatch]
  );

  const handleOnEvent = useCallback(
    (event) => {
      dispatch(trackEvent(EVENTS.PLAID_INTEGRATION_CLICKED, { event: event }));
    },
    [dispatch]
  );

  const config = {
    onExit: handleOnExit,
    onSuccess: handleOnSuccess,
    onEvent: handleOnEvent,
    token: linkToken,
  };

  if (receivedRedirectUri) {
    //the redirect URI with an OAuth state ID parameter
    config.receivedRedirectUri = receivedRedirectUri;
  }

  return usePlaidLink(config);
};

export default usePlaidLinkWrapper;
