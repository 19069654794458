import React from "react";

const ProfileLabelInfo = (props) => (
  <div className="sw-profile-label-info fs-block">
    <label className="has-text-weight-medium" htmlFor={props.name}>
      {props.label}
    </label>
    <div name={props.name} {...props}>
      {props.value || <br></br>}
    </div>
    {/* <div name={props.name} {...props}>
      {props.value || <a>Add info</a>}
    </div> */}
    {/* <input
      readOnly
      name={props.name}
      type="text"
      {...props}
      className={`input ${props.className}`}
    /> */}
  </div>
);

export default ProfileLabelInfo;
