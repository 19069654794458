import React from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";

import Logo from "../Logo/Logo";
import ROUTES, { ADMIN_ROUTES } from "../../constants/routes";
import { LINK_URLS } from "../../constants/constants";
import { trackEvent, EVENTS } from "../../store/actions/analyticsActions";
import UserIcon from "../Communities/UserIcon";
import { useCourseRoutes } from "../FinancialPlan/useCourseRouting";

import { onToggleNav, onToggleSideNav } from "./menuHelpers";

const NavLinkWrapper = ({ to, loginRoute, children }) => {
  return (
    <NavLink
      to={to}
      className="navbar-item"
      onClick={onToggleNav}
      state={{ loginRoute: loginRoute }}
    >
      {children}
    </NavLink>
  );
};

const UserMenu = ({ showAdmin, currentUser, trackEventFn }) => {
  return (
    <div className="navbar-item has-dropdown is-hoverable">
      <div className="navbar-link is-arrowless">
        <UserIcon authorProfile={currentUser} sizeClass="is-32x32"></UserIcon>
      </div>
      <div className="navbar-dropdown is-right">
        {/* <NavLinkWrapper to={ROUTES.PROFILE}>Account settings</NavLinkWrapper>
        <hr className="navbar-divider" />
        <a
          href={LINK_URLS.HELP_CENTER}
          onClick={() => trackEventFn(EVENTS.HELP_CLICKED)}
          target="_blank"
          rel="noopener noreferrer"
          className="navbar-item"
        >
          Help
        </a> */}
        <NavLinkWrapper to={ROUTES.SIGN_OUT} loginRoute={ROUTES.SCHOOL_LOG_IN}>
          Sign Out
        </NavLinkWrapper>
      </div>
    </div>
  );
};

const OrgNavBar = (props) => {
  const { getCoursesRoute } = useCourseRoutes();
  const isLoggedIn = props.isLoggedIn;
  const isWhite = props.isWhite;

  return (
    <nav
      className={isWhite ? "navbar" : "navbar sw-has-no-background"}
      role="navigation"
      aria-label="main navigation"
    >
      <section className="container">
        <div className="navbar-brand">
          {isLoggedIn && (
            <Link className={"navbar-item"} to={getCoursesRoute()}>
              <Logo altText="Snowball Wealth - Go Home" hideText={false} />
            </Link>
          )}
          {!isLoggedIn && (
            <a className="navbar-item" href={LINK_URLS.LANDING_HOME}>
              <Logo altText="Snowball Wealth Logo - Go to website" />
            </a>
          )}
        </div>

        <div className="navbar-menu">
          <div className="navbar-end">
            {isLoggedIn && (
              <UserMenu
                displayName={props.displayName}
                trackEventFn={props.trackEvent}
                currentUser={props.currentUser}
                showAdmin={props.showAdmin}
              />
            )}
          </div>
        </div>
      </section>
    </nav>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackEvent: (event) => dispatch(trackEvent(event)),
  };
};

const mapStateToProps = (state) => {
  // Note: Here, we want to wait for auth.isAuth, to prevent showing navbar before we get user
  const isLoggedIn =
    state.firebase.auth && state.firebase.auth.uid && state.auth.isAuth;

  const currentUser = state.auth.currentUser;
  let showAdmin = false;
  let displayName = "";

  if (currentUser) {
    displayName = currentUser.email;
    // if first name, use it instead of email
    if (currentUser.first_name) {
      displayName = currentUser.first_name;
    }

    const userRoles = state.auth.userRoles;
    if (userRoles && userRoles.length > 0) {
      // note: don't care about which roles
      showAdmin = true;
    }
  }

  return {
    isLoggedIn: isLoggedIn,
    displayName: displayName,
    showAdmin: showAdmin,
    currentUser: currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgNavBar);
