import React, { Fragment } from "react";
import helpers from "../../services/helpers";
import moment from "moment";

const APRDetails = ({ loanInfo }) => {
  const accountObj = loanInfo.account_details;

  if (!accountObj.aprs || accountObj.aprs.length < 1) return <></>;

  // sort by balance first (greatest goes first), and alphabetical second
  const sortFunction = (a, b) => {
    if (a.balance_subject_to_apr > b.balance_subject_to_apr) return -1;
    if (a.balance_subject_to_apr < b.balance_subject_to_apr) return 1;
    else return a.apr_type > b.apr_type ? 1 : -1;
  };
  const sortedAprs = [].concat(accountObj.aprs).sort(sortFunction);

  return (
    <div className="box is-vertical">
      <p className="sw-padding--bottom-md">APR details</p>
      <table className="table is-hoverable is-fullwidth is-striped is-narrow fs-block">
        <tbody>
          {sortedAprs &&
            sortedAprs.map((aprObj, index) => {
              let aprTypeStr = aprObj.apr_type || "";
              aprTypeStr = aprTypeStr.replaceAll("_", " ");
              aprTypeStr = aprTypeStr.replace("apr", "");
              aprTypeStr = helpers.capitalizeFirstLetter(aprTypeStr);

              if (
                aprObj.balance_subject_to_apr > 0 ||
                aprObj.interest_charge_amount > 0 ||
                aprObj.apr_type === "purchase_apr"
              ) {
                return (
                  <Fragment key={index}>
                    <tr>
                      <td>{aprTypeStr} APR</td>
                      <td className="has-text-right has-text-weight-semibold">
                        {aprObj.apr_percentage}%
                      </td>
                    </tr>
                    <tr>
                      <td>{aprTypeStr} balance</td>
                      <td className="has-text-right has-text-weight-semibold">
                        {helpers.currencyFormat(
                          aprObj.balance_subject_to_apr,
                          0,
                          accountObj.isoCurrencyCode
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{aprTypeStr} interest charge</td>
                      <td className="has-text-right has-text-weight-semibold">
                        {helpers.currencyFormat(
                          aprObj.interest_charge_amount,
                          0,
                          accountObj.isoCurrencyCode
                        )}
                      </td>
                    </tr>
                  </Fragment>
                );
              } else return null;
            })}
        </tbody>
      </table>
    </div>
  );
};

const CreditDetails = ({ loanInfo }) => {
  const accountObj = loanInfo.account_details;
  return (
    <>
      <div className="box is-vertical">
        <p className="sw-padding--bottom-md">Credit card details</p>
        <table className="table is-hoverable is-fullwidth is-striped is-narrow fs-block">
          <tbody>
            <tr>
              <td>Name</td>
              <td className="has-text-right has-text-weight-semibold is-capitalized">
                {loanInfo.name}
              </td>
            </tr>
            <tr>
              <td>Balance limit</td>
              <td className="has-text-right has-text-weight-semibold">
                {helpers.currencyFormat(
                  loanInfo.balance_limit,
                  0,
                  accountObj.isoCurrencyCode
                )}
              </td>
            </tr>

            {loanInfo.balance_available !== null && (
              <tr>
                <td>Available balance</td>
                <td className="has-text-right has-text-weight-semibold">
                  {helpers.currencyFormat(
                    loanInfo.balance_available,
                    0,
                    accountObj.isoCurrencyCode
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <APRDetails loanInfo={loanInfo}></APRDetails>

      <div className="box is-vertical">
        <p className="sw-padding--bottom-md">Payment details</p>
        <table className="table is-hoverable is-fullwidth is-striped is-narrow fs-block">
          <tbody>
            <tr>
              <td>Minimum payment</td>
              <td className="has-text-right has-text-weight-semibold">
                {helpers.currencyFormat(
                  accountObj.minimum_payment_amount,
                  0,
                  accountObj.isoCurrencyCode
                )}
              </td>
            </tr>
            <tr>
              <td>Next payment date</td>
              <td className="has-text-right has-text-weight-semibold">
                {accountObj.next_payment_due_date &&
                  moment(accountObj.next_payment_due_date, "YYYY-MM-DD").format(
                    "MM/D/YYYY"
                  )}
              </td>
            </tr>
            <tr>
              <td>Last statement balance</td>
              <td className="has-text-right has-text-weight-semibold">
                {helpers.currencyFormat(
                  accountObj.last_statement_balance,
                  0,
                  accountObj.isoCurrencyCode
                )}
              </td>
            </tr>
            <tr>
              <td>Last payment</td>
              <td className="has-text-right has-text-weight-semibold">
                {helpers.currencyFormat(
                  accountObj.last_payment_amount,
                  0,
                  accountObj.isoCurrencyCode
                )}
              </td>
            </tr>
            <tr>
              <td>Last payment date</td>
              <td className="has-text-right has-text-weight-semibold">
                {accountObj.last_payment_date &&
                  moment(accountObj.last_payment_date, "YYYY-MM-DD").format(
                    "MM/D/YYYY"
                  )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CreditDetails;
