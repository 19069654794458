import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import QuestionsPartOneForm from "../../components/Onboarding/QuestionsPartOneForm";

import {
  getUserFinancialProfile,
  asyncUpdateQuestionsPartOne,
  GOAL_OPTIONS_MAP,
  FEDERAL_PROGRAM_OPTIONS_MAP,
} from "../../store/actions/profileActions";
import ROUTES, { ONBOARDING_PAGES } from "../../constants/routes";

const QuestionsOne = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // note: used for "update_profile", in which case we go back to profile
  let mode = searchParams.get("mode");

  const getFinancialProfile = props.getUserFinancialProfile;
  useEffect(() => {
    getFinancialProfile();
  }, [getFinancialProfile]);

  const onSubmit = (profileObj) => {
    props.asyncUpdateQuestionsPartOne(profileObj).then(() => {
      let nextRoute = ONBOARDING_PAGES.STEP_TWO;

      if (mode === "update_profile") {
        nextRoute += "?mode=update_profile";
      }
      navigate(nextRoute);
    });
  };

  const onBack = () => {
    if (mode === "update_profile") {
      navigate(ROUTES.STUDENT_LOAN_DASHBOARD);
    } else {
      navigate(ROUTES.STUDENT_LOAN_DASHBOARD);
    }
  };

  if (props.isLoading) {
    return (
      <section className="sw-onboarding-page-container section">
        <div className="container">
          <div className="sw-flex-layout--column-center">
            <LoadingSpinner></LoadingSpinner>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="sw-onboarding-page-container section">
      <div className="container">
        <div className=" sw-flex-layout--column-center">
          <p className="has-text-grey sw-padding--bottom-xl">Step 1 of 3</p>
          <div className="sw-container--fixed-width sw-padding--bottom-xl">
            <QuestionsPartOneForm
              onSubmit={onSubmit}
              onBack={onBack}
              initialValues={props.initialValues}
              goalsObj={props.goalsObj}
            ></QuestionsPartOneForm>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserFinancialProfile: () => dispatch(getUserFinancialProfile()),
    asyncUpdateQuestionsPartOne: (responsesObj) =>
      dispatch(asyncUpdateQuestionsPartOne(responsesObj)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const initialValues = {
    currentPayment: "",
    federalProgram: FEDERAL_PROGRAM_OPTIONS_MAP["Standard repayment"],
    employmentLoanForgiveness: "",
  };

  let goalsObj = {};
  if (state.profile.profile) {
    if (
      state.profile.profile.monthly_min_payment !== undefined &&
      state.profile.profile.monthly_min_payment !== null
    ) {
      initialValues.currentPayment = state.profile.profile.monthly_min_payment;
    }

    if (
      state.profile.profile.federal_program !== undefined &&
      state.profile.profile.federal_program !== null
    ) {
      initialValues.federalProgram = state.profile.profile.federal_program;
    }

    if (
      state.profile.profile.employment_loan_forgiveness !== undefined &&
      state.profile.profile.employment_loan_forgiveness !== null
    ) {
      initialValues.employmentLoanForgiveness =
        state.profile.profile.employment_loan_forgiveness;
    }

    let goalsArr = state.profile.profile.goals;
    if (goalsArr) {
      goalsArr.forEach((goal) => {
        if (GOAL_OPTIONS_MAP[goal]) {
          goalsObj[GOAL_OPTIONS_MAP[goal]] = true;
        }
      });
    }
  }

  return {
    isLoading: state.profile.getLoading || state.profile.updateLoading,
    initialValues,
    goalsObj,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsOne);
