import { useDispatch, useSelector } from "react-redux";

import { trackEvent, EVENTS } from "../../store/actions/analyticsActions";
import {
  getCheckoutLink,
  getPremiumPortal,
} from "../../store/actions/premiumActions";
import { setIAPConfirmMessage } from "../../store/actions/alertingActions";

export const usePremiumPlanCheckout = (returnUrl) => {
  const dispatch = useDispatch();

  const { currentUser, hasPremium } = useSelector((state) => {
    return state.auth;
  });

  const goToPortal = () => {
    dispatch(getPremiumPortal(returnUrl)).then((res) => {
      if (res) {
        // similar behavior as an HTTP redirect
        window.location.replace(res);
      }
    });
  };

  const onPremiumCheckout = (productId, priceId, freeTrial) => {
    const has_active_iap_subscription = currentUser.has_active_iap_subscription;
    const currentPlan = currentUser.subscription_plan
      ? currentUser.subscription_plan
      : "";

    dispatch(trackEvent(EVENTS.PREMIUM_PRICING_CLICKED), {
      product_clicked: productId,
      product_current: currentPlan,
      has_active_iap_subscription: has_active_iap_subscription,
    });

    if (has_active_iap_subscription) {
      dispatch(setIAPConfirmMessage());
      return;
    }

    if (hasPremium) {
      // if user already has plan, take them to manage plans
      goToPortal();
    } else {
      // otherwise take them to checkout page
      dispatch(getCheckoutLink(priceId, freeTrial, returnUrl, returnUrl)).then(
        (res) => {
          if (res) {
            // similar behavior as an HTTP redirect
            window.location.replace(res);
          }
        }
      );
    }
  };

  return onPremiumCheckout;
};
