import React from "react";

const LoansDropdownMenu = ({
  className,
  updateLoans,
  showGrid,
  toggleGrid,
}) => {
  let iconClass = showGrid ? "fas fa-check" : "";

  return (
    <div id="sw-loans-dropdown" className={className}>
      <div className="dropdown is-hoverable is-right">
        <div className="dropdown-trigger">
          <button
            className="button is-medium is-white"
            aria-haspopup="true"
            aria-controls="loans-dropdown-menu"
          >
            <span className="icon is-medium">
              <i className="fas fa-ellipsis-h" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div className="dropdown-menu" id="loans-dropdown-menu" role="menu">
          <div className="dropdown-content">
            <button
              className="sw-link-button dropdown-item is-hoverable"
              onClick={toggleGrid}
            >
              <span
                key="sw-checked-grid"
                className="icon is-small is-pulled-left"
              >
                <i key={iconClass}>
                  <span className={iconClass} />
                </i>
              </span>
              <span>Show Grid</span>
            </button>
            <button
              className="sw-link-button dropdown-item is-hoverable"
              onClick={updateLoans}
            >
              <span>Refresh</span>
              <span className="icon is-small is-pulled-left">
                <i className="fas fa-sync"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoansDropdownMenu;
