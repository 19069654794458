import React from "react";
import { useSelector, useDispatch } from "react-redux";

import PostToFeedComponent from "../../components/Communities/PostToFeedComponent";

import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";
import { asyncPost } from "../../store/actions/communityActions";

const TaskTypePrompt = ({ task, course, onCompleteTask }) => {
  const dispatch = useDispatch();

  const taskContent = task.content;
  const isComplete = task.progress.is_completed ? true : false;
  const promptData = taskContent.feed_item_prompt;
  let firstPrompt = null;
  if (promptData && promptData.length > 0) {
    firstPrompt = promptData[0];
  }

  const { currentUser } = useSelector((state) => {
    return state.auth;
  });

  const onCreatePost = (fields) => {
    dispatch(asyncPost(fields)).then(() => {
      onCompleteTask();
    });
    dispatch(
      trackEvent(EVENTS.PLAN_TASK_POST_ADDED, {
        plan_slug: course.course.slug,
        course_type: course.course.course_type,
        cohort_uuid: course.cohort.cohort_uuid,
      })
    );
  };

  return (
    <div className="box">
      <div className="sw-padding--top-lg sw-padding--bottom-md">
        <h1 className="title">{taskContent.title}</h1>
        {firstPrompt && (
          <p className="sw-enable-line-break">{firstPrompt.description}</p>
        )}
        {firstPrompt && (
          <p className="is-size-7 sw-enable-line-break">{firstPrompt.hint}</p>
        )}

        <div className="sw-padding--top-lg">
          <PostToFeedComponent
            showExtraOptions={false}
            authorProfile={currentUser}
            communities={[course.cohort.community]}
            onCreatePost={onCreatePost}
            placeholderText={firstPrompt ? firstPrompt.hint : ""}
          ></PostToFeedComponent>
        </div>

        <div className="sw-padding--top-lg has-text-centered">
          <button
            className={"button is-secondary"}
            disabled={isComplete ? true : false}
            onClick={onCompleteTask}
          >
            {isComplete ? "Completed" : "Skip and mark complete"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TaskTypePrompt;
