import React from "react";

import Modal from "../Modal/Modal";
import { ICONS } from "../../constants/images";
import { ImageIconSqr } from "../Icon/ImageIcon";
import { PLAID_PRODUCTS } from "../../constants/constants";
import PlaidAddAccountButton from "../PlaidIntegration/PlaidAddAccountButton";

const AccountTypeItem = ({ text, imgSrc, imgAlt, plaidProduct, onSuccess }) => {
  return (
    <li className="is-flex is-flex-grow-1">
      <PlaidAddAccountButton
        className="button is-white sw-modal-button"
        onSyncSuccess={onSuccess}
        plaidProduct={plaidProduct}
      >
        <ImageIconSqr iconSrc={imgSrc} altText={imgAlt}></ImageIconSqr>
        <p className="sw-padding--left-md sw-section-header">{text}</p>
      </PlaidAddAccountButton>
    </li>
  );
};

const SelectAccountType = ({
  onClose,
  isOpen,
  onPlaidSuccess,
  onTrackManually,
}) => {
  const onPlaidSyncSuccess = () => {
    if (onPlaidSuccess) onPlaidSuccess();
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} title="Link an account">
      <ul className="is-flex-grow-1">
        <AccountTypeItem
          text="Cash (i.e. savings, checkings)"
          imgSrc={ICONS.CASH_ICON}
          imgAlt="bill"
          onSuccess={onPlaidSyncSuccess}
          plaidProduct={PLAID_PRODUCTS.TRANSACTIONS}
        ></AccountTypeItem>
        <AccountTypeItem
          text="Debt (i.e. credit card, student loan, mortgage)"
          imgSrc={ICONS.CREDIT_ICON}
          imgAlt="credit card"
          onSuccess={onPlaidSyncSuccess}
          plaidProduct={PLAID_PRODUCTS.LIABILITIES}
        ></AccountTypeItem>
        <AccountTypeItem
          text="Investments (i.e. retirement, brokerage, stocks)"
          imgSrc={ICONS.CHART_UP}
          imgAlt="chart going up"
          onSuccess={onPlaidSyncSuccess}
          plaidProduct={PLAID_PRODUCTS.INVESTMENTS}
        ></AccountTypeItem>
        <li className="is-flex is-flex-grow-1">
          <button
            className="button is-white sw-modal-button"
            onClick={onTrackManually}
          >
            <ImageIconSqr
              iconSrc={ICONS.PLUS_SIGN}
              altText={"plus sign"}
            ></ImageIconSqr>
            <p className="sw-padding--left-md sw-section-header">
              Track manually
            </p>
          </button>
        </li>
      </ul>
    </Modal>
  );
};

export default SelectAccountType;
