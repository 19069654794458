import React from "react";

import Modal from "../Modal/Modal";
import NumberInput from "../Input/NumberInput";
import TextInput from "../Input/TextInput";
import Select from "../Input/Select";

import { useFormFields } from "../Form/useFormFields";

const ASSET_TYPES = [
  "Checking",
  "Savings",
  "Investment",
  "Real estate",
  "Auto",
  "Other",
];

export const ASSET_TYPES_MAP = {
  Checking: "checking",
  Savings: "savings",
  Investment: "investment",
  "Real estate": "real_estate",
  Auto: "real_auto",
  Other: "other_asset",
};

const TrackAssetModal = ({ onClose, isOpen, onBack, onNext }) => {
  const [fields, handleFieldChange] = useFormFields({
    accountType: ASSET_TYPES_MAP["Checking"],
    nickname: "",
    balance: "",
  });

  const footerObj = (
    <button
      form="sw-track-asset-form"
      type="submit"
      className="button is-primary"
    >
      Track this account
    </button>
  );

  const onSubmitForm = (event) => {
    event.preventDefault();
    onNext(fields);
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} onBack={onBack} footer={footerObj}>
      <form id="sw-track-asset-form" onSubmit={(event) => onSubmitForm(event)}>
        <div className="sw-padding--left-lg sw-padding--right-lg">
          <p className="modal-card-body__title">Track an asset</p>

          <div className="field">
            <Select
              label="Type"
              name="accountType"
              className="fs-block is-medium"
              value={fields.accountType}
              onChange={handleFieldChange}
            >
              {ASSET_TYPES.map((accountType) => {
                return (
                  <option
                    key={ASSET_TYPES_MAP[accountType]}
                    value={ASSET_TYPES_MAP[accountType]}
                  >
                    {accountType}
                  </option>
                );
              })}
            </Select>
          </div>

          <div className="field">
            <TextInput
              label="Name"
              name="nickname"
              required={true}
              className="fs-block"
              value={fields.nickname}
              onChange={handleFieldChange}
              placeholder="My asset account"
            ></TextInput>
          </div>

          <div className="field">
            <NumberInput
              label="Current value"
              name="balance"
              required={true}
              className="fs-block"
              value={fields.balance}
              onChange={handleFieldChange}
            ></NumberInput>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default TrackAssetModal;
