import React, { useState } from "react";
import { connect } from "react-redux";

import AddAccountModal from "../AddAccountFlow/AddAccountModal";
import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";

const AddAccountButton = ({ onAddAccountSuccess, trackEventFn, className }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClickFn = () => {
    trackEventFn(EVENTS.ADD_ACCOUNT_CLICKED);
    setIsOpen(true);
  };

  return (
    <div>
      {isOpen && (
        <AddAccountModal
          onClose={() => setIsOpen(false)}
          isOpen={isOpen}
          onAddAccountSuccess={onAddAccountSuccess}
        ></AddAccountModal>
      )}
      <button
        className={className ? className : "button is-rounded"}
        onClick={onClickFn}
      >
        + Add account
      </button>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackEventFn: (event, properties) =>
      dispatch(trackEvent(event, properties)),
  };
};

export default connect(null, mapDispatchToProps)(AddAccountButton);
