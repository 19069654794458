import React from "react";

const ProgressBar = ({ progressPercent }) => {
  let percentShown = progressPercent;
  // add boundaries
  if (percentShown < 0) {
    // don't show negatives
    percentShown = 0;
  } else if (percentShown > 100) {
    // cap at 100% max
    percentShown = 100;
  }

  const backgroundDiv = {
    height: 30,
    width: "100%",
    backgroundColor: "#EFEFEF",
    borderRadius: 30,
  };

  const progressDiv = {
    height: "100%",
    width: `${percentShown}%`,
    backgroundColor: "#357887",
    borderRadius: 30,
    textAlign: "right",
  };

  return (
    <div style={backgroundDiv}>
      <div style={progressDiv}></div>
    </div>
  );
};

export default ProgressBar;
