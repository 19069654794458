import React from "react";
import ExpandableMessageBox from "../Messages/ExpandableMessageBox";
import helpers from "../../services/helpers";

const EMPTY_STRING = "Not Disclosed";

const LoanList = props => {
  const loanList = props.loanList;
  if (!loanList || loanList.length < 1) {
    return EMPTY_STRING;
  }

  return loanList.map((eligibleLoan, index) => {
    return (
      <span key={index}>
        {index ? ", " : ""}
        {eligibleLoan}
      </span>
    );
  });
};

// Assume that the first item in the list is the minimum degree
// This is what we do for the landing page, and the degrees should be in order.
// Note: Check this to make sure it works.
const MinDegree = props => {
  const degreeList = props.degreeList;
  if (!degreeList || degreeList.length < 1) {
    return EMPTY_STRING;
  }

  return degreeList[0];
};

const CurrencyOrEmpty = props => {
  const value = props.value;
  const emptyValue = props.empty;

  return helpers.notNullnotUndefined(value)
    ? helpers.currencyFormat(value, 0)
    : emptyValue;
};

const WhatTheyLookFor = props => {
  let isPersonalDebt = props.isPersonalDebt || false;

  const lender = props.lender;
  const tableContent = (
    <table className="sw-expandable-message-box--table">
      <tbody>
        <tr>
          <td className="has-text-grey">Minimum credit</td>
          <td className="is-capitalized">
            {lender.content.minimum_credit_score || EMPTY_STRING}
          </td>
        </tr>
        <tr>
          <td className="has-text-grey">Minimum income</td>
          <td className="is-capitalized">
            <CurrencyOrEmpty
              value={lender.content.minimum_income}
              empty={EMPTY_STRING}
            ></CurrencyOrEmpty>
          </td>
        </tr>
        <tr>
          <td className="has-text-grey">Loan balance minimum</td>
          <td className="is-capitalized">
            <CurrencyOrEmpty
              value={lender.content.loan_balance_min}
              empty={EMPTY_STRING}
            ></CurrencyOrEmpty>
          </td>
        </tr>
        <tr>
          <td className="has-text-grey">Loan balance maximum</td>
          <td className="is-capitalized">
            <CurrencyOrEmpty
              value={lender.content.loan_balance_max}
              empty={EMPTY_STRING}
            ></CurrencyOrEmpty>
          </td>
        </tr>
        {!isPersonalDebt && (
          <tr>
            <td className="has-text-grey">Eligible loans</td>
            <td className="is-capitalized">
              <LoanList loanList={lender.content.eligible_loans}></LoanList>
            </td>
          </tr>
        )}
        {!isPersonalDebt && (
          <tr>
            <td className="has-text-grey">Minimum degree</td>
            <td className="is-capitalized">
              <MinDegree
                degreeList={lender.content.eligible_degrees}
              ></MinDegree>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );

  return (
    <ExpandableMessageBox
      text="What they look for"
      content={tableContent}
    ></ExpandableMessageBox>
  );
};

export default WhatTheyLookFor;
