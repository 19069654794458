import React from "react";
import { connect } from "react-redux";
/**
 * Use react-player/lazy to lazy load the appropriate
 * player for the url you pass in. This adds several
 * reactPlayer chunks to your output, but reduces your main bundle size.
 */
import ReactPlayer from "react-player/lazy";
import { trackEvent, EVENTS } from "../../store/actions/analyticsActions";

const VideoPlayer = (props) => {
  const videoLink = props.link;
  const onPlay = () => {
    props.trackEventFn(EVENTS.VIDEO_VIEWED, { link: videoLink });
  };

  return (
    <div className="sw-react-player-wrapper">
      <ReactPlayer url={videoLink} controls {...props} onPlay={onPlay} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackEventFn: (eventName, properties) =>
      dispatch(trackEvent(eventName, properties)),
  };
};

export default connect(null, mapDispatchToProps)(VideoPlayer);
