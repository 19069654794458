import React from "react";
import ExpandableMessageBox from "../Messages/ExpandableMessageBox";

const EMPTY_STRING = "Not Disclosed";

const TermList = (props) => {
  const termList = props.terms;
  if (!termList || termList.length < 1) {
    return EMPTY_STRING;
  }

  // sort list before using, treat these as numbers
  let sortedList = termList.sort(function compare(a, b) {
    return Number(a) - Number(b);
  });

  return sortedList.map((term, index) => {
    return (
      <span key={index}>
        {index ? ", " : ""}
        {term}
      </span>
    );
  });
};

const SpecificDetails = (props) => {
  const content = props.lender.content;
  let isPersonalDebt = props.isPersonalDebt || false;

  const tableContent = (
    <table className="sw-expandable-message-box--table">
      <tbody>
        {!isPersonalDebt && (
          <tr>
            <td className="has-text-grey">Servicer</td>
            <td className="is-capitalized">{content.servicer}</td>
          </tr>
        )}
        <tr>
          <td className="has-text-grey">Easy rate check</td>
          <td>{content.easy_rate_check ? "Yes" : "No"}</td>
        </tr>
        {!isPersonalDebt && (
          <tr>
            <td className="has-text-grey">Forbearance</td>
            <td>{content.forbearance && content.forbearance[0]}</td>
          </tr>
        )}
        {!isPersonalDebt && (
          <tr>
            <td className="has-text-grey">Available terms (years)</td>
            <td>
              <TermList terms={content.available_terms}></TermList>
            </td>
          </tr>
        )}
        {isPersonalDebt && (
          <tr>
            <td className="has-text-grey">Available terms</td>
            <td>{content.available_terms}</td>
          </tr>
        )}
        {!isPersonalDebt && (
          <tr>
            <td className="has-text-grey">Origination or prepayment fees</td>
            <td className="is-capitalized">
              {content.origination_prepayment_fees &&
                content.origination_prepayment_fees[0]}
            </td>
          </tr>
        )}
        {isPersonalDebt && (
          <tr>
            <td className="has-text-grey">Prepayment fees</td>
            <td className="is-capitalized">
              {content.prepayment_fees ? "Yes" : "No"}
            </td>
          </tr>
        )}

        {isPersonalDebt && (
          <tr>
            <td className="has-text-grey">Origination fees</td>
            <td>{content.origination_fees}</td>
          </tr>
        )}
        {!isPersonalDebt && (
          <tr>
            <td className="has-text-grey">Cosigner allowed</td>
            <td>{content.cosigner_allowed ? "Yes" : "No"}</td>
          </tr>
        )}
        <tr>
          <td className="has-text-grey">Locations</td>
          <td>{content.locations || EMPTY_STRING}</td>
        </tr>
      </tbody>
    </table>
  );

  return (
    <ExpandableMessageBox
      text="The specific details"
      content={tableContent}
    ></ExpandableMessageBox>
  );
};

export default SpecificDetails;
