import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { initNexus, updateBorrower } from "../../store/actions/payitoffActions";

const usePayitoffNexusEnroll = (payitoffId, userId, onSuccessFn) => {
  const dispatch = useDispatch();

  useEffect(() => {
    let nexusId = payitoffId ? payitoffId : userId;
    dispatch(initNexus(nexusId));
  }, [dispatch, initNexus, payitoffId, userId]);

  const onNexusClick = (e) => {
    window.Nexus.enroll({
      // If using API—`repayment_plan.type` from Repayment Options
      // If using Nexus.assess—`requested_plan.name` from assess response
      // This field is OPTIONAL
      // plan: "repaye",

      // the function to call when borrower is done
      onSuccess: function (response) {
        dispatch(updateBorrower(response.result.borrower.uuid));
        if (onSuccessFn) {
          onSuccessFn();
        }
      },
      // the function to call when borrower quits or error occurs
      onExit: function (response) {
        dispatch(updateBorrower(response.result.borrower.uuid));

        // we call this here too, since many users will quit the flow before enrolling
        if (onSuccessFn) {
          onSuccessFn();
        }
      },
    });
  };

  return onNexusClick;
};

export default usePayitoffNexusEnroll;
