import React, { useCallback, useEffect } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";

import { toggleIntercomVisibility } from "../../store/actions/analyticsActions";

const PageModal = ({
  isOpen,
  onClose,
  onBack,
  title,
  children,
  footer,
  hideHeader,
  toggleIntercomVisibilityFn,
}) => {
  const closeOnEscapeKeyDown = useCallback(
    (e) => {
      if ((e.charCode || e.keyCode) === 27) {
        onClose();
      }
    },
    [onClose]
  );

  // this handles hiding the intercom chat when the modal is open
  useEffect(() => {
    toggleIntercomVisibilityFn(isOpen);

    return function cleanup() {
      toggleIntercomVisibilityFn(false);
    };
  }, [toggleIntercomVisibilityFn, isOpen]);

  // cleanup - make sure to remove event listener
  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, [closeOnEscapeKeyDown]);

  let modalClassName = "modal sw-page-modal";
  if (isOpen) {
    modalClassName += " is-active";
  }

  return ReactDOM.createPortal(
    <div className="is-pulled-right">
      <div className={modalClassName}>
        <div className="modal-background" onClick={onClose}></div>
        <div className="modal-content">
          <button
            onClick={onClose}
            className="modal-close is-large"
            aria-label="close"
          ></button>
          {children}
        </div>
      </div>
    </div>,
    document.getElementById("root")
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleIntercomVisibilityFn: (hideLauncher) =>
      dispatch(toggleIntercomVisibility(hideLauncher)),
  };
};

export default connect(null, mapDispatchToProps)(PageModal);
