import React, { Component } from "react";
import helpers from "../../services/helpers";

class PasswordForgetForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: "",
      },
      valid: false,
      error: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    event.preventDefault();
    const obj = this.state.formData;
    const enteredText = event.target.value;
    obj[event.target.name] = enteredText;
    this.setState(obj);

    if (!helpers.validateEmail(this.state.formData.email)) {
      this.setState({ error: "Email must be a valid email address." });
    } else {
      this.setState({ valid: true });
      this.setState({ error: "" });
    }
  }

  async handleUserFormSubmit(event) {
    event.preventDefault();
    this.props.onSubmit(this.state.formData.email);
  }

  render() {
    return (
      <form
        onSubmit={(event) => this.handleUserFormSubmit(event)}
        className="sw-content sw-content--wide"
      >
        <h1 className="title">
          <span role="img" aria-label="Closed Lock With Key Emoji">
            🔐
          </span>{" "}
          Forgot your password?
        </h1>
        <div className="field">
          <label className="label has-text-weight-normal" htmlFor="email">
            Email Address
          </label>
          <div className="control has-icons-left has-icons-right">
            <input
              name="email"
              id="email"
              className="input is-medium fs-block"
              type="email"
              required
              value={this.state.formData.email}
              onChange={this.handleChange}
              autoComplete="email"
            />
            <span className="icon is-small is-left">
              <i className="fas fa-envelope"></i>
            </span>
          </div>
          {this.state.error && (
            <p className="help is-danger">{this.state.error}</p>
          )}
        </div>
        <br />
        <button
          type="submit"
          className="button is-dark is-medium"
          disabled={!this.state.valid}
        >
          Send Me Instructions
        </button>
      </form>
    );
  }
}

export default PasswordForgetForm;
