import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { ADMIN_USER_PAGES, ADMIN_ROUTES } from "../../constants/routes";
import { NW_TABS } from "../../constants/constants";

import NetWorthTracker from "../../components/NetWorth/NetWorthTracker";

import {
  getNetWorthForUser,
  getBadgesForUser,
} from "../../store/actions/adminActions";

const AdminUserNetWorth = ({
  userId,
  loanResponse,
  getNetWorthForUserFn,
  getBadgesForUserFn,
}) => {
  // on page load, get net worth items, graph, and user profile
  useEffect(() => {
    // getBadgesForUserFn(userId);
    getNetWorthForUserFn(userId);
  }, [getNetWorthForUserFn, userId]);

  const [searchParams] = useSearchParams();
  const tab = searchParams.get("filter") || NW_TABS.NET_WORTH;

  return (
    <div className="fs-block">
      <div className="sw-container--fixed-width sw-padding--bottom-xl">
        <NetWorthTracker
          tab={tab}
          baseRoute={`${ADMIN_ROUTES.USER_PREFIX}${userId}/${ADMIN_USER_PAGES.NET_WORTH}`}
          netWorth={loanResponse}
          isLoading={loanResponse ? false : true}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const userId = ownProps.userId;
  let loanResponse = null;
  let badgesResponse = null;

  if (state.admin.userLoans && state.admin.userLoans[userId]) {
    loanResponse = state.admin.userLoans[userId];
  }

  if (state.admin.badgesInfo && state.admin.badgesInfo[userId]) {
    badgesResponse = state.admin.badgesInfo[userId];
  }

  return {
    userId: userId,
    loanResponse: loanResponse,
    badgesResponse: badgesResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNetWorthForUserFn: (userId) => dispatch(getNetWorthForUser(userId)),
    getBadgesForUserFn: (userId) => dispatch(getBadgesForUser(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserNetWorth);
