import { AUTH_ACTIONS } from "./authReducer";

const initState = {
  blogs: null,
  videos: [],
  getError: null,
  getLoading: false,
};

const ACTION_PREFIX = "BLOG/";

export const BLOG_ACTIONS = {
  GET: ACTION_PREFIX + "GET",
  GET_ERROR: ACTION_PREFIX + "GET_ERROR",
  GET_SUCCESS: ACTION_PREFIX + "GET_SUCCESS",
  GET_VIDEOS: ACTION_PREFIX + "GET_VIDEOS",
  GET_VIDEOS_ERROR: ACTION_PREFIX + "GET_VIDEOS_ERROR",
  GET_VIDEOS_SUCCESS: ACTION_PREFIX + "GET_VIDEOS_SUCCESS",
};

const lendersReducer = (state = initState, action) => {
  switch (action.type) {
    case BLOG_ACTIONS.GET:
      return {
        ...state,
        getError: null,
        getLoading: true,
      };
    case BLOG_ACTIONS.GET_ERROR:
      return {
        ...state,
        blogs: null,
        getError: action.err.message,
        getLoading: false,
      };
    case BLOG_ACTIONS.GET_SUCCESS:
      return {
        ...state,
        blogs: action.blogs,
        getError: null,
        getLoading: false,
      };
    case BLOG_ACTIONS.GET_VIDEOS:
      return {
        ...state,
        getError: null,
        getLoading: true,
      };
    case BLOG_ACTIONS.GET_VIDEOS_ERROR:
      return {
        ...state,
        videos: [],
        getError: action.err.message,
        getLoading: false,
      };
    case BLOG_ACTIONS.GET_VIDEOS_SUCCESS:
      return {
        ...state,
        videos: action.videos,
        getError: null,
        getLoading: false,
      };
    case AUTH_ACTIONS.SIGNOUT:
      return initState; // Always return the initial state
    default:
      return state;
  }
};

export default lendersReducer;
