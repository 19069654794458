import React from "react";

import {
  CashAccountList,
  InvestmentAccountList,
  OtherAssetAccountsList,
} from "./AccountAccordionLists";

const AssetsAccounts = ({ deposits, investments, otherAssets, sortBy }) => {
  return (
    <React.Fragment>
      {deposits &&
        (deposits.plaid_accounts.length > 0 ||
          deposits.me_accounts.length > 0) && (
          <CashAccountList
            accounts={deposits.plaid_accounts}
            manualAccounts={deposits.me_accounts}
            totalAmount={deposits.total}
            isoCurrencyCode={"USD"}
            sortBy={sortBy}
          />
        )}
      {investments &&
        (investments.plaid_accounts.length > 0 ||
          investments.me_accounts.length > 0) && (
          <InvestmentAccountList
            accounts={investments.plaid_accounts}
            manualAccounts={investments.me_accounts}
            totalAmount={investments.total}
            isoCurrencyCode={"USD"}
            sortBy={sortBy}
          />
        )}
      {otherAssets && otherAssets.me_accounts.length > 0 && (
        <OtherAssetAccountsList
          manualAccounts={otherAssets.me_accounts}
          totalAmount={otherAssets.total}
          isoCurrencyCode={"USD"}
          sortBy={sortBy}
        />
      )}
    </React.Fragment>
  );
};

export default AssetsAccounts;
