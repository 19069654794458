import React, { useMemo } from "react";

import "react-datepicker/dist/react-datepicker.css";

const AdminCommunityForm = ({
  onSubmitForm,
  fields,
  handleFieldChange,
  submitLoading,
}) => {
  const newChallengeValidated = useMemo(() => {
    const validationState = {
      title: fields.title.length > 0,
      isPrivate: true || false,
      isGlobal: true || false,
      description: true,
      imageUrl: fields.imageUrl.length > 0,
      thumbnailImageUrl: fields.thumbnailImageUrl.length > 0,
    };
    return !Object.values(validationState).includes(false);
  }, [fields]);

  return (
    <form onSubmit={onSubmitForm}>
      <div className="field">
        <label className="label has-text-weight-normal">Title</label>
        <div className="control">
          <input
            name="title"
            id="title"
            className="input is-medium fs-block"
            type="text"
            maxLength={255}
            required
            value={fields.title}
            onChange={handleFieldChange}
          />
        </div>
      </div>

      <div className="field">
        <label className="label has-text-weight-normal">
          Description (optional)
        </label>
        <div className="control">
          <textarea
            name="description"
            id="description"
            className="input is-medium fs-block"
            type="text"
            maxLength={500}
            style={{ height: 200 }}
            value={fields.description}
            onChange={handleFieldChange}
          />
        </div>
      </div>
      <div className="field">
        <label className="label has-text-weight-normal">
          Image URL (main image, wide for community pages)
        </label>
        <div className="control">
          <input
            name="imageUrl"
            id="imageUrl"
            className="input is-medium fs-block"
            type="text"
            required
            value={fields.imageUrl}
            onChange={handleFieldChange}
          />
        </div>
      </div>

      <div className="field">
        <label className="label has-text-weight-normal">
          Thumbnail Image URL (small, for explore view)
        </label>
        <div className="control">
          <input
            name="thumbnailImageUrl"
            id="thumbnailImageUrl"
            className="input is-medium fs-block"
            type="text"
            required
            value={fields.thumbnailImageUrl}
            onChange={handleFieldChange}
          />
        </div>
      </div>

      <div className="field">
        <label className="label has-text-weight-normal">Is private</label>
        <div className="select fs-block field">
          <select
            id="isPrivate"
            name="isPrivate"
            value={fields.isPrivate}
            onChange={handleFieldChange}
          >
            {[true, false].map((arrayItem) => {
              return (
                <option key={arrayItem} value={arrayItem}>
                  {arrayItem ? "true" : "false"}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="field">
        <label className="label has-text-weight-normal">Is global</label>
        <div className="select fs-block field">
          <select
            id="isGlobal"
            name="isGlobal"
            value={fields.isGlobal}
            onChange={handleFieldChange}
          >
            {[true, false].map((arrayItem) => {
              return (
                <option key={arrayItem} value={arrayItem}>
                  {arrayItem ? "true" : "false"}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <br />
      <div className="has-text-centered">
        <input
          type="submit"
          className={
            submitLoading
              ? "button is-warning is-outline is-loading"
              : "button is-warning is-outline"
          }
          value={"Submit"}
          disabled={!newChallengeValidated}
        />
      </div>
    </form>
  );
};

export default AdminCommunityForm;
