const helpers = {
  currencyFormat: (value, decimalPlaces, currencyFormat) => {
    if (value === null || value === undefined) {
      return null;
    }

    let fractionDigits = decimalPlaces;

    // can't do falsy check, in case decimal places is 0
    if (decimalPlaces === undefined || decimalPlaces === null) {
      fractionDigits = 2;
    }

    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currencyFormat || "USD",
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    }).format(value);
  },

  /**
   * Returns currency format, rounds to nearest 100k
   * I.e. 249999 ==> $250K
   * @param {*} value
   * @param {*} isAbsolute - if should use absolute value (for negatives)
   * @param {*} currencyFormat
   * @returns
   */
  currencyFormatInKs: (value, isAbsolute, currencyFormat) => {
    if (value === null || value === undefined) {
      return null;
    }

    const HUNDRED_K = 100000;
    const ONE_K = 1000;

    let modifiedValue = Math.abs(value);
    if (Math.abs(value) >= HUNDRED_K) {
      // round to nearest 100k, and drop last 3 digits
      modifiedValue = Math.round(value / ONE_K);
    }

    if (isAbsolute) {
      modifiedValue = Math.abs(modifiedValue);
    }

    modifiedValue = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currencyFormat || "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(modifiedValue);

    if (Math.abs(value) >= HUNDRED_K) {
      // add the "K" only if over hundred thousand
      return modifiedValue + "K";
    }

    return modifiedValue;
  },

  interestFormat: (value) => {
    if (value === null || value === undefined) {
      return null;
    }

    // note: we are usually getting a percent already
    value = value / 100;

    return new Intl.NumberFormat("en-US", {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 4,
    }).format(value);
  },

  setCookie: (name, value) => {
    let cookieString = `${name}=${value}; SameSite=Strict; path=/;`;
    document.cookie = cookieString;
  },

  deleteCookie: (name) => {
    document.cookie = `${name}=''; expires=Thu, 18 Dec 2013 12:00:00 UTC; SameSite=Strict; path=/;`;
  },

  getCookie: (cname) => {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },

  getMessageFromErrObj: (errObj, backupMessageString) => {
    let message =
      backupMessageString ||
      "Something went wrong. Please contact the Snowball team and we'll help you resolve this.";
    if (
      errObj &&
      errObj.response &&
      errObj.response.data &&
      errObj.response.data.message
    ) {
      message = errObj.response.data.message;
    }
    return message;
  },

  validateEmail(email) {
    // eslint-disable-next-line
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },

  notNullnotUndefined(thing) {
    return thing !== null && thing !== undefined;
  },

  convertMonthsToYears(months) {
    return Math.round(months / 12);
  },

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },

  // Option formats:
  // https://medium.com/swlh/use-tolocaledatestring-to-format-javascript-dates-2959108ea020
  DATE_OPTIONS: {
    M_D: {
      month: "numeric",
      day: "numeric",
    },
    MMM_D: {
      month: "short",
      day: "numeric",
    },
  },

  getDateFromTimestamp(timestamp, options) {
    if (!timestamp) {
      // if no timestamp return null
      return "";
    }

    // use date instead of moment
    const date = new Date(timestamp * 1000);

    if (options) {
      return date.toLocaleDateString(undefined, options);
    }

    return date.toLocaleDateString();
  },

  getDaysDiff(firstDateTimestamp, secondDateTimestamp) {
    var date1 = new Date(firstDateTimestamp);
    var date2 = new Date(secondDateTimestamp * 1000);

    // Calculate the time difference of two dates
    var diffInTime = date2.getTime() - date1.getTime();

    // Calculate the no. of days between two dates
    return Math.floor(diffInTime / (1000 * 60 * 60 * 24));
  },

  /**
   * Looks for pathname in location to check for a redirect route
   * otherwise returns defaultRoute
   * @param {*} location 
   * @param {*} defaultRoute 
   * @returns 
   */
  getNextRoute(location, defaultRoute) {
    if (
      location &&
      location.state &&
      location.state.from &&
      location.state.from.pathname
    ) {
      return location.state.from.pathname;
    }
    return defaultRoute;  
  }
};

export default helpers;
