import React from "react";
import { connect } from "react-redux";

import { trackEvent, EVENTS } from "../../store/actions/analyticsActions";

const GuidePreview = (props) => {
  const postLink = props.link;
  const title = props.title;
  const image = props.image;

  return (
    <div className="tile is-child">
      <a
        className="content"
        href={postLink}
        onClick={() =>
          props.trackEvent(EVENTS.RESOURCE_GUIDE_CLICKED, { post: postLink })
        }
      >
        {image && (
          <div>
            <img src={image} alt={title} />
          </div>
        )}
      </a>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackEvent: (eventName, properties) =>
      dispatch(trackEvent(eventName, properties)),
  };
};

export default connect(null, mapDispatchToProps)(GuidePreview);
