import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

import PlaidReconnectWrapper from "./PlaidReconnectWrapper";
import { asyncCreateLinkToken } from "../../store/actions/netWorthActions";

const PlaidReconnectButton = ({
  children,
  className,
  itemId,
  asyncCreateLinkTokenFn,
}) => {
  // token, saved in state
  const [token, setToken] = useState(null);

  useEffect(() => {
    (async () => {
      const linkToken = await asyncCreateLinkTokenFn(null, itemId);
      setToken(linkToken);
    })();
  }, [asyncCreateLinkTokenFn, itemId, setToken]);

  if (token) {
    return (
      <PlaidReconnectWrapper token={token} style={className}>
        {children}
      </PlaidReconnectWrapper>
    );
  } else return <div className="loader"></div>;
};

const mapDispatchToProps = (dispatch) => {
  return {
    asyncCreateLinkTokenFn: (plaidProduct, itemId) =>
      dispatch(asyncCreateLinkToken(plaidProduct, itemId)),
  };
};

export default connect(null, mapDispatchToProps)(PlaidReconnectButton);
