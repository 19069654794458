import { AUTH_ACTIONS } from "./authReducer";

const initState = {
  profile: null,
  getError: null,
  getLoading: false,
  updateError: null,
  updateLoading: false
};

const ACTION_PREFIX = "PROFILE/";

export const PROFILE_ACTIONS = {
  GET: ACTION_PREFIX + "GET",
  GET_ERROR: ACTION_PREFIX + "GET_ERROR",
  GET_SUCCESS: ACTION_PREFIX + "GET_SUCCESS",
  UPDATE: ACTION_PREFIX + "UPDATE",
  UPDATE_ERROR: ACTION_PREFIX + "UPDATE_ERROR",
  UPDATE_SUCCESS: ACTION_PREFIX + "UPDATE_SUCCESS"
};

const studentLoansReducer = (state = initState, action) => {
  switch (action.type) {
    case PROFILE_ACTIONS.GET:
      return {
        ...state,
        getError: null,
        getLoading: true
      };
    case PROFILE_ACTIONS.GET_ERROR:
      return {
        ...state,
        getError: action.err.message,
        getLoading: false
      };
    case PROFILE_ACTIONS.GET_SUCCESS:
      return {
        ...state,
        profile: action.res.data.data.profile,
        getError: null,
        getLoading: false
      };
    case PROFILE_ACTIONS.UPDATE:
      return {
        ...state,
        updateError: null,
        updateLoading: true
      };
    case PROFILE_ACTIONS.UPDATE_ERROR:
      return {
        ...state,
        updateError: action.err.message,
        updateLoading: false
      };
    case PROFILE_ACTIONS.UPDATE_SUCCESS:
      return {
        ...state,
        profile: action.res.data.data.profile,
        updateError: null,
        updateLoading: false
      };
    case AUTH_ACTIONS.SIGNOUT:
      return initState; //Always return the initial state
    default:
      return state;
  }
};

export default studentLoansReducer;
