export const hideSideMenuNavbar = () => {
  let sideMenu = document.querySelector(".sw-layout-side-nav");
  sideMenu.classList.remove("is-active");
};

export const onToggleNav = () => {
  let menu = document.querySelector(".navbar-menu");
  menu.classList.toggle("is-active");
};

export const onToggleSideNav = () => {
  let sideMenu = document.querySelector(".sw-layout-side-nav");
  sideMenu.classList.toggle("is-active");
};
