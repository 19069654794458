import React, { Component } from "react";

class ExpandableMessage extends Component {
  constructor(props) {
    super(props);
    this.state = { isExpanded: false };
    this.toggleExpand = this.toggleExpand.bind(this);
  }

  toggleExpand() {
    this.setState({ isExpanded: !this.state.isExpanded });
  }

  render() {
    const expandedIcon = (
      <i className="fas fa-angle-down" aria-hidden="true"></i>
    );

    const notExpandedIcon = (
      <i className="fas fa-angle-up" aria-hidden="true"></i>
    );

    let buttonText = this.props.text;
    if (this.state.isExpanded) buttonText = "Hide";

    return (
      <div className="sw-expandable-message">
        <button
          className="button is-small sw-button-link is-fullwidth"
          onClick={this.toggleExpand}
        >
          <span>{buttonText}</span>
          {!this.state.isExpanded && (
            <span className="icon is-small">{expandedIcon}</span>
          )}
          {this.state.isExpanded && (
            <span className="icon is-small">{notExpandedIcon}</span>
          )}
        </button>
        {this.state.isExpanded && (
          <div className="sw-expandable-message--content is-size-7 sw-flex-layout--column-center">
            {this.props.content}
          </div>
        )}
      </div>
    );
  }
}

export default ExpandableMessage;
