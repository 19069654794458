import React from "react";

import TaskTypePrompt from "./TaskTypePrompt";
import TaskTypeContent from "./TaskTypeContent";
import { ICONS } from "../../constants/images";
import { useCourseRoutes } from "./useCourseRouting";
import { useNavigate } from "react-router-dom";

const TaskContent = ({ task, course, onCompleteTask }) => {
  const { getVideoRoute } = useCourseRoutes();

  const taskContent = task.content;
  const videoUrl = task.content.video_url;
  const taskType = task.task.task_type;
  const navigate = useNavigate();
  const VIDEO_ROUTE = getVideoRoute(course, task, true);

  return (
    <div className="sw-course-content-container">
      <div className="sw-course-cover-img is-full-screen">
        {videoUrl ? (
          <div>
            <video className="sw-course-video-preview">
              <source src={videoUrl} type="video/mp4" />
            </video>
            <div
              className="sw-course-play-button-overlay"
              onClick={() => navigate(VIDEO_ROUTE)}
            >
              <div className="sw-course-play-button-container">
                <img src={ICONS.PLAY_BUTTON} alt="play button"></img>
              </div>
            </div>
          </div>
        ) : (
          <img
            src={taskContent.cover_photo_image.filename}
            alt={taskContent.cover_photo_image.alt}
          />
        )}
      </div>
      {taskType === "content" && (
        <TaskTypeContent
          course={course}
          task={task}
          onCompleteTask={onCompleteTask}
        ></TaskTypeContent>
      )}
      {taskType === "prompt" && (
        <TaskTypePrompt
          task={task}
          onCompleteTask={onCompleteTask}
          course={course}
        ></TaskTypePrompt>
      )}
    </div>
  );
};

export default TaskContent;
