import React, { useState } from "react";
import { Link } from "react-router-dom";

import InfoBox from "../Box/InfoBox";
import PageModal from "../Modal/PageModal";
import { LINK_URLS } from "../../constants/constants";
import ROUTES from "../../constants/routes";

const contentWhatYouShouldKnow = (
  <div className="content">
    <p>
      Interest on federal student loans resumed Sept. 1, 2023, and the Education
      Department will begin collecting monthly payments from borrowers starting
      in October.
      <br></br>
      <br></br>A new repayment plan, SAVE, has been introduced that can lower
      your payments and eventually provide loan forgiveness for qualifying
      loans.
      <br />
      <br />
      <span>Here are key dates to note:</span>
    </p>
    <ul>
      <li>
        <strong>September 1, 2023:</strong> Interest begins to accrue again on
        your loan. If you choose not to pay in September, you will be charged
        interest for this month. Also in September: Many people will get their
        bill, with the payment amount and due date, at least 21 days before your
        due date.
      </li>
      <li>
        <strong>October 2023:</strong> Your first payment is due this month, but
        the dates your actual payments are due can vary depending on your loan
        administrator. You can expect to get your bill sometime in late
        September or early October and you’ll have at least 21 days before your
        due date once it’s sent to pay the bill.
      </li>
      <li>
        <strong>December 31, 2023:</strong> Deadline for borrowers with
        commercially-held Federal Family Education Loans, Perkins loans held by
        a school or Health Education Assistance Loans, to consolidate their
        loans and be considered for an adjustment. Additionally, borrowers who
        have federal student loans will automatically be considered for the
        Income Driven Repayment Account Adjustment plan after this date.
      </li>
    </ul>
    <p>
      To explore repayment options, check out our{" "}
      <Link to={ROUTES.FEDERAL_PROGRAMS}>Federal Programs Page</Link> or{" "}
      <Link to={ROUTES.LENDERS_STUDENT_MARKETPLACE}>Refinancing Center</Link>.
    </p>
  </div>
);

const summaryWhatYouShouldKnow = (
  <p className="content">
    <ul>
      <li>Interest on federal student loans resumed Sept. 1, 2023</li>
      <li>Monthly payments resume in October</li>
      <li>
        A new repayment plan, SAVE, has been introduced that can lower your
        payments and eventually provide loan forgiveness for qualifying loans.
      </li>
    </ul>
  </p>
);

const WhatYouShouldKnow = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onClickFn = () => {
    setIsOpen(true);
  };

  return (
    <div>
      <InfoBox
        title="What you should know"
        textComponent={summaryWhatYouShouldKnow}
        buttonText="Read more"
        onClick={onClickFn}
      ></InfoBox>

      {isOpen && (
        <PageModal onClose={() => setIsOpen(false)} isOpen={isOpen}>
          {contentWhatYouShouldKnow}
        </PageModal>
      )}
    </div>
  );
};

export default WhatYouShouldKnow;
