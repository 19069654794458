import React from "react";

import LenderBoxLi from "../Box/LenderBox";

const LendersList = (props) => {
  return (
    <React.Fragment>
      <div className="sw-lender-box__header columns is-mobile">
        <span className="column sw-lender-box__fixed-column">Lender</span>
        <span className="column sw-lender-box__fixed-column">Fixed APR</span>
        <span className="column sw-lender-box__main-column">
          What Snowball says
        </span>
      </div>
      <ul className="sw-flex-layout--column-center sw-full-width">
        {props.lenders.map((lender) => {
          return <LenderBoxLi key={lender.id} lender={lender}></LenderBoxLi>;
        })}
      </ul>
    </React.Fragment>
  );
};

export default LendersList;
