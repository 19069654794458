import React from "react";
import { useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";

import ROUTES from "../../constants/routes";
import { connect } from "react-redux";

/***
 * Requires the user to have one of the allowed roles
 */
const RequireAdminAuth = ({ isLoggedIn, allowedRoles, children }) => {
  let location = useLocation();
  
  const { userRoles } = useSelector((state) => {
    return state.auth;
  });

  if (!isLoggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={ROUTES.SIGN_IN} state={{ from: location }} replace />;
  }

  // Allowed roles is an allow list, if the allows
  // list includes the user role, we let them continue
  for (const userRole of userRoles) {
    if (allowedRoles.includes(userRole)) {
      return children;
    }
  }

  return <Navigate to={ROUTES.HOME} />;
};

const mapStateToProps = (state, ownProps) => {
  const isLoggedIn =
    state.firebase.auth && state.firebase.auth.uid && state.auth.isAuth;

  return {
    isLoggedIn: isLoggedIn,
  };
};

export default connect(mapStateToProps)(RequireAdminAuth);
