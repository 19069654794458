import React, { useState, useEffect } from "react";
import { useLocation, Navigate } from "react-router-dom";

import ROUTES from "../../constants/routes";
import { connect } from "react-redux";
import { getBranchData } from "../../store/actions/branchActions";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const RequireAuth = ({ isLoggedIn, getBranchDataFn, children }) => {
  let location = useLocation();
  const [checkedBranch, setCheckedBranch] = useState(false);
  const [redirectPath, setRedirectPath] = useState("");

  useEffect(() => {
    // Check branch data to see if we want to redirect
    getBranchDataFn()
      .then((newPath) => {
        if (newPath) {
          setRedirectPath(newPath);
        }
      })
      .finally(() => {
        setCheckedBranch(true);
      });
  }, [getBranchDataFn]);

  if (!checkedBranch && !isLoggedIn) {
    return (
      <div>
        <LoadingSpinner></LoadingSpinner>
      </div>
    );
  }

  if (!isLoggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    const fromState = redirectPath ? { pathname: redirectPath } : location;

    // Note: If we're on a schools page, redirect to schools logged out page
    let loggedOutRoute = ROUTES.SIGN_IN;
    if (location.pathname.includes("/schools")) {
      loggedOutRoute = ROUTES.SCHOOL_LOG_IN;
    }
    return <Navigate to={loggedOutRoute} state={{ from: fromState }} replace />;
  }

  // only try to redirect if the redirect path is different from current path
  if (redirectPath && redirectPath !== location.pathname) {
    // check if location has the branch params
    const locationSearch = location.search;
    let containsBranch = locationSearch.includes("branch");

    // only redirect if branch is still in paramss
    if (containsBranch) {
      // Redirect them to the new path passed from branch
      return <Navigate to={redirectPath} />;
    }
  }

  return children;
};

const mapStateToProps = (state) => {
  // Note: We ignore the auth.isAuth for now, since that delay rendering
  const isLoggedIn = state.firebase.auth && state.firebase.auth.uid;
  return {
    isLoggedIn: isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBranchDataFn: () => dispatch(getBranchData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequireAuth);
