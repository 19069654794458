import React from "react";
import helpers from "../../services/helpers";

import { REC_TYPES } from "../../constants/constants";

const BreakdownTable = (props) => {
  const rec = props.rec;
  const baseCase = props.baseCase;
  let baseCaseTitle = "No Change";
  let comparisonTitle = "Snowball Rec";
  const baseYearsLeft = baseCase.pay_down_length_years;
  const yearsLeft = rec.details.pay_down_length_years;
  let hideFirstColumn = false;

  if (rec.type === REC_TYPES.REPAYMENT_TEN) {
    baseCaseTitle = `${baseYearsLeft} Year`;
    comparisonTitle = `${yearsLeft} Year`;
  } else if (rec.type === REC_TYPES.REPAYMENT_LOWER_INTEREST) {
    baseCaseTitle = `${baseYearsLeft} Year`;
  } else if (rec.type === REC_TYPES.REPAYMENT_TWENTYFIVE) {
    baseCaseTitle = `${baseYearsLeft} Year`;
    comparisonTitle = `${yearsLeft} Year`;
    hideFirstColumn = true;
  }

  return (
    <div className="box">
      <p className="has-text-weight-bold has-text-centered">
        <span role="img" aria-label="Woman Scientist emoji">
          👩🏽‍🔬
        </span>{" "}
        Breaking it down
      </p>
      <br />
      <table className="table is-hoverable is-fullwidth has-text-left fs-block">
        <thead>
          <tr>
            <th></th>
            {!hideFirstColumn && <th>{baseCaseTitle}</th>}
            <th className="has-text-primary">{comparisonTitle}</th>
          </tr>
        </thead>
        {!hideFirstColumn && (
          <tfoot>
            <tr>
              <th>Total Savings</th>
              <td></td>
              <th className="has-text-primary">
                {helpers.currencyFormat(
                  baseCase.total_interest_plus_principal -
                    rec.details.total_interest_plus_principal,
                  0
                )}
              </th>
            </tr>
          </tfoot>
        )}

        <tbody>
          <tr>
            <th>Monthly payment</th>
            {!hideFirstColumn && (
              <td>{helpers.currencyFormat(baseCase.payment, 0)}</td>
            )}
            <td className="has-text-primary">
              {helpers.currencyFormat(rec.details.payment, 0)}
            </td>
          </tr>
          <tr>
            <th>Length of loan</th>
            {!hideFirstColumn && (
              <td>
                {baseCase.pay_down_length_years}{" "}
                {baseCase.pay_down_length_years === 1 ? "Year" : "Years"}
              </td>
            )}
            <td className="has-text-primary">
              {rec.details.pay_down_length_years}{" "}
              {rec.details.pay_down_length_years === 1 ? "Year" : "Years"}
            </td>
          </tr>
          <tr>
            <th>Interest Rate</th>
            {!hideFirstColumn && <td>{baseCase.rate_annual}%</td>}
            <td className="has-text-primary">{rec.details.rate_annual}%</td>
          </tr>
          <tr>
            <th></th>
            {!hideFirstColumn && <td></td>}
            <td></td>
          </tr>
          <tr>
            <th>Principal</th>
            {!hideFirstColumn && (
              <td>
                {helpers.currencyFormat(
                  baseCase.total_interest_plus_principal -
                    baseCase.total_interest,
                  0
                )}
              </td>
            )}
            <td className="has-text-primary">
              {helpers.currencyFormat(
                rec.details.total_interest_plus_principal -
                  rec.details.total_interest,
                0
              )}
            </td>
          </tr>
          <tr>
            <th>Interest</th>
            {!hideFirstColumn && (
              <td>{helpers.currencyFormat(baseCase.total_interest, 0)}</td>
            )}
            <td className="has-text-primary">
              {helpers.currencyFormat(rec.details.total_interest, 0)}
            </td>
          </tr>
          <tr>
            <th>Total Paid</th>
            {!hideFirstColumn && (
              <td>
                {helpers.currencyFormat(
                  baseCase.total_interest_plus_principal,
                  0
                )}
              </td>
            )}
            <td className="has-text-primary">
              {helpers.currencyFormat(
                rec.details.total_interest_plus_principal,
                0
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BreakdownTable;
