import { INSTITUTION_ACTIONS } from "../reducers/institutionReducers";
import { apiGet, apiPost } from "./apiActions";
import { setError } from "./errorActions";
// import { trackEvent, EVENTS } from "./analyticsActions";

export const getAccountItems = () => {
  return (dispatch, getState) => {
    const endpoint = `/api/liabilities/institutions`;
    dispatch({ type: INSTITUTION_ACTIONS.GET });

    const onSuccess = res => {
      dispatch({
        type: INSTITUTION_ACTIONS.GET_SUCCESS,
        res
      });
    };

    const onError = err => {
      dispatch({
        type: INSTITUTION_ACTIONS.GET_ERROR,
        err
      });

      dispatch(setError(err));
    };

    dispatch(apiGet(endpoint, onSuccess, onError));
  };
};

export const updateAccountItems = () => {
  return (dispatch, getState) => {
    const endpoint = `/api/liabilities/institutions`;
    dispatch({ type: INSTITUTION_ACTIONS.GET });

    const onSuccess = res => {
      dispatch({
        type: INSTITUTION_ACTIONS.GET_SUCCESS,
        res
      });
    };

    const onError = err => {
      dispatch({
        type: INSTITUTION_ACTIONS.GET_ERROR,
        err
      });

      dispatch(setError(err));
    };

    dispatch(apiPost(endpoint, null, onSuccess, onError));
  };
};

export const asyncDeleteItem = (itemId) => {
  return (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
      const endpoint = "/api/liabilities/delete_item";
      const data = { item_id: itemId };
    //   dispatch({ type: LOAN_ACTIONS.ADD });

      const onSuccess = res => {
        // make sure to re-request student loans
        dispatch(getAccountItems());
        // dispatch({ type: LOAN_ACTIONS.ADD_SUCCESS });
        resolve();
      };

      const onError = err => {
        const backupErrMessage =
          "There was an error deleting this institution. Please contact the Snowball team and we'll help you resolve this.";

        // dispatch({ type: LOAN_ACTIONS.ADD_ERROR, err });
        dispatch(setError(err, null, backupErrMessage));
        reject(err);

      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
  });
 };
};
