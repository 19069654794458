import React from "react";

const CommentButton = ({ onClick, numComments }) => {
  return (
    <div className="is-inline-block">
      <button className="button is-small is-rounded" onClick={onClick}>
        <span className="icon is-small">
          <i className="fa fa-comment"></i>
        </span>
        {numComments > 0 && (
          <span className="sw-padding--left-sm">{numComments}</span>
        )}
      </button>
    </div>
  );
};

export default CommentButton;
