import React from "react";
import { Link } from "react-router-dom";

import DatePicker from "react-datepicker";

const AdminChallengesList = ({ challenges }) => {
  return (
    <div>
      <table className="table is-hoverable is-fullwidth fs-block">
        <thead>
          <tr>
            <th></th>
            <th>ID</th>
            <th>Participants</th>
            <th>Title</th>
            <th>Status</th>
            <th>Community</th>
            <th>Start date</th>
            <th>End date</th>
          </tr>
        </thead>
        <tbody>
          {challenges.map((challenge) => {
            return (
              <tr key={challenge.challenge_id}>
                <td>
                  <Link to={`/admin/challenges/${challenge.challenge_id}`}>
                    Edit
                  </Link>
                </td>
                <td>{challenge.challenge_id}</td>
                <td>{challenge.participant_count}</td>
                <td>{challenge.title}</td>
                <td>{challenge.status}</td>
                <td>{challenge.community_uuid}</td>
                <td>
                  <DatePicker selected={challenge.start_date * 1000} />
                </td>
                <td>
                  <DatePicker selected={challenge.end_date * 1000} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AdminChallengesList;
