import React, { useCallback, useMemo } from "react";
import { useState } from "react";
import Checkbox from "../../components/Input/Checkbox";

import "react-datepicker/dist/react-datepicker.css";
import { POLL_TYPES } from "../../constants/constants";

const AdminPollsForm = ({
  onSubmitForm,
  fields,
  handleFieldChange,
  submitLoading,
}) => {
  const newPollValidated = useMemo(() => {
    // Make sure we have at least 2 options in a poll
    const currentOptions = fields.currentOptions;
    const newOptions = fields.newOptions;
    const totalOptionsLength = currentOptions.length + newOptions.length;
    const optionsValidated = totalOptionsLength >= 2;

    // If a quiz type, we'll need to make sure an answer is selected
    var validQuizAnswer = fields.pollType !== "quiz";
    if (!validQuizAnswer) {
      const correctQuizAnswerText = fields.correctQuizAnswerText;
      const isNewOptionAnswer = newOptions.includes(correctQuizAnswerText);
      const filteredOptions = currentOptions.filter((option) => {
        return option["text"] === correctQuizAnswerText;
      });
      validQuizAnswer = isNewOptionAnswer || filteredOptions.length === 1;
    }

    const validationState = {
      prompt: fields.prompt.length > 0,
      pollType: true,
      currentOptions: optionsValidated,
      newOptions: optionsValidated,
      quizAnswer: validQuizAnswer,
    };
    return !Object.values(validationState).includes(false);
  }, [fields]);

  const [newOptionText, setNewOptionText] = useState("");

  const onNewOptionChange = useCallback(
    (e) => {
      setNewOptionText(e.target.value);
    },
    [setNewOptionText]
  );

  const updateCorrectQuizAnswer = useCallback(
    (answerText) => {
      handleFieldChange({
        target: {
          id: "correctQuizAnswerText",
          value: answerText,
        },
      });
    },
    [handleFieldChange]
  );

  const updatePollOptions = useCallback(
    (id, pollOptions) => {
      handleFieldChange({
        target: {
          id: id,
          value: pollOptions,
        },
      });
    },
    [handleFieldChange]
  );

  const handleNewOptionKeyDown = useCallback(
    (e) => {
      // Add new option when the user process "Enter"
      if (e.key === "Enter" && newOptionText.length > 0) {
        e.preventDefault();
        updatePollOptions("newOptions", [...fields.newOptions, newOptionText]);
        setNewOptionText("");
      }
    },
    [newOptionText, setNewOptionText, fields, updatePollOptions]
  );

  const removeCurrentOption = useCallback(
    (optionToDelete) => {
      updatePollOptions(
        "currentOptions",
        fields.currentOptions.filter(
          (option) =>
            option["poll_option_uuid"] !== optionToDelete["poll_option_uuid"]
        )
      );
    },
    [updatePollOptions, fields]
  );

  const removeNewOption = useCallback(
    (optionToDelete) => {
      const newOptions = [...fields.newOptions];
      const index = newOptions.findIndex((option) => option === optionToDelete);
      if (index >= 0) {
        newOptions.splice(index, 1);
        updatePollOptions("newOptions", newOptions);
      }
    },
    [updatePollOptions, fields]
  );

  const currentOptionComponents = useMemo(() => {
    return (
      <div>
        {fields.currentOptions.map((currentOption, index) => {
          return (
            <div key={currentOption["poll_option_uuid"]}>
              <label>{currentOption["text"]}</label>
              {fields.pollType === "quiz" && (
                <Checkbox
                  checked={
                    currentOption["text"] === fields.correctQuizAnswerText
                  }
                  onChange={() =>
                    updateCorrectQuizAnswer(currentOption["text"])
                  }
                />
              )}
              <button
                type="button"
                className="button	is-text is-small"
                onClick={() => {
                  removeCurrentOption(currentOption);
                }}
              >
                Remove
              </button>
            </div>
          );
        })}
      </div>
    );
  }, [fields, removeCurrentOption]);

  const newOptionComponents = useMemo(() => {
    return (
      <div>
        {fields.newOptions.map((newOption, index) => {
          return (
            <div key={`newOption_${index}`}>
              <label>{newOption}</label>
              {fields.pollType === "quiz" && (
                <Checkbox
                  checked={newOption === fields.correctQuizAnswerText}
                  onChange={() => updateCorrectQuizAnswer(newOption)}
                />
              )}
              <button
                type="button"
                className="button	is-text is-small"
                onClick={() => {
                  removeNewOption(newOption);
                }}
              >
                Remove
              </button>
            </div>
          );
        })}{" "}
      </div>
    );
  }, [fields, removeNewOption]);

  return (
    <form onSubmit={onSubmitForm}>
      <div className="field">
        <label className="label has-text-weight-normal">Prompt</label>
        <div className="control">
          <input
            name="prompt"
            id="prompt"
            className="input is-medium fs-block"
            type="text"
            maxLength={500}
            required
            value={fields.prompt}
            onChange={handleFieldChange}
          />
        </div>
      </div>
      <div className="field">
        <label className="label has-text-weight-normal">Poll Type</label>
        <div className="select fs-block field">
          <select
            id="pollType"
            name="pollType"
            value={fields.pollType}
            onChange={handleFieldChange}
          >
            {POLL_TYPES.map((pollType) => {
              return (
                <option key={pollType} value={pollType}>
                  {pollType}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <label className="label has-text-weight-normal">Options</label>
      {currentOptionComponents}
      {newOptionComponents}
      <div className="control">
        <input
          name="newOptions"
          id="newOptions"
          className="input is-medium fs-block"
          type="text"
          maxLength={500}
          value={newOptionText}
          onChange={onNewOptionChange}
          onKeyDown={handleNewOptionKeyDown}
        />
      </div>
      <label className="label has-text-weight-normal">Hit Enter to Add</label>
      <div className="has-text-centered">
        <input
          type="submit"
          className={
            submitLoading
              ? "button is-warning is-outline is-loading"
              : "button is-warning is-outline"
          }
          value={"Submit"}
          disabled={!newPollValidated}
        />
      </div>
    </form>
  );
};

export default AdminPollsForm;
