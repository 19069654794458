import React, { useState } from "react";
import { connect } from "react-redux";

import ManualAccountUpdateModal from "../AddAccountFlow/ManualAccountUpdateModal";

import { setConfirmMessage } from "../../store/actions/alertingActions";
import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";
import {
  updateManualEntry,
  deleteManualEntry,
} from "../../store/actions/netWorthActions";

const UpdateManualAccountButton = ({
  accountObj,
  trackEventFn,
  updateManualEntryFn,
  deleteManualEntryFn,
  setConfirmMessageFn,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClickFn = () => {
    trackEventFn(EVENTS.MANUAL_ACCOUNT_CLICKED);
    setIsOpen(true);
  };

  const onSubmitManualEntry = (fields) => {
    // format data to match what endpoint/action wants
    const accountData = {
      name: fields.nickname,
      balance: fields.balance,
      interest_rate: fields.interestRate,
    };

    updateManualEntryFn(accountData, accountObj.account_id);
    trackEventFn(EVENTS.MANUAL_ACCOUNT_SAVED, { subtype: accountObj.subtype });
    setIsOpen(false);
  };

  const onDelete = () => {
    setConfirmMessageFn(
      "Delete account",
      "Are you sure you want to do this? This action cannot be undone.",
      "Delete",
      () => {
        deleteManualEntryFn(accountObj.account_id);
        trackEventFn(EVENTS.MANUAL_ACCOUNT_DELETED, {
          subtype: accountObj.subtype,
        });
      },
      true
    );
  };

  return (
    <div>
      {isOpen && (
        <ManualAccountUpdateModal
          onClose={() => setIsOpen(false)}
          accountObj={accountObj}
          isOpen={isOpen}
          onNext={onSubmitManualEntry}
          onDelete={onDelete}
        ></ManualAccountUpdateModal>
      )}
      <button className="button is-text is-small" onClick={onClickFn}>
        Update account
      </button>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackEventFn: (event, properties) =>
      dispatch(trackEvent(event, properties)),
    updateManualEntryFn: (accountData, accountId) =>
      dispatch(updateManualEntry(accountData, accountId)),
    deleteManualEntryFn: (accountId) => dispatch(deleteManualEntry(accountId)),
    setConfirmMessageFn: (title, message, confirmText, onConfirm, isDanger) =>
      dispatch(
        setConfirmMessage(title, message, confirmText, onConfirm, isDanger)
      ),
  };
};

export default connect(null, mapDispatchToProps)(UpdateManualAccountButton);
