export const EMAILS = {
  SUPPORT: "support@snowballwealth.com",
};

export const USER_ROLES = {
  admin: "admin", // admins have access to everything
  coach: "financial_coach",
  support: "support",
};

export const COVID_END_DATE = "January 2023";

export const ACCOUNT_SUBTYPES = {
  CREDIT: "credit card",
  STUDENT: "student",
  MORTGAGE: "mortgage",
  SAVINGS: "savings",
  CASH: "cash",
};

export const NW_TABS = {
  NET_WORTH: "networth",
  DEBT: "debt",
  ASSETS: "assets",
};

export const NW_CHART_BUTTONS = {
  M_ONE: "M_ONE",
  M_THREE: "M_THREE",
  M_SIX: "SIX_M",
  Y_ONE: "ONE_Y",
  ALL: "ALL",
};

export const PLAID_PRODUCTS = {
  LIABILITIES: "liabilities",
  TRANSACTIONS: "transactions",
  INVESTMENTS: "investments",
};

export const SORT_BY_OPTIONS = [
  "Name",
  "Interest (high to low)",
  "Amount (low to high)",
];

export const SORT_BY_MAP = {
  "Interest (high to low)": "interest",
  "Amount (low to high)": "amount",
  Name: "name",
};

export const SORT_BY_VALUES = {
  interest: "interest",
  amount: "amount",
  name: "name",
};

export const REC_TYPES = {
  LOWER_INTEREST: "lower_interest",
  LOWER_INTEREST_REACH: "lower_interest_reach",
  INCREASE_MODERATE: "increase_payment",
  INCREASE_REACH: "increase_payment_reach",
  INTEREST_GOOD: "interest_good",
  REPAYMENT_TEN: "repayment_ten_yr",
  REPAYMENT_TWENTYFIVE: "repayment_twentyfive_yr",
  REPAYMENT_LOWER_INTEREST: "repayment_lower_interest",
  REPAYMENT_INCOME_BASED: "repayment_income_based",
  PERSONAL_DEBT: "personal_debt",
  COVID_PAY: "covid_pay",
  COVID_POST_INCREASE: "covid_post_increase",
  COVID_POST_LOWER_INTEREST: "covid_post_lower_interest",
  FEDERAL_OVERVIEW: "federal_overview",
  PRIVATE_OVERVIEW: "private_overview",
};

export const REC_MODEL_TYPE = {
  REC_COVID: "REC_COVID",
  REC_STANDARD: "REC_STANDARD",
  REC_START_PAYMENT: "REC_START_PAYMENT",
};

export const REC_CATEGORIES = {
  PERSONAL: "personal",
  STUDENT: "student",
};

export const LINK_URLS = {
  APP_STORE_LINK:
    "https://apps.apple.com/app/apple-store/id1601325190?pt=123893525&ct=loginPage-web&mt=8",
  BLOG_HOME: "https://www.snowballwealth.com/blog",
  HELP_CENTER: "https://help.snowballwealth.com",
  REFER_A_FRIEND: "https://premium.snowballwealth.com/refer",
  LANDING_HOME: "https://www.snowballwealth.com",
  COVID_HELP_CENTER:
    "https://help.snowballwealth.com/en/articles/3972638-what-does-the-cares-act-mean-for-my-student-loans",
  COVID_HELP_CENTER_AUG_UPDATE:
    "https://help.snowballwealth.com/en/articles/4382856-what-does-trump-s-student-loan-order-mean-for-my-student-loans",
  COVID_BLOG_DEC_UPDATE:
    "https://www.snowballwealth.com/blog/covid-relief-extended-until-january-31-2021",
  COVID_BLOG_JAN_21_UPDATE:
    "https://www.snowballwealth.com/blog/breaking-news-covid-relief-extended-until-september-30-2021",
  COVID_BLOG_AUG_6_2021_UPDATE:
    "https://www.snowballwealth.com/blog/student-loan-relief-extended-to-january-31-2022",
  COVID_BLOG_DEC_22_2021_UPDATE:
    "https://www.snowballwealth.com/blog/biden-extending-the-pause-on-student-loan-repayment-an-additional-90-days",
  COVID_BLOG_APR_22_2022_UPDATE:
    "https://www.snowballwealth.com/blog/biden-extending-the-pause-on-student-loan-repayment-until-august-31",
  COVID_BLOG_AUG_24_2022_UPDATE:
    "https://www.snowballwealth.com/blog/biden-is-canceling-up-to-10k-in-student-loans",
  STUDENT_LOANS_UDPATE_SEP_2023:
    "https://www.snowballwealth.com/blog/student-loans-key-dates-and-changes-to-know-october-2023",
  GOV_STUDENT_AID: "https://studentaid.gov/",
  SNOWBALL_CONSULT: "https://calendly.com/snowballwealth",
};

export const LOAN_STATUS_TYPES = {
  REPAYMENT: "repayment",
  IN_SCHOOL: "in school",
  IN_GRACE: "in grace",
  FORBEARANCE: "forbearance",
  DEFERMENT: "deferment",
};

export const BLOG_LINK_URLS = {
  BALANCE_TRANSFER:
    "https://www.snowballwealth.com/blog/hacking-your-way-out-of-debt-what-your-bank-wont-tell-you",
  SIDE_HUSTLE:
    "https://www.snowballwealth.com/blog/how-to-start-your-side-hustle",
  COVID_RESOURCES:
    "https://web-dev.snowballwealth.com/blog/cheat-sheet-of-resources-for-covid-19",
};

export const NOTION_LINKS_URLS = {
  NEW_GRAD_GUIDE:
    "https://snowballwealth.notion.site/2020-New-Graduate-s-Guide-to-Student-Debt-During-COVID-19-4f80ca096ed44c7fb588f04eadace700",
  REFI_GUIDE:
    "https://snowballwealth.notion.site/Ultimate-Guide-to-Student-Loan-Refinancing-1763b3de3ae54c00b6a2d6a5d558c39e",
};

export const VIDEO_LINKS = {
  WATCH_MORE:
    "https://www.youtube.com/playlist?list=PL4Ozp_BGbqP6s2IQJgghBQjwUx-EgJ9vM",
  NET_WORTH: "https://youtu.be/95j9BZFP2Bk",
};

export const RESOURCES_LINKS = {
  BUDGET_TEMPLATE:
    "https://docs.google.com/spreadsheets/d/1Sg4nIZa56fuzknqh2-OQ6XcGvzXkywzvTid7Izv9PS4/edit?usp=sharing",
  DEBT_TO_INCOME_RATIO:
    "https://docs.google.com/spreadsheets/d/1WXFcaTqSE5Wy1lH7JGjP6floTLrOMSL2XqWx68_N5yY/edit?usp=sharing",
};

export const FEATURE_FLAGS = {
  FEDERAL_PROGRAMS: "federal-programs",
  //  BILLING_PORTAL: "billing-portal", // DEPRECATED. ON PERMANENTLY.
  // SOCIAL: "social-web", // DEPRECATED. ON PERMANENTLY. allows viewing feed tab
  // CREATE_COMMUNITY_POSTS: "create-community-posts", // DEPRECATED. ON PERMANENTLY.
  // PREMIUM_ONBOARDING: "premium-onboarding", // DEPRECATED. ON PERMANENTLY.
};

export const DEFAULT_CHALLENGE_PROGRESS_COLOR = "#09a6ba";

export const CHALLENGE_GOAL_TYPES = ["save", "payoff"];

export const CHALLENGE_GOAL_STATUS = ["active", "inactive"];

export const POLL_TYPES = ["single", "multiselect", "quiz"];

export const COURSE_TASK_TYPES = ["content", "prompt"];

export const UNIVERSAL_LINK_BASE_PATHS = [
  "challenge_details/",
  "community_item/",
  "user_profile/",
  "community/",
  "premium/",
  "course_details/",
];

export const COURSE_TYPES = ["self_guided", "cohort"];
export const COURSE_TYPES_MAP = {
  self_guided: "self_guided",
  cohort: "cohort",
};

export const COURSE_ACCESS = ["all", "general_only", "schools_only"];
export const COURSE_ACCESS_MAP = {
  all: "all",
  general: "general_only",
  schools: "schools_only",
};

export const ACCOUNT_MANUAL_TYPE_MAP = {
  checking: "Checking",
  savings: "Savings",
  credit_card: "Credit card",
  student_loan: "Student loan",
  mortgage: "Mortgage",
  investment: "Investment",
  real_estate: "Real estate",
  real_auto: "Auto",
  auto_loan: "Auto loan",
  general_loan: "Loan",
  other_asset: "Other asset",
  other_liability: "Other debt",
};

export const ACCOUNT_SUBTYPE_MAP = {
  checking: "Checking",
  savings: "Savings",
  hsa: "Health Savings Account",
  cd: "CD",
  "money market": "Money market",
  paypal: "PayPal depository",
  prepaid: "Prepaid debit card",
  "cash management": "Cash management",
  ebt: "Electronic Benefit Transfer (EBT)",
  529: "529",
  "401a": "401(a)",
  "401k": "401(k)",
  "403b": "403(b)",
  "457b": "457(b)",
  brokerage: "Brokerage",
  "cash isa": "Cash ISA",
  "education savings account": "Education savings account",
  "fixed annuity": "Fixed annuity",
  gic: "Guaranteed Investment Certificate",
  "health reimbursement arrangement": "HRA",
  ira: "Traditional IRA",
  keog: "Keogh self-employed retirement plan",
  "mutual fund": "Mutual fund account",
  "non-taxable brokerage account": "Non-taxable brokerage account",
  pension: "Standard pension account",
  "profit sharing plan": "Plan that gives employees share of company profits",
  qshr: "Qualifying share account",
  retirement: "Retirement account",
  roth: "Roth IRA",
  "roth 401k": "Roth 401(k)",
  "sep ira": "SEP IRA",
  "simple ira": "Savings Incentive Match Plan for Employees IRA",
  "stock plan": "Standard stock plan",
  trust: "Trust",
  ugma: "Uniform Gift to Minors Act",
  utma: "Uniform Transfers to Minors Act",
  "variable annuity": "Tax-deferred capital accumulation annuity contract",
  other: "Other",
};

export const PREMIUM = {
  ANNA_IMG: "https://a.storyblok.com/f/45004/480x480/d3b628dc5f/anna_paul.png",
  CALENDLY_LINK: "https://calendly.com/snowballwealth",
  TEXT_NUMBER: "415.612.6934",
  BUDGET_TEMPLATE:
    "https://docs.google.com/spreadsheets/d/1oqdRckDCV2UU8Kv6-5UrvsB_F8gVUmfrPO9FzuZGCmU/edit#gid=382879283",
  ROADMAP:
    "https://docs.google.com/document/d/117AlJ5BhIRoDSWlPapqLGrGmOOeopXtr9G_mZDEOLJI/edit",
  SNOWBALL_LUMA_EVENTS: "https://lu.ma/embed-events/usr-mKdBVAwFb5ogexl",
};

export const TASK_STEP_MAP = {
  Initial: "initial",
  Video: "video",
  Content: "content",
  Poll: "poll",
};
