import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import CourseInfo from "./CourseInfo";
import Curriculum from "./Curriculum";
import { isInProgress } from "./courseHelpers";
import { useCourseRoutes } from "./useCourseRouting";
import ContentMarkdown from "../Markdown/ContentMarkdown";

export const COURSE_TABS = {
  CURRICULUM: "curriculum",
  INFO: "info",
};

const CourseTabs = ({ baseRoute, activeTab }) => {
  let defaultClass = " is-primary";

  return (
    <div className="sw-tabs-container sw-net-worth-tabs tabs is-toggle">
      <ul>
        <li className={activeTab === COURSE_TABS.CURRICULUM ? "is-active" : ""}>
          <Link
            to={`${baseRoute}?filter=${COURSE_TABS.CURRICULUM}`}
            className={defaultClass}
          >
            <span>Curriculum</span>
          </Link>
        </li>
        <li className={activeTab === COURSE_TABS.INFO ? "is-active" : ""}>
          <Link
            to={`${baseRoute}?filter=${COURSE_TABS.INFO}`}
            className={defaultClass}
          >
            <span>Course info</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

const CourseSideContent = ({ course, onClickEnroll }) => {
  const courseInProgress = isInProgress(course);

  const { isLoading } = useSelector((state) => {
    return state.courses;
  });

  return (
    <div className="sw-course-side-info">
      <div className="sw-course-side-info__top">
        <h1 className="sw-title--big has-text-weight-bold">
          {course.content.course_overview.title}
        </h1>
        <div className="sw-padding--top-md">
          <ContentMarkdown>
            {course.content.course_overview.description}
          </ContentMarkdown>
        </div>
        {!courseInProgress && (
          <div className="sw-padding--top-lg">
            <button
              className={
                isLoading
                  ? "button is-medium is-primary sw-full-width is-loading"
                  : "button is-medium is-primary sw-full-width"
              }
              onClick={onClickEnroll}
            >
              Get started
            </button>
          </div>
        )}
      </div>
      <div className="sw-course-side-cover-img">
        <img
          src={course.content.course_overview.cover_photo_web.filename}
          alt=""
        />
      </div>
    </div>
  );
};

const CourseMainContent = ({ course, tasks, onClickEnroll }) => {
  const { getCourseRoute } = useCourseRoutes();
  const courseInProgress = isInProgress(course);
  const currentRoute = getCourseRoute(course);
  // we want to usesearch params instead
  const [searchParams] = useSearchParams();
  const filterTab = searchParams.get("filter");

  if (courseInProgress && tasks) {
    return (
      <div>
        {false && (
          <div className="">
            <CourseTabs
              baseRoute={currentRoute}
              activeTab={filterTab ? filterTab : COURSE_TABS.CURRICULUM}
            ></CourseTabs>
          </div>
        )}

        {filterTab !== COURSE_TABS.INFO && (
          <Curriculum course={course} tasks={tasks}></Curriculum>
        )}
        {filterTab === COURSE_TABS.INFO && (
          <CourseInfo
            course={course}
            onClickEnroll={onClickEnroll}
          ></CourseInfo>
        )}
      </div>
    );
  }

  return (
    <div>
      <CourseInfo course={course} onClickEnroll={onClickEnroll}></CourseInfo>
    </div>
  );
};

const CourseContent = ({ course, tasks, onClickEnroll }) => {
  return (
    <div className="sw--c-docs">
      <div className="sw-c-side-menu">
        <CourseSideContent
          course={course}
          tasks={tasks}
          onClickEnroll={onClickEnroll}
        ></CourseSideContent>
      </div>
      <div className="sw--c-docs-main">
        <CourseMainContent
          course={course}
          tasks={tasks}
          onClickEnroll={onClickEnroll}
        />
      </div>
    </div>
  );
};

export default CourseContent;
