import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route, Outlet } from "react-router-dom";

// components and helpers
import OrgLayout from "../PageLayouts/OrgLayout";
import RequireAuth from "../RouteWrappers/RequireAuth";
import ROUTES from "../../constants/routes";
import { TASK_STEP_MAP } from "../../constants/constants";

// actions
import { setOrgName } from "../../store/actions/orgActions";

////// ROUTES for pages

// not found page
import NotFoundPage from "../../pages/NotFoundPage/NotFoundPage";
// redirect page
import RedirectPage from "../../pages/RedirectPage/RedirectPage";

// signed in app pages

// course pages
import OrgBrowseCoursesPage from "../../pages/FinancialPlanPages/OrgBrowseCoursesPage";
import CoursePage from "../../pages/FinancialPlanPages/CoursePage";
import TaskPage from "../../pages/FinancialPlanPages/TaskPage";

const SignedInWrapper = () => {
  const [pageTitle, setPageTitle] = useState("");

  return (
    <RequireAuth>
      <OrgLayout pageTitle={pageTitle} hideAllNav={false} hideSideMenu={false}>
        <Outlet context={setPageTitle} />
      </OrgLayout>
    </RequireAuth>
  );
};

const RoutesSignedIn = ({ orgName }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // keep track of org name
    dispatch(setOrgName(orgName));
  }, [orgName]);

  return (
    <Routes>
      <Route path="/" element={<SignedInWrapper />}>
        <Route index element={<OrgBrowseCoursesPage />} />

        <Route path={ROUTES.COURSES} element={<OrgBrowseCoursesPage />} />
        <Route
          path={`${ROUTES.COURSES}/:cohortUuid`}
          exact
          element={<CoursePage />}
        />
        <Route
          path={`${ROUTES.COURSES}/:cohortUuid/:taskUuid`}
          exact
          element={<TaskPage taskStep={TASK_STEP_MAP.Content} />}
        />
        <Route
          path={`${ROUTES.COURSE_VIDEO}/:cohortUuid/:taskUuid`}
          exact
          element={<TaskPage taskStep={TASK_STEP_MAP.Video} />}
        />
        <Route
          path={`${ROUTES.COURSE_POLL}/:cohortUuid/:taskUuid`}
          exact
          element={<TaskPage taskStep={TASK_STEP_MAP.Poll} />}
        />

        <Route
          path={`${ROUTES.APP_REDIRECT}/*`}
          element={<RedirectPage />}
        ></Route>

        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default RoutesSignedIn;
