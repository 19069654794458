import React from "react";
import { Link } from "react-router-dom";

import Logo from "../Logo/Logo";
import ROUTES from "../../constants/routes";
import { LINK_URLS } from "../../constants/constants";

const LogoContainer = ({ isLoggedIn, useWhiteLogo }) => {
  if (isLoggedIn) {
    return (
      <Link className="navbar-item" to={ROUTES.HOME}>
        <Logo altText="Snowball Wealth - Go Home" isWhite={useWhiteLogo} />
      </Link>
    );
  }

  return (
    <a className="navbar-item" href={LINK_URLS.LANDING_HOME}>
      <Logo
        altText="Snowball Wealth Logo - Go to website"
        isWhite={useWhiteLogo}
      />
    </a>
  );
};
// Nav that is only logo
export const SignupLogoNav = ({ useWhiteLogo }) => {
  return (
    <div className="sw-signup-page__logo">
      <LogoContainer
        isLoggedIn={false}
        useWhiteLogo={useWhiteLogo}
      ></LogoContainer>
    </div>
  );
};

// Full navbar with only logo
export const LogoFullNav = ({ isLoggedIn }) => {
  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <section className="container">
        <div className="navbar-brand">
          <LogoContainer isLoggedIn={isLoggedIn}></LogoContainer>
        </div>
      </section>
    </nav>
  );
};
