import React from "react";
import { Link } from "react-router-dom";

const NextArrow = () => {
  return (
    <>
      <span>Next</span>
      <span className="icon is-small">
        <i className="fas fa-chevron-right" aria-hidden="true"></i>
      </span>
    </>
  );
};

export const NextButton = ({ className, onClick, isLoading }) => {
  let buttonClassName = `button ${className}`;
  if (isLoading) {
    buttonClassName += " is-loading";
  }

  return (
    <button className={buttonClassName} onClick={onClick}>
      <NextArrow></NextArrow>
    </button>
  );
};

export const NextLink = ({ routeLink }) => {
  return (
    <Link className="level level-left is-mobile" to={routeLink}>
      <NextArrow></NextArrow>
    </Link>
  );
};

export const NextLinkButton = ({ className, nextRoute }) => {
  let buttonClassName = `button ${className}`;

  return (
    <Link className={buttonClassName} to={nextRoute}>
      <NextArrow></NextArrow>
    </Link>
  );
};

export const NextSubmitButton = ({ className }) => {
  let buttonClassName = `button ${className}`;

  return (
    <button className={buttonClassName} type="submit">
      <NextArrow></NextArrow>
    </button>
  );
};
