import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";

import { FormFieldError } from "./FormErrors.jsx";
import { useFormFields } from "./useFormFields";
import ContinueWithGoogle from "../Buttons/ContinueWithGoogle.jsx";

import { signUp, signInGoogle } from "../../store/actions/authActions";
import helpers from "../../services/helpers";

const FIELD_ERRORS = {
  email: "Email must be a valid email address.",
  password: "Password must be greater than 10 characters.",
  firstName: "First name must be at least 2 characters.",
};

const CreateAccountForm = ({
  signUpFn,
  addLoading,
  authError,
  signInGoogleFn,
  showGoogleButton,
  showUserPw,
}) => {
  const firstRender = useRef(true); // used to test if it's the first render

  const [isValid, setIsValid] = useState(false);

  const [formRules, setFormRules] = useState({
    email: false,
    password: false,
    firstName: false,
  });

  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    firstName: "",
  });

  const validateForm = useCallback(() => {
    const updatedRules = {
      email: helpers.validateEmail(fields.email),
      password: fields.password.length > 10,
      firstName: fields.firstName.length > 1,
    };
    setFormRules(updatedRules);

    for (let key in updatedRules) {
      let value = updatedRules[key];
      if (!value) {
        return false;
      }
    }
    return true;
  }, [fields]);

  // only re-run the effect if fields changes
  useEffect(() => {
    // we want to skip validation on first render
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    const formIsValid = validateForm();
    setIsValid(formIsValid);
  }, [fields, validateForm]);

  const handleSubmitClick = (e) => {
    e.preventDefault();

    // only call signup if form is valid
    const formIsValid = validateForm();
    if (formIsValid) {
      signUpFn(fields.email, fields.password, fields.firstName);
    }
  };

  return (
    <>
      {showGoogleButton && (
        <div className="sw-padding--bottom-lg">
          <div className="sw-padding--bottom-lg">
            <ContinueWithGoogle onClick={signInGoogleFn}></ContinueWithGoogle>
          </div>
        </div>
      )}
      {showGoogleButton && showUserPw && (
        <div className="sw-padding--bottom-lg">
          <div className="sw-padding--bottom-lg">
            <hr className="sw-hr"></hr>
            <div className="help">OR</div>
          </div>
        </div>
      )}
      {showUserPw && (
        <form
          onSubmit={handleSubmitClick}
          className="sw-container--fixed-width"
        >
          <div className="field">
            <label
              className="label sw-is-x-small is-uppercase"
              htmlFor="firstName"
            >
              First Name
            </label>
            <div className="control">
              <input
                name="firstName"
                id="firstName"
                className="input is-medium fs-block"
                type="text"
                required
                value={fields.firstName}
                onChange={handleFieldChange}
              />
            </div>
            {fields.firstName && (
              <FormFieldError
                fieldName="firstName"
                fieldError={FIELD_ERRORS.firstName}
                isValid={formRules.firstName}
              />
            )}
          </div>

          <div className="field">
            <label className="label sw-is-x-small is-uppercase" htmlFor="email">
              Email Address
            </label>
            <div className="control">
              <input
                name="email"
                id="email"
                className="input is-medium fs-block"
                type="email"
                required
                value={fields.email}
                onChange={handleFieldChange}
                autoComplete="email"
              />
            </div>
            {fields.email && (
              <FormFieldError
                fieldName="email"
                fieldError={FIELD_ERRORS.email}
                isValid={formRules.email}
              />
            )}
          </div>

          <div className="field">
            <label
              className="label sw-is-x-small is-uppercase"
              htmlFor="password"
            >
              Password
            </label>
            <div className="control">
              <input
                name="password"
                id="password"
                className="input is-medium"
                type="password"
                required
                value={fields.password}
                onChange={handleFieldChange}
                autoComplete="new-password"
              />
            </div>
            {fields.password && (
              <FormFieldError
                fieldName="password"
                fieldError={FIELD_ERRORS.password}
                isValid={formRules.password}
              />
            )}
          </div>

          <div>
            {authError && <p className="help is-danger">{authError}</p>}
            <br />
            <button
              type="submit"
              className={
                addLoading
                  ? "button is-primary is-loading"
                  : "button is-primary"
              }
              disabled={!isValid}
            >
              Create account
            </button>
          </div>
        </form>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    addLoading: state.auth.addLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signUpFn: (email, password, firstName) =>
      dispatch(signUp(email, password, firstName)),
    signInGoogleFn: () => dispatch(signInGoogle()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccountForm);
