import React from "react";

const CheckedListLi = ({ children }) => {
  return (
    <li>
      <span className="icon">
        <i className="fa fa-check"></i>
      </span>
      <span className="sw-padding--left-md">{children}</span>
    </li>
  );
};

export default CheckedListLi;
