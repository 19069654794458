import React from "react";
import { GRAPHICS } from "../../constants/images";
import { ImageIconSqr } from "../Icon/ImageIcon";

const ComingSoonBox = ({ redirectLink }) => {
  return (
    <div className="sw-federal-programs-cta box">
      <div className="columns sw-flex-layout--centered">
        <div className="column is-narrow is-hidden-mobile">
          <ImageIconSqr
            iconSrc={GRAPHICS.BUILDING}
            alt="green check"
            size="large"
          />
        </div>
        <div className="column sw-padding--left-lg">
          <div className="sw-padding--bottom-lg">
            <p className="sw-federal-programs-cta__line-two">
              Explore federal programs
            </p>
            <p className="sw-federal-programs-cta__line-three">
              Visit the Federal Student Aid website to learn more about the new
              SAVE plan and understand other federal programs. options.
            </p>
          </div>
          <a
            className="button sw-is-wide is-outlined is-primary"
            href={redirectLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </a>
        </div>
      </div>
    </div>
  );
};

export default ComingSoonBox;
