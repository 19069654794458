import React from "react";
import { NavLink } from "react-router-dom";

import { ImageIconSqr } from "../Icon/ImageIcon";

export const MenuItem = ({
  text,
  to,
  iconSrc,
  isNew,
  className,
  onClickFn,
}) => {
  const navLinkClassName = `sw-side-menu-link ${className}`;
  const onClick = () => {
    onClickFn();
  };

  return (
    <li className="sw-side-menu-item">
      <NavLink
        to={to}
        onClick={onClick}
        className={({ isActive }) =>
          navLinkClassName + (isActive ? " is-active" : "")
        }
      >
        <div className="sw-side-menu-link-container" style={{ width: "100%" }}>
          <div className="level is-mobile">
            <span className="sw-side-menu-link__img">
              {iconSrc && (
                <ImageIconSqr iconSrc={iconSrc} alt={text} size="menu" />
              )}
              {!iconSrc && (
                <span className="is-size-7 is-hidden-desktop">{text}</span>
              )}
            </span>
            <span className="sw-side-menu-link__text">{text}</span>
            {isNew && (
              <div className="sw-side-menu-link__text sw-padding--left-md">
                <div className="sw-box-tag--new">New</div>
              </div>
            )}
          </div>
        </div>
      </NavLink>
    </li>
  );
};

export const MenuItemHref = ({ text, href, iconSrc, onClickFn, className }) => {
  const onClick = () => {
    onClickFn();
  };
  return (
    <li className="sw-side-menu-item">
      <a
        href={href}
        className={`sw-side-menu-link ${className}`}
        onClick={onClick}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="sw-side-menu-link-container" style={{ width: "100%" }}>
          <div className="level">
            <span className="sw-side-menu-link__img">
              <ImageIconSqr iconSrc={iconSrc} alt="green check" size="menu" />
            </span>
            <span className="sw-side-menu-link__text">{text}</span>
          </div>
        </div>
      </a>
    </li>
  );
};
