import React, { useEffect } from "react";
import { useParams, useSearchParams, useOutletContext } from "react-router-dom";
import { connect } from "react-redux";

import NetWorthTracker from "../../components/NetWorth/NetWorthTracker";
import FAQSection from "../../components/NetWorth/FAQsection";
import AddAccountButton from "../../components/Buttons/AddAccountButton";

import { NW_TABS } from "../../constants/constants";
import ROUTES from "../../constants/routes";

import { asyncGetNetWorthItems } from "../../store/actions/netWorthActions";
import { getNetWorthGraphData } from "../../store/actions/netWorthGraphActions";
import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";
import { setAccountErrorsWarning } from "../../store/actions/errorActions";
import { getUserFinancialProfile } from "../../store/actions/profileActions";
import { updateNetWorthItems } from "../../store/actions/netWorthActions";

const NetWorthPage = (props) => {
  const setPageTitle = useOutletContext();
  useEffect(() => {
    setPageTitle("Net worth");
  }, [setPageTitle]);

  let { tab } = useParams(); // this is deprecated
  // we want to usesearch params instead
  const [searchParams] = useSearchParams();
  const filterTab = searchParams.get("filter");
  let activeTab = NW_TABS.NET_WORTH;

  if (filterTab) {
    activeTab = filterTab;
  } else if (tab) {
    activeTab = tab;
  }

  const asyncGetNetWorthItemsFn = props.asyncGetNetWorthItems;
  const getNetWorthGraphDataFn = props.getNetWorthGraphData;
  const trackEventFn = props.trackEvent;
  const plaidErrors = props.accountPlaidErrors;
  const setAccountErrorsWarningFn = props.setAccountErrorsWarning;

  // on page load, get net worth items, graph, and user profile
  useEffect(() => {
    asyncGetNetWorthItemsFn();
    getNetWorthGraphDataFn();
  }, [asyncGetNetWorthItemsFn, getNetWorthGraphDataFn]);

  // check if we have plaid errors and set warning
  useEffect(() => {
    if (plaidErrors) {
      const numPlaidErrors = plaidErrors.length;
      if (numPlaidErrors > 0) {
        setAccountErrorsWarningFn(numPlaidErrors);
      }
    }
  }, [setAccountErrorsWarningFn, plaidErrors]);

  // trigger event every time we change tabs
  useEffect(() => {
    trackEventFn(EVENTS.NET_WORTH_VIEWED, { tab: activeTab });
  }, [trackEventFn, activeTab]);

  const onAddAccountSuccess = () => {
    asyncGetNetWorthItemsFn();
    getNetWorthGraphDataFn();
  };

  return (
    <div className="sw-flex-layout--column-center">
      <div className="sw-container--fixed-width sw-padding--bottom-xl">
        <NetWorthTracker
          tab={activeTab}
          baseRoute={ROUTES.NET_WORTH}
          netWorth={props.netWorth}
          graphData={props.graphData}
          updateNetWorthItemsFn={props.updateNetWorthItemsFn}
          isLoading={props.isLoading}
        />

        <div className="sw-flex-layout--column-center sw-padding--top-xl sw-padding--bottom-xl">
          <AddAccountButton
            onAddAccountSuccess={onAddAccountSuccess}
          ></AddAccountButton>
        </div>

        <FAQSection></FAQSection>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    asyncGetNetWorthItems: () => dispatch(asyncGetNetWorthItems()),
    getNetWorthGraphData: () => dispatch(getNetWorthGraphData()),
    getUserFinancialProfile: () => dispatch(getUserFinancialProfile()),
    updateNetWorthItemsFn: () => dispatch(updateNetWorthItems()),
    trackEvent: (event, properties) => dispatch(trackEvent(event, properties)),
    setAccountErrorsWarning: (numPlaidErrors) =>
      dispatch(setAccountErrorsWarning(numPlaidErrors)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.netWorth.isLoading,
    accountPlaidErrors: state.netWorth.metadata.plaid_errors,
    netWorth: state.netWorth.netWorthData,
    graphData: state.netWorthGraph.graphData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NetWorthPage);
