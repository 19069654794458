import {
  getBlogsForHomepage,
  getBlogsForRefiSuccess,
} from "../../services/storyblokApi";
import { BLOG_ACTIONS } from "../reducers/blogReducer";
import { setError } from "./errorActions";

// Youtube data api URL
const YOUTUBE_URL =
  "https://www.googleapis.com/youtube/v3/playlistItems?part=contentDetails";

// Youtube Snowball playlist
const SNOWBALL_PLAYLIST = "PL4Ozp_BGbqP6s2IQJgghBQjwUx-EgJ9vM";

export const getHomeBlogs = () => {
  return (dispatch, getState) => {
    dispatch({ type: BLOG_ACTIONS.GET });

    getBlogsForHomepage()
      .then((response) => {
        const blogs = response.data.stories;
        dispatch({
          type: BLOG_ACTIONS.GET_SUCCESS,
          blogs: blogs,
        });
      })
      .catch((err) => {
        dispatch(setError(err));
        dispatch({ type: BLOG_ACTIONS.GET_ERROR, err });
      });
  };
};

export const getRefiSuccessBlogs = (limit) => {
  return (dispatch, getState) => {
    dispatch({ type: BLOG_ACTIONS.GET });

    getBlogsForRefiSuccess(limit)
      .then((response) => {
        const blogs = response.data.stories;
        dispatch({
          type: BLOG_ACTIONS.GET_SUCCESS,
          blogs: blogs,
        });
      })
      .catch((err) => {
        dispatch(setError(err));
        dispatch({ type: BLOG_ACTIONS.GET_ERROR, err });
      });
  };
};

export const getVideoLinks = () => {
  return (dispatch, getState) => {
    const maxResults = "2"; // we only want 2 videos for now
    const apiKey = process.env.REACT_APP_FB_API_KEY;

    // https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=PLB03EA9545DD188C3&key=MY_API_KEY
    const finalUrl = `${YOUTUBE_URL}&maxResults=${maxResults}&playlistId=${SNOWBALL_PLAYLIST}&key=${apiKey}`;

    dispatch({ type: BLOG_ACTIONS.GET_VIDEOS });

    fetch(finalUrl)
      .then((res) => res.json())
      .then((resData) => {
        // go through response and return video urls
        let videoObjs = [];
        if (resData && resData.items) {
          resData.items.forEach((videoItem) => {
            if (videoItem && videoItem.contentDetails) {
              videoObjs.push({
                id: videoItem.contentDetails.videoId,
                link: "https://youtu.be/" + videoItem.contentDetails.videoId,
              });
            }
          });
        }

        dispatch({
          type: BLOG_ACTIONS.GET_VIDEOS_SUCCESS,
          videos: videoObjs,
        });
      })
      .catch((err) => {
        dispatch(setError(err));
        dispatch({ type: BLOG_ACTIONS.GET_VIDEOS_ERROR, err });
      });
  };
};
