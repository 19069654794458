import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getAllChallenges } from "../../store/actions/adminChallengesActions";
import AdminChallengesList from "../../components/Challenges/AdminChallengesList";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

const AdminChallengesPage = (props) => {
  const dispatch = useDispatch();
  const { challenges, adminError, getChallengesLoading } = useSelector(
    (state) => {
      return state.adminChallenges;
    }
  );

  useEffect(() => {
    dispatch(getAllChallenges());
  }, [dispatch]);

  return (
    <div className="fs-block">
      <h1 className="title">Admin Challenges</h1>
      {getChallengesLoading && <LoadingSpinner></LoadingSpinner>}
      {challenges && <AdminChallengesList challenges={challenges} />}
      {adminError && <p className="help is-danger">Error: {adminError}</p>}
      <br />
      <Link className="button is-primary" to="new">
        Add a challenge
      </Link>
    </div>
  );
};

export default AdminChallengesPage;
