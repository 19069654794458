import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ICONS } from "../../constants/images";

import { NextLinkButton } from "../../components/Buttons/NextButton";
import ROUTES from "../../constants/routes";
import { trackEvent, EVENTS } from "../../store/actions/analyticsActions";

const WelcomeTextColumn = () => {
  return (
    <div className="column is-half sw-onboarding-page-column">
      <h2 className="sw-onboarding__title">
        Here's what you can do on Snowball...
      </h2>

      <ul className="sw-onboarding__overview-list">
        <li>
          <img
            src={ICONS.FLAG_BLACK}
            alt="flag icon is-small"
            width="20"
            height="20"
          />
          Sync your accounts and track your net worth
        </li>
        <li>
          <img
            src={ICONS.GLASS_BLACK}
            alt="glass icon is-small"
            width="20"
            height="20"
          />
          Get a free student loan plan to understand how you can save money
        </li>
        <li>
          <img
            src={ICONS.PIGGY_BLACK}
            alt="piggy bank icon is-small"
            width="20"
            height="20"
          />
          Join a money challenge to jumpstart your savings
        </li>
      </ul>

      <p className="sw-onboarding__subtitle has-text-primary">
        You’re taking the first step to Snowball-ing Wealth!
      </p>
      <NextLinkButton
        className="sw-onboarding__next is-medium is-primary"
        nextRoute={ROUTES.HOME}
      ></NextLinkButton>
    </div>
  );
};

const ImageColumn = () => {
  return (
    <div className="column is-half sw-onboarding__img sw-onboarding__img--overview"></div>
  );
};

const OverviewPage = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(trackEvent(EVENTS.ONBOARDING_REC_STARTED));
  }, [dispatch]);

  return (
    <div className="columns sw-onboarding-page-container">
      <WelcomeTextColumn></WelcomeTextColumn>
      <ImageColumn></ImageColumn>
    </div>
  );
};

export default OverviewPage;
