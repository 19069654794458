import React from "react";
import { SORT_BY_OPTIONS, SORT_BY_MAP } from "../../constants/constants";

const SortByDropdown = ({ sortBy, setSortBy }) => {
  return (
    <div className="select fs-block">
      <select
        id="sortBy"
        name="sortBy"
        value={sortBy}
        onChange={(e) => setSortBy(e.target.value)}
      >
        {SORT_BY_OPTIONS.map((sortOption) => {
          return (
            <option
              key={SORT_BY_MAP[sortOption]}
              value={SORT_BY_MAP[sortOption]}
            >
              {sortOption}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default SortByDropdown;
