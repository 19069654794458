import React from "react";

import Modal from "../Modal/Modal";
import NumberInput, { NUMBER_INPUT_TYPES } from "../Input/NumberInput";
import TextInput from "../Input/TextInput";
import Select from "../Input/Select";

import { useFormFields } from "../Form/useFormFields";

const DEBT_TYPES = [
  "Credit card",
  "Student loan",
  "Mortgage",
  "Auto loan",
  "Loan",
  "Other",
];

export const DEBT_TYPES_MAP = {
  "Credit card": "credit_card",
  "Student loan": "student_loan",
  Mortgage: "mortgage",
  "Auto loan": "auto_loan",
  Loan: "general_loan",
  Other: "other_liability",
};

const TrackAssetModal = ({ onClose, isOpen, onBack, onNext }) => {
  const [fields, handleFieldChange] = useFormFields({
    accountType: DEBT_TYPES_MAP["Credit card"],
    nickname: "",
    balance: "",
    interestRate: "",
  });

  const footerObj = (
    <button
      form="sw-track-debt-form"
      type="submit"
      className="button is-primary"
    >
      Track this account
    </button>
  );

  const onSubmitForm = (event) => {
    event.preventDefault();
    onNext(fields);
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} onBack={onBack} footer={footerObj}>
      <form id="sw-track-debt-form" onSubmit={(event) => onSubmitForm(event)}>
        <div className="sw-padding--left-lg sw-padding--right-lg">
          <p className="modal-card-body__title">Track debt</p>

          <div className="field">
            <Select
              label="Type"
              name="accountType"
              className="fs-block is-medium"
              value={fields.accountType}
              onChange={handleFieldChange}
            >
              {DEBT_TYPES.map((accountType) => {
                return (
                  <option
                    key={DEBT_TYPES_MAP[accountType]}
                    value={DEBT_TYPES_MAP[accountType]}
                  >
                    {accountType}
                  </option>
                );
              })}
            </Select>
          </div>

          <div className="field">
            <TextInput
              label="Name"
              name="nickname"
              required={true}
              className="fs-block"
              value={fields.nickname}
              onChange={handleFieldChange}
              placeholder="My debt account"
            ></TextInput>
          </div>

          <div className="field">
            <NumberInput
              label="Current balance"
              name="balance"
              required={true}
              className="fs-block"
              value={fields.balance}
              onChange={handleFieldChange}
            ></NumberInput>
          </div>

          <div className="field">
            <NumberInput
              label="Interest Rate"
              name="interestRate"
              className="fs-block"
              value={fields.interestRate}
              onChange={handleFieldChange}
              required={DEBT_TYPES_MAP["Student loan"] === fields.accountType}
              numberType={NUMBER_INPUT_TYPES.INTEREST}
            ></NumberInput>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default TrackAssetModal;
