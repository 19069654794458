import React from "react";

const FeedPagination = ({ pagination, getPageContent }) => {
  return (
    <div className="level">
      {pagination && (
        <button
          className="leve-item level-left button is-rounded is-small"
          disabled={!pagination.has_prev}
          onClick={() => getPageContent(pagination.prev_num)}
        >
          Back
        </button>
      )}
      {pagination && (
        <div className="leve-item level-center is-size-7 sw-padding--left-md sw-padding--right-md">
          Page {pagination.page}
        </div>
      )}
      {pagination && (
        <button
          className="leve-item level-right button is-rounded is-small"
          disabled={!pagination.has_next}
          onClick={() => getPageContent(pagination.next_num)}
        >
          Next
        </button>
      )}
    </div>
  );
};

export default FeedPagination;
