import React, { useMemo } from "react";

import {
  COURSE_ACCESS,
  COURSE_TYPES,
  COURSE_TYPES_MAP,
} from "../../constants/constants";

import { AdminFormSelectField, AdminTextField } from "../Input/AdminFormFields";
import NumberInput, {
  NUMBER_INPUT_TYPES,
} from "../../components/Input/NumberInput";

const AdminCoursesForm = ({
  onSubmitForm,
  fields,
  handleFieldChange,
  isLoading,
}) => {
  const newCourseValidated = useMemo(() => {
    let validationState = {
      courseType: COURSE_TYPES.includes(fields.courseType),
      courseAccess: COURSE_ACCESS.includes(fields.courseAccess),
      referenceName: fields.referenceName.length > 0,
      slug: fields.slug.length > 0,
      isActive: fields.isActive != null,
    };

    if (fields.courseType === COURSE_TYPES_MAP.cohort) {
      // make sure we check extra cohort fields
      validationState["groupDueDateOffset"] = fields.groupDueDateOffset > 0;
      validationState["durationInDays"] = fields.durationInDays > 0;
    } else {
      validationState["groupDueDateOffset"] = true;
      validationState["durationInDays"] = true;
    }

    return !Object.values(validationState).includes(false);
  }, [fields]);

  return (
    <form onSubmit={onSubmitForm} className="box">
      <h2 className="subtitle">Course Information</h2>
      <AdminFormSelectField
        label="Course Type"
        fields={fields}
        fieldName="courseType"
        onChange={handleFieldChange}
        disabled={fields.courseUuid ? true : false}
      >
        {COURSE_TYPES.map((courseType) => {
          return (
            <option key={courseType} value={courseType}>
              {courseType}
            </option>
          );
        })}
      </AdminFormSelectField>

      <AdminFormSelectField
        label="Course Access"
        fields={fields}
        fieldName="courseAccess"
        onChange={handleFieldChange}
      >
        {COURSE_ACCESS.map((courseAccess) => {
          return (
            <option key={courseAccess} value={courseAccess}>
              {courseAccess}
            </option>
          );
        })}
      </AdminFormSelectField>

      <AdminTextField
        label="Reference Name (should be unique, used to easily identify a course)"
        fields={fields}
        fieldName="referenceName"
        onChange={handleFieldChange}
      ></AdminTextField>

      <AdminTextField
        label="Slug (must match Storyblok)"
        fields={fields}
        fieldName="slug"
        onChange={handleFieldChange}
      ></AdminTextField>

      <AdminTextField
        label="Linked Community UUID (can be empty or must match an existing community uuid)"
        fields={fields}
        fieldName="linkedCommunityUuid"
        onChange={handleFieldChange}
      ></AdminTextField>

      <p className="help is-danger">
        IMPORTANT:
        <br />
        1. If you put a linked community uuid, this course will only show up for
        users that are in this community.
        <br />
        2. If this field is empty, this course will show up for everyone.
        <br />
        3. If there is a linked community uuid, cohorts that are created for
        this course will default to having this same community uuid.
      </p>

      <AdminFormSelectField
        label="Is Active (if false, doesn't show up when browsing in the app)"
        fields={fields}
        fieldName="isActive"
        onChange={handleFieldChange}
        disabled={fields.courseUuid ? false : true}
      >
        {[false, true].map((isActive) => {
          return (
            <option key={isActive} value={isActive}>
              {isActive ? "true" : "false"}
            </option>
          );
        })}
      </AdminFormSelectField>

      <p className="help is-danger">
        IMPORTANT: ONLY set this to active when:
        <br />
        1. The cohort is set up.
        <br />
        2. Tasks are created.
        <br />
        Otherwise this will break the courses tab.
      </p>

      {fields.courseType === COURSE_TYPES_MAP.cohort && (
        <div className="columns sw-padding--top-md sw-padding--bottom-md">
          <NumberInput
            label="Course duration (in days)"
            name="durationInDays"
            value={fields.durationInDays}
            onChange={handleFieldChange}
            numberType={NUMBER_INPUT_TYPES.INTEGER}
          ></NumberInput>
          <NumberInput
            label="Group due date offset (in days)"
            name="groupDueDateOffset"
            value={fields.groupDueDateOffset}
            onChange={handleFieldChange}
            numberType={NUMBER_INPUT_TYPES.INTEGER}
          ></NumberInput>
        </div>
      )}

      <div className="has-text-centered">
        <input
          type="submit"
          className={
            isLoading
              ? "button is-warning is-outline is-loading"
              : "button is-warning is-outline"
          }
          value={"Save"}
          disabled={!newCourseValidated}
        />
      </div>
    </form>
  );
};

export default AdminCoursesForm;
