import React from "react";
import { useDispatch } from "react-redux";

import {
  asyncGetFeed,
  asyncJoinCommunity,
} from "../../store/actions/communityActions";
import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";

const CommunityThumbnail = ({ communityObj }) => {
  const dispatch = useDispatch();

  const onJoinClick = () => {
    dispatch(asyncJoinCommunity(communityObj.community_uuid)).then(() => {
      dispatch(asyncGetFeed());
    });
    dispatch(
      trackEvent(EVENTS.COMMUNITY_JOINED, {
        community_uuid: communityObj.community_uuid,
      })
    );
  };

  if (!communityObj) {
    return <div></div>;
  }

  return (
    <div
      className="sw-community-thumbnail is-flex"
      style={{
        backgroundImage: `url("${communityObj.thumbnail_image_url}")`,
      }}
    >
      <figure className="sw-community-thumbnail__img">
        <img
          src={communityObj.thumbnail_image_url}
          alt={`${communityObj.name} icon`}
        />
      </figure>
      <span className="sw-padding--left-sm">{communityObj.name}</span>
      {!communityObj.has_joined && (
        <button
          className="button is-small is-rounded is-outlined"
          onClick={onJoinClick}
        >
          Join
        </button>
      )}
    </div>
  );
};

export default CommunityThumbnail;
