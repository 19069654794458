import React from "react";
import helpers from "../../services/helpers";
import moment from "moment";

const MortgageDetails = ({ loanInfo }) => {
  const accountObj = loanInfo.account_details;

  return (
    <>
      <div className="box is-vertical">
        <p className="sw-padding--bottom-md">Loan details</p>
        <table className="table is-hoverable is-fullwidth is-striped is-narrow fs-block">
          <tbody>
            <tr>
              <td>Name</td>
              <td className="has-text-right has-text-weight-semibold is-capitalized">
                {loanInfo.name}
              </td>
            </tr>
            {accountObj.property_address && (
              <tr>
                <td>Property address</td>
                <td className="has-text-right has-text-weight-semibold">
                  {accountObj.property_address.street}
                </td>
              </tr>
            )}
            <tr>
              <td>Loan type</td>
              <td className="has-text-right has-text-weight-semibold">
                {accountObj.loan_term} {accountObj.interest_rate_type}
              </td>
            </tr>
            <tr>
              <td>Original loan amount</td>
              <td className="has-text-right has-text-weight-semibold">
                {helpers.currencyFormat(
                  accountObj.origination_principal_amount,
                  0,
                  accountObj.isoCurrencyCode
                )}
              </td>
            </tr>
            {accountObj.past_due_amount && (
              <tr>
                <td>Past due amount</td>
                <td className="has-text-right has-text-weight-semibold">
                  {helpers.currencyFormat(
                    accountObj.past_due_amount,
                    0,
                    accountObj.isoCurrencyCode
                  )}
                </td>
              </tr>
            )}
            {accountObj.current_late_fee && (
              <tr>
                <td>Late fee</td>
                <td className="has-text-right has-text-weight-semibold">
                  {helpers.currencyFormat(
                    accountObj.current_late_fee,
                    0,
                    accountObj.isoCurrencyCode
                  )}
                </td>
              </tr>
            )}
            <tr>
              <td>Escrow balance</td>
              <td className="has-text-right has-text-weight-semibold">
                {helpers.currencyFormat(
                  accountObj.escrow_balance,
                  0,
                  accountObj.isoCurrencyCode
                )}
              </td>
            </tr>
            <tr>
              <td>Private mortgage insurance (PMI)</td>
              <td className="has-text-right has-text-weight-semibold">
                {accountObj.has_pmi ? "Yes" : "No"}
              </td>
            </tr>
            <tr>
              <td>Prepayment penalty</td>
              <td className="has-text-right has-text-weight-semibold">
                {accountObj.has_prepayment_penalty ? "Yes" : "No"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="box is-vertical">
        <p className="sw-padding--bottom-md">Payment details</p>
        <table className="table is-hoverable is-fullwidth is-striped is-narrow fs-block">
          <tbody>
            <tr>
              <td>Next payment</td>
              <td className="has-text-right has-text-weight-semibold">
                {helpers.currencyFormat(
                  accountObj.next_monthly_payment,
                  0,
                  accountObj.isoCurrencyCode
                )}
              </td>
            </tr>
            <tr>
              <td>Next payment date</td>
              <td className="has-text-right has-text-weight-semibold">
                {accountObj.next_payment_due_date &&
                  moment(accountObj.next_payment_due_date, "YYYY-MM-DD").format(
                    "MM/D/YYYY"
                  )}
              </td>
            </tr>
            <tr>
              <td>Last payment</td>
              <td className="has-text-right has-text-weight-semibold">
                {helpers.currencyFormat(
                  accountObj.last_payment_amount,
                  0,
                  accountObj.isoCurrencyCode
                )}
              </td>
            </tr>
            <tr>
              <td>Last payment date</td>
              <td className="has-text-right has-text-weight-semibold">
                {accountObj.last_payment_date &&
                  moment(accountObj.last_payment_date, "YYYY-MM-DD").format(
                    "MM/D/YYYY"
                  )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default MortgageDetails;
