import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";

import { useFormFields } from "../Form/useFormFields";
import UserIcon from "./UserIcon";
import TextInput from "../Input/TextInput";
import CommentItem from "./CommentItem";

import {
  asyncPostComment,
  asyncGetComments,
  asyncDeleteComment,
} from "../../store/actions/communityActions";

const CommentsSection = ({ currentUser, feedItemUuid, communityUuid }) => {
  const defaultFields = useMemo(() => {
    return {
      text: "",
      feedItemUuid: feedItemUuid,
    };
  }, [feedItemUuid]);

  const [fields, handleFieldChange, setValues] = useFormFields(defaultFields);
  const dispatch = useDispatch();

  const newCommentValidated = useMemo(() => {
    const validationState = {
      text: fields.text.length > 0,
    };
    return !Object.values(validationState).includes(false);
  }, [fields]);

  useEffect(() => {
    dispatch(asyncGetComments(feedItemUuid));
  }, [dispatch, feedItemUuid]);

  const { postCommentsDict } = useSelector((state) => {
    return state.communities;
  });

  const onSubmitForm = (event) => {
    event.preventDefault();

    dispatch(asyncPostComment(fields)).then(() => {
      setValues(defaultFields);
      // refresh comment data
      dispatch(asyncGetComments(feedItemUuid));
    });
    dispatch(
      trackEvent(EVENTS.COMMUNITY_COMMENT_CREATED, {
        community_uuid: communityUuid,
        feed_item_uuid: feedItemUuid,
      })
    );
  };

  const onDeleteComment = (comment) => {
    dispatch(asyncDeleteComment(comment.comment_uuid)).then(() => {
      // refresh comment data
      dispatch(asyncGetComments(feedItemUuid));
    });

    dispatch(
      trackEvent(EVENTS.COMMUNITY_COMMENT_DELETED, {
        community_uuid: communityUuid,
        feed_item_uuid: feedItemUuid,
        comment_uuid: comment.comment_uuid,
      })
    );
  };

  let commentsObj = null;
  if (postCommentsDict && postCommentsDict[feedItemUuid]) {
    commentsObj = postCommentsDict[feedItemUuid];
  }

  return (
    <>
      <form
        className="sw-media__comment media"
        id={`sw-make-comment-form-${feedItemUuid}`}
        onSubmit={(event) => onSubmitForm(event)}
      >
        <div className="media-left is-hidden-mobile">
          <UserIcon authorProfile={currentUser} sizeClass="is-32x32" />
        </div>
        <div className="media-content">
          <div className="field">
            <TextInput
              name="text"
              required={true}
              className="fs-block"
              value={fields.text}
              onChange={handleFieldChange}
              placeholder="Add a comment..."
            ></TextInput>
          </div>
        </div>
        {newCommentValidated && (
          <button
            form={`sw-make-comment-form-${feedItemUuid}`}
            type="submit"
            className="button is-ghost"
            disabled={!newCommentValidated}
          >
            Post
          </button>
        )}
      </form>
      {commentsObj &&
        commentsObj.comments &&
        commentsObj.comments.map((comment) => {
          return (
            <CommentItem
              key={comment.comment_uuid}
              comment={comment}
              communityUuid={communityUuid}
              onDeleteComment={onDeleteComment}
            />
          );
        })}
    </>
  );
};

export default CommentsSection;
