import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getRefiSuccessBlogs } from "../../store/actions/blogActions";
import BlogPreview from "../../components/Blog/BlogPreview";

const RefiSuccessStories = ({ blogs, getRefiSuccessBlogsFn }) => {
  useEffect(() => {
    getRefiSuccessBlogsFn();
  }, [getRefiSuccessBlogsFn]);
  return (
    <div className="box">
      <h2 className="subtitle">Refinancing success stories</h2>
      <p className="sw-padding--bottom-lg">
        Read about how these Snowball members refinanced, saved money and got
        out of debt.
      </p>

      {blogs && (
        <div className="columns is-mobile is-multiline sw-padding--bottom-sm">
          {blogs.map((blog, index) => {
            return (
              <div
                className="column is-half-desktop is-half-tablet is-full-mobile"
                key={blog.uuid}
              >
                <BlogPreview blog={blog}></BlogPreview>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRefiSuccessBlogsFn: () => dispatch(getRefiSuccessBlogs(2)),
  };
};
const mapStateToProps = (state) => {
  return {
    blogs: state.blog.blogs,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RefiSuccessStories);
