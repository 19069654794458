import React, { useMemo } from "react";

import "react-datepicker/dist/react-datepicker.css";
import { UNIVERSAL_LINK_BASE_PATHS } from "../../constants/constants";

const AdminUniversalLinkAttachmentForm = ({
  onSubmitForm,
  fields,
  handleFieldChange,
  submitLoading,
}) => {
  const universalLinkAttachmentValidated = useMemo(() => {
    const validationState = {
      basePath: fields.basePath.length > 0,
      displayText: fields.displayText.length > 0,
      pathExtension: true,
    };
    return !Object.values(validationState).includes(false);
  }, [fields]);

  return (
    <form onSubmit={onSubmitForm}>
      <div className="field">
        <label className="label has-text-weight-normal">Link Path</label>
        <div className="control">
          <select
            id="basePath"
            name="basePath"
            value={fields.basePath}
            onChange={handleFieldChange}
          >
            {UNIVERSAL_LINK_BASE_PATHS.map((arrayItem) => {
              return (
                <option key={arrayItem} value={arrayItem}>
                  {arrayItem}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <div className="field">
        <label className="label has-text-weight-normal">Link Parameter</label>
        <div className="control">
          <input
            name="pathExtension"
            id="pathExtension"
            className="input is-medium fs-block"
            type="text"
            maxLength={100}
            value={fields.pathExtension}
            onChange={handleFieldChange}
          />
        </div>
      </div>

      <div className="field">
        <label className="label has-text-weight-normal">Display Text</label>
        <div className="control">
          <input
            name="displayText"
            id="displayText"
            className="input is-medium fs-block"
            type="text"
            maxLength={255}
            value={fields.displayText}
            onChange={handleFieldChange}
          />
        </div>
      </div>

      <br />
      <div className="has-text-centered">
        <input
          type="submit"
          className={
            submitLoading
              ? "button is-warning is-outline is-loading"
              : "button is-warning is-outline"
          }
          value={"Submit"}
          disabled={!universalLinkAttachmentValidated}
        />
      </div>
    </form>
  );
};

export default AdminUniversalLinkAttachmentForm;
