import { AUTH_ACTIONS } from "./authReducer";

const initState = {
  progressBarProgress: null,
  showConfirmMessage: false,
  confirmMessageProps: null,
  showSticky: false,
  stickyBannerProps: null,
};

const ACTION_PREFIX = "PROGRESS_BAR/";

export const ALERTING_ACTIONS = {
  SET_PROGRESS: ACTION_PREFIX + "SET_PROGRESS",
  CLEAR_PROGRESS: ACTION_PREFIX + "CLEAR_PROGRESS",
  SET_CONFIRM: ACTION_PREFIX + "SET_CONFIRM",
  CLEAR_CONFIRM: ACTION_PREFIX + "CLEAR_CONFIRM",
  SET_STICKY: ACTION_PREFIX + "SET_STICKY",
  CLEAR_STICKY: ACTION_PREFIX + "CLEAR_STICKY",
};

const alertingReducer = (state = initState, action) => {
  switch (action.type) {
    case ALERTING_ACTIONS.SET_PROGRESS:
      return {
        progress: action.progressBarProgress,
      };
    case ALERTING_ACTIONS.CLEAR_PROGRESS:
      return {
        progress: null,
      };
    case ALERTING_ACTIONS.SET_CONFIRM:
      return {
        showConfirmMessage: true,
        confirmMessageProps: action.confirmMessageProps,
      };
    case ALERTING_ACTIONS.CLEAR_CONFIRM:
      return {
        showConfirmMessage: false,
      };
    case ALERTING_ACTIONS.SET_STICKY:
      return {
        showSticky: true,
        stickyBannerProps: action.stickyBannerProps,
      };
    case ALERTING_ACTIONS.CLEAR_STICKY:
      return {
        showSticky: false,
      };
    case AUTH_ACTIONS.SIGNOUT:
      return initState; //Always return the initial state
    default:
      return state;
  }
};

export default alertingReducer;
