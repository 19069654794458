import React from "react";

export const NumberedListItemLi = ({ num, title, text }) => {
  return (
    <li className="sw-numbered-li columns is-mobile">
      <div className="column is-narrow">
        <span className="sw-numbered-li--number">{num}</span>
      </div>
      <div className="column">
        <strong className="">{title}</strong>
        <br />
        <span className="has-text-grey">{text}</span>
      </div>
    </li>
  );
};

export const NumberedListItem = ({ num, title }) => {
  return (
    <div className="sw-numbered-div">
      <span className="sw-numbered-div--number">{num}</span>
      <span className="sw-numbered-div--text">{title}</span>
    </div>
  );
};
