import { AUTH_ACTIONS } from "./authReducer";

const initState = {
  prices: null,
  err: null,
  isLoading: false,
};

const ACTION_PREFIX = "PREMIUM/";

export const PREMIUM_ACTIONS = {
  PRICES: ACTION_PREFIX + "PRICES",
  PRICES_SUCCESS: ACTION_PREFIX + "PRICES_SUCCESS",
  PRICES_ERROR: ACTION_PREFIX + "PRICES_ERROR",
};

const premiumReducer = (state = initState, action) => {
  switch (action.type) {
    case PREMIUM_ACTIONS.PRICES:
      return {
        ...state,
        err: null,
        isLoading: true,
      };
    case PREMIUM_ACTIONS.PRICES_ERROR:
      return {
        ...state,
        err: action.err.message,
        isLoading: false,
      };
    case PREMIUM_ACTIONS.PRICES_SUCCESS:
      return {
        ...state,
        prices: action.res.data.prices,
        err: null,
        isLoading: false,
      };
    case AUTH_ACTIONS.SIGNOUT:
      return initState; //Always return the initial state
    default:
      return state;
  }
};

export default premiumReducer;
