import React from "react";

import helpers from "../../services/helpers";
import { ERROR_ACTIONS } from "../reducers/errorReducer";
import ROUTES from "../../constants/routes";

export const clearError = () => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({ type: ERROR_ACTIONS.CLEAR });
  };
};

export const clearWarning = () => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({ type: ERROR_ACTIONS.CLEAR_WARNING });
  };
};

/**
 * Sets API error action with given error.
 * @param {*} err - Err object return from api call
 * @param {*} message - Optional, use if don't want to use message from backend
 * @param {*} backupMessage - Optional, use as backup if can't find message in err obj
 */
export const setError = (err, message, backupMessage, title) => {
  return (dispatch, getState, { getFirebase }) => {
    const defaultMessage = helpers.getMessageFromErrObj(err, backupMessage);

    dispatch({
      type: ERROR_ACTIONS.ERROR,
      err,
      message: message || defaultMessage,
      title: title,
    });
  };
};

/**
 * Sets API error for LOGOUT with given error.
 * @param {*} err - Err object return from api call
 * @param {*} message - Optional, use if don't want to use message from backend
 * @param {*} backupMessage - Optional, use as backup if can't find message in err obj
 */
export const setLogoutError = (err, message, backupMessage) => {
  return (dispatch, getState, { getFirebase }) => {
    const defaultMessage = helpers.getMessageFromErrObj(err, backupMessage);

    dispatch({
      type: ERROR_ACTIONS.ERROR_LOGOUT,
      err,
      message: message || defaultMessage,
    });
  };
};

/**
 * Sets warning action with given error.
 * @param {*} title - Title
 * @param {*} message - Message text
 * @param {*} redirectRoute - Optional: Where to redirect after clicking on message
 */
export const setWarning = (title, message, redirectRoute) => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: ERROR_ACTIONS.WARNING,
      title: title,
      message: message,
      redirectRoute: redirectRoute,
    });
  };
};

/**
 * Sets error for account sync warnings
 * @param {*} numPlaidErrors - Number plaid account errors
 * @param {*} callback - Optional: OnClick handler. Triggered on clicks in message
 */
export const setAccountErrorsWarning = (numPlaidErrors, callback) => {
  return (dispatch, getState, { getFirebase }) => {
    let title =
      numPlaidErrors === 1
        ? `${numPlaidErrors} account needs attention!`
        : `${numPlaidErrors} accounts need attention!`;
    const message = (
      <div className="level">
        <button className="button is-text level-item level-left">
          Click here to fix it now.
        </button>
      </div>
    );
    const redirectRoute = ROUTES.PROFILE_ACCOUNTS;

    dispatch(setWarning(title, message, redirectRoute));
  };
};
