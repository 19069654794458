import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";

import {
  updateAccountItems,
  getAccountItems,
  asyncDeleteItem,
} from "../../store/actions/institutionActions";
import { setConfirmMessage } from "../../store/actions/alertingActions";

import AddAccountButton from "../../components/Buttons/AddAccountButton";

import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";
import InstitutionsSection from "../../components/Profile/InstitutionsSection";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

const LinkedAccountsPage = ({ institutions, isLoading }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Note: We probably want to rate limit at some point
    dispatch(updateAccountItems());
    dispatch(trackEvent(EVENTS.LINKED_ACCOUNTS_VIEWED));
  }, [dispatch]);

  const setPageTitle = useOutletContext();
  useEffect(() => {
    setPageTitle("Connected accounts");
  }, [setPageTitle]);

  const updateLoans = () => {
    dispatch(updateAccountItems());
  };

  const onAddAccountSuccess = () => {
    dispatch(getAccountItems());
  };

  const onDeleteClicked = (itemId) => {
    dispatch(
      setConfirmMessage(
        "Delete account",
        "Are you sure you want to do this? This action cannot be undone.",
        "Delete",
        () => dispatch(asyncDeleteItem(itemId)),
        true
      )
    );
  };

  return (
    <div className="sw-flex-layout--column-center">
      <div className="sw-container--fixed-width">
        {isLoading && (
          <div className="sw-flex-layout--column-center">
            <LoadingSpinner></LoadingSpinner>
            <p>This might take a few seconds...</p>
          </div>
        )}
        <InstitutionsSection
          institutions={institutions}
          updateLoans={updateLoans}
          onDelete={onDeleteClicked}
        ></InstitutionsSection>
        <br />
        {!isLoading && (
          <AddAccountButton
            onAddAccountSuccess={onAddAccountSuccess}
          ></AddAccountButton>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const isLoading = state.institution.getLoading;

  return {
    isLoading: isLoading,
    institutions: state.institution.institutions,
  };
};

export default connect(mapStateToProps, null)(LinkedAccountsPage);
