import React, { useEffect } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";

import { NumberedListItemLi } from "../../components/Lists/NumberedListItem";
import { BackButton } from "../../components/Buttons/BackButtons";

const ValuesPage = () => {
  let navigate = useNavigate();

  const setPageTitle = useOutletContext();
  useEffect(() => {
    setPageTitle("Snowball values");
  }, [setPageTitle]);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="sw-flex-layout--column-center">
      <div className="sw-container--fixed-width sw-is-wide">
        <div className="sw-padding--bottom-lg">
          <BackButton onBack={goBack} text="Back"></BackButton>
        </div>
      </div>

      <ul className="sw-container--fixed-width sw-padding--bottom-xl">
        <NumberedListItemLi
          num="1"
          title="Our Snowball community comes first"
          text="We guide community members only to products and services that we would feel comfortable recommending to a close family member."
        ></NumberedListItemLi>
        <NumberedListItemLi
          num="2"
          title="Do what’s right for each Snowball user"
          text='Our community is at the core of everything that we do.  We listen to you intently, and with each decision we make we ask ourselves “What’s best for our user?"'
        ></NumberedListItemLi>
        <NumberedListItemLi
          num="3"
          title="Taking a holistic approach"
          text="When helping you make a financial decision, we understand that you are not just a number, and take other considerations into account."
        ></NumberedListItemLi>
      </ul>
    </div>
  );
};

export default ValuesPage;
