import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "../Modal/Modal";
import TextInput from "../Input/TextInput";

import { useFormFields } from "../Form/useFormFields";
import { updateUserSettings } from "../../store/actions/userSettingsActions";
import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";
import { getCurrentUser } from "../../store/actions/authActions";

const UserSettingsModal = ({ onClose, isOpen, onBack }) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => {
    return state.auth;
  });

  const [fields, handleFieldChange] = useFormFields({
    name: currentUser.first_name,
    displayName: currentUser.display_name || "",
    profileImageUrl: currentUser.profile_img_url || "",
  });

  const footerObj = (
    <button
      form="sw-edit-user-settings-form"
      type="submit"
      className="button is-primary"
    >
      Save
    </button>
  );

  const onSubmitForm = (event) => {
    event.preventDefault();
    dispatch(updateUserSettings(fields)).then(() => {
      dispatch(getCurrentUser());
    });
    dispatch(trackEvent(EVENTS.USER_SETTINGS_SAVED));
    onClose();
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} onBack={onBack} footer={footerObj}>
      <form
        id="sw-edit-user-settings-form"
        onSubmit={(event) => onSubmitForm(event)}
      >
        <div className="sw-padding--left-lg sw-padding--right-lg">
          <p className="modal-card-body__title">Edit personal info</p>
          <div className="field">
            <TextInput
              label="Name"
              name="name"
              required={true}
              className="fs-block"
              value={fields.name}
              onChange={handleFieldChange}
              placeholder="First name"
            ></TextInput>
          </div>

          <div className="field">
            <TextInput
              label="Username"
              name="displayName"
              required={true}
              className="fs-block"
              value={fields.displayName}
              onChange={handleFieldChange}
              placeholder="Username"
            ></TextInput>
          </div>

          {false && (
            <div className="field">
              <TextInput
                label="Profile image url"
                name="profileImageUrl"
                className="fs-block"
                value={fields.profileImageUrl}
                onChange={handleFieldChange}
                placeholder="Profile image url"
              ></TextInput>
            </div>
          )}
        </div>
      </form>
    </Modal>
  );
};

export const EditUserSettingsButton = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClickFn = () => {
    setIsOpen(true);
  };

  return (
    <div>
      {isOpen && (
        <UserSettingsModal
          onClose={() => setIsOpen(false)}
          isOpen={isOpen}
        ></UserSettingsModal>
      )}
      <button
        className={className ? className : "button is-size-7 is-ghost"}
        onClick={onClickFn}
      >
        Edit
      </button>
    </div>
  );
};
