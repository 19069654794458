import { NET_WORTH_ACTIONS } from "../reducers/netWorthReducer";
import { setError, setWarning, clearWarning } from "./errorActions";
import { apiGet, apiPost } from "./apiActions";
import { trackEvent, EVENTS } from "./analyticsActions";
import { getAccountItems } from "../../store/actions/institutionActions";
import { getNetWorthGraphData } from "../../store/actions/netWorthGraphActions";

export const asyncGetNetWorthItems = () => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/liabilities/net_worth";
      dispatch({ type: NET_WORTH_ACTIONS.GET });

      const onSuccess = (res) => {
        dispatch({ type: NET_WORTH_ACTIONS.GET_SUCCESS, res });
        resolve();
      };

      const onError = (err) => {
        const errMessage =
          "There was an error getting your loans. Please contact the Snowball team and we'll help you resolve this.";
        dispatch({ type: NET_WORTH_ACTIONS.GET_ERROR, err });
        dispatch(setError(err, errMessage));
        reject(err);
      };

      dispatch(apiGet(endpoint, onSuccess, onError));
    });
  };
};

/**
 * Checks status of job given task_id
 * @param {*} task_id
 * @returns
 */
export const checkAddItemStatus = (task_id) => {
  return (dispatch, getState) => {
    const endpoint = `/api/net_worth/task/status/${task_id}`;

    const onSuccess = (res) => {
      if (res && res.data && res.data.message) {
        if (res.data.message === "finished") {
          // if it's done, trigger a refresh of net worth items and data
          dispatch(asyncGetNetWorthItems());
          dispatch(getNetWorthGraphData());
          dispatch(clearWarning());
        } else {
          // console.log(res.data.message);
          setWarning("Syncing account...", "Try refreshing in a few minutes.");
        }
      }
    };

    const onError = (err) => {
      const backupErrMessage =
        "There was an error adding this loan. Please contact the Snowball team and we'll help you resolve this.";

      dispatch({ type: NET_WORTH_ACTIONS.ADD_ERROR, err });
      dispatch(setError(err, null, backupErrMessage));
    };

    dispatch(apiGet(endpoint, onSuccess, onError));
  };
};

export const asyncAddNetWorthPlaidItem = (token, metadata) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/liabilities/net_worth";
      const data = {
        public_token: token,
        institution: metadata.institution,
        metadata: metadata,
      };
      dispatch({ type: NET_WORTH_ACTIONS.ADD });

      const onSuccess = (res) => {
        if (res && res.data && res.data.task_id) {
          dispatch(
            setWarning(
              "Syncing accounts...",
              "We're updating your accounts, please refresh the page in a few minutes."
            )
          );
          dispatch({ type: NET_WORTH_ACTIONS.ADD_SUCCESS });
          resolve(res.data.task_id);
        } else {
          dispatch({ type: NET_WORTH_ACTIONS.ADD_SUCCESS });
          resolve();
        }
      };

      const onError = (err) => {
        const backupErrMessage =
          "There was an error adding this loan. Please contact the Snowball team and we'll help you resolve this.";

        dispatch({ type: NET_WORTH_ACTIONS.ADD_ERROR, err });
        dispatch(setError(err, null, backupErrMessage));
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};

/**
 * Calls backend and updates all net worth items and data.
 * This updates net_worth_data in the reducer as well,
 * doing the same as asyncGetNetWorthItems.
 * @returns net_worth response
 */
export const updateNetWorthItems = () => {
  return (dispatch, getState) => {
    const endpoint = `/api/liabilities/net_worth/refresh`;
    dispatch({ type: NET_WORTH_ACTIONS.UPDATE });
    dispatch(trackEvent(EVENTS.LOANS_UPDATE_STARTED));

    const onSuccess = (res) => {
      dispatch({
        type: NET_WORTH_ACTIONS.UPDATE_SUCCESS,
        res,
      });

      // make sure to refetch items and graph too
      // again, this is probably a bit inefficient
      dispatch(getAccountItems());
      dispatch(getNetWorthGraphData());
      dispatch(trackEvent(EVENTS.LOANS_UPDATE_COMPLETED));
    };

    const onError = (err) => {
      dispatch({
        type: NET_WORTH_ACTIONS.UPDATE_ERROR,
        err,
      });

      dispatch(setError(err));
    };

    dispatch(apiPost(endpoint, null, onSuccess, onError));
  };
};

/**
 * Adds or update a manual account entry.
 * @param {*} accountData
 * @param {*} accountId - Optional. If passed, updates existing manual entry vs. creating a new one.
 * @returns
 */
export const updateManualEntry = (accountData, accountId) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/liabilities/manual_entry";
      const data = {
        account_id: accountId,
        name: accountData.name,
        type: accountData.type,
        balance: accountData.balance,
        interest_rate: accountData.interest_rate,
      };

      dispatch({ type: NET_WORTH_ACTIONS.MANUAL_ENTRY });

      const onSuccess = (res) => {
        // need to make sure to update net worth data and graph
        dispatch({ type: NET_WORTH_ACTIONS.MANUAL_ENTRY_SUCCESS, res });
        // need to make sure to update net worth data and graph
        dispatch(asyncGetNetWorthItems());
        dispatch(getNetWorthGraphData());
        resolve();
      };

      const onError = (err) => {
        dispatch({ type: NET_WORTH_ACTIONS.MANUAL_ENTRY_ERROR, err });
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};

/**
 * Delete manual account entry.
 * @param {*} accountId - Optional. If passed, updates existing manual entry vs. creating a new one.
 * @returns
 */
export const deleteManualEntry = (accountId) => {
  return (dispatch, getState) => {
    const endpoint = "/api/liabilities/manual_entry/delete";
    const data = {
      account_id: accountId,
    };

    dispatch({ type: NET_WORTH_ACTIONS.MANUAL_ENTRY });

    const onSuccess = (res) => {
      // need to make sure to update net worth data and graph
      dispatch(asyncGetNetWorthItems());
      dispatch(getNetWorthGraphData());
      dispatch({ type: NET_WORTH_ACTIONS.MANUAL_ENTRY_SUCCESS, res });
    };

    const onError = (err) => {
      dispatch({ type: NET_WORTH_ACTIONS.MANUAL_ENTRY_ERROR, err });
      dispatch(setError(err));
    };

    dispatch(apiPost(endpoint, data, onSuccess, onError));
  };
};

/**
 * Gets a link token from plaid in order to authenticate other
 * requests to plaid. This function is used both for connecting new loans
 * and reconnecting existing loans. One of the two need to be passed for this to work.
 * If both params are null, default is liabilities for new account sync.
 * @param {string} plaidProduct - for new accounts, specify which plaid product to initialize
 * @param {*} itemId - for reconnect flow, cannot be null
 */
export const asyncCreateLinkToken = (plaidProduct, itemId) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = "/api/liabilities/create_link_token";
      const data = {
        item_id: itemId,
        plaid_product: plaidProduct,
      };
      dispatch({ type: NET_WORTH_ACTIONS.CREATE_LINK_TOKEN });

      const onSuccess = (res) => {
        dispatch({ type: NET_WORTH_ACTIONS.CREATE_LINK_TOKEN_SUCCESS, res });
        resolve(res.data.link_token);
      };

      const onError = (err) => {
        dispatch({
          type: NET_WORTH_ACTIONS.CREATE_LINK_TOKEN_ERROR,
        });
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiPost(endpoint, data, onSuccess, onError));
    });
  };
};
