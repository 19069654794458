import React from "react";

import { ManualAccountBox, AccountBox } from "../Accounts/AccountBoxes";

export const AccountBoxWrapper = (props) => {
  const accountObj = props.accountObj;

  if (accountObj.is_manual) {
    return <ManualAccountBox {...props}></ManualAccountBox>;
  }

  return <AccountBox {...props}></AccountBox>;
};
