import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useOutletContext } from "react-router-dom";

import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import LenderProfile from "../../components/Lenders/LenderProfile";
import { getLenderPersonalFromSlug } from "../../store/actions/lendersActions";

const LenderPersonalProfilePage = ({
  loading,
  getLenderPersonalFromSlugFn,
  trackEventFn,
  lenderProfile,
}) => {
  let { slug } = useParams();

  const setPageTitle = useOutletContext();
  useEffect(() => {
    setPageTitle("Personal loans");
  }, [setPageTitle]);

  useEffect(() => {
    getLenderPersonalFromSlugFn(slug);
  }, [getLenderPersonalFromSlugFn, slug]);

  // track page
  useEffect(() => {
    trackEventFn(EVENTS.LENDER_DETAILS_VIEWED, {
      lender: slug,
    });
  }, [trackEventFn, slug]);

  if (loading) {
    return (
      <div className="sw-flex-layout--column-center">
        <LoadingSpinner></LoadingSpinner>
      </div>
    );
  }

  let lender = null;
  if (lenderProfile && lenderProfile[slug]) {
    lender = lenderProfile[slug];
  }

  if (lender) {
    return (
      <div className="sw-flex-layout--column-center">
        <div className="sw-container--fixed-width sw-is-wide">
          <LenderProfile lender={lender}></LenderProfile>
        </div>
      </div>
    );
  }

  return (
    <div className="sw-flex-layout--column-center">
      <p>Sorry, we could not find a lender matching this profile.</p>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLenderPersonalFromSlugFn: (slug) =>
      dispatch(getLenderPersonalFromSlug(slug)),
    trackEventFn: (event, properties) =>
      dispatch(trackEvent(event, properties)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.lenders.getProfileLoading,
    lenderProfile: state.lenders.lenderProfile,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LenderPersonalProfilePage);
