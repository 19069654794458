import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import SelectAccountType from "./SelectAccountType";
import TrackManuallyModal from "./TrackManuallyModal";
import TrackAssetModal from "./TrackAssetModal";
import TrackDebtModal from "./TrackDebtModal";
import AddingAccountLoadingModal from "./AddingAccountLoadingModal";

import { updateManualEntry } from "../../store/actions/netWorthActions";
import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";

const MODAL_PAGES = {
  START: "start",
  MANUAL_SELECT: "manual_select",
  MANUAL_ASSET: "manual_asset",
  MANUAL_DEBT: "manual_debt",
};

const AddAccountModal = ({
  isOpen,
  onClose,
  onAddAccountSuccess,
  updateManualEntryFn,
  trackEventFn,
  isAddingPlaidItem,
}) => {
  const [modalState, setModalState] = useState(MODAL_PAGES.START);

  useEffect(() => {
    setModalState(MODAL_PAGES.START);
  }, [isOpen]);

  const goToPage = (page) => {
    return () => setModalState(page);
  };

  const onManualTrackNext = (isAsset) => {
    if (isAsset) {
      setModalState(MODAL_PAGES.MANUAL_ASSET);
    } else {
      setModalState(MODAL_PAGES.MANUAL_DEBT);
    }
  };

  const onSubmitManualEntry = (fields) => {
    // format data to match what endpoint/action wants
    const accountData = {
      name: fields.nickname,
      type: fields.accountType,
      balance: fields.balance,
      interest_rate: fields.interestRate,
    };

    updateManualEntryFn(accountData).then(() => {
      onAddAccountSuccess();
    });
    trackEventFn(EVENTS.MANUAL_ACCOUNT_ADDED, {
      subtytpe: fields.accountType,
    });
    onClose();
  };

  const onSubmitPlaid = () => {
    onAddAccountSuccess();
    onClose();
  };

  if (modalState === MODAL_PAGES.MANUAL_SELECT) {
    return (
      <TrackManuallyModal
        onClose={onClose}
        isOpen={isOpen}
        onBack={goToPage(MODAL_PAGES.START)}
        onNext={onManualTrackNext}
      ></TrackManuallyModal>
    );
  }

  if (modalState === MODAL_PAGES.MANUAL_ASSET) {
    return (
      <TrackAssetModal
        onClose={onClose}
        isOpen={isOpen}
        onBack={goToPage(MODAL_PAGES.MANUAL_SELECT)}
        onNext={onSubmitManualEntry}
      ></TrackAssetModal>
    );
  }

  if (modalState === MODAL_PAGES.MANUAL_DEBT) {
    return (
      <TrackDebtModal
        onClose={onClose}
        isOpen={isOpen}
        onBack={goToPage(MODAL_PAGES.MANUAL_SELECT)}
        onNext={onSubmitManualEntry}
      ></TrackDebtModal>
    );
  }

  if (isAddingPlaidItem) {
    return (
      <AddingAccountLoadingModal
        onClose={onClose}
        isOpen={isOpen}
      ></AddingAccountLoadingModal>
    );
  }

  return (
    <SelectAccountType
      onClose={onClose}
      isOpen={isOpen}
      onPlaidSuccess={onSubmitPlaid}
      onTrackManually={goToPage(MODAL_PAGES.MANUAL_SELECT)}
    ></SelectAccountType>
  );
};

const mapStateToProps = (state) => {
  const isAddingPlaidItem = state.netWorth.isLoadingAddItem;

  return {
    isAddingPlaidItem: isAddingPlaidItem,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateManualEntryFn: (accountData) =>
      dispatch(updateManualEntry(accountData)),
    trackEventFn: (event, properties) =>
      dispatch(trackEvent(event, properties)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAccountModal);
