import React from "react";
import MaterialCheckbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";

const Checkbox = withStyles({
  root: {
    color: "#979797",
    "&$checked": {
      color: "#1F7CFA"
    }
  },
  checked: {}
})(props => <MaterialCheckbox color="default" {...props} />);

export default Checkbox;
