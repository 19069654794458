import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useOutletContext, useNavigate } from "react-router-dom";

import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";
import {
  asyncGetFeedItem,
  asyncDeletePost,
} from "../../store/actions/communityActions";
import { setConfirmMessage } from "../../store/actions/alertingActions";

import ROUTES from "../../constants/routes";

import { BackLink } from "../../components/Buttons/BackButtons";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import FeedItem from "../../components/Communities/FeedItem";

const PostPage = ({
  trackEventFn,
  asyncGetFeedItemFn,
  asyncDeletePostFn,
  setConfirmMessageFn,
  isFeedItemsLoading,
  feedItems,
  feedError,
  currentUser,
}) => {
  // set page title
  const setPageTitle = useOutletContext();
  const navigate = useNavigate();

  useEffect(() => {
    setPageTitle("Community");
  }, [setPageTitle]);

  let { feedItemUuid } = useParams();

  useEffect(() => {
    trackEventFn(EVENTS.COMMUNITY_ACTIVITY_VIEWED);
  }, [trackEventFn]);

  useEffect(() => {
    if (feedItemUuid) {
      asyncGetFeedItemFn(feedItemUuid);
    }
  }, [feedItemUuid, asyncGetFeedItemFn]);

  const onDeletePost = (communityUuid, feedItemUuid) => {
    setConfirmMessageFn(
      "Delete post",
      "Are you sure you want to do this? This action cannot be undone.",
      "Delete",
      () => {
        asyncDeletePostFn(feedItemUuid).then(() => {
          // this item does not exist anymore, navigate back to community tab
          return navigate(ROUTES.COMMUNITY);
        });
        trackEventFn(EVENTS.COMMUNITY_POST_DELETED, {
          community_uuid: communityUuid,
          feed_item_uuid: feedItemUuid,
        });
      },
      true
    );
  };

  if (isFeedItemsLoading) {
    return (
      <div className="sw-flex-layout--column-center">
        <LoadingSpinner></LoadingSpinner>
      </div>
    );
  }

  if (feedError) {
    return (
      <div>
        <BackLink
          routeLink={ROUTES.COMMUNITY}
          text="Back to Community"
        ></BackLink>
        <p>Post not found</p>
      </div>
    );
  }

  return (
    <div>
      <BackLink
        routeLink={ROUTES.COMMUNITY}
        text="Back to Community"
      ></BackLink>

      <h2 className="subtitle sw-padding--top-lg">Post</h2>
      {feedItems &&
        feedItems.map((feedItem, index) => {
          return (
            <FeedItem
              key={index}
              feedItem={feedItem}
              currentUser={currentUser}
              onDeletePost={onDeletePost}
              showCommentDefault={true}
            ></FeedItem>
          );
        })}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackEventFn: (event, properties) =>
      dispatch(trackEvent(event, properties)),
    asyncGetFeedItemFn: (feedItemUuid) =>
      dispatch(asyncGetFeedItem(feedItemUuid)),
    asyncDeletePostFn: (feedItemUuid) =>
      dispatch(asyncDeletePost(feedItemUuid)),
    setConfirmMessageFn: (title, message, confirmText, onConfirm, isDanger) =>
      dispatch(
        setConfirmMessage(title, message, confirmText, onConfirm, isDanger)
      ),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isFeedItemsLoading: state.communities.isFeedItemsLoading,
    feedItems: state.communities.feedItems,
    feedError: state.communities.err,
    currentUser: state.auth.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostPage);
