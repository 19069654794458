import React from "react";

const ReactionImg = ({ reactionObj, reactionKey }) => {
  return (
    <figure className="image is-24x24">
      <img src={reactionObj.image_url} alt={`${reactionKey} emoji`} />
    </figure>
  );
};

export default ReactionImg;
