import React from "react";

import { RefiConsiderations } from "../../components/Messages/CovidRecConsiderations";
import ExpandableMessageBox from "../Messages/ExpandableMessageBox";

const FAQS = [
  {
    title: "What is student loan refinancing?",
    content: (
      <div>
        <ul>
          <li>
            When you refinance student loans, your old loans are paid off and
            combined into one, single loan with a new lender.
          </li>
          <li>
            Your new loan will have a new interest rate, length of repayment
            (e.g., 10 years), and terms (e.g., deferment during grad school.
          </li>
          <li>
            You might consider refinancing if you want to lower your interest
            rate, decrease your monthly payments, or switch from a fixed rate to
            a variable rate loan.
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: "Can I refinance private loans?",
    content: `Yes, and it’s often beneficial to do so if your interest rate is high, your monthly payments are unmanageable, or you have less favorable terms (e.g., no deferment during grad school).`,
  },
  {
    title: "Can I refinance federal loans?",
    content: `Yes, but it might not make sense if you are taking advantage of the COVID-19 relief, public service loan forgiveness, income-based repayment plans, or deferment and forbearance.`,
  },
  {
    title: "What are the pros of refinancing?",
    content: (
      <div>
        <p>By refinancing, you can take advantage of these options:</p>
        <ul>
          <li>
            Lowering your interest rate. You’ll be paying less interest month to
            month, which can add to large savings.
          </li>
          <li>
            Simplifying your monthly payments to a lender of your choice with
            one payment amount
          </li>
          <li>Lowering your monthly payment by choosing a longer term</li>
          <li>
            Changing from a variable to a fixed interest rate, so your interest
            will not change
          </li>
          <li>
            Having a more flexible lender if you go back to school or need to
            stop payments
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: "What are the cons of refinancing?",
    content: (
      <div>
        <p>
          You may not want to refinance because of these potential
          disadvantages:
        </p>
        <ul>
          <li>
            If you have federal loans, you will lose federal repayment
            protections, including the ability to stop payments if you can’t
            afford them for a longer period of time
          </li>
          <li>
            You lose the flexibility to change your payment plan, particularly
            if you can’t afford your payments
          </li>
          <li>
            If you are on a loan forgiveness program, you will no longer be
            eligible for the program
          </li>
        </ul>
      </div>
    ),
  },
  {
    title:
      "How do I calculate how much I will save (if anything) by refinancing?",
    content: `
      How much you will save on refinancing will depend on a number of factors including your current interest rate, your specific loans, and what offer you can get with refinancing lenders. You can check out our recommendation to see what refinancing could look like for you.`,
  },
];

const RefiFAQ = () => {
  return (
    <div className="box">
      <h2 className="subtitle">Frequently asked questions</h2>
      <RefiConsiderations></RefiConsiderations>
      {FAQS.map((faqObj, index) => {
        return (
          <ExpandableMessageBox
            key={index}
            text={faqObj.title}
            content={faqObj.content}
          ></ExpandableMessageBox>
        );
      })}
    </div>
  );
};

export default RefiFAQ;
