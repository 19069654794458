import React, { Component } from "react";
import { connect } from "react-redux";

import {
  updateAccountItemsForUser,
  updateNetWorthItemsForUser,
  getAccountItemsForUser,
  deletePlaidItemForUser,
} from "../../store/actions/adminActions";
import { setConfirmMessage } from "../../store/actions/alertingActions";
import PlaidInstitution from "../../components/Profile/PlaidInstitution";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

class AdminUserSummaryPage extends Component {
  constructor(props) {
    super(props);
    this.updateUserItems = this.updateUserItems.bind(this);
    this.updateNetWorthLoans = this.updateNetWorthLoans.bind(this);
    this.onDeleteClicked = this.onDeleteClicked.bind(this);
  }

  componentDidMount() {
    let userId = this.props.userId;
    this.props.getAccountItemsForUser(userId);
  }

  updateUserItems() {
    const userId = this.props.userId;
    this.props.updateAccountItemsForUser(userId);
  }

  updateNetWorthLoans() {
    const userId = this.props.userId;
    this.props.updateNetWorthItemsForUser(userId);
  }

  onDeleteClicked(itemId) {
    this.props.setConfirmMessage(
      "Delete Item",
      "Are you sure you want to do this? This action cannot be undone.",
      "Delete",
      () => this.props.deletePlaidItemForUser(this.props.userId, itemId),
      true
    );
  }

  render() {
    if (this.props.isLoading) {
      return <LoadingSpinner></LoadingSpinner>;
    }

    return (
      <div className="fs-block sw-container--fixed-width">
        <h3 className="sw-section-header">Linked Accounts</h3>
        <div className="buttons">
          <button
            className={
              this.props.updateUserItemsLoading
                ? " button is-warning is-outline is-loading"
                : "button is-warning is-outline"
            }
            onClick={this.updateUserItems}
          >
            Update Plaid Items
          </button>
          <button
            className={
              this.props.updateUserLoansLoading
                ? " button is-warning is-outline is-loading"
                : "button is-warning is-outline"
            }
            onClick={this.updateNetWorthLoans}
          >
            Update all data
          </button>
        </div>

        <br />
        <br />
        {this.props.plaidItems &&
          this.props.plaidItems.map((account, index) => {
            return (
              <PlaidInstitution
                key={account.item_id}
                account={account}
                onDelete={this.onDeleteClicked}
              ></PlaidInstitution>
            );
          })}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const userId = ownProps.userId;
  let plaidItems = [];
  if (state.admin.userPlaidItems) {
    plaidItems = state.admin.userPlaidItems[userId];
  }

  return {
    userId: userId,
    plaidItems: plaidItems,
    updateUserLoansLoading: state.admin.updateUserLoansLoading,
    updateUserItemsLoading: state.admin.updateUserItemsLoading,
    isLoading: state.admin.getUserItemsLoading || state.admin.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAccountItemsForUser: (userId) =>
      dispatch(getAccountItemsForUser(userId)),
    updateAccountItemsForUser: (userId) =>
      dispatch(updateAccountItemsForUser(userId)),
    updateNetWorthItemsForUser: (userId) =>
      dispatch(updateNetWorthItemsForUser(userId)),
    deletePlaidItemForUser: (userId, itemId) =>
      dispatch(deletePlaidItemForUser(userId, itemId)),
    setConfirmMessage: (title, message, confirmText, onConfirm, isDanger) =>
      dispatch(
        setConfirmMessage(title, message, confirmText, onConfirm, isDanger)
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUserSummaryPage);
