import React from "react";

import ExpandableMessage from "../Messages/ExpandableMessage";

const RecDisclaimerMessage = (props) => {
  const disclaimerContent = (
    <div>
      <div>
        <p>
          In order to calculate your recommendations, we assume the following:
        </p>
        <ul>
          <li>
            If initial enrollment, we assume a payment plan that allows for 10
            or 25 year repayment. This will vary depending on your lender.
          </li>
          <li>
            If additional monthly payment, we assume this is allocated evenly
            across loans based on weighting.
          </li>
          <li>
            Savings are calculated based on the information you provided for
            your current student debt assuming no change in monthly payment.
          </li>
          <li>
            Loan terms are not guaranteed and are for illustrative purposes
            only. They are subject to review and verification by a lender.
          </li>
          <li>Tax implications are not taken into account.</li>
          <li>
            Any special programs (e.g. subsidized interest or loan forgiveness)
            are not taken into account.
          </li>
          <li>
            Any special repayment programs are not taken into account or are
            based on available information. This may differ from your current
            repayment program.
          </li>
          <li>
            Federally held loans are currently impacted by the CARES Act,
            Trump’s student loan order, and any guidance by the Department of
            Education. If your current interest rate is set to 0%, we have an
            estimated rate. This can be materially different based on your type
            of loan.
          </li>
        </ul>
        <p>Snowball Wealth is not a financial advisor.</p>
      </div>
    </div>
  );

  return (
    <ExpandableMessage
      text="See details and assumptions"
      content={disclaimerContent}
    ></ExpandableMessage>
  );
};

export default RecDisclaimerMessage;
