const INTERCOM_KEY = process.env.REACT_APP_INTERCOM_SECRET_KEY;

export const EVENTS = {
  ACCOUNT_CREATED: "Account Created",
  ACCOUNT_DETAILS_VIEWED: "Account Details Viewed",
  ADD_ACCOUNT_CLICKED: "Add Account Clicked",
  BLOG_CLICKED: "Blog Clicked",
  BLOG_POST_CLICKED: "Blog Post Clicked",
  BLOG_MORE_CLICKED: "Blog More Clicked",
  BROWSE_PLANS_VIEWED: "Browse Plans Viewed",
  CHALLENGES_VIEWED: "Challenges Viewed",
  CHALLENGE_JOINED: "Challenge Joined",
  CHALLENGE_VIEWED: "Challenge Viewed",
  CHALLENGE_SAVED: "Challenge Saved",
  CHECK_RATE_CLICKED: "Check Rate Clicked",
  COMMENT_REACTION_CLICKED: "Comment Reaction Clicked",
  COMMUNITY_ACTIVITY_VIEWED: "Community Activity Viewed",
  COMMUNITY_COMMENT_CREATED: "Community Comment Created",
  COMMUNITY_COMMENT_DELETED: "Community Comment Deleted",
  COMMUNITY_POST_CREATED: "Community Post Created",
  COMMUNITY_POST_DELETED: "Community Post Deleted",
  COMMUNITY_VIEWED: "Community Viewed", // todo
  COMMUNITY_JOINED: "Community Joined", // todo
  COMPLETE_PLAN_TASK_CLICKED: "Complete Plan Task Clicked",
  FEDERAL_PROGRAMS_VIEWED: "Federal Programs Viewed",
  FEDERAL_COMPARE_VIEWED: "Federal Compare Viewed",
  FEDERAL_NOTIFY_CLICKED: "Federal Notify Clicked",
  HELP_CLICKED: "Help Clicked",
  INCREASE_STEPS_VIEWED: "Increase Steps Viewed",
  INSIGHTS_VIEWED: "Insights Viewed",
  INVITE_CLICKED: "Invite Clicked",
  ITEM_REACTION_CLICKED: "Item Reaction Clicked", // todo
  LENDER_DETAILS_VIEWED: "Lender Details Viewed",
  LENDERS_VIEWED: "Lenders Viewed",
  LINKED_ACCOUNTS_VIEWED: "Linked Accounts Viewed",
  LOAN_SUMMARY_VIEWED: "Loan Summary Viewed",
  LOAN_RECONNECT_CLICKED: "Loan Reconnect Clicked",
  LOANS_UPDATE_COMPLETED: "Loans Update Completed",
  LOANS_UPDATE_STARTED: "Loans Update Started",
  MANUAL_ACCOUNT_ADDED: "Manual Account Added",
  MANUAL_ACCOUNT_CLICKED: "Manual Account Clicked",
  MANUAL_ACCOUNT_DELETED: "Manual Account Deleted",
  MANUAL_ACCOUNT_SAVED: "Manual Account Saved",
  MY_PLAN_VIEWED: "My Plan Viewed",
  NET_WORTH_VIEWED: "Net Worth Viewed",
  ONBOARDING_REC_COMPLETED: "Onboarding Rec Completed",
  ONBOARDING_REC_SAVED: "Onboarding Rec Saved",
  ONBOARDING_REC_STARTED: "Onboarding Rec Started",
  PASSWORD_FORGET_CLICKED: "Password Forget Clicked",
  PLAID_INTEGRATION_CANCELLED: "Plaid Integration Cancelled",
  PLAID_INTEGRATION_CLICKED: "Plaid Integration Clicked",
  PLAID_INTEGRATION_COMPLETED: "Plaid Integration Completed",
  PLAID_RECONNECT_COMPLETED: "Plaid Reconnect Completed",
  PLAN_TASK_VIEWED: "Plan Task Viewed",
  PLAN_JOINED: "Plan Joined",
  PLAN_TASK_POST_ADDED: "Plan Task Post Added",
  PREMIUM_CLICKED: "Premium Clicked", // todo: Deprecate
  PREMIUM_VIEWED: "Premium Viewed",
  PREMIUM_LEVEL_CLICKED: "Premium Level Clicked",
  PREMIUM_TASK_CLICKED: "Premium Task Clicked",
  PREMIUM_PRICING_VIEWED: "Premium Pricing Viewed",
  PREMIUM_PRICING_CLICKED: "Premium Pricing Clicked",
  PREMIUM_WAITLIST_VIEWED: "Premium Waitlist Viewed",
  PREMIUM_WAITLIST_CLICKED: "Premium Waitlist Clicked",
  REC_DETAILS_VIEWED: "Recommendation Details Viewed",
  REC_CLICKED: "Recommendation Clicked",
  REC_VIEWED: "Recommendation Viewed",
  REFI_STEPS_VIEWED: "Refi Steps Viewed",
  RESOURCES_VIEWED: "Resources Viewed",
  RESOURCE_GUIDE_CLICKED: "Resource Guide Clicked",
  RESOURCE_TEMPLATE_CLICKED: "Resouce Template Clicked",
  START_DIGITAL_TRIAL_CLICKED: "Start Digital Trial Clicked",
  STICKY_BANNER_CLICKED: "Sticky Banner Clicked",
  USER_SETTINGS_SAVED: "User Settings Saved",
  VIDEO_VIEWED: "Video Viewed",
  VIDEO_MORE_CLICKED: "Video More Clicked",
};

async function hmacSha256Hash(message, key) {
  const getUtf8Bytes = (str) =>
    new Uint8Array(
      [...unescape(encodeURIComponent(str))].map((c) => c.charCodeAt(0))
    );

  const keyBytes = getUtf8Bytes(key);
  const messageBytes = getUtf8Bytes(message);

  const cryptoKey = await crypto.subtle.importKey(
    "raw",
    keyBytes,
    { name: "HMAC", hash: "SHA-256" },
    true,
    ["sign"]
  );
  const sig = await crypto.subtle.sign("HMAC", cryptoKey, messageBytes);

  // to lowercase hexits
  return [...new Uint8Array(sig)]
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
}

export const resetAnalyticsSession = () => {
  return (dispatch, getState, { getFirebase }) => {
    window.analytics.ready(() => {
      // https://community.segment.com/t/y76s29/clean-up-intercom-on-logout
      window.Intercom("shutdown");

      // Note, this triggers a race condition, no clear fix yet
      // https://community.intercom.com/t/http-post-session-empty-error/1241/11
      window.analytics.reset();

      // re-start session and show messenger again
      dispatch(trackPage());
    });
  };
};

export const trackIdentify = (
  userId,
  userEmail,
  firstName,
  additionalTraits,
  callback
) => {
  return (dispatch, getState, { getFirebase }) => {
    let traitsObj = {
      email: userEmail,
      name: firstName,
    };

    if (additionalTraits) {
      traitsObj = { ...traitsObj, ...additionalTraits };
    }

    window.analytics.ready(() => {
      hmacSha256Hash(userId, INTERCOM_KEY).then((res) => {
        window.analytics.identify(
          userId,
          traitsObj,
          {
            Intercom: {
              user_hash: res,
            },
          },
          callback
        );
      });
    });
  };
};

export const trackPage = () => {
  return (dispatch, getState, { getFirebase }) => {
    window.analytics.ready(() => {
      window.analytics.page();
      // Todo: At some point track with name or properties
      // window.analytics.page(name, properties);
    });
  };
};

export const trackEvent = (event, properties) => {
  return (dispatch, getState, { getFirebase }) => {
    window.analytics.ready(() => {
      // console.log("tracked: ", event, properties);
      window.analytics.track(event, properties);
    });
  };
};

export const toggleIntercomVisibility = (hideLauncher) => {
  return (dispatch, getState, { getFirebase }) => {
    window.analytics.ready(() => {
      window.Intercom("update", {
        hide_default_launcher: hideLauncher,
      });
    });
  };
};
