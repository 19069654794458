import React, { useState, useEffect } from "react";

const TextArea = ({
  name,
  label,
  placeholder,
  className,
  value,
  rows,
  maxLength,
  required,
  onChange,
}) => {
  const id = name;
  const [isActive, setIsActive] = useState(value === "" ? false : true);
  const [isFocused, setIsFocused] = useState(false);
  const [displayPlaceholder, setDiplayPlaceholder] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // don't show placeholder until is active to avoid overlapping text
  useEffect(() => {
    if (placeholder && value === "" && isFocused) {
      setDiplayPlaceholder(placeholder);
    } else {
      setDiplayPlaceholder("");
    }
  }, [value, placeholder, isFocused]);

  function handleTextChange(e) {
    onChange(e);

    if (e.target.value.length >= maxLength) {
      setErrorMessage("Character limit reached");
    } else {
      setErrorMessage("");
    }

    if (e.target.value !== "") {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }

  return (
    <fieldset className="sw-label-input">
      <textarea
        id={id}
        maxLength={maxLength ? maxLength : null}
        name={id}
        type="text"
        value={value}
        required={required}
        className={`sw-label-input_input ${className}`}
        onChange={handleTextChange}
        placeholder={displayPlaceholder}
        rows={rows ? rows : 1}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <label className={isActive ? "active" : ""} htmlFor={id}>
        {label}
      </label>
      {errorMessage && <p className="help is-danger">{errorMessage}</p>}
    </fieldset>
  );
};

export default TextArea;
