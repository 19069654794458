import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useSearchParams,
  useParams,
  useOutletContext,
  useNavigate,
} from "react-router-dom";

import {
  asyncGetCourseDetail,
  asyncGetTasks,
  asyncCompleteTask,
} from "../../store/actions/courseActions";
import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";

import { TASK_STEP_MAP } from "../../constants/constants";

// import hooks for routing
import { useCourseRoutes } from "../../components/FinancialPlan/useCourseRouting";

import { isInProgress } from "../../components/FinancialPlan/courseHelpers";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import TaskWrapper from "../../components/FinancialPlan/TaskWrapper";

const TaskPage = ({ taskStep }) => {
  // set page title
  const setPageTitle = useOutletContext();
  let { cohortUuid, taskUuid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getNextTask } = useCourseRoutes();
  const [thisTask, setThisTask] = useState(null);

  const [searchParams] = useSearchParams();
  const fromTaskContent = searchParams.get("from_task_content") === "true";

  const { isLoading, course, tasks, taskMap } = useSelector((state) => {
    return state.courses;
  });

  useEffect(() => {
    setPageTitle("Courses");
  }, [setPageTitle]);

  useEffect(() => {
    if (course && thisTask) {
      dispatch(
        trackEvent(EVENTS.PLAN_TASK_VIEWED, {
          plan_slug: course.course.slug,
          course_type: course.course.course_type,
          cohort_uuid: course.cohort.cohort_uuid,
          task_slug: thisTask.task.storyblok_slug,
          level_slug: thisTask.task.group_position,
        })
      );
    }
  }, [dispatch, course, thisTask]);

  // get course details and tasks
  useEffect(() => {
    if (!isLoading) {
      if (cohortUuid && !course && !tasks) {
        dispatch(asyncGetCourseDetail(cohortUuid)).then((courseData) => {
          if (courseData && isInProgress(courseData)) {
            dispatch(asyncGetTasks(cohortUuid));
          }
        });
      }
    }
    // todo -- if course or tasks don't match need to re-request
  }, [dispatch, isLoading, course, cohortUuid, tasks]);

  useEffect(() => {
    if (taskMap && taskUuid) {
      // Grab the one task we're displaying
      setThisTask(taskMap[taskUuid]);
    }
  }, [taskMap, taskUuid]);

  const onCompleteTask = useCallback(async () => {
    dispatch(asyncCompleteTask(taskUuid)).then(() => {
      const NEXT_TASK_ROUTE = getNextTask(
        course,
        thisTask,
        taskStep ? taskStep : TASK_STEP_MAP.Content
      );

      // refetch tasks
      dispatch(asyncGetTasks(cohortUuid));
      navigate(NEXT_TASK_ROUTE);

      // track completion
      dispatch(
        trackEvent(EVENTS.COMPLETE_PLAN_TASK_CLICKED, {
          plan_slug: course.course.slug,
          course_type: course.course.course_type,
          cohort_uuid: course.cohort.cohort_uuid,
          task_slug: thisTask.task.storyblok_slug,
          level_slug: thisTask.task.group_position,
        })
      );
    });
  }, [dispatch, taskUuid, thisTask, cohortUuid, course, navigate]);

  if (isLoading || !thisTask || !course) {
    return <LoadingSpinner />;
  }

  return (
    <TaskWrapper
      course={course}
      thisTask={thisTask}
      onCompleteTask={onCompleteTask}
      taskStep={taskStep ? taskStep : TASK_STEP_MAP.Content}
      fromTaskContent={fromTaskContent}
    ></TaskWrapper>
  );
};

export default TaskPage;
