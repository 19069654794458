import { AUTH_ACTIONS } from "./authReducer";

const initState = {
  graphData: {
    dates: {},
    snapshot_data: [],
    net_worth_deltas: {
      assets: {
        all_time: null,
        one_year: null,
        six_month: null,
        three_month: null,
      },
      debts: {
        all_time: null,
        one_year: null,
        six_month: null,
        three_month: null,
      },
      net_worth: {
        all_time: null,
        one_year: null,
        six_month: null,
        three_month: null,
      },
    },
  },
  isLoading: false,
};

const ACTION_PREFIX = "NETWORTHGRAPH/";
export const NET_WORTH_GRAPH_ACTIONS = {
  GET: ACTION_PREFIX + "GET",
  GET_ERROR: ACTION_PREFIX + "GET_ERROR",
  GET_SUCCESS: ACTION_PREFIX + "GET_SUCCESS",
};

const netWorthGraphReducer = (state = initState, action) => {
  switch (action.type) {
    case NET_WORTH_GRAPH_ACTIONS.GET:
      return {
        ...state,
        isLoading: true,
      };
    case NET_WORTH_GRAPH_ACTIONS.GET_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case NET_WORTH_GRAPH_ACTIONS.GET_SUCCESS:
      const resData = action.res.data;

      return {
        ...state,
        isLoading: false,
        graphData: {
          net_worth_deltas: resData.net_worth_deltas,
          snapshot_data: resData.snapshot_data,
          dates: resData.dates,
        },
      };
    case AUTH_ACTIONS.SIGNOUT:
      return initState; // Always return the initial state
    default:
      return state;
  }
};

export default netWorthGraphReducer;
