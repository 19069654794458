const SAVE_PROS = [
  "Undergraduate loans have a 5% income threshold (starting July 1, 2024)",
  "Interest does not accrue",
  "Forgiveness after as little as 10 years for some borrowers",
  "Excludes spousal income for married-filing separately",
];

const SAVE_CONS = [
  "Some changes take effect in 2024",
  "Monthly payment adjusts as income changes",
];

const IDR_PROS = [
  "Adjustable as your income changes",
  "Intended to be affordable based on your income and family size",
  "Eligible for forgiveness",
];

const IDR_CONS = [
  "Long term, you may pay more in interest",
  "There are multiple plans to choose from: SAVE, PAYE, PAYE, ICR",
  "Forgiveness happens after 20 years or more",
];

const PSLF_PROS = [
  "Lower monthly payment if you have a lower income",
  "Forgiveness is not taxed",
];

const PSLF_CONS = [
  "Must work for a qualifying nonprofit, government agency or other qualifying organization",
  "Will have to make qualifying payments for 10 years",
  "If payment doesn’t cover interest, loan will keep growing larger over time",
];

const CONSOLIDATION_PROS = [
  "One monthly payment with one servicer",
  "Can lower monthly payment by extending the time to repay loans",
  "Switch any variable-rate loans to a fixed interest rate",
];

const CONSOLIDATION_CONS = [
  "If pay less per month or extend the term, will take longer to pay off",
  "Blended interest rate with less control over how to pay off",
  "Could lose certain benefits- such as interest rate discounts, principal rebates, or some loan cancellation",
];

export const SAVE = {
  PROS: SAVE_PROS,
  CONS: SAVE_CONS,
  MORE_LINK:
    "https://www.snowballwealth.com/blog/student-loans-key-dates-and-changes-to-know-october-2023",
};

export const IDR = {
  PROS: IDR_PROS,
  CONS: IDR_CONS,
  MORE_LINK:
    "https://studentaid.gov/manage-loans/repayment/plans/income-driven",
};

export const PSLF = {
  PROS: PSLF_PROS,
  CONS: PSLF_CONS,
  MORE_LINK:
    "https://studentaid.gov/manage-loans/forgiveness-cancellation/public-service",
};

export const CONSOLIDATION = {
  PROS: CONSOLIDATION_PROS,
  CONS: CONSOLIDATION_CONS,
  MORE_LINK:
    "https://www.snowballwealth.com/blog/what-is-federal-student-loan-consolidation",
};

export const IDR_PLANS = [
  { term: "Standard", name: "Standard" },
  { term: "New IBR", name: "Income-Based (2014)" },
  { term: "IBR", name: "Income-Based (2009)" },
  { term: "PAYE", name: "Pay As You Earn" },
  { term: "REPAYE", name: "Revised Pay As You Earn" },
  { term: "ICR", name: "Income-Contingent" },
];

export const IDR_PLANS_OPTIONS = [
  "Standard",
  "New IBR",
  "IBR",
  "PAYE",
  "REPAYE",
  "ICR",
];

const STANDARD_CONTENT = {
  TITLE: "What is Standard",
  SUMMARY:
    "Monthly payments are 10% of discretionary income and any outstanding balance is forgiven after 20 years of qualifying repayment (25 years for graduate / professional borrowers).",
  PROS: [
    "Strongest Interest Subsidy: If the monthly payment doesn’t cover the full amount of interest that accrues, the government pays the full amount of the difference on subsidized loans for the first three years, and half of the difference after that, as well as half of the difference on unsubsidized loans during all periods.",
  ],
  CONS: [
    "Spouse Income Always Included: For married borrowers, their spouse's AGI will always be included in monthly payment calculation regardless of whether they file taxes separately.",
    "No Monthly Payment Cap: As AGI increases, payments continue to increase without any ceiling.",
    "Parent Borrowers Don't Qualify: Parent borrowers don't qualify for this plan.",
    "FFEL Loans Don't Qualify: Borrowers with FFEL loans need to do a Direct Consolidation in order to qualify.",
  ],
};

const NEW_IBR_CONTENT = {
  TITLE: "What is Income-Based Repayment 2014 (New IBR)",
  SUMMARY:
    "For borrowers who took out their first federal loan after July 1, 2014, monthly payments are 10% of discretionary income and any outstanding balance is forgiven after 20 years of qualifying repayment.",
  PROS: [
    "Monthly Payment Cap: as AGI increases, payments can never be higher than the Standard 10-Year payment.",
    "Interest Subsidy: If the monthly payment doesn't cover the full amount of interest that accrues on subsidized loans, the government pays the difference for the first three years.",
  ],
  CONS: [
    "Parent Borrowers Don't Qualify: Parent borrowers don't qualify for this plan.",
    "FFEL Loans Don't Qualify: Borrowers with FFEL loans need to do a Direct Consolidation in order to qualify.",
  ],
};

const IBR_CONTENT = {
  TITLE: "What is Income-Based Repayment 2009 (IBR)",
  SUMMARY:
    "Monthly payments are 15% of discretionary income and any outstanding balance is forgiven after 25 years of qualifying repayment.",
  PROS: [
    "Monthly Payment Cap: as AGI increases, payments can never be higher than the Standard 10-Year payment.",
    "Interest Subsidy: If the monthly payment doesn't cover the full amount of interest that accrues on subsidized loans, the government pays the difference for the first three years.",
    "Includes Direct and FFEL: IBR accepts borrowers with a mix of loan types without needing to do a consolidation.",
  ],
  CONS: [
    "Parent Borrowers Don't Qualify: Parent borrowers don't qualify for this plan.",
  ],
};

const PAYE_CONTENT = {
  TITLE: "What is Pay As You Earn (PAYE)",
  SUMMARY:
    "Monthly payments are 10% of discretionary income and any outstanding balance is forgiven after 20 years of qualifying repayment.",
  PROS: [
    "Monthly Payment Cap: as AGI increases, payments can never be higher than the Standard 10-Year payment.",
    "Interest Subsidy: If the monthly payment doesn't cover the full amount of interest that accrues on subsidized loans, the government pays the difference for the first three years.",
    "Capitalized Interest Cap: The amount of unpaid interest that may be capitalized is limited to 10 percent of the original loan principal balance at the time the borrower entered the PAYE Plan.",
    "Ability to Exclude Spouse Income: Payment is based on the combined income and loan debt when filing a joint federal income tax return. Only a borrower's income is considered if when filing a separate return from their spouse.",
  ],
  CONS: [
    "Parent Borrowers Don't Qualify: Parent borrowers don't qualify for this plan.",
    "FFEL Loans Don't Qualify: Borrowers with FFEL loans need to do a Direct Consolidation in order to qualify.",
  ],
};

const REPAYE_CONTENT = {
  TITLE: "What is Revised Pay As You Earn (REPAYE)",
  SUMMARY:
    "Monthly payments are 10% of discretionary income and any outstanding balance is forgiven after 20 years of qualifying repayment (25 years for graduate / professional borrowers).",
  PROS: [
    "Strongest Interest Subsidy: If the monthly payment doesn’t cover the full amount of interest that accrues, the government pays the full amount of the difference on subsidized loans for the first three years, and half of the difference after that, as well as half of the difference on unsubsidized loans during all periods.",
  ],
  CONS: [
    "Spouse Income Always Included: For married borrowers, their spouse's AGI will always be included in monthly payment calculation regardless of whether they file taxes separately.",
    "No Monthly Payment Cap: As AGI increases, payments continue to increase without any ceiling.",
    "Parent Borrowers Don't Qualify: Parent borrowers don't qualify for this plan.",
    "FFEL Loans Don't Qualify: Borrowers with FFEL loans need to do a Direct Consolidation in order to qualify.",
  ],
};

const ICR_CONTENT = {
  TITLE: "What is Income-Contingent Repayment (ICR)",
  SUMMARY:
    "Monthly payments are either 20% of discretionary income or a fixed, 12-year payment. Any outstanding balance is forgiven after 25 years of qualifying repayment",
  PROS: [
    "Parent Borrowers Qualify: This is the only income-driven repayment option available for parent borrowers.",
    "Ability to Exclude Spouse Income: Payment is based on the combined income and loan debt when filing a joint federal income tax return. Only a borrower's income is considered if when filing a separate return from their spouse.",
  ],
  CONS: [
    "No Interest Subsidy: No interest benefit is offered for loans that accrue outstanding interest.",
    "Interest Capitalizes Annually: Accrued but unpaid interest is capitalized annually until the capitalized interest reaches 10% or more of the principal balance when the loan entered repayment.",
  ],
};

export const IDR_PLANS_CONTENT_MAP = {
  Standard: STANDARD_CONTENT,
  "New IBR": NEW_IBR_CONTENT,
  IBR: IBR_CONTENT,
  PAYE: PAYE_CONTENT,
  REPAYE: REPAYE_CONTENT,
  ICR: ICR_CONTENT,
};
