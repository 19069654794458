import helpers from "../../services/helpers";

/**
 * If challenge:
 *   has not started - retuns how many days before start
 *   starts today - returns starts today
 *   started already - returns how many days before challenge is over
 *
 * @param {*} startDate
 * @param {*} endDate
 * @returns string to show for "days left"
 */
export const getDaysLeftString = (startDate, endDate) => {
  const today = Date.now();
  const daysToStartLeft = helpers.getDaysDiff(today, startDate);
  const daysToEndLeft = helpers.getDaysDiff(today, endDate);

  // get string for days left of challenge etc
  if (daysToStartLeft === 1) {
    return "Starts tomorrow";
  } else if (daysToStartLeft > 0) {
    return `Starts in ${daysToStartLeft} days`;
  } else if (daysToStartLeft === 0) {
    return "Starts today";
  } else if (daysToEndLeft === 1) {
    return "Ends tomorrow";
  } else if (daysToEndLeft === 0) {
    return "Ends today";
  } else if (daysToEndLeft > 0) {
    return `Ends in ${daysToEndLeft} days`;
  } else if (daysToEndLeft < 0) {
    // means it already ended
    // todo: in the future show date of when it ended
    return "Ended";
  }

  return "";
};

export const getProgressPercent = (goalProgress, goalAmount) => {
  // don't divide by 0
  if (!goalProgress || !goalAmount) {
    return 0;
  }

  return Math.round((goalProgress / goalAmount) * 100);
};

export const getProgressString = (hasStarted, hasEnded, goalRemaining) => {
  if (!hasStarted) {
    return "Not started";
  }

  if (hasEnded) {
    if (goalRemaining > 0) {
      return "Ended";
    } else if (goalRemaining <= 0) {
      return "You met your goal!";
    }
  }

  // in progress
  if (goalRemaining != null) {
    if (goalRemaining > 0) {
      return `${helpers.currencyFormat(goalRemaining)}  to go`;
    } else if (goalRemaining <= 0) {
      return "You've met your goal!";
    }
  }

  return "";
};
