import React from "react";

import helpers from "../../services/helpers";
import IdrPlansDropdown from "../Buttons/IdrPlansDropdown";

const ComparePlansTable = ({
  current,
  optionOne,
  optionTwo,
  setOptionOne,
  setOptionTwo,
}) => {
  const baseCaseTitle = "Your current plan";
  const comparisonTitleOne = "Option 1";
  const comparisonTitleTwo = "Option 2";

  const headerClassName = "is-uppercase sw-is-grey-800 sw-is-x-small";

  const comparisonAmountFields = [
    { name: "First monthly payment", key: "first_monthly_payment" },
    { name: "Last monthly payment", key: "last_monthly_payment" },
    { name: "Total amount paid", key: "total_paid" },
    { name: "Total cost", key: "total_cost" },
    { name: "Projected forgiveness", key: "projected_forgiveness" },
  ];

  const comparisonPlainFields = [
    { name: "Number of payments", key: "number_of_payments" },
  ];

  // check if have any eligible loans
  const optionOneHasEligibleLoans = optionOne["has_eligible_loans"];
  const optionTwoHasEligibleLoans = optionTwo["has_eligible_loans"];

  return (
    <>
      <table className="table is-hoverable is-fullwidth has-text-left fs-block">
        <thead>
          <tr>
            <th></th>
            <th className={headerClassName}>{baseCaseTitle}</th>
            <th className={headerClassName}>{comparisonTitleOne}</th>
            <th className={headerClassName}>{comparisonTitleTwo}</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <th className={headerClassName}>Terms</th>
            <th></th>
            <th>
              <IdrPlansDropdown
                selectedOption={optionOne["term"]}
                setOption={setOptionOne}
              ></IdrPlansDropdown>
            </th>
            <th>
              <IdrPlansDropdown
                selectedOption={optionTwo["term"]}
                setOption={setOptionTwo}
              ></IdrPlansDropdown>
            </th>
          </tr>

          {comparisonAmountFields.map((field, index) => {
            const valueCurr = current[field.key];
            const valueOne = optionOne[field.key];
            const valueTwo = optionTwo[field.key];

            return (
              <tr key={index}>
                <th className={headerClassName}>{field.name}</th>
                <td>{helpers.currencyFormat(valueCurr, 2)}</td>
                <td>
                  {optionOneHasEligibleLoans
                    ? helpers.currencyFormat(valueOne, 2)
                    : "Not eligible"}
                </td>
                <td>
                  {optionTwoHasEligibleLoans
                    ? helpers.currencyFormat(valueTwo, 2)
                    : "Not eligible"}
                </td>
              </tr>
            );
          })}
          {comparisonPlainFields.map((field, index) => {
            const valueCurr = current[field.key];
            const valueOne = optionOne[field.key];
            const valueTwo = optionTwo[field.key];

            return (
              <tr key={index}>
                <th className={headerClassName}>{field.name}</th>
                <td>{valueCurr}</td>
                <td>{optionOneHasEligibleLoans ? valueOne : "Not eligible"}</td>
                <td>{optionTwoHasEligibleLoans ? valueTwo : "Not eligible"}</td>
              </tr>
            );
          })}

          {/* {(!optionOneHasEligibleLoans || !optionTwoHasEligibleLoans) && (
            <tr>
              <th className={headerClassName}>Ineligible Reasons</th>
              <td></td>
              <td>{optionOne["ineligible_reasons"]}</td>
              <td>{optionTwo["ineligible_reasons"]}</td>
            </tr>
          )} */}
        </tbody>
      </table>
    </>
  );
};

export default ComparePlansTable;
