import React from "react";

const SbImage = props => {
  const blok = props.blok;
  const finalUrl = blok.image;

  return (
    <div>
      <img src={finalUrl} className={blok.class} alt={blok.alt} />
    </div>
  );
};

export default SbImage;
