import React from "react";
import ExpandableMessageBox from "./ExpandableMessageBox";

export const MakePaymentsConsiderations = (props) => {
  const messageContent = (
    <div>
      <p>
        The federal government is offering COVID-19 relief on your student loans
        in case you need to take advantage of it. Make sure that you have a
        solid financial foundation before you start paying off your student
        loans. This can include:
      </p>
      <ul>
        <li>Having an emergency fund of 3-6 months</li>
        <li>Paying off any high interest personal debt</li>
        <li>Any other obligations (e.g., family)</li>
      </ul>
    </div>
  );

  //   <p>
  //   You can make your payments in several ways depending on your preference.
  //   You can pay off your highest interest student loan first, your smallest
  //   loan amount, or save up the monthly payments and pay it off right before
  //   your interest returns to normal (this will give you a bigger emergency
  //   fund).
  // </p>

  return (
    <ExpandableMessageBox
      text="What to consider before making payments"
      content={messageContent}
    ></ExpandableMessageBox>
  );
};

export const RefiConsiderations = (props) => {
  const messageContent = (
    <div>
      <p>
        You may want to refinance today if your loan balance and interest rate
        is high and you are worried about rates increasing in the future.
        <br />
        <br />
        However, refinancing will require you to move to a private lender. This
        means you will no longer be eligible for 0% interest rates and the
        payment pause for qualifying federal loans. Interest resumes on Sept. 1,
        and payments are due in October. A new federal program is being rolled
        out, we recommend you explore your options with the SAVE Plan before
        deciding to refinance.
      </p>
    </div>
  );

  return (
    <ExpandableMessageBox
      text="I have federal loans, should I refinance?"
      content={messageContent}
    ></ExpandableMessageBox>
  );
};
