import { AUTH_ACTIONS } from "./authReducer";

const initState = {
    orgName: null,
};

const ACTION_PREFIX = "ORG/";

export const ORG_ACTIONS = {
  SET_ORG: ACTION_PREFIX + "DASHBOARD",
};

const payitoffReducer = (state = initState, action) => {
  switch (action.type) {
    case ORG_ACTIONS.SET_ORG:
      return {
        ...state,
        orgName: action.orgName,
      };
    case AUTH_ACTIONS.SIGNOUT:
      return initState; //Always return the initial state
    default:
      return state;
  }
};

export default payitoffReducer;
