import React from "react";

import ChallengeCard from "./ChallengeCard";

const ChallengeSection = ({ challengeList, title, joinFn }) => {
  if (challengeList && challengeList.length < 1) {
    return <div></div>;
  }

  return (
    <div>
      {challengeList && (
        <div className="sw-padding--bottom-lg">
          <p className="has-text-weight-semibold is-uppercase sw-padding--bottom-md">
            {title}
          </p>
          {challengeList.map((challengeObj, index) => {
            return (
              <ChallengeCard
                key={index}
                challengeObj={challengeObj}
                joinFn={joinFn}
              ></ChallengeCard>
            );
          })}
        </div>
      )}
    </div>
  );
};

const ChallengesSections = ({ challenges, joinFn }) => {
  const available = challenges.available;
  const completed = challenges.completed;
  const past = challenges.past;
  const yourChallenges = challenges.your_challenges;

  return (
    <div>
      <ChallengeSection
        challengeList={yourChallenges}
        title="Your challenges"
        joinFn={joinFn}
      />
      <ChallengeSection
        challengeList={available}
        title="Available to join"
        joinFn={joinFn}
      />
      <ChallengeSection
        challengeList={completed}
        title="Completed"
        joinFn={joinFn}
      />
      <ChallengeSection challengeList={past} title="Past" joinFn={joinFn} />
    </div>
  );
};

export default ChallengesSections;
