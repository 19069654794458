import React, { useState } from "react";

import { AccountBoxWrapper } from "./AccountBox";
import { ToggleCircleButton } from "../Buttons/CircleButtons";
import helpers from "../../services/helpers";
import { SORT_BY_VALUES } from "../../constants/constants";

const AccountsContainerBox = (props) => {
  let {
    accounts,
    manualAccounts,
    title,
    totalAmount,
    isoCurrencyCode,
    displayImg,
    isExpandedInit,
    sortBy, // optional
  } = props;
  const [hideAccounts, setHideAccounts] = useState(!isExpandedInit);

  const toggleButton = () => {
    setHideAccounts(!hideAccounts);
  };

  // default, sort by name
  let sortFunction = (a, b) => (a.display_name > b.display_name ? 1 : -1);
  if (sortBy === SORT_BY_VALUES.interest) {
    sortFunction = (a, b) => {
      if (a.interest_rate < b.interest_rate) return 1;
      if (a.interest_rate > b.interest_rate) return -1;
      else return a.display_name > b.display_name ? 1 : -1;
    };
  } else if (sortBy === SORT_BY_VALUES.amount) {
    sortFunction = (a, b) => (Math.abs(a.total) > Math.abs(b.total) ? 1 : -1);
  }

  // copy your state.data to a new array and sort it by appropriate function
  // and then map
  const sortedAccounts = []
    .concat(accounts)
    .concat(manualAccounts)
    .sort(sortFunction)
    .map((item, i) => (
      <AccountBoxWrapper
        key={`${item.account_id}-${item.account_type}`}
        accountObj={item}
        className="sw-account-box"
        showFullDetails={true}
      ></AccountBoxWrapper>
    ));

  return (
    <div className="sw-container-box">
      <div className="level is-mobile">
        {/* <!-- Left side --> */}
        <div className="level-left">
          <div className="level-item">
            {displayImg}
            <p className="sw-padding--left-md sw-section-header">{title}</p>
          </div>
        </div>

        {/* <!-- Right side --> */}
        <div className="level-right">
          <p className="sw-section-header sw-padding--right-md fs-block">
            {helpers.currencyFormat(totalAmount, 0, isoCurrencyCode)}
          </p>
          <ToggleCircleButton
            onToggle={toggleButton}
            isExpandedInit={isExpandedInit}
          ></ToggleCircleButton>
        </div>
      </div>

      {!hideAccounts && sortedAccounts}
    </div>
  );
};

export default AccountsContainerBox;
