import React from "react";
import { Link } from "react-router-dom";

import CourseBlogPreview from "../Blog/CourseBlogPreview";
import ContentMarkdown from "../Markdown/ContentMarkdown";
import { useCourseRoutes } from "./useCourseRouting";

const StepsSection = ({ steps }) => {
  return (
    <div className="sw-padding--top-lg">
      <h2 className="subtitle">Steps</h2>
      <ContentMarkdown>{steps}</ContentMarkdown>
    </div>
  );
};

const BlogSection = ({ blog }) => {
  return (
    <div className="sw-padding--top-lg">
      <h2 className="subtitle">Blog</h2>
      <CourseBlogPreview blog={blog}></CourseBlogPreview>
    </div>
  );
};

const TaskTypeContent = ({ course, task, onCompleteTask }) => {
  const { getPollRoute } = useCourseRoutes();

  const taskContent = task.content;
  const isComplete = task.progress.is_completed ? true : false;
  const hasPoll = task.content.poll ? true : false;

  return (
    <div className="box">
      <div className="sw-padding--top-lg sw-padding--bottom-md">
        <div className="level columns">
          <h1 className="title column">{taskContent.title}</h1>

          {hasPoll && (
            <Link
              className="help column is-narrow has-text-weight-normal"
              to={getPollRoute(course, task, true)}
            >
              View poll
            </Link>
          )}
        </div>
        <ContentMarkdown>{taskContent.subtitle}</ContentMarkdown>

        {taskContent.steps && (
          <StepsSection steps={taskContent.steps}></StepsSection>
        )}

        {taskContent.blog && (
          <BlogSection blog={taskContent.blog}></BlogSection>
        )}

        <div className="sw-padding--top-lg has-text-centered">
          <button
            className={"button is-primary"}
            disabled={isComplete ? true : false}
            onClick={onCompleteTask}
          >
            {isComplete ? "Completed" : "Mark complete"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TaskTypeContent;
