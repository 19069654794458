import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { EVENTS, trackEvent } from "../../store/actions/analyticsActions";
import { getPricingPlans } from "../../store/actions/premiumActions";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import PremiumPricesSection from "../../components/Premium/PremiumPricesSection";

const PremiumPricingPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(trackEvent(EVENTS.PREMIUM_PRICING_VIEWED));
  }, [dispatch]);

  useEffect(() => {
    // get pricing info
    dispatch(getPricingPlans());
  }, [dispatch]);

  const { currentUser, hasPremium } = useSelector((state) => {
    return state.auth;
  });

  const { prices, isLoading } = useSelector((state) => {
    return state.premium;
  });

  if (isLoading) {
    return (
      <div className="sw-flex-layout--column-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="sw-flex-layout--column-center">
      <div className="sw-container--fixed-width sw-is-wide has-text-centered">
        <h1 className="title is-size-2">Master Your Finances</h1>
        <h2 className="subtitle has-text-weight-normal">
          Unlock personalized strategies to conquer debt, build wealth, and gain
          expert support with{" "}
          <a href="https://premium.snowballwealth.com/">Snowball Premium</a>{" "}
        </h2>
      </div>

      {prices && (
        <div className="sw-padding--top-xl">
          <PremiumPricesSection
            priceList={[{ ...prices.digital_monthly, className: "is-pro" }]}
            // priceList={[
            //   { ...prices.starter_monthly, className: "is-pro" },
            //   { ...prices.wealth_builder_monthly, className: "is-plus" },
            // ]}
            currentPlan={hasPremium ? currentUser.subscription_plan : ""}
            learnMoreLink="https://premium.snowballwealth.com/"
          />
        </div>
      )}
    </div>
  );
};

export default PremiumPricingPage;
