import React, { useCallback, useEffect } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";

import { BackIcon } from "../Buttons/BackButtons";

import { toggleIntercomVisibility } from "../../store/actions/analyticsActions";

const Modal = ({
  isOpen,
  onClose,
  onBack,
  title,
  children,
  footer,
  hideHeader,
  toggleIntercomVisibilityFn,
}) => {
  const closeOnEscapeKeyDown = useCallback(
    (e) => {
      if ((e.charCode || e.keyCode) === 27) {
        onClose();
      }
    },
    [onClose]
  );

  // this handles hiding the intercom chat when the modal is open
  useEffect(() => {
    toggleIntercomVisibilityFn(isOpen);

    return function cleanup() {
      toggleIntercomVisibilityFn(false);
    };
  }, [toggleIntercomVisibilityFn, isOpen]);

  // cleanup - make sure to remove event listener
  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, [closeOnEscapeKeyDown]);

  let modalClassName = "modal";
  if (isOpen) {
    modalClassName += " is-active";
  }

  let bodyClassName = "modal-card-body";
  if (!footer) {
    bodyClassName += " sw-modal-card-body--no-footer";
  }

  if (hideHeader) {
    bodyClassName += " sw-modal-card-body--no-header";
  }

  return ReactDOM.createPortal(
    <div className="is-pulled-right">
      <div className={modalClassName}>
        <div className="modal-background" onClick={onClose}></div>

        <div className="modal-card">
          {!hideHeader && (
            <header className="modal-card-head">
              {title && <p className="modal-card-title">{title}</p>}
              {!title && onBack && (
                <div className="modal-card-title">
                  <button
                    className="button is-white"
                    aria-label="back"
                    onClick={onBack}
                  >
                    <BackIcon></BackIcon>
                    <span className="sw-padding--left-sm">Back</span>
                  </button>
                </div>
              )}

              <button
                className="button is-white"
                aria-label="close"
                onClick={onClose}
              >
                <span className="icon is-small">
                  <i className="fas fa-times"></i>
                </span>
              </button>
            </header>
          )}

          <section className={bodyClassName}>{children}</section>

          {footer && <footer className="modal-card-foot">{footer}</footer>}
        </div>

        <button
          onClick={onClose}
          className="modal-close is-large"
          aria-label="close"
        ></button>
      </div>
    </div>,
    document.getElementById("root")
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleIntercomVisibilityFn: (hideLauncher) =>
      dispatch(toggleIntercomVisibility(hideLauncher)),
  };
};

export default connect(null, mapDispatchToProps)(Modal);
