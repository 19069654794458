import { ADMIN_ACTIONS } from "../reducers/adminReducer";
import { apiGet, apiPost } from "./apiActions";
import { setError } from "./errorActions";

export const getUsers = (fields) => {
  return (dispatch, getState) => {
    const endpoint = "/api/admin/users";
    const data = {
      user_id: fields.user_id,
      email: fields.email,
      display_name: fields.display_name,
    };

    dispatch({ type: ADMIN_ACTIONS.GET_USERS });

    const onSuccess = (res) => {
      dispatch({
        type: ADMIN_ACTIONS.GET_USERS_SUCCESS,
        res: res,
      });
    };

    const onError = (err) => {
      setError(err);
      dispatch({ type: "ADMIN_ERROR", err });
    };

    dispatch(apiPost(endpoint, data, onSuccess, onError));
  };
};

export const getUserStats = () => {
  return (dispatch, getState) => {
    const endpoint = "/api/admin/users/stats";
    dispatch({ type: ADMIN_ACTIONS.GET_STATS });

    const onSuccess = (res) => {
      dispatch({
        type: ADMIN_ACTIONS.GET_STATS_SUCCESS,
        res: res,
      });
    };

    const onError = (err) => {
      setError(err);
      dispatch({ type: "ADMIN_ERROR", err });
    };

    dispatch(apiGet(endpoint, onSuccess, onError));
  };
};

export const getNetWorthForUser = (userId) => {
  return (dispatch, getState) => {
    const endpoint = `/api/admin_coach/net_worth/${userId}`;

    const onSuccess = (res) => {
      dispatch({
        type: "ADMIN_GET_USER_LOANS",
        payload: { res: res, userId: userId },
      });
    };

    const onError = (err) => {
      dispatch({ type: "ADMIN_ERROR", err });
    };

    dispatch(apiGet(endpoint, onSuccess, onError));
  };
};

export const updateNetWorthItemsForUser = (userId) => {
  return (dispatch, getState) => {
    const endpoint = `/api/admin_coach/net_worth/${userId}`;

    dispatch({ type: ADMIN_ACTIONS.UDPATE_USER_LOANS });
    const onSuccess = (res) => {
      dispatch({
        type: ADMIN_ACTIONS.UDPATE_USER_LOANS_SUCCESS,
        payload: { res: res, userId: userId },
      });
    };

    const onError = (err) => {
      dispatch({ type: ADMIN_ACTIONS.UDPATE_USER_LOANS_ERROR, err });
    };

    dispatch(apiPost(endpoint, null, onSuccess, onError));
  };
};

export const getAccountItemsForUser = (userId) => {
  return (dispatch, getState) => {
    const endpoint = `/api/admin/liabilities/institutions/${userId}`;
    dispatch({ type: ADMIN_ACTIONS.GET_USER_ITEMS });

    const onSuccess = (res) => {
      dispatch({
        type: ADMIN_ACTIONS.GET_USER_ITEMS_SUCCESS,
        payload: { res: res, userId: userId },
      });
    };

    const onError = (err) => {
      dispatch({ type: ADMIN_ACTIONS.GET_USER_ITEMS_ERROR, err });
    };

    dispatch(apiGet(endpoint, onSuccess, onError));
  };
};

export const updateAccountItemsForUser = (userId) => {
  return (dispatch, getState) => {
    const endpoint = `/api/admin/liabilities/institutions/${userId}`;
    dispatch({ type: ADMIN_ACTIONS.UPDATE_USER_ITEMS });

    const onSuccess = (res) => {
      dispatch({
        type: ADMIN_ACTIONS.UPDATE_USER_ITEMS_SUCCESS,
        payload: { res: res, userId: userId },
      });
    };

    const onError = (err) => {
      dispatch({ type: ADMIN_ACTIONS.UPDATE_USER_ITEMS_ERROR, err });
    };

    dispatch(apiPost(endpoint, null, onSuccess, onError));
  };
};

export const getProfileInforForUser = (userId) => {
  return (dispatch, getState) => {
    dispatch({ type: ADMIN_ACTIONS.GET_USER_PROFILE });

    const endpoint = `/api/admin/users/${userId}/profile`;

    const onSuccess = (res) => {
      dispatch({
        type: ADMIN_ACTIONS.GET_USER_PROFILE_SUCCESS,
        payload: { res: res, userId: userId },
      });
    };

    const onError = (err) => {
      dispatch({ type: "ADMIN_ERROR", err });
    };

    dispatch(apiGet(endpoint, onSuccess, onError));
  };
};

export const getBadgesForUser = (userId) => {
  return (dispatch, getState) => {
    dispatch({ type: ADMIN_ACTIONS.GET_USER_PROFILE });

    const endpoint = `/api/admin/users/${userId}/badges`;

    const onSuccess = (res) => {
      dispatch({
        type: ADMIN_ACTIONS.GET_USER_BADGES_SUCCESS,
        payload: { res: res, userId: userId },
      });
    };

    const onError = (err) => {
      dispatch({ type: "ADMIN_ERROR", err });
    };

    dispatch(apiGet(endpoint, onSuccess, onError));
  };
};

export const calculateLoanPayoff = (balance, interest, payment, maxPayment) => {
  return (dispatch, getState) => {
    const endpoint = "/api/admin/calculator";
    const data = {
      balance: balance,
      interest: interest,
      payment: payment,
    };

    if (maxPayment) {
      data["max_payment"] = maxPayment;
    }

    const onSuccess = (res) => {
      dispatch({ type: "CALCULATION_UPDATE", res });
    };

    const onError = (err) => {
      console.error(err);
      dispatch({ type: "ADMIN_ERROR", err });
    };

    dispatch(apiPost(endpoint, data, onSuccess, onError));
  };
};

export const deletePlaidItemForUser = (userId, itemId) => {
  return (dispatch, getState) => {
    const endpoint = "/api/admin/liabilities/institution/delete";
    const data = {
      user_id: userId,
      item_id: itemId,
    };
    dispatch({ type: ADMIN_ACTIONS.DELETE_USER_ITEM });

    const onSuccess = (res) => {
      dispatch({ type: ADMIN_ACTIONS.DELETE_USER_ITEM_SUCCESS, res });
      dispatch(getAccountItemsForUser(userId));
    };

    const onError = (err) => {
      console.error(err);
      dispatch({ type: ADMIN_ACTIONS.DELETE_USER_ITEM_ERROR, err });
    };

    dispatch(apiPost(endpoint, data, onSuccess, onError));
  };
};

export const updateUserData = (userId, newEmail, isVerified) => {
  return (dispatch, getState) => {
    const endpoint = "/api/admin/users/update";
    const data = {
      user_id: userId,
    };

    if (newEmail) {
      data["new_email"] = newEmail;
    }

    if (isVerified !== null || isVerified !== undefined) {
      data["is_verified"] = isVerified;
    }
    // dispatch({ type: ADMIN_ACTIONS.DELETE_USER });

    const onSuccess = (res) => {
      // dispatch({ type: ADMIN_ACTIONS.DELETE_USER_SUCCESS, res });
    };

    const onError = (err) => {
      // console.error(err);
      // dispatch({ type: ADMIN_ACTIONS.DELETE_USER_ERROR, err });
    };

    dispatch(apiPost(endpoint, data, onSuccess, onError));
  };
};

export const blockUser = (userId, isBlocked) => {
  return (dispatch, getState) => {
    const endpoint = "/api/admin/feed/block_user";
    const data = {
      user_id: userId,
      to_unblock: !isBlocked,
    };

    const onSuccess = (res) => {};

    const onError = (err) => {};

    dispatch(apiPost(endpoint, data, onSuccess, onError));
  };
};

export const deleteUser = (userId) => {
  return (dispatch, getState) => {
    const endpoint = "/api/admin/users/delete";
    const data = {
      user_id: userId,
    };
    dispatch({ type: ADMIN_ACTIONS.DELETE_USER });

    const onSuccess = (res) => {
      dispatch({ type: ADMIN_ACTIONS.DELETE_USER_SUCCESS, res });
    };

    const onError = (err) => {
      console.error(err);
      dispatch({ type: ADMIN_ACTIONS.DELETE_USER_ERROR, err });
    };

    dispatch(apiPost(endpoint, data, onSuccess, onError));
  };
};

/**
 * UPDATES ALL LOANS IN DATABASE -- MIGHT TAKE A FEW SECONDS TO COMPLETE
 */
export const updateAllPlaidItems = () => {
  return (dispatch, getState) => {
    const endpoint = "/api/admin/liabilities/update";
    const data = {};
    dispatch({ type: ADMIN_ACTIONS.UPDATE_ALL_LOANS });

    const onSuccess = (res) => {
      dispatch({ type: ADMIN_ACTIONS.UPDATE_ALL_LOANS_SUCCESS, res });
    };

    const onError = (err) => {
      console.error(err);
      dispatch({ type: ADMIN_ACTIONS.ERROR, err });
    };

    dispatch(apiPost(endpoint, data, onSuccess, onError));
  };
};

export const asyncGetItemInfo = (itemId) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = `/api/admin/liabilities/item_diff/${itemId}`;

      // dispatch({ type: NET_WORTH_ACTIONS.CREATE_LINK_TOKEN });

      const onSuccess = (res) => {
        // dispatch({ type: NET_WORTH_ACTIONS.CREATE_LINK_TOKEN_SUCCESS, res });
        resolve(res);
      };

      const onError = (err) => {
        // dispatch({
        //   type: NET_WORTH_ACTIONS.CREATE_LINK_TOKEN_ERROR,
        // });
        // dispatch(setError(err));
        reject(err);
      };

      dispatch(apiGet(endpoint, onSuccess, onError));
    });
  };
};

export const asyncAdminCoachGetFinancialPlan = (userId) => {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const endpoint = `/api/admin_coach/financial_plan/${userId}`;

      const onSuccess = (res) => {
        resolve(res);
      };

      const onError = (err) => {
        dispatch(setError(err));
        reject(err);
      };

      dispatch(apiGet(endpoint, onSuccess, onError));
    });
  };
};
