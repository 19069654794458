import React from "react";
import { Link } from "react-router-dom";

const backIcon = <i className="fas fa-chevron-left" aria-hidden="true"></i>;

export const BackIcon = () => {
  return <span className="icon is-small sw-padding--right-md">{backIcon}</span>;
};

export const BackLink = ({ routeLink, text }) => {
  return (
    <Link className="level level-left is-mobile" to={routeLink}>
      <BackIcon></BackIcon> {text}
    </Link>
  );
};

export const BackButton = ({ onBack, text }) => {
  return (
    <button
      className="button sw-link-button level level-left is-mobile"
      onClick={onBack}
    >
      <BackIcon></BackIcon> {text}
    </button>
  );
};
