import React from "react";

import {
  StudentLoanList,
  MortgageLoanList,
  CreditLoanList,
  OtherDebtsAccountsList,
} from "./AccountAccordionLists";

const DebtAccounts = ({ credit, mortgage, student, otherDebts, sortBy }) => {
  return (
    <React.Fragment>
      {student &&
        (student.plaid_accounts.length > 0 ||
          student.me_accounts.length > 0) && (
          <StudentLoanList
            accounts={student.plaid_accounts}
            manualAccounts={student.me_accounts}
            totalAmount={student.total}
            isoCurrencyCode={"USD"}
            sortBy={sortBy}
          />
        )}
      {credit &&
        (credit.plaid_accounts.length > 0 || credit.me_accounts.length > 0) && (
          <CreditLoanList
            accounts={credit.plaid_accounts}
            manualAccounts={credit.me_accounts}
            totalAmount={credit.total}
            isoCurrencyCode={"USD"}
            sortBy={sortBy}
          ></CreditLoanList>
        )}
      {mortgage &&
        (mortgage.plaid_accounts.length > 0 ||
          mortgage.me_accounts.length > 0) && (
          <MortgageLoanList
            accounts={mortgage.plaid_accounts}
            manualAccounts={mortgage.me_accounts}
            totalAmount={mortgage.total}
            isoCurrencyCode={"USD"}
            sortBy={sortBy}
          ></MortgageLoanList>
        )}
      {otherDebts &&
        (otherDebts.plaid_accounts.length > 0 ||
          otherDebts.me_accounts.length > 0) && (
          <OtherDebtsAccountsList
            accounts={otherDebts.plaid_accounts}
            manualAccounts={otherDebts.me_accounts}
            totalAmount={otherDebts.total}
            isoCurrencyCode={"USD"}
          ></OtherDebtsAccountsList>
        )}
      {/* {hasHiddenLoans && (
        <HiddenLoans paidLoans={props.paidOffLoans}></HiddenLoans>
      )} */}
    </React.Fragment>
  );
};

export default DebtAccounts;
