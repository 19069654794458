import { useSelector } from "react-redux";

import ROUTES from "../../constants/routes";
import { TASK_STEP_MAP } from "../../constants/constants";

const getCoursesRoute = (orgName) => {
  if (orgName) {
    return `/${orgName}${ROUTES.COURSES}`;
  }

  return ROUTES.COURSES;
};

const getCourseRoute = (orgName, course) => {
  const baseCoursesRoute = getCoursesRoute(orgName);
  return `${baseCoursesRoute}/${course.cohort.cohort_uuid}`;
};

const getTaskRoute = (orgName, course, task) => {
  const baseCoursesRoute = getCoursesRoute(orgName);
  return `${baseCoursesRoute}/${course.cohort.cohort_uuid}/${task.task.course_task_uuid}`;
};

const getVideoRoute = (orgName, course, task, fromTaskContent = false) => {
  let baseCourseVideoRoute = orgName
    ? `/${orgName}${ROUTES.COURSE_VIDEO}`
    : `${ROUTES.COURSE_VIDEO}`;

  let videoRoute = `${baseCourseVideoRoute}/${course.cohort.cohort_uuid}/${task.task.course_task_uuid}`;

  if (fromTaskContent) {
    videoRoute = `${videoRoute}?from_task_content=${fromTaskContent}`;
  }

  return videoRoute;
};

const getPollRoute = (orgName, course, task, fromTaskContent = false) => {
  let baseCoursePollRoute = orgName
    ? `/${orgName}${ROUTES.COURSE_POLL}`
    : ROUTES.COURSE_POLL;

  // add the course cohort and task
  let pollRoute = `${baseCoursePollRoute}/${course.cohort.cohort_uuid}/${task.task.course_task_uuid}`;

  if (fromTaskContent) {
    pollRoute = `${pollRoute}?from_task_content=${fromTaskContent}`;
  }

  return pollRoute;
};

const getNextTask = (orgName, course, task, step) => {
  const browseCoursesRoute = getCoursesRoute(orgName);
  const courseRoute = getCourseRoute(orgName, course);
  const taskRoute = getTaskRoute(orgName, course, task);
  const videoRoute = getVideoRoute(orgName, course, task);
  const pollRoute = getPollRoute(orgName, course, task);

  const videoUrl = task.content.video_url;
  const hasPoll = task.content.poll ? true : false;

  if (!course || !task) {
    // if no course or task, return browse courses route
    return browseCoursesRoute;
  }

  switch (step) {
    case TASK_STEP_MAP.Initial:
      if (task.progress.is_completed) {
        return taskRoute;
      }
      // Task in progress handling
      if (videoUrl) {
        return videoRoute;
      } else if (hasPoll) {
        return pollRoute;
      } else {
        return taskRoute;
      }
    case TASK_STEP_MAP.Video:
      if (hasPoll) {
        return pollRoute;
      } else {
        return taskRoute;
      }
    case TASK_STEP_MAP.Poll:
      return taskRoute;
    case TASK_STEP_MAP.Content:
      return courseRoute;
    default:
      return taskRoute;
  }
};

const getPreviousTask = (
  orgName,
  course,
  task,
  step,
  fromContent = false // Pass `fromContent` to `true` if navigating pushed from the TaskPage (TASK_STEP_MAP.Content)
) => {
  const browseCoursesRoute = getCoursesRoute(orgName);
  const courseRoute = getCourseRoute(orgName, course);
  const taskRoute = getTaskRoute(orgName, course, task);
  const videoRoute = getVideoRoute(orgName, course, task);
  const pollRoute = getPollRoute(orgName, course, task);

  const hasPoll = task.content.poll ? true : false;
  const isCompleted = task.progress.is_completed;
  const videoUrl = task.content.video_url;

  if (!course || !task) {
    // if no course or task, return browse courses route
    return browseCoursesRoute;
  }

  switch (step) {
    case TASK_STEP_MAP.Initial:
      return courseRoute;
    case TASK_STEP_MAP.Video:
      if (fromContent) {
        return taskRoute;
      } else {
        return courseRoute;
      }
    case TASK_STEP_MAP.Poll:
      if (fromContent) {
        return taskRoute;
      }
      if (videoUrl && !isCompleted) {
        return videoRoute;
      } else {
        return courseRoute;
      }
    case TASK_STEP_MAP.Content:
      if (videoUrl && !isCompleted) {
        return videoRoute;
      } else if (hasPoll && !isCompleted) {
        return pollRoute;
      } else {
        return courseRoute;
      }
    default:
      return courseRoute;
  }
};

export const useCourseRoutes = () => {
  const { orgName } = useSelector((state) => {
    return state.org;
  });

  return {
    getCoursesRoute: () => getCoursesRoute(orgName),
    getCourseRoute: (course) => getCourseRoute(orgName, course),
    getPollRoute: (course, task, fromTaskContent) =>
      getPollRoute(orgName, course, task, fromTaskContent),
    getVideoRoute: (course, task, fromTaskContent) =>
      getVideoRoute(orgName, course, task, fromTaskContent),
    getNextTask: (course, task, step) =>
      getNextTask(orgName, course, task, step),
    getPreviousTask: (course, task, step, fromContent) =>
      getPreviousTask(orgName, course, task, step, fromContent),
  };
};
