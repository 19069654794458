import React from "react";

import WhatTheyLookFor from "./WhatTheyLookFor";
import SpecificDetails from "./SpecificDetails";
import LenderDisclaimerMessage from "./LenderDisclaimerMessage";
import { BackButton } from "../Buttons/BackButtons";

import { trackEvent, EVENTS } from "../../store/actions/analyticsActions";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const LenderProfile = (props) => {
  let isPersonalDebtLender = true;
  const navigate = useNavigate();

  if (props.lender.full_slug) {
    isPersonalDebtLender =
      props.lender.full_slug.indexOf("personal-debt") !== -1;
  }

  return (
    <div>
      <div className="sw-padding--bottom-lg">
        <BackButton onBack={() => navigate(-1)} text="Back"></BackButton>
      </div>

      <div className="level">
        <h1 className="title level-item has-text-centered">
          {props.lender.content.icon && (
            <img
              width="64"
              height="64"
              src={props.lender.content.icon}
              alt="lender icon"
              className="sw-padding--right-md"
            ></img>
          )}
          {props.lender.name}
        </h1>
      </div>
      <p>{props.lender.content.summary}</p>
      <br />
      <ul>
        <li className="sw-profile-section-text">
          <strong>What we like about them:</strong>
          <br />
          {props.lender.content.what_we_like}
        </li>
        <li className="sw-profile-section-text">
          <strong>What they offer:</strong>
          <br />
          {props.lender.content.what_they_offer}
        </li>
        <li className="sw-profile-section-text">
          <strong>Need to know:</strong>
          <br />
          {props.lender.content.need_to_know}
        </li>
        <li className="sw-profile-section-text">
          <strong>Current rates:</strong>
          <br />
          {props.lender.content.apr_fixed_min ? (
            <span>
              • Fixed APR {props.lender.content.apr_fixed_min}% -{" "}
              {props.lender.content.apr_fixed_max}%
            </span>
          ) : (
            "• N/A"
          )}
          <br />
          {!isPersonalDebtLender && props.lender.content.apr_variable_max && (
            <span>
              • Variable APR {props.lender.content.apr_variable_min}% -{" "}
              {props.lender.content.apr_variable_max}%
            </span>
          )}
        </li>
      </ul>
      <br />
      <div className="sw-flex-layout--column-center">
        <a
          className="button is-medium is-primary"
          onClick={() =>
            props.trackEvent(EVENTS.CHECK_RATE_CLICKED, {
              lender: props.lender.slug,
              full_slug: props.lender.full_slug,
            })
          }
          href={
            props.lender.content.referral_link || props.lender.content.website
          }
        >
          Check out rate now
        </a>
      </div>
      <br />
      <br />
      <WhatTheyLookFor
        lender={props.lender}
        isPersonalDebt={isPersonalDebtLender}
      ></WhatTheyLookFor>
      <SpecificDetails
        lender={props.lender}
        isPersonalDebt={isPersonalDebtLender}
      ></SpecificDetails>
      <br />
      <br />
      <LenderDisclaimerMessage></LenderDisclaimerMessage>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackEvent: (event, properties) => dispatch(trackEvent(event, properties)),
  };
};

export default connect(null, mapDispatchToProps)(LenderProfile);
