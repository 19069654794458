import React, { useState, useEffect } from "react";

import Modal from "../Modal/Modal";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const FadeOutText = ({ text, onNext }) => {
  const [fadeProp, setFadeProp] = useState({ fade: "sw-fade-in" });
  useEffect(() => {
    if (fadeProp.fade === "sw-fade-in") {
      const timeout = setInterval(() => {
        setFadeProp({
          fade: "sw-fade-out",
        });
      }, 5000);
      return () => clearInterval(timeout);
    } else {
      const timeoutTwo = setInterval(() => {
        setFadeProp({
          fade: "sw-fade-in",
        });
        onNext();
      }, 1000);
      return () => clearInterval(timeoutTwo);
    }
  }, [fadeProp, onNext]);

  return <span className={fadeProp.fade}>{text}</span>;
};

const ROTATING_TEXT_ARRAY = [
  "Syncing accounts...",
  "Updating net worth...",
  "Updating your plan...",
  "This might take a few minutes...",
];

const AddingAccountLoadingModal = ({ onClose, isOpen }) => {
  const [rotatingText, setRotatingText] = useState(ROTATING_TEXT_ARRAY[0]);
  const [textIndex, setTextIndex] = useState(1);

  // rotates text
  const onNext = () => {
    setRotatingText(ROTATING_TEXT_ARRAY[textIndex]);
    // makes sure to only get an index within the array length
    // this will lead to the text rotating
    setTextIndex((textIndex + 1) % ROTATING_TEXT_ARRAY.length);
  };

  const textClasName =
    "modal-card-body__title sw-padding--top-lg sw-padding--bottom-xl";

  return (
    <Modal onClose={onClose} isOpen={isOpen} hideHeader={true}>
      <div className="sw-flex-layout--column-center">
        <p className={textClasName}>
          <FadeOutText text={rotatingText} onNext={onNext}></FadeOutText>
        </p>
        <LoadingSpinner size="medium"></LoadingSpinner>
      </div>
    </Modal>
  );
};

export default AddingAccountLoadingModal;
