import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { isLoaded } from "react-redux-firebase";

import Main from "./components/Main/Main";
import { onAuthChangeListener } from "./store/actions/authActions";

import "./App.scss";
import { trackPage } from "./store/actions/analyticsActions";

const App = ({ onAuthChangeListenerFn, trackPageFn, tokenReady }) => {
  // listen to auth changes
  useEffect(() => {
    const unsubscribeAuthChangeListener = onAuthChangeListenerFn();
    return function cleanup() {
      unsubscribeAuthChangeListener();
    };
  });

  // listen to route changes
  const location = useLocation();
  useEffect(() => {
    // runs on location, i.e. route, change
    trackPageFn();
  }, [trackPageFn, location]);

  // We rely on firebase to be loaded and token ready to render
  if (!isLoaded || !tokenReady) {
    return <div></div>;
  }

  return <Main />;
};

const mapStateToProps = (state, ownProps) => {
  return {
    tokenReady: state.auth.tokenReady,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuthChangeListenerFn: () => dispatch(onAuthChangeListener()),
    trackPageFn: () => dispatch(trackPage()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
