import React, { useCallback } from "react";
import DatePicker from "react-datepicker";

export const AdminFormSelectField = ({
  label,
  fields,
  fieldName,
  onChange,
  disabled,
  children,
}) => {
  return (
    <div className="field">
      {label && <label className="label has-text-weight-normal">{label}</label>}
      <div className="select fs-block field">
        <select
          id={fieldName}
          name={fieldName}
          value={fields[`${fieldName}`]}
          onChange={onChange}
          disabled={disabled}
        >
          {children}
        </select>
      </div>
    </div>
  );
};

export const AdminTextField = ({
  label,
  fields,
  fieldName,
  onChange,
  disabled,
}) => {
  return (
    <div className="field">
      {label && <label className="label has-text-weight-normal">{label}</label>}
      <div className="control">
        <input
          id={fieldName}
          name={fieldName}
          className="input is-small fs-block"
          type="text"
          maxLength={255}
          value={fields[`${fieldName}`]}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export const AdminDatePicker = ({
  label,
  fields,
  fieldName,
  onChange,
  showTimeInput,
  disabled,
  minDate,
}) => {
  const updateDate = useCallback(
    (id, date) => {
      onChange({
        target: {
          id: id,
          value: date,
        },
      });
    },
    [onChange]
  );

  return (
    <div className="field">
      {label && <label className="label has-text-weight-normal">{label}</label>}
      <div className="control">
        <DatePicker
          id={fieldName}
          name={fieldName}
          selected={fields[`${fieldName}`]}
          showTimeInput={showTimeInput}
          onChange={(date) => updateDate(fieldName, date)}
          minDate={minDate}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
